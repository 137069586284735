// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandButton, Flex } from '..';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { CancelTradeAction } from 'phoenix/redux/actions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { Order } from 'phoenix/redux/models';
import { CancelOrderModal } from '../Modals/CancelOrderModal/CancelOrderModal';
import { EditTradeModal } from '../Modals/EditTradeModal';
import { OrderRow, OrderRowSkeleton } from './OrderRow';
import { UniqueBy } from 'phoenix/util';
import { CulledCollapse } from '../CulledCollapse/CulledCollapse';
import { XS } from 'phoenix/xstream/XS';
import { useTradeTicketViewModel } from 'components/TradeTicket/Store/useTradeTicketViewModel';
import { TradeTicketViewModel } from 'components/TradeTicket/Store/TradeTicketViewModel';
import { GetMarketOpenStatusAction } from 'phoenix/redux/actions/MarketActions';
import animationData from 'phoenix/assets/animations/Files.json';
import { BlankView } from 'components/BlankView';
import { useText } from 'phoenix/hooks/UseText';

interface OrderListProps {
    orders: Order[];
    onCancelClick?: (order: Order) => void; // When the user indicates that they would like to cancel
    onCanceled?: (orderId: string, success: boolean) => void; // When the user actually submits an order cancelation
    loading?: boolean;
    unexpandedSize?: number;
    expandedSize?: number;
    allowExpand?: boolean;
    securityKnown?: boolean;
    autoExpandedOrder?: Order;
    onEditSuccess?: () => void;
    showBlankView?: boolean;
}

export const OrderList = React.memo((props: OrderListProps) => {
    const { orders, unexpandedSize, expandedSize, allowExpand, autoExpandedOrder } = props;
    const dispatch = useDispatch();
    const canceling = useSelector((s: GlobalState) => s.trading.cancel);
    const [cancelOrderId, setCancelOrderId] = useState<string | null>(null);
    const [editOrder, setEditOrder] = useState<Order>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const { setViewModel } = useTradeTicketViewModel<TradeTicketViewModel>();
    const blankText = useText((s) => s.blankViews);
    const expandButtonText = useText((s) => s.misc);

    useEffect(() => {
        GetMarketOpenStatusAction();
        return () => XS.stopNs(LiveDataNamespaces.OrderRow);
    }, []);

    const { shownOrders, extraOrders, hasAny, hasExtras } = useMemo(() => {
        const o = orders || [];
        if (!o.length) {
            return { shownOrders: [], extraOrders: [], extraSymbols: [], hasAny: false, hasExtras: false };
        }
        const sorted = UniqueBy(o, (x) => x.orderId).sort(
            (a, b) => new Date(b.placedDate || b.completedDate).getTime() - new Date(a.placedDate || a.completedDate).getTime()
        );
        const shownOrders = unexpandedSize ? sorted.slice(0, unexpandedSize) : sorted;
        const extraOrders = unexpandedSize ? sorted.slice(unexpandedSize, expandedSize) : sorted;
        const extraSymbols = UniqueBy(
            extraOrders.map((o) => o.symbol),
            (x) => x
        );
        return { shownOrders, extraOrders, extraSymbols, hasAny: true, hasExtras: !!extraOrders.length };
    }, [orders, unexpandedSize, expandedSize]);

    const handleCancelClick = (order: Order, event: MouseEvent<HTMLButtonElement>) => {
        if (props.onCancelClick) props.onCancelClick(order);
        if (event?.ctrlKey || event?.metaKey) dispatch(CancelTradeAction(order.orderId));
        else {
            setCancelOrderId(order.orderId);
        }
    };

    const handleCancelComplete = (orderId: string, success: boolean) => {
        if (props.onCanceled) props.onCanceled(orderId, success);
    };

    const handleEditOrderClick = useCallback(
        (order) => {
            setEditOrder(order);
            setViewModel({ modifyingOrder: true });
            setModalOpen(true);
        },
        [setViewModel]
    );

    const toggleModal = (nextOpenState: boolean) => {
        if (!nextOpenState) {
            setViewModel({ modifyingOrder: false });
        }
        setModalOpen(nextOpenState);
    };

    if (props.loading && orders.length === 0) {
        return (
            <Flex column className='order-list'>
                <OrderRowSkeleton />
                <OrderRowSkeleton />
                <OrderRowSkeleton />
            </Flex>
        );
    }

    return (
        <>
            {hasAny ? (
                <Flex column className='order-list'>
                    {shownOrders.map((order, key) => (
                        <OrderRow
                            isExpandedByDefault={autoExpandedOrder && autoExpandedOrder.orderId === order.orderId}
                            canceling={canceling?.loading && canceling?.data === order.orderId}
                            hideBottomBorder={key === shownOrders.length - 1 && !expanded}
                            key={order.orderId}
                            order={order}
                            securityKnown={props.securityKnown}
                            onDelete={(event) => handleCancelClick(order, event)}
                            onEdit={() => handleEditOrderClick(order)}
                        />
                    ))}
                    {allowExpand && hasExtras ? (
                        <Flex column>
                            <CulledCollapse eventTag='Order List' in={expanded}>
                                {extraOrders.map((order, key) => (
                                    <OrderRow
                                        isExpandedByDefault={autoExpandedOrder && autoExpandedOrder.orderId === order.orderId}
                                        canceling={canceling?.loading && canceling?.data === order.orderId}
                                        hideBottomBorder={key === shownOrders.length - 1}
                                        key={order.orderId}
                                        order={order}
                                        securityKnown={props.securityKnown}
                                        onDelete={(event) => handleCancelClick(order, event)}
                                        onEdit={() => handleEditOrderClick(order)}
                                    />
                                ))}
                            </CulledCollapse>
                            <ExpandButton expanded={expanded} contractedLabel={expandButtonText.showMore} onClick={() => setExpanded(!expanded)} />
                        </Flex>
                    ) : null}
                    <CancelOrderModal
                        open={!!cancelOrderId}
                        orderId={cancelOrderId}
                        toggleModal={(on: boolean) => setCancelOrderId(on ? cancelOrderId : null)}
                        onCancel={(success: boolean) => handleCancelComplete(cancelOrderId, success)}
                    />
                </Flex>
            ) : (
                props.showBlankView && <BlankView animationData={animationData} secondaryText={blankText.tradeHistory.secondary} text={blankText.tradeHistory.main} />
            )}
            <EditTradeModal onSuccess={props.onEditSuccess} isOpen={modalOpen} order={editOrder} toggleModal={toggleModal} />
        </>
    );
});
