// @ts-strict-ignore
import { Link, SvgIconProps, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, Link as ReactLink } from 'react-router-dom';
import { AlertsModal, Flex } from '../';
import { AccountShortcutsPopover } from 'components/Popovers/AccountShortcutsPopover';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { GetVariant } from 'phoenix/util/Variant';
import { ShiningWrapper } from '../ShiningWrapper';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useColors } from 'hooks/UseColors';
import { Routes } from 'util/Routes';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import * as Sentry from '@sentry/react';
import './Header.scss';
import { useActiveAndPendingAccounts } from 'phoenix/hooks/useActiveAndPendingAccounts';
import { GetShowFixedPlusNavLink } from 'phoenix/util';
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';

const HeaderNavLinksUnmemoized = () => {
    // Reenable if/when Premium is a thing again
    // const { permittedForAlerts } = useTier();
    const permittedForAlerts = true;

    // read that the user has at least one account that is sub 205
    const { fixedPlusEligibleAccounts } = useActiveAndPendingAccounts();
    const showFixedPlus = GetShowFixedPlusNavLink() || !!fixedPlusEligibleAccounts?.length

    const text = useText((s) => s.header);

    return <HeaderNavLinksPresentation {...{ showAlerts: permittedForAlerts, showFixedPlus, text }} />;
};

export const HeaderNavLinks = React.memo(HeaderNavLinksUnmemoized);

type NavLinkProps = {
    route?: string;
    label: string;
    unreadCount?: number;
    id?: string;
    premium?: boolean;
    linkStyle?: React.CSSProperties;
    eventTag?: string;
};

const NavLinkUnmemoized = (props: NavLinkProps) => {
    const colors = useColors();
    const location = useLocation();

    const handleClick = (e: { preventDefault: () => void; }) => {
        if (location.pathname === props.route)
            e.preventDefault()
    }

    return (
        <Flex column>
            <Link
                onClick={handleClick}
                className='stonex-header-nav-link'
                color={location.pathname === props.route ? 'primary' : 'textSecondary'}
                component={props.route ? ReactLink : undefined}
                to={props.route ? props.route : undefined}
                id={props.id}
                style={{ ...props.linkStyle, color: location.pathname === props.route ? colors.primaryItemColor : null }}
                underline='hover'
            >
                {props.premium ? (
                    <ShiningWrapper withLock>
                        <Typography className='stonex-header-nav-link-text' variant='h6'>
                            {props.label}
                        </Typography>
                    </ShiningWrapper>
                ) : (
                    <Typography className='stonex-header-nav-link-text' variant='h6'>
                        {props.label}
                    </Typography>
                )}
            </Link>
        </Flex>
    );
};

const NavLink = React.memo(NavLinkUnmemoized);

type KeyboardArrowProps = {
    isOpen: boolean;
} & SvgIconProps;

const KeyboardArrow = ({ isOpen, ...rest }: KeyboardArrowProps) => (isOpen ? <KeyboardArrowUp {...rest} /> : <KeyboardArrowDown {...rest} />);

type PortfolioLinkProps = Partial<NavLinkProps>;

const PortfolioLinkUnmemoized = (props: PortfolioLinkProps) => {
    const text = useText((s) => s.header);
    const [isOpen, setIsOpen] = useState(false);

    const numberOfAccounts = useSnexStore((s) => s.accounts.all?.data?.length);
    const onboardingV2Flag = useSnexStore((s) => s.featureFlags.byId[FeatureFlags.onboardingV2]);
    const hasAccount = onboardingV2Flag?.enabled || !!numberOfAccounts;

    return (
        <Flex row align='center' justify='center' style={{ position: 'relative', paddingBottom: 5, borderRadius: 5, marginRight: 5 }}>
            <NavLink {...props} eventTag='Summary' label={text.summary} linkStyle={{ padding: '0px', marginRight: 5 }} route={Routes.portfolio()} />
            {hasAccount && (
                <div
                    onClick={() => setIsOpen(true)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') setIsOpen(!isOpen);
                        if (e.key === 'Escape') setIsOpen(false);
                    }}
                    tabIndex={0}
                >
                    <KeyboardArrow className='account-shortcut-arrow' isOpen={isOpen} style={{ color: 'grey', fontSize: 22 }} />
                </div>
            )}
            <AccountShortcutsPopover open={isOpen} onClose={() => setIsOpen(false)} />
        </Flex>
    );
};

const PortfolioLink = React.memo(PortfolioLinkUnmemoized);

type HeaderNavLinksPresentationProps = {
    showAlerts: boolean;
    showFixedPlus: boolean;
    text: Snex1LanguagePack['header'];
};

const HeaderNavLinksPresentation = ({ showAlerts, text, showFixedPlus }: HeaderNavLinksPresentationProps): JSX.Element => {
    const [showAlertsModal, setShowAlertsModal] = useState(false);
    const oneProFlagEnabled = useFeatureFlag(FeatureFlags.OneProNetwork);
    const { canViewTradeHistory } = GetVariant();
    const colors = useColors()

    return (
        <Flex row align='flex-start'>
            <PortfolioLink />
            {/* Change div to PremiumContentClickable upon Premium being a thing again */}
            {showAlerts && (
                <div
                    onClick={() => {
                        setShowAlertsModal(true);
                        Sentry.captureMessage('Alerts Clicked', 'info');
                    }}
                // products={['sx1', 'mi+sx1']}
                >
                    <NavLink premium label={text.alerts} />
                </div>
            )}
            {
                showFixedPlus && (
                    <Flex column>
                        <Link

                            className='stonex-header-nav-link'
                            color={location.pathname === Routes.fixedPlus() ? 'primary' : 'textSecondary'}
                            component={ReactLink}
                            to={Routes.fixedPlus()}
                            id={'fixedPlusNavLink'}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: location.pathname === Routes.fixedPlus() ? colors.primaryItemColor : null }}
                            underline='hover'
                        >
                            <Typography className='stonex-header-nav-link-text' variant='h6'>
                                {text.fixedPlus}
                            </Typography>
                        </Link>
                    </Flex>
                )
            }
            <NavLink label={text.positions} eventTag='Positions' route={Routes.positions()} />
            {canViewTradeHistory && <NavLink label={text.tradeHistory} route={Routes.orders()} eventTag='Trade History Screen' />}
            {oneProFlagEnabled && <NavLink label={'One Pro Network'} route={Routes.oneProNetwork()} eventTag={'One Pro Network'} linkStyle={{letterSpacing: -.5}} /> }
            <NavLink label={text.banking} eventTag='Banking' route={Routes.funding()} /> 
            <AlertsModal isOpen={showAlertsModal} toggleModal={setShowAlertsModal} />
        </Flex>
    );
};
