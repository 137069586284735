// @ts-strict-ignore
import { ActionModal, Flex, LoadingSpinner } from "components";
import { useColors } from "hooks/UseColors";
import { FundingSource } from "phoenix/redux/models/Funding/FundingSource";
import { AppColorTheme } from "phoenix/theming/ColorVariants/AppColorTheme";
import React, { useMemo, useState } from "react";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CircularProgress, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import { FormatNumber, MoneyGreen } from "phoenix/util";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import { CheckCircle } from "@mui/icons-material";
import moment from "moment";
import { useFundingLimitsStore } from "phoenix/stores/FundingLimitsStore";
import { useFundingSourcesStore } from "phoenix/stores/FundingSourcesStore";
import { FundingHelpers } from "phoenix/util/Funding/v2/FundingHelpers";
import { ErrorLogger } from "phoenix/util/ErrorLogger";
import { SnexErrorFromError } from "phoenix/models/SnexError";
import { FundingErrors } from "phoenix/constants/FundingErrorsV2";
import { DoPlaid, PlaidEnvironment } from "util/PlaidHelpers";
import { GetConfig } from "phoenix/constants";
import { CreateTransferErrorResultResponse } from "phoenix/util/Funding/v2/FundingTransferHelpers";
import { useFundingTicketStore } from "./FundingTicketStore";
import { T } from "phoenix/assets/lang/T";
import { useText } from "phoenix/hooks/UseText";
import { SnexButton } from "components/SnexButton";

const sourceButtonStyle = (colors: AppColorTheme, selected?: boolean, isSandbox?: boolean): React.CSSProperties => 
    {
    return {
        border: `2px solid ${isSandbox? colors.green : colors.primaryItemColor}`,
        borderRadius: 16,
        height: 100,
        fontSize: '1.25rem',
        overflow: 'hidden',
        transition: '1s ease all',
        position: 'relative',
        gap: 16,
        padding: '8px 16px',
        boxSizing: 'border-box',
        color: selected? '#fff' : colors.generalTextColor,
        backgroundColor: selected? isSandbox? colors.green : colors.primaryItemColor : colors.greyBackgroundColor,
        opacity: .85
    }
}

export const HiddenBalance = ({visible, isSandbox, source}: {visible?: boolean, isSandbox?: boolean, source?: FundingSource}): JSX.Element => {
    const colors = useColors()
    const [showBalance, setShowBalance] = useState<boolean>(visible)
    const darkBackground = isSandbox? MoneyGreen : "#2c6cb4" // colors.primaryItemBackdropColor

    const toggleShowBalance = () => { if(!showBalance) {
        FundingHelpers.GetAvailableBalance(source?.fundingSourceId)
    }; setShowBalance(!showBalance) }

    return (
        <Flex row style={{gap: 4, fontSize: '1.25rem', marginTop: '4px', height: '28px'}} >
            <Flex column>$</Flex>
            <Flex row fullWidth style={{
                    textIndent: '8px', 
                    backgroundColor: darkBackground, 
                    color: '#fff', 
                    borderRadius: 16, 
                    border: `1px solid ${darkBackground}`}}
                    onClick={(e) => { e.stopPropagation(); toggleShowBalance()}}>
                <Flex column fullWidth={showBalance === true} style={{
                    width: showBalance? 'inherit' : 0,
                    transition: '.5s ease all'
                    }}>
                    {source?.balance && source?.balance > 0 ? FormatNumber.toMoneyOpt2(source?.balance, {signDisplay: 'never'}) : showBalance? 
                        <Skeleton animation='wave' style={{ marginLeft: '16px', width: '80%' }} /> : null }</Flex>
                    <Flex column fullWidth={showBalance !== true} justify={'center'} align={showBalance === true? 'center' : 'flex-start'} 
                        style={{
                            backgroundColor: showBalance? isSandbox? colors.green : colors.primaryItemColor : darkBackground, 
                            color: '#fff', 
                            borderRadius: 16, 
                            width: showBalance? 32 : 'inherit',
                            height: 'auto',
                            transition: '.5s ease all',
                            }} >
                                <Tooltip title={showBalance? 'Hide Balance' : 'Show Balance'} placement={'bottom'}>
                                {showBalance ? 
                                    <VisibilityOffIcon style={{width: 16, height: 'auto'}}/>  :
                                    <VisibilityIcon style={{width: 16, height: 'auto', marginLeft: '8px'}}/> 
                                }
                                </Tooltip>
                    </Flex>
            </Flex>
        </Flex>
    )
}



export const FundingTicketSourceButton = ({source, selected, onClick}: {source: FundingSource, selected?: boolean, onClick?: (fundingSourceId: string) => void}): JSX.Element => {
    const colors = useColors()
    
    const { isLoading: limitsLoading, limits: fundingLimits } = useFundingLimitsStore();
    const { isLoading: deletingSource } = useFundingSourcesStore();
    const [showFundingModal, setShowFundingModal] = useState<boolean>(false)
    const canVoid = fundingLimits?.canDelete;
    const nextVoid =fundingLimits?.nextDeleteAllowed;
  
    const isSandbox = source?.isSandbox === true
    const loaded = !limitsLoading && !deletingSource
    const deleteLabel = loaded? canVoid ? 'Delete funding source' :`You can delete this account on ${moment(nextVoid).format('MM/DD/YYYY')}.` : 'Loading limits'

    const handleDelete = async (e) => {
         e.stopPropagation();  
         setShowFundingModal(true)
    }

    return (
        <>
        <DeleteFundingModal onClose={() => setShowFundingModal(false)} show={showFundingModal} source={source}/>
        <Flex row center align={'center'} style={sourceButtonStyle(colors, selected, isSandbox)} onClick={(e) => { e.stopPropagation(); onClick(source.fundingSourceId)}}>
            {selected? 
                <Flex column style={{flex: 0, width: 48}}>{  <CheckCircle style={{ opacity: 0.95, color: MoneyGreen, backgroundColor: '#fff', fontSize: '38px', borderRadius: '38px' }} />}</Flex> :
                <Flex column style={{flex: 0, width: 40}}>{source?.logo? <img src={`data:image/png;base64,${source.logo}`} alt={`${source.name} logo`}/> : 
                <div style={{ backgroundColor: isSandbox? MoneyGreen : colors.primaryItemBackdropColor, width: '40px', height: '40px', borderRadius: '48px' }} /> }</Flex>
            }
           
            <Flex column style={{flex: 1, paddingRight: '32px'}}>
                <Flex column>{source.name}</Flex>
                <Flex row style={{fontSize: '.85rem', gap: 8}}>
                    <Flex column style={{opacity: '.5'}}>{source.subtype.toUpperCase()}</Flex>
                    <Flex column style={{opacity: '.8'}}>{source.mask}</Flex>
                </Flex>
                <HiddenBalance source={source} isSandbox={isSandbox}/>
            </Flex>
            <Tooltip title={deleteLabel} placement={'bottom'}>
            <IconButton 
                style={{
                    height: 32, 
                    width: 32, 
                    position: 'absolute', 
                    top: '50%', 
                    right: 8, 
                    color: selected? '#fff' : colors.generalTextColor,
                    transform: 'translate(0, -50%)', 
                    opacity: canVoid ? 1: .5 }} 
                    onClick={ !loaded || deletingSource || !canVoid ? null : handleDelete }>
                {canVoid ? <DeleteIcon/> : <DeleteForeverIcon /> }
            </IconButton> 
            </Tooltip> 
        </Flex> 
        </>
    )
}

export const DeleteFundingModal = ({source, show, onClose}: {source?: FundingSource, show?: boolean, onClose:() => void}) => {

    const {isLoading, delete: deleteSource, fundingSources: sources } = useFundingSourcesStore();
    const config = GetConfig();
    const ReauthenticateWithPlaid = async (fundingSourceId: string, env?: PlaidEnvironment) => {
        const { success, error } = await DoPlaid('update', null, fundingSourceId, env);
        if (!success) {
            ErrorLogger.RecordError(error, 'PLAID_REAUTH_FAILED', {info: {fundingSourceId: source.fundingSourceId}});
            throw error
        }
        return { success: true };
    };

    const text = useText(t => t.funding.delete)
    const handleDelete = async () => { 
        
        try
        {
            await deleteSource(source.fundingSourceId) // delete the source card
            await Promise.all([FundingHelpers.GetSources(), FundingHelpers.GetLimits()]) // refresh limits and sources
        }
        catch(error)
        {
            ErrorLogger.RecordError(error, 'PLAID_DELETE_FAILED', {info: {fundingSourceId: source.fundingSourceId}});
            const snexError = await CreateTransferErrorResultResponse(useFundingTicketStore.getState().transfer, error)
            if(snexError.needsPlaidReauth)
            {
                const env: PlaidEnvironment = sources.find((s) => s.fundingSourceId === source.fundingSourceId)?.isSandbox
                ? 'sandbox'
                : (config.Plaid.Environment as PlaidEnvironment);
                await ReauthenticateWithPlaid(source.fundingSourceId, env);
                await deleteSource(source.fundingSourceId) // delete the source card
                await Promise.all([FundingHelpers.GetSources(), FundingHelpers.GetLimits()]) // refresh limits and sources
            } else {
                throw snexError
            }

        }  
        finally
        {
            if(onClose) onClose()
        }
    }

    const colors = useColors()

    return (
        <ActionModal closerStyle='x-icon' isOpen={show} toggleModal={onClose} label={text.title}>
            <Flex row center fullWidth>
                <Typography variant={'h5'} style={{fontWeight: 'normal'}}>{text.message}</Typography>
            </Flex>
            <Flex column center justify={'center'} fullWidth style={{marginTop: 32}}>
                <SnexButton flavor={'submit'} style={{backgroundColor: colors.orange, width: '140px', cursor: 'pointer'}} onClick={isLoading? null : handleDelete}>
                    {isLoading? <LoadingSpinner />: text.submitButton }
                </SnexButton>
                <SnexButton flavor={'action-link'} style={{color: colors.fadedTextColor, cursor: 'pointer', fontSize: '14px'}} onClick={onClose}>{text.cancelButton}</SnexButton>
            </Flex>
        </ActionModal>
    )
}

export const SkeletonFundingTicketSourceButton = () => {
    const colors = useColors()
    return (
        <Flex row center align={'center'} style={{
            borderRadius: 16,
            height: 100,
            fontSize: '1.25rem',
            overflow: 'hidden',
            transition: '1s ease all',
            position: 'relative',
            gap: 16,
            padding: '8px 16px',
            boxSizing: 'border-box',
            color: colors.generalTextColor,
            backgroundColor: colors.greyBackgroundColor,
            opacity: .85
        }}>
            <Flex column style={{flex: 0, width: 40}}>
                <Skeleton height={45} width={40} style={{borderRadius: 40}} />
            </Flex>
            <Flex column style={{flex: 1, paddingRight: '32px'}}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </Flex>
        </Flex> 
    )
}