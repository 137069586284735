// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2LzsMctUgzfs1OuSFC7T0E{align-items:center;background:none;border:none;box-sizing:border-box;color:inherit;display:flex;padding:.7rem 1rem;transition:.2s;width:100%}._2LzsMctUgzfs1OuSFC7T0E:hover{background:var(--selectListItemColor)}._2LzsMctUgzfs1OuSFC7T0E.NVUooa0-80mvtr2WL9Pp-{opacity:.3;cursor:auto;user-select:none}._2LzsMctUgzfs1OuSFC7T0E.NVUooa0-80mvtr2WL9Pp-:hover{background:var(--cardBackgroundColor)}", ""]);
// Exports
exports.locals = {
	"menuItemWrapper": "_2LzsMctUgzfs1OuSFC7T0E",
	"disabled": "NVUooa0-80mvtr2WL9Pp-"
};
module.exports = exports;
