// @ts-strict-ignore
import React, { useEffect, useMemo } from 'react';
import { GetSecurityTypeFromStore } from 'phoenix/util';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { GetVariant } from 'phoenix/util/Variant';
import { Card } from '../Card';
import { EquityTradeTicket } from './Equity/EquityTradeTicket';
import { FundTradeTicket } from './Fund/FundTradeTicket';
import { FutureTradeTicket } from './Futures/FutureTradeTicket';
import { OptionTradeTicket } from './Option/OptionTradeTicket';
import { SecurityUnavailableForTrading } from './Shared/SecurityUnavailableForTrading';
import { TradeLoadingPage } from './Shared/TradeLoadingPage';
import { TelemetryProvider } from 'providers/TelemetryContext';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { ErrorBoundary, errorWrap } from 'components/ErrorBoundary/ErrorBoundary';
import { GetSecurityMetadataAction, GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { useText } from 'phoenix/hooks/UseText';
import { ApiData } from 'phoenix/models';
import { SecurityLogo } from 'phoenix/redux/models/SecurityLogo/SecurityLogo';
import { useAppDispatch } from 'AppRoot';
import { GetTradeTicketQuote } from './Shared/helpers';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { XS } from 'phoenix/xstream/XS';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { useTradeTicketViewModel } from './Store/useTradeTicketViewModel';
import { TradeTicketViewModel } from './Store/TradeTicketViewModel';
import CryptoTradeTicket from './Crypto/CryptoTradeTicket';
import './index.css';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';

interface TradeTicketProps {
    card?: boolean;
    logo?: ApiData<SecurityLogo>;
    style?: React.CSSProperties;
}

const ConditionalCard = ({ condition, children, className }: { condition: boolean; children: JSX.Element; className?: string }) =>
    condition ? <Card className={className}>{children}</Card> : <>{children}</>;

const TradeTicketComponent = (props: TradeTicketProps) => {
    const { card = true, logo, style } = props;
    const { loading: loadingFromStore, symbol } = useTradeTicketViewModel<TradeTicketViewModel>();
    const dispatch = useAppDispatch();
    const secType = GetSecurityTypeFromStore(symbol);
    const apiQuote = useSnexStore((s) => s.securityQuote.bySymbol[symbol]); // Use for loading initial request state, Xstream state doesn't work well for this
    const quote = GetTradeTicketQuote(symbol);
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata);
    const text = useText((t) => t);
    const assetClass = useAssetClass(symbol);
    const loading = loadingFromStore || apiQuote?.pristine || !apiQuote || apiQuote?.loading || !meta || meta?.pristine || meta?.loading || !symbol;
    const flaggedOff = !useFeatureFlag(assetClass.flags?.web?.trade) && !!assetClass.flags?.web?.trade;
    const completelyUnknown = useMemo(() => !loading && !secType, [loading, secType]);
    const { load: loadPositions } = usePositionsStore();
    /*
        added to fix issue where security screen quote and ticket quote were out of sync.
        setting the trade ticket namespace for xstream to be the same as the security screen.
        also getting the security quote on load to prevent any issues with being out of sync prior to
        xstream starting
    */
    const xdispatch = useXstreamDispatch();
    useEffect(() => {
        if (symbol) {
            dispatch(GetSecurityQuoteAction(symbol));
            dispatch(GetSecurityMetadataAction(symbol));
            loadPositions();
            assetClass?.derivative === 'option'
                ? xdispatch(XS.OptionQuotes.start([symbol], LiveDataNamespaces.SecurityScreenPrimarySymbol))
                : xdispatch(XS.Quotes.start(symbol, LiveDataNamespaces.SecurityScreenPrimarySymbol));
        }
    }, [assetClass?.derivative, dispatch, symbol, xdispatch]);

    // hide trade ticket if not allowed by variant
    if (!GetVariant()?.showTradeTicket) return null;

    if (loading) return <TradeLoadingPage />;
    if (completelyUnknown || flaggedOff) {
        return (
            <div className={`trade-ticket ${assetClass.family}`}>
                <Card>
                    <SecurityUnavailableForTrading {...{ logo, quote, symbol, text }} />
                </Card>
            </div>
        );
    }

    return (
        <div className={`trade-ticket ${assetClass.family}`} style={style}>
            <ConditionalCard condition={card}>
                <ErrorBoundary>
                    <Content />
                </ErrorBoundary>
            </ConditionalCard>
        </div>
    );
};

export const TradeTicket = React.memo(TelemetryProvider(TradeTicketComponent, TelemetryCategories.trade));

const CardContentComponent = () => {
    const { symbol } = useTradeTicketViewModel<TradeTicketViewModel>();
    const secType = GetSecurityTypeFromStore(symbol);

    switch (secType) {
        case 'mutual-fund':
            return errorWrap(<FundTradeTicket />, false, true);
        case 'option':
            return errorWrap(<OptionTradeTicket />, false, true);
        case 'future':
            return errorWrap(<FutureTradeTicket />, false, true);
        case 'crypto':
            return errorWrap(<CryptoTradeTicket />, false, true);
        case null:
            return errorWrap(<TradeLoadingPage />, false, true);
        case 'equity':
        case 'adr':
        default:
            return errorWrap(<EquityTradeTicket />, false, true);
    }
};

const Content = React.memo(CardContentComponent);
Content.displayName = 'ContentMemo';
