import { Card } from 'components';
import { SnexButton } from 'components/SnexButton';
import { SnexMessage } from 'phoenix/redux/models/Messages/SnexMessage';
import { useMessagesStore } from 'phoenix/stores/MessagesStore';
import React, { ReactElement } from 'react';

export const ZanderDebugScreen = (): ReactElement => {
    const messagesStore = useMessagesStore((s) => s);

    const message: SnexMessage = {
        id: '1',
        title: 'Test Message',
        message: 'This is a test message',
        sendDate: new Date().toString()
    };

    return (
        <Card>
            <h1>Zander Debug Screen</h1>
            <SnexButton onClick={() => console.log(messagesStore)}>Log Messages Store</SnexButton>
        </Card>
    );
};
