import { Button, CircularProgress, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from '../..';
import { CancelTradeAction } from 'phoenix/redux/actions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { UseDeleteButtonStyles } from 'theming';
import { useText } from 'phoenix/hooks/UseText';
import AnimatedCheckMark from '../../AnimatedCheckMark/AnimatedCheckMark';
import { ActionModal } from '../ActionModal';
import { FormattedTextDisplay } from '../../FormattedTextDisplay/FormattedTextDisplay';
import { useColors } from 'hooks/UseColors';
import { set } from 'lodash';
import AnimatedFailure from 'components/AnimatedFailure/AnimatedFailure';

interface CancelOrderModalProps {
    open: boolean;
    orderId: string;
    toggleModal: (nextOpenState: boolean) => void;
    onCancel?: (success: boolean) => any;
}

export const CancelOrderModal = (props: CancelOrderModalProps) => {
    const { open, toggleModal } = props;
    const [orderCancelSuccess, setOrderCancelSuccess] = useState(false);
    const [orderCancelFailure, setOrderCancelFailure] = useState(false);
    const colors = useColors();
    const buttonClasses = UseDeleteButtonStyles(colors)();
    const cancel = useSelector((state: GlobalState) => state.trading.cancel);
    const dispatch = useDispatch();
    const text = useText((s) => s.modals.cancelOrder);

    const handleCancelExperience = () => {
        setOrderCancelSuccess(true);
        setTimeout(() => {
            toggleModal(false);
            setOrderCancelSuccess(false);
        }, 2500);
    };

    const handleCancel = async () => {
        // @ts-ignore
        const { failed } = await dispatch(CancelTradeAction(props.orderId));
        setOrderCancelFailure(false);
        if (!failed) handleCancelExperience();
        else setOrderCancelFailure(true);
        if (props.onCancel) props.onCancel(!failed);
    };

    const labelText = useMemo(() => {
        if (!orderCancelSuccess && !orderCancelFailure) return text.cancelOrder;
        else if (orderCancelSuccess) return text.orderCancelled;
        else return text.cancelOrderFailed;
    }, [orderCancelFailure, orderCancelSuccess, text.cancelOrder, text.cancelOrderFailed, text.orderCancelled]);

    return (
        <ActionModal height='auto' isOpen={open} label={labelText} toggleModal={toggleModal}>
            <Flex column fullWidth center>
                {orderCancelSuccess ? (
                    <AnimatedCheckMark size={200} />
                ) : !orderCancelFailure ? (
                    <>
                        <Flex column center justify='space-between' align='center' style={{ height: 155 }}>
                            <Typography variant='h6' style={{ marginBottom: '20px' }}>
                                {text.areYouSure}
                            </Typography>
                            <Flex column center style={{ textAlign: 'center' }}>
                                <FormattedTextDisplay text={text.ctrlKeyTip} />
                            </Flex>
                        </Flex>
                        <Flex row center style={{ marginTop: 30 }}>
                            <Button classes={{ ...buttonClasses }} disabled={cancel.loading} onClick={handleCancel}>
                                {cancel.loading ? <CircularProgress size={18} style={{ color: 'white' }} /> : text.cancel}
                            </Button>
                        </Flex>
                    </>
                ) : (
                    <>
                        <Flex column center justify='space-between' align='center' style={{ height: 155 }}>
                            <Typography variant='h6' style={{ marginBottom: '15px' }}>
                                {text.retry}
                            </Typography>
                            <Flex column center style={{ textAlign: 'center' }}>
                                <AnimatedFailure size={125} />
                            </Flex>
                        </Flex>
                        <Flex row center style={{ marginTop: 30 }}>
                            <Button classes={{ ...buttonClasses }} disabled={cancel.loading} onClick={handleCancel}>
                                {cancel.loading ? <CircularProgress size={18} style={{ color: 'white' }} /> : text.retryCancel}
                            </Button>
                        </Flex>
                    </>
                )}
            </Flex>
        </ActionModal>
    );
};
