// @ts-strict-ignore
import { ItemUpdate } from 'lightstreamer-client-web';
import { LiveDataGroups, StorageKeys, Urls } from 'phoenix/constants';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { Actions, LiveMessageOptions } from 'phoenix/redux/actions';
import { Order } from 'phoenix/redux/models';
import {
    AdminMessageDeliveryControls,
    ClientMdcEventName,
    ClientMessageDeliveryControl,
    SystemMessageDeliveryControls
} from 'phoenix/redux/models/Messages/ClientMessageDeliveryControls';
import { SnexMessage } from 'phoenix/redux/models/Messages/SnexMessage';
import { SnexOrderUpdateMessagePayload } from 'phoenix/redux/models/Messages/SnexOrderUpdateMessagePayload';
import { UnreadMessagesStats } from 'phoenix/redux/models/Messages/UnreadMessagesStats';
import { GetPersistentStringAsync, SetPersistentStringAsync } from 'phoenix/resolvers/PersistentStringStorage';
import { SnexAxios } from 'phoenix/stores/AxiosHelpers';
import { GetEnableDebugLogging, GetJwtClaim, Jwt, Lightstreamer } from 'phoenix/util';
import { create } from 'zustand';
import { ZustandLiveSubscribe } from './helpers';

type MessagesStoreData = {
    statsLoading: boolean;
    stats: UnreadMessagesStats | null;
    unreadLoading: boolean;
    unread: number | null;

    messagesLoading: boolean;
    messages: SnexMessage[];
    latestMessage: SnexMessage | null;
    systemControls: SystemMessageDeliveryControls[]; // System-level MDC controls, as visible to the client
};

type MessagesStoreMethods = {
    recordLastViewedTime: (dt?: Date) => Promise<void>;
    clearUnreadStats: () => void;
    loadUnreadStats: () => Promise<UnreadMessagesStats>;
    incrementUnread: () => number;
    clearAll: () => void;
    subscribeToLiveMessages: (userSub?: string, options?: LiveMessageOptions) => Promise<string>;
    loadMessages: (page?: number, pageSize?: number) => Promise<SnexMessage[]>;
    generateMessage: (reset?: boolean) => Promise<void>;
    markAsRead: (messageId: string) => Promise<void>;
    deleteMessages: (messageIds: string[]) => Promise<void>;
    deleteAllMessages: () => Promise<void>;
    getSystemMessageControls: () => Promise<SystemMessageDeliveryControls[]>;
};

export type MessagesStoreState = MessagesStoreData & MessagesStoreMethods;

export const useMessagesStore = create<MessagesStoreState>((set, get) => ({
    // Note: messages/unread-stats and messages/unread return different values for unread message count; unread-stats does not update immediately upon a message being read on web.
    // TODO: Look into this api-wise and see if we can consolidate.
    // Mobile uses stats, web uses unread. The only reason for this is that mobile app already uses this store for unread message stats.
    statsLoading: false,
    stats: null,
    unreadLoading: false,
    unread: null,
    messages: [],
    latestMessage: null,
    messagesLoading: false,
    systemControls: [],

    // Records the last time the user viewed messages. This is used to determine the unread message count.
    recordLastViewedTime: async (dt?: Date) => {
        const d = dt || new Date();
        console.log(`[MessagesStore] Setting last viewed time: ${d.toISOString()}`);
        await SetPersistentStringAsync(StorageKeys.MessagesLastViewedTime, d.toISOString());
    },

    // Clears the unread message stats for the current user.
    clearUnreadStats: () => {
        console.log('[MessagesStore] Clearing unread stats');
        set({ stats: { unreadCount: 0 }, unread: 0 });
        set({ unread: 0 });
    },

    // Loads the unread message stats for the current user. This is used to display the unread message count in the UI.
    loadUnreadStats: async () => {
        try {
            set({ statsLoading: true });
            const lastRead = new Date((await GetPersistentStringAsync(StorageKeys.MessagesLastViewedTime)) || '2020-01-01');
            if (GetEnableDebugLogging()) console.log(`[MessagesStore] Getting unread stats as of: ${lastRead.toISOString()}`);
            const statsResult = await SnexAxios.ApiGet<UnreadMessagesStats>(Urls.messages.countStats(lastRead)).run();
            set({ unreadLoading: true });
            const result = await SnexAxios.ApiGet<number>(Urls.messages.countUnread()).run();
            set({ stats: statsResult, statsLoading: false, unread: result, unreadLoading: false });
            return statsResult;
        } catch (e) {
            console.error(e);
            return null;
        }
    },

    // Increments the unread message count by 1 and returns the new count.
    incrementUnread: () => {
        const existing = get().stats;
        const unreadExisting = get().unread;
        const newCount = (existing?.unreadCount || 0) + 1;
        const newUnreadCount = (unreadExisting || 0) + 1;
        set({ stats: { ...existing, unreadCount: newCount }, unread: newUnreadCount });
        return newCount;
    },

    // Clears all messages and stats. Currently not used on web.
    clearAll: () =>
        set({
            statsLoading: false,
            stats: null,
            unreadLoading: false,
            unread: null
        }),

    // Subscribes to live messages for the current user.
    subscribeToLiveMessages: async (userSub?: string, options?: LiveMessageOptions) => {
        const opts = {
            fetchPositionsOnOrderUpdates: true, // for back compat
            ...options
        };
        const subEffective = userSub || GetJwtClaim(Lightstreamer.GetCurrentUserId(), 'sub');
        return ZustandLiveSubscribe(
            LiveDataGroups.Messages,
            [`msg:${subEffective}`],
            Actions.Messages.LiveStream,
            // prettier-ignore
            ['id','recipVulcanId','senderId','senderName','title','message','subjectSymbol','subjectUserId','time','type','payloadType','disposition','appPermalink','externalLink','imageUrl','readDate','data'],
            async (item: ItemUpdate) => {
                try {
                    const message = SnexMessage.FromLiveData(item);

                    MessageStore_IncrementUnread();

                    if (message.data?.type === 'OrderUpdate' && opts.fetchPositionsOnOrderUpdates) {
                        const order = message.data as SnexOrderUpdateMessagePayload & Order;
                        if (order?.orderStatus?.toLowerCase()?.includes('fill')) {
                            //only refresh positions on fill update..
                            // @ts-ignore
                            setTimeout(() => usePositionsStore.getState().load(), 10000);
                        }
                    }

                    set((s) => ({ messages: [message, ...s.messages], latestMessage: message }));

                    return message;
                } catch (e) {
                    console.log('[MessagesStore] MESSAGE UPDATE ERROR', e);
                }
            },
            {
                mode: 'DISTINCT',
                includeSnapshot: false,
                maxFrequencySeconds: 1,
                namespace: LiveDataNamespaces.Messages
            }
        );
    },

    // Loads the (default) first page of messages. Can be paginated by passing in a page number and page size.
    loadMessages: async (page?: number, pageSize?: number) => {
        set({ messagesLoading: true });
        const filters = { page: page || 1, size: pageSize || 20 };
        const results = await SnexAxios.ApiGet<SnexMessage[]>(Urls.messages.search(filters)).run();
        set({ messages: [...get().messages, ...results], messagesLoading: false });
        return results;
    },

    // Generates a stock StoneX One Message for testing purposes. This returns the "Welcome to StoneX" message.
    generateMessage: async (reset = false) => {
        await SnexAxios.ApiPost(Urls.messages.generate())
            .run()
            .then(() => {
                if (reset) {
                    set({ messages: [] });
                    useMessagesStore.getState().loadMessages();
                }
            });
    },

    // Marks a message as read.
    markAsRead: async (messageId: string) => {
        await SnexAxios.ApiPut(Urls.messages.markRead([messageId])).run();
        set({
            messages: get().messages.map((m) => {
                if (m.id === messageId) m.readDate = new Date().toISOString();
                return m;
            }),
            unread: get().unread - 1
        });
    },

    // Deletes messages by ID.
    deleteMessages: async (messageIds: string[]) => {
        await SnexAxios.ApiDelete(Urls.messages.delete(messageIds.flatMap((l) => l.split(',')))).run();
        set({ messages: get().messages.filter((m) => !messageIds.includes(m.id)) });
    },

    // Deletes all messages.
    deleteAllMessages: async () => {
        await SnexAxios.ApiDelete(Urls.messages.deleteAll()).run();
        set({ messages: [] });
    },

    // Gets the system-level message delivery controls.
    getSystemMessageControls: async () => {
        const result = await SnexAxios.ApiGet<SystemMessageDeliveryControls[]>(Urls.messages.systemControls()).run();
        set({ systemControls: result });
        return result;
    }
}));

export const MessageStore_LoadStats = () => useMessagesStore.getState().loadUnreadStats();
export const MessageStore_IncrementUnread = () => useMessagesStore.getState().incrementUnread();
const MessageStore_SubscribeToLiveMessages = async (userSub?: string, options?: LiveMessageOptions) =>
    await useMessagesStore.getState().subscribeToLiveMessages(userSub, options);

export const ZustandSubscribeToLiveMessagesWithJwt = (jwt?: string, options?: LiveMessageOptions) => {
    // Okta tokens need to use `cdsid:<CDSID>`; Vulcan tokens just use the token's subject.
    const iss = Jwt.GetIssuer(jwt);
    const sub = Jwt.TryGetSubject(jwt);
    const cds = Jwt.TryGetClaim(jwt, 'cds_id');
    const userId = iss === 'okta' ? `cdsid:${cds}` : sub;
    return MessageStore_SubscribeToLiveMessages(userId, options);
};
