import { ListItemIcon, Menu, PopoverProps, Tooltip, Typography } from '@mui/material';
import { useColors } from 'hooks/UseColors';
import { useTelemetry } from 'hooks/UseTelemetry';
import React, { CSSProperties, MouseEvent, PropsWithChildren, ReactElement, ReactNode, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem } from './MenuItem';

export interface IAnchoredMenuItem {
    IconComponent?: string;
    disabled?: boolean;
    eventTag?: string;
    icon?: ReactNode;
    label: string;
    onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
    route?: string;
    textStyle?: CSSProperties;
    tooltipText?: string;
}

interface AnchoredMenuProps {
    anchorEl: HTMLElement | null;
    handleClose: (e?: MouseEvent<HTMLButtonElement> | PopoverProps['onClose']) => void;
    items?: IAnchoredMenuItem[];
    headerSection?: ReactNode;
    topOffset?: number;
    showBackdropOverlay?: boolean;
}

const SeparatorLabel = '__SEP';
export const AnchoredMenuSeparator = { label: '__SEP', onClick: (): void => undefined, icon: null };

export const AnchoredMenu = (props: PropsWithChildren<AnchoredMenuProps>): ReactElement => {
    const { anchorEl, handleClose, items, headerSection, children, topOffset, showBackdropOverlay } = props;

    const colors = useColors();
    const LogEvent = useTelemetry();

    const Separator = () => <hr style={{ border: `solid 1px ${colors.dividerColor}`, color: 'transparent', margin: 0 }} />;

    const MenuItemChildren = useCallback(({ item }: { item: IAnchoredMenuItem }) => {
        return (
            <>
                {item.icon && <ListItemIcon style={item.textStyle}>{item.icon} </ListItemIcon>}
                <Typography style={item.textStyle} variant='button'>
                    {item.label}
                </Typography>
            </>
        );
    }, []);

    const AnchoredMenuItem = (item: IAnchoredMenuItem, key: number) =>
        item.label === SeparatorLabel ? (
            <Separator key={key} />
        ) : (
            <MenuItem
                ariaLabel={item?.tooltipText} // Simulate the aria-label that MUI adds to Tooltips - this parent component will receive the focus so it needs the label
                disabled={item?.disabled}
                key={key}
                onClick={(e) => {
                    handleClose(e);
                    item?.onClick && item.onClick(e);
                    if (item.eventTag) LogEvent(`${item.eventTag} clicked`);
                }}
            >
                {item?.tooltipText ? (
                    <Tooltip
                        title={item.tooltipText}
                        placement='left'
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, 20] // Increase the distance from the menu item so the tooltip doesn't overlap
                                        }
                                    }
                                ]
                            }
                        }}
                    >
                        <div>
                            <MenuItemChildren item={item} />
                        </div>
                    </Tooltip>
                ) : (
                    <MenuItemChildren item={item} />
                )}
            </MenuItem>
        );

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            keepMounted={false}
            MenuListProps={{ style: { padding: 0, backgroundColor: colors.cardBackgroundColor } }}
            open={!!anchorEl}
            style={{ padding: 0, zIndex: 9999999, marginTop: topOffset || 0, backgroundColor: showBackdropOverlay ? colors.colorOverlay : 'transparent' }}
            onClose={handleClose as PopoverProps['onClose']}
        >
            {headerSection}
            {headerSection && <Separator />}
            {children ||
                items?.map((item, key) =>
                    item.route ? (
                        <Link to={item.route} key={key}>
                            {AnchoredMenuItem(item, key)}
                        </Link>
                    ) : (
                        AnchoredMenuItem(item, key)
                    )
                )}
        </Menu>
    );
};
