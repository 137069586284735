// @ts-strict-ignore
import { getWeekOfMonth, isSameYear } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { ShadedTextUtils } from 'phoenix/assets/lang/ShadedTextUtils';
import { AppliedAppTheme } from 'phoenix/constants/AppliedAppTheme';
import { PremiumProducts } from 'phoenix/constants/PremiumProducts';
import { GetPriceFormatInfo } from 'phoenix/hooks/UsePriceFormatInfo';
import { ApiTradeRequest, ApiTradeStrategy } from 'phoenix/models/ApiTradeRequest';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { GetAssetClassForSecurity } from 'phoenix/models/AssetClasses/useAssetClass';
import { TransferRequest } from 'phoenix/models/TransferRequest';
import { CryptoSymbol } from 'phoenix/redux/models/Crypto/CryptoSymbol';
import { FundingLimits } from 'phoenix/redux/models/Funding/FundingLimits';
import { FundingSource } from 'phoenix/redux/models/Funding/FundingSource';
import { Environment } from '../../constants';
import { IndustryGroups } from '../../constants/IndustryGroups';
import { SecurityTypes } from '../../constants/SecurityTypes';
import { OrderTypes } from '../../constants/Trade';
import { Account } from '../../redux/models/Accounts/Account';
import { SnexDocument } from '../../redux/models/Documents/SnexDocument';
import { FundingManagementLimits } from '../../redux/models/Funding/FundingManagementLimits';
import { FuturesSymbol } from '../../redux/models/Futures/FuturesSymbol';
import { OptionSymbol } from '../../redux/models/Options/OptionSymbol';
import { Order } from '../../redux/models/Orders/Order';
import { Transaction } from '../../redux/models/Transactions/Transaction';
import { SpokenJoin } from '../../util/ArrayMutations';
import { SafeFormat, SafeFormatLocale, SafeFormatToNow } from '../../util/DateFormatting';
import { DetermineOrderType } from '../../util/DetermineOrderType';
import { FormatNumber } from '../../util/FormatNumber';
import { DecodeSecurityMasterType, TradeableSecurityType } from '../../util/IsMutualFund';
import { QualifiedId } from '../../util/QualifiedId';
import { disclosures, proDisclosures } from './disclosures';
import { Dark, Light, ShadedTextList, Snex1LanguagePack } from './Snex1LanguagePack';
import { de } from 'date-fns/locale';
import { OptionsPutCallAbbreviation, OrderType, TradeRequest } from 'phoenix/redux/models';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { ActionSummaryProps, getActionSummary } from './helpers';

const DelayedParagraph = [{ text: 'Aktualisierungshäufigkeit: 15-minütige Verzögerung' }];

const nums = ['null', 'eins', 'zwei', 'drei', 'vier', 'fünf', 'sechs', 'sieben', 'acht', 'neun', 'zehn'];

export const Snex1German: Snex1LanguagePack = {
    funding: {
        delete: {
            title: 'Konto löschen',
            message: 'Möchten Sie dieses Konto wirklich entfernen?',
            submitButton:'Konto entfernen',
            cancelButton:'Nein, Konto behalten'
        },
    },
    errorBoundary: {
        title: 'Hoppla!',
        sorryMessage: 'Entschuldigung! Es sieht so aus, als ob etwas schief gelaufen ist.',
        oopsSomethingWentWrong: 'Hoppla, etwas ist schief gelaufen.',
        tryAgain: 'Erneut versuchen',
        contactSupport: 'Support kontaktieren',
        onboardingErrorOccurred: 'Ein Fehler ist aufgetreten',
        onboardingTryAgainOrContactSupport: 'Bitte versuchen Sie es erneut oder wenden Sie sich an den Support, wenn das Problem weiterhin besteht'
    },
    welcome: {
        skipTour: 'Tour überspringen',
        showMeAround: 'Führe mich herum!',
        pickLang: 'Wählen Sie die Sprache, mit der Sie sich am wohlsten fühlen',
        successText: 'Ihr Konto wurde erfolgreich erstellt!',
        loadingText: 'Überprüfe den Status deines Kontos...',
        errorText: `Beim Auffinden Ihres Kontos ist ein Problem aufgetreten. Die Initialisierung Ihres Kontos kann einige Minuten dauern. Wenn es nach 10 Minuten nicht verfügbar ist, wenden Sie sich bitte an unseren Support unter`,
        noAccountsText: 'Es sieht so aus, als ob Ihr Konto noch nicht ganz fertig ist. Die Initialisierung Ihres Kontos kann einige Minuten dauern. Wenn es nach 10 Minuten nicht verfügbar ist, wenden Sie sich bitte an unseren Support unter',
    },
    webTos: {
        title: 'Geschäftsbedingungen akzeptieren',
        url: 'https://snex1storage.blob.core.windows.net/$web/docs/StoneX_Client_TC.pdf',
        linkText: 'Geschäftsbedingungen anzeigen',
        agreeButtonLabel: 'Ich akzeptiere'
    },

    general: {
        to: 'zu',
        ai: 'KI',
        languageNameEnglish: 'German',
        languageNameLocal: 'Deutsch',
        dateFnsLocale: de,
        localeShort: 'de',
        localeLong: 'de-DE',
        submit: 'Absenden',
        continue: 'Weiter',
        acceptRateAndSubmit: 'Zinssatz akzeptieren und absenden',
        acceptTermsAndSubmit: 'Bedingungen akzeptieren und absenden',
        submitting: 'Wird übermittelt...',
        loading: 'Wird geladen',
        cancel: 'Abbrechen',
        news: (showMI) => `Nachrichten${showMI ? ' und Marktinformatik' : ''}`,
        writtenInteger0Thru10: (n) => (n >= 0 && n <= 10 ? nums[n] : FormatNumber.toCommas(n)),
        unavailable: 'Nicht verfügbar',
        open: 'Öffnen',
        done: 'Fertig',
        none: 'Keine',
        dismiss: 'Schließen',
        confirmAndContinue: "Bestätigen und Fortfahren",
        contactSupport: 'Support kontaktieren',
        search: 'Suche',
        trade: 'Handel',
        dateUnknown: 'Datum unbekannt',
        unknown: 'Unbekannt',
        unableToAuthenticate: 'Entschuldigung, wir konnten Sie nicht authentifizieren',
        browseDataUnavailable: 'Entschuldigung, die Durchsuchungsdaten sind derzeit nicht verfügbar.',
        yesLeave: 'Ja, verlassen',
        noStayHere: 'Nein, hier bleiben',
        returningToStonex: 'Rückkehr zu StoneX One',
        aboutToReturnToStonex: 'Sie kehren gleich zu StoneX One zurück. Sind Sie sicher?',
        months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        quantity: 'Menge',
        strikes: 'Strikes',
        shares: (n = 0) => (Math.abs(n) === 1 ? 'Aktie' : 'Aktien'),
        fundShares: (n = 0) => (Math.abs(n) === 1 ? 'Aktie' : 'Aktien'),
        contracts: (n = 0) => (Math.abs(n) === 1 ? 'Kontrakt' : 'Kontrakte'),
        bonds: (n = 0) => (Math.abs(n) === 1 ? 'Anleihe' : 'Anleihen'),
        coins: (n = 0) => (Math.abs(n) === 1 ? 'Münze' : 'Münzen'),
        unit: (symbol: string, quantity = 0, typeHint: string = null) => {
            if (FuturesSymbol.IsFuturesSymbol(symbol)) return Snex1German.general.contracts(quantity);
            if (OptionSymbol.IsOptionSymbol(symbol) || DecodeSecurityMasterType(typeHint) === 'option') return Snex1German.general.contracts(quantity);
            if (CryptoSymbol.IsCryptoSymbol(symbol)) return Snex1German.general.coins(quantity);
            const torchType = typeHint;
            if (torchType === 'Festverzinslich' || torchType === 'Schuld') return Snex1German.general.bonds(quantity);
            return Snex1German.general.shares(quantity);
        },

        symbolName: (
            symbol: string,
            optionVariant: 'extra-short' | 'short' | 'long' | 'no-exp' | 'symbol-putcall' = 'short',
            quantity = 1,
            secMeta: SecurityMetadata = null
        ): string => {
            const optSym = new OptionSymbol(symbol);

            if (optSym.isOption) {
                const { underlyingSymbol, expDate } = optSym;
                const strike = secMeta?.strikePrice || optSym.strike;
                const term = getWeekOfMonth(expDate, { weekStartsOn: 6 }) !== 3 ? 'wöchentlich' : 'monatlich';
                const putness = Snex1German.general.options.putCallWord(optSym.putCall);
                const strikeFormat = secMeta ? GetPriceFormatInfo(symbol, secMeta.tickSize) : undefined;
                const strikeStr = FormatNumber.toMoneyOpt2(strike, strikeFormat?.moneyFormatOptions);
                const { exp } = Snex1German.general.options;
                const locale = Snex1German.general.dateFnsLocale;

                switch (optionVariant) {
                    case 'long':
                        return `${underlyingSymbol} ${SafeFormatLocale({ date: expDate, formatString: 'dd MMMM yyyy', locale })} ${term} ${strikeStr} ${putness}${
                            quantity === 1 ? '' : 's'
                        }`;
                    case 'no-exp':
                        return `${underlyingSymbol} ${strikeStr} ${putness}${quantity === 1 ? '' : 's'}`;
                    case 'symbol-putcall':
                        return `${optSym.underlyingSymbol} ${putness}`;
                    case 'extra-short': {
                        const dateFmt = isSameYear(expDate, new Date()) ? 'MM/dd' : 'MM/dd/yy';
                        return `${optSym.underlyingSymbol} ${strikeStr}${optSym.putCall} ${SafeFormatLocale({ date: expDate, formatString: dateFmt, locale })}`;
                    }
                    case 'short':
                    default:
                        return `${underlyingSymbol} ${strikeStr} ${putness}, ${exp} ${SafeFormatLocale({ date: expDate, formatString: 'MM/dd/yyyy', locale })}`;
                }
            }

            return QualifiedId.RemovePrefix(symbol);
        },
        spokenJoin: (items) => SpokenJoin(items, 'und'),
        isShort: 'Dies ist eine Short-Position',
        buyingPower: 'Kaufkraft',
        availableBalance: 'Verfügbares Guthaben',
        pendingApproval: 'Genehmigung ausstehend',
        pending: 'Ausstehend',
        maintenanceCall: 'Wartungsaufruf',
        maintenanceCallForAccounts: 'Wartungsaufruf für ein oder mehrere Konten',
        maintenanceCallTooltip:
            'Ein Margin Call tritt auf, wenn der Wert der Wertpapiere in einem Brokerage-Konto unter ein bestimmtes Niveau fällt, das als Wartungsmarge bekannt ist, und der Kontoinhaber zusätzliches Bargeld oder Wertpapiere einzahlen muss, um die Margenanforderungen zu erfüllen.',
        fedCallTooltip:
            'Wenn ein Anleger Aktien kauft und nicht genügend Eigenkapital im Konto hat, um die 50% ige Eigenkapitalanforderung zu erfüllen, wird ein Fed-Margin-Call ausgelöst.',
        dayTradingFlag: 'Dieses Konto wurde für den Day-Trading markiert.',
        fplEligible: 'Dieses Konto ist berechtigt, am Fully Paid Lending teilzunehmen',
        fplEnabled: 'Dieses Konto ist am Fully Paid Lending teilgenommen',
        ninetyDayRestriction: {
            description: 'Ein Kunde kann aus den folgenden Gründen für 90 Tage eingeschränkt werden:',
            forAccounts: '90-Tage-Beschränkung für ein oder mehrere Konten',
            list: [
                'Nichtzahlung einer REG T-Verpflichtung',
                'Jede Aktivität außer Bargeldeinzahlung während der Verlängerung (Liquidierung, Stornierung usw.)',
                'Free Riding',
                'Drei gute Glaubensverletzungen in einem rollenden 12-Monats-Zeitraum',
                'Nichtzahlung eines Day-Trade-Anrufs'
            ],
            secondParagraph:
                'Während der 90-tägigen Beschränkung ist ein Kunde nur zur Eröffnung von Transaktionen mit abgerechneten Geldern berechtigt. Ein Kunde kann daran gehindert werden, Transaktionen zu eröffnen, wenn nicht genügend abgerechnete Gelder vorhanden sind. Übersteigende Handel, die abgerechnete Gelder überschreiten, können ebenfalls storniert werden.',
            title: '90-Tage-Beschränkung',
            withDate: (date) => `90-tägige Beschränkung${date ? ` läuft ab ${SafeFormat(date, 'MM/dd/yyyy')}` : ''}`
        },
        options: {
            put: 'Put',
            call: 'Call',
            exp: 'Exp',
            putCallWord: (putCall: OptionsPutCallAbbreviation) => {
                switch (putCall) {
                    case 'C' as OptionsPutCallAbbreviation:
                        return Snex1German.general.options.call;
                    case 'P' as OptionsPutCallAbbreviation:
                        return Snex1German.general.options.put;
                    default:
                        return Snex1German.general.unknown;
                }
            },
            adjustedOptionsSubtext: (deliverableCount: number) => {
                return `${deliverableCount} shares per Contract`;
            }
        },
        productTypes: {
            'offshore-mutual-fund': { singular: 'Fonds', plural: 'Fonds' },
            'mutual-fund': { singular: 'Fonds', plural: 'Fonds' },
            adr: { singular: 'ADR', plural: 'ADRs' },
            crypto: { singular: 'Kryptowährung', plural: 'Kryptowährungen' },
            equity: { singular: 'Eigenkapital', plural: 'Eigenkapital' },
            etf: { singular: 'ETF', plural: 'ETFs' },
            future: { singular: 'Zukunft', plural: 'Zukünfte' },
            option: { singular: 'Option', plural: 'Optionen', upperSingular: 'Option' },
            unknown: { singular: 'Sicherheit', plural: 'Wertpapiere' }
        },
        assetClassLabels: {
            equities: 'Aktien',
            futures: 'Futures',
            cryptos: 'Cryptos'
        },
        sorrySomethingWentWrong: 'Entschuldigung, etwas ist schief gelaufen',
        notApplicable: 'N/V',
        learnMore: 'Mehr erfahren',
        expiresSoon: 'Bald ablaufend',
        expiresToday: 'Heute ablaufend',
        expiresTomorrow: 'Morgen ablaufend',
        daily: 'Täglich',
        oneTime: 'Einmal',
        repeatDaily: 'Täglich wiederholen',
        numberBought: '# Gekauft',
        numberSold: '# Verkauft',
        avgBuyPrice: 'Durchschnittlicher Kaufpreis',
        avgSellPrice: 'Durchschnittlicher Verkaufspreis',
        topDayActivity: 'Top-Tagesaktivität'
    },
    webRegistration: {
        verifySMSCodeTitle: 'Überprüfen Sie mit Ihrem Telefon',
        enterCodeAndLogin: 'Geben Sie unten Ihren Code ein und legen wir los!',
        verifySMSCodeSubtitle: (phone: string) => `Senden Sie einen Code per SMS an ${phone}`,
        messageChargesAlert: 'Nachrichtenkosten des Anbieters können anfallen',
        sendingSMSCode: 'Code wird validiert',
        invalidSMSCode: 'Ungültiger SMS-Bestätigungscode',
        resentSMSCode: 'SMS-Bestätigungscode erneut gesendet',
        getSMSCode: 'Erhalten Sie einen Code per SMS',
        submitSMSCode: 'Code absenden',
        alternateMFA: 'Wählen Sie eine andere MFA-Methode',
        welcome: 'Willkommen!',
        getAnAccount: 'Lassen Sie uns ein StoneX One-Konto für Sie einrichten',
        getUserInfo: 'Lassen Sie uns Ihren Namen und Ihre E-Mail-Adresse erfassen',
        nextButton: 'Weiter',
        firstName: 'Vorname',
        lastName: 'Nachname',
        phoneNumber: 'Mobiltelefonnummer',
        verifyEmail: 'E-Mail verifizieren',
        emailVerified: 'E-Mail verifiziert!',
        email: 'E-Mail',
        confirmEmail: 'E-Mail bestätigen',
        userExists: (email: string) => `Benutzer mit der E-Mail ${email || ''} existiert bereits.`,
        personalInfo: 'Persönliche Informationen',
        usCitizenQuestion: 'Sind Sie ein US-Bürger?',
        yes: 'Ja',
        no: 'Nein',
        usCitizenAnswer: 'Ich bin ein US-Bürger',
        nonUSCitizenAnswer: 'Ich bin kein US-Bürger',
        usCitizenAlert: 'Derzeit müssen Sie US-Bürger sein, um ein Konto zu eröffnen',
        createPassword: 'Lassen Sie uns ein Passwort erstellen',
        createUser: 'Benutzer erstellen',
        passwordRequirement: 'Das Passwort muss mindestens 14 Zeichen lang sein',
        password: 'Passwort',
        confirmPassword: 'Passwort bestätigen',
        verifyYourEmail: 'Lassen Sie uns Ihre E-Mail verifizieren',
        sendingVerificationCode: 'Wir senden einen Bestätigungscode an die zuvor angegebene E-Mail.',
        enterInBoxBelow: 'Bitte geben Sie ihn in das untenstehende Feld ein.',
        invalidVerificationCode: 'Ungültiger Bestätigungscode',
        resentVerificationCode: 'Bestätigungscode erneut gesendet',
        verificationCode: 'Bestätigungscode',
        submit: 'Absenden',
        resendCode: 'Code erneut senden',
        completeTitle: 'Registrierung abgeschlossen',
        completeSubtitle: 'Klicken Sie auf die Schaltfläche unten und melden Sie sich bei StoneX One an',
        login: 'Anmelden',
        alreadyExists: 'Benutzer bereits vorhanden',
        wouldYouLikeToLogin: 'Möchten Sie sich bei StoneX One anmelden?',
        steps: {
            welcome: 'Willkommen',
            userInfo: 'Benutzerinformationen',
            personalInfo: 'Persönliche Informationen',
            setupPassword: 'Passwort einrichten',
            verifyCreate: 'E-Mail verifizieren',
            enrollMFA: 'MFA anmelden',
            login: 'Anmelden'
        },
        errors: {
            required: 'Feld ist erforderlich',
            userInfo: {
                invalidPhone: 'Ungültige Telefonnummer.',
                invalidEmail: 'Ungültige E-Mail',
                emailDoesNotMatch: 'E-Mail und confirmEmail stimmen nicht überein',
                emailExists: 'E-Mail existiert bereits'
            },
            password: {
                passwordsDoNotMatch: 'Passworteingaben stimmen nicht überein',
                invalidPassword: 'Passwörter müssen mindestens 14 Zeichen lang sein'
            }
        }
    },
    assetClass: {
        futures: 'Futures',
        cryptocurrency: 'Kryptowährung',
        crypto: 'Krypto',
        futuresOptions: 'Terminkontrakt-Optionen',
        future: 'Terminkontrakt',
        equitiesOption: 'Aktienoption',
        otcOption: 'OTC-Option',
        etf: 'ETF',
        mutualFund: 'Investmentfonds',
        oilGasReit: 'Öl- & Gas-REIT',
        escrowReceiptsLetterGuaranty: 'Hinterlegungsbeleg / Garantieschreiben',
        treasury: 'Schatzanweisung',
        municipleBond: 'Kommunalanleihe',
        corporateUit: 'Unternehmens-UIT',
        municipalUit: 'Kommunale UIT',
        unit: 'Einheit',
        preciousMetals: 'Edelmetalle',
        commonStock: 'Stammaktie',
        annuity: 'Rentenversicherung',
        miscellaneous: 'Sonstiges',
        offShoreMutualFund: 'Offshore-Investmentfonds',
        brokerage: 'Aktien'
    },
    appReview: {
        yes: 'Ja',
        no: 'Nein',
        enjoyingStoneX: 'Genießen Sie StoneX One?',
        rateApp: 'Möchten Sie die App bewerten?',
        later: 'Später',
        noThanks: 'Nein, danke',
        feedback: 'Vielen Dank für Ihr Feedback. Möchten Sie mit dem Chat-Support sprechen?'
    },
    connectionStatus: {
        title: 'Keine Verbindung',
        message: 'Es sieht so aus, als wären Sie nicht mit dem Internet verbunden.',
        retryConnection: 'Verbindung erneut versuchen'
    },
    premiumModal: {
        addTheseTools: 'Fügen Sie diese Tools zu Ihrem Arsenal hinzu:',
        ready: 'Bereit, diesen zusätzlichen Vorteil zu erlangen?',
        unlock: 'Schalten Sie Analysen wie keine andere frei.',
        upgradeToday: 'Noch heute auf Premium upgraden',
        deepAnalysis: {
            title: 'Zugang zu tiefgehenden Analysen',
            content: 'Navigieren Sie die Märkte mit Echtzeit-Marktdaten und exklusiven Unternehmensanalysen in einer einzigen umfassenden Ansicht.'
        },
        multiCharts: {
            title: 'Mehrere Diagramme, ein Bildschirm',
            content:
                'Führen Sie technische Analysen an mehreren Diagrammen von einer einzigen Ansicht aus durch. Analysieren und vergleichen Sie Muster für verschiedene Wertpapiere aus einer einzigen Ansicht.'
        },
        streamingNews: {
            title: 'Streaming-Nachrichten',
            content: 'Bewegen Sie sich, wenn sich der Markt bewegt, mit Streaming-Nachrichten. Neue Artikel erscheinen oben in Ihren Nachrichtenströmen.'
        },
        alerts: {
            title: 'Preiswarnungen',
            content: 'Behalten Sie die für Sie wichtigsten Wertpapiere mit benutzerdefinierten Warnungen im Auge. Verpassen Sie niemals eine Gelegenheit.',
            isAbove: 'ist über',
            isAboveOrEqual: 'ist über oder gleich',
            isBelow: 'ist unter',
            isBelowOrEqual: 'ist unter oder gleich'
        },
        miPremium: {
            title: 'Market Intelligence',
            content:
                'Nachrichten, Daten und Kommentare zu all unseren Märkten mit direkten Perspektiven von Brokern und Analysten. Enthält umsetzbare Einblicke, die Sie einen Schritt voraus halten!'
        }
    },
    marketTimeBadge: {
        open: 'Der US-Aktienmarkt ist geöffnet',
        premarket: 'US-Aktien Pre-Market Stunden',
        holiday: 'Handelsfeiertag',
        postmarket: 'US-Aktien Post-Market Stunden',
        closed: 'Der US-Aktienmarkt ist geschlossen',
        loading: 'Laden'
    },
    blankViews: {
        holdingsTable: {
            main: 'Keine Positionen gefunden',
            secondary: 'Versuchen Sie, Ihre Filter zu ändern',
            notFundedMain: 'Willkommen auf dem Positionen-Bildschirm',
            notFundedSecondary: 'Sobald Ihr Konto finanziert ist, finden Sie Ihre Positionen und Salden hier',
            notFundedAction: 'Konto finanzieren'
        },
        documents: {
            main: (documentType) => `Sie haben derzeit keine ${documentType}`,
            secondary: (documentType) => `Zukünftige ${documentType} werden hier angezeigt, sobald sie verfügbar sind`
        },
        transactions: {
            main: 'Es sieht so aus, als hätten Sie keine Transaktionen',
            secondary: 'Versuchen Sie andere Filter oder schauen Sie später noch einmal vorbei'
        },
        projectedIncome: {
            main: 'Kein prognostiziertes Einkommen für dieses Konto gefunden',
            secondary: 'Versuchen Sie ein anderes Konto oder schauen Sie später noch einmal vorbei'
        },
        tradeHistory: {
            main: 'Es sieht so aus, als hätten Sie keine aufgezeichneten Trades, die Ihren Suchkriterien entsprechen',
            secondary: 'Suchen Sie nach einem Wertpapier und tätigen Sie einen Handel, um es hier zu sehen'
        },
        messages: {
            main: 'Keine Nachrichten gefunden',
            secondary: 'Hier finden Sie alle Nachrichten, die Sie erhalten haben. Schauen Sie später noch einmal vorbei, um neue Nachrichten zu sehen!'
        }
    },
    premiumScreen: {
        createAlert: (symbol: string) => `Erstellen Sie eine Warnung für ${symbol}`,
        deepAnalysisAlt: 'Vorschau des Deep Analysis-Bildschirms',
        returnToSummary: 'Zurück zur Übersicht',
        subHeader: 'Sie haben gerade Ihr Spiel verbessert. Verwenden Sie die untenstehenden Schaltflächen, um Ihre neuen Superkräfte zu erkunden.',
        viewCharts: (symbol: string, symbol2: string) => `${symbol} und ${symbol2} vergleichen`,
        viewChartsAlt: 'Vorschau mehrerer Diagramme',
        viewDeepAnalysis: (symbol: string) => `${symbol} Deep Analysis anzeigen`,
        viewMi: (symbol: string) => `Market Intelligence-Inhalte für ${symbol} jetzt anzeigen!`,
        viewMiAlt: 'Bild von Rohstoff-Futures',
        welcomeHeader: (name: string) => `Willkommen bei ${name}!`,
        checkout: {
            title: 'Zur Kasse',
            changePlan: 'Plan ändern',
            nextBilled: (date: Date) => `Sie werden das nächste Mal am ${format(date, 'dd. MMM yyyy')} abgerechnet`,
            cancelAnytime: 'Jederzeit kündbar',
            offerTerms: 'Angebotsbedingungen gelten',
            details: {
                title: 'Wichtig',
                body: (productName: string) => `Zahlungen für ${productName} werden automatisch über Ihr StoneX-Konto abgerechnet.`,
                multipleAccounts:
                    'Da Sie mehrere Konten haben, müssen Sie uns mitteilen, welches Konto Sie verwenden möchten. Bitte tun Sie dies unten (Sie können dies jederzeit ändern):'
            },
            selectAccount: (hasMultipleAccounts?: boolean) => (hasMultipleAccounts ? 'Wählen Sie ein Brokerage-Konto zum Abbuchen aus' : 'Brokerage-Konto zum Abbuchen'),
            buttonText: 'Abonnement starten'
        },
        selectProduct: {
            goPremium: 'Premium werden',
            or: 'Oder',
            pickYourPackage: 'Wählen Sie Ihr Paket',
            perMonth: 'pro Monat',
            comingSoon: 'Demnächst!',
            comingSoonToStonexOne: 'Demnächst bei StoneX One:',
            select: 'Auswählen',
            bestValue: 'Bestes Preis-Leistungs-Verhältnis!',
            jumbotron: {
                ourBestDeal: 'Unser bestes Angebot!',
                title: [
                    // [
                    // { text: 'StoneX One Premium', weight: 'bold', size: 'title' },
                    // { text: ' and ', size: 'title' },
                    // { text: 'Market Intelligence', weight: 'bold', size: 'title' },
                    // { text: ' in one convenient bundle.', size: 'title' }
                    // ]
                ],
                perMonthAbbr: '/Monat',
                moreOptions: 'Weitere Optionen',
                getStarted: 'Loslegen'
            },
            products: {
                sx1Premium: 'StoneX One Premium',
                miPremium: 'Market Intelligence Premium',
                sx1miBundle: 'StoneX One + MI Bundle'
            },
            features: {
                deepAnalysis: {
                    title: 'Deep Analysis',
                    subtitle: 'Insider, Gewinnaufrufe und mehr!'
                },
                multiChart: { title: 'Mehrere Diagramme' },
                customAlerts: { title: 'Benutzerdefinierte Warnungen' },
                miPremiumArticles: { title: 'Premium-Rohstoffforschung' }
            }
        }
    },

    managePremiumScreen: {
        premium: 'Premium',
        havePremiumTitle: 'Sie haben StoneX One Premium!',
        havePremiumSubtitle: 'Rock on!',
        dontHavePremiumTitle: 'Sie haben kein Premium',
        dontHavePremiumSubtitle: 'Schalten Sie Analysen frei wie keine andere.',
        features: {
            earningsCalls: 'Hören Sie sich aktuelle und historische Gewinnaufrufe für alle Aktien mit Transkripten an.',
            financials:
                'Erhalten Sie Zugriff auf Finanzdaten wie Sentiment-Analyse, Bilanz, Gewinn- und Verlustrechnung, Cashflow-Berichte, prognostizierte Umsätze, Dividendenhistorie und mehr für alle Aktien.',
            institutions: 'Sehen Sie, welche Institutionen, Fonds und Führungskräfte die meisten Aktien eines Unternehmens handeln.'
        },
        startForJust: (price: string | number) => `Starten Sie für nur $${price}/Monat.`,
        howPaymentsAreMade: 'Zahlungen erfolgen direkt von Ihrem StoneX-Konto, keine Karte erforderlich.',
        startPremiumNow: 'Jetzt Premium starten!',
        paymentAccount: 'Zahlungskonto',
        selectPaymentAccount: 'Zahlungskonto auswählen',
        nextBill: 'Nächste Rechnung',
        contactSupportForBillingHistory: 'Bitte kontaktieren Sie den Support bezüglich Ihrer Rechnungshistorie.',
        contactSupport: 'Support kontaktieren',
        cancelMyPremium: 'Mein Premium kündigen',
        cancelAreYouSure: 'Sind Sie sicher, dass Sie kündigen möchten?',
        cancelYouCanUncancel: (ex: Date) => `Sie können Ihre Kündigung jederzeit vor dem ${SafeFormat(ex, 'd. MMMM')} rückgängig machen, um Ihr Premium beizubehalten.`,
        yesCancelMyPremium: 'Ja, mein Premium kündigen',
        noKeepMyPremium: 'Nein, mein Premium behalten',
        premiumEnd: 'Premium-Ende',
        canceledTitle: (ex: Date) => `Ihr Premium endet am ${SafeFormat(ex, 'd. MMMM')}`,
        canceledSubtitle: 'Heben Sie Ihre Kündigung unten auf, um Ihr Premium zu behalten.',
        undoCancelation: 'Kündigung widerrufen'
    },

    weather: {
        sectionTitle: 'Wetter',
        current: {
            chanceOfPrecipitation: 'Niederschlagswahrscheinlichkeit',
            dewPoint: 'Taupunkt',
            humidity: 'Luftfeuchtigkeit',
            visibility: 'Sichtweite',
            feelsLike: 'Gefühlte Temperatur',
            airPressure: 'Luftdruck',
            wind: 'Wind',
            gusts: 'Böen',
            sprayCondition: 'Sprühbedingung'
        },
        hourly: {
            hour: 'Stunde',
            climate: 'Klima',
            rainChance: 'Regenwahrscheinlichkeit',
            rainAmount: 'Regenmenge',
            temperature: 'Temperatur',
            windGust: 'Wind (Böen)'
        }
    },
    misc: {
        backToTorch: 'Vorherige Version anzeigen',
        manageAccountApplications: 'Kontobewerbungen verwalten',
        selectAnAccount: 'Ein Konto auswählen',
        account: 'Konto',
        allAccounts: 'Alle Konten',
        showFewer: 'Weniger anzeigen',
        showLess: 'Weniger anzeigen',
        showXMore: (n: number) => `Zeige ${n} mehr`,
        showMore: 'Mehr anzeigen',
        accounts: (n: number) => `Konto${n === 1 ? '' : 's'}`,
        heldBy: 'gehalten von',
        addAccountButton: 'Konto hinzufügen',
        addAccount: 'Konto hinzufügen',
        addEquitiesAccount: 'Aktienkonto hinzufügen',
        addFuturesAccount: 'Futures-Konto hinzufügen',
        noResults: 'Keine Ergebnisse',
        noMoreResults: 'Keine weiteren Ergebnisse',
        errorLoadingResults: 'Fehler beim Laden der Ergebnisse',
        tryAgain: 'Erneut versuchen',
        today: 'Heute',
        ranges: {
            oneDay: '1T',
            twentyFourHours: '24h',
            fiveDay: '5T',
            oneWeek: '1W',
            oneMonth: '1M',
            threeMonths: '3M',
            sixMonths: '6M',
            oneYear: '1J',
            fiveYear: '5J',
            all: 'ALLE'
        },
        search: {
            noResults: 'Keine Ergebnisse gefunden, versuchen Sie eine andere Suche',
            placeholder: 'Nach einem Wertpapier suchen...'
        },
        news: {
            noTitle: 'Kein Titel',
            noSummaryAvailable: 'Zusammenfassung nicht verfügbar'
        },
        tradeTypeButton: {
            buyToOpen: 'Kaufen zum Eröffnen',
            sellToOpen: 'Verkaufen zum Eröffnen',
            sellToClose: 'Verkaufen zum Schließen',
            buyToClose: 'Kaufen zum Schließen',
            buy: 'Kaufen',
            sell: 'Verkaufen',
            shortSell: 'Leerverkauf',
            buySell: 'Kauf-Verkauf',
            sellBuy: 'Verkauf-Kauf',
            liquidate: 'Liquidieren',
            liquidatePosition: (securityId: string) => `Oder, Sie können Ihre Position liquidieren, was den Verkauf aller Ihrer Anteile an ${securityId} bedeutet.`
        },
        chatWithSupport: 'Chatten Sie mit uns',
        showVolume: 'Volumen anzeigen',
        loggingOut: 'Sie werden abgemeldet...',
        amountEstimate: 'Dieser Betrag ist eine Schätzung. Ihre Bestellung wird zum bestmöglichen Preis ausgeführt',
        newIPO: 'Neue IPOs am Horizont.',
        offeringPrice: 'Angebotspreis:',
        screenNotExist: 'Dieser Bildschirm existiert nicht.',
        goHomeScreen: 'Zur Startseite gehen!',
        stopAllStreaming: 'Alle Streams stoppen'
    },
    disclosureMessages: {
        tax: 'StoneX One bietet keine Steuerberatung an.',
        finra: 'StoneX © 2021. Konten geführt von StoneX Financial Inc., Mitglied von FINRA/SIPC, und eine hundertprozentige Tochtergesellschaft der StoneX Group Inc.',
        delayedQuotes: 'Kurse sind um 15 Minuten verzögert.',
        glancePreviousClose: 'Änderung gegenüber dem vorherigen Schlusskurs',
        projectedOrderCost: 'Auftragskosten basieren auf dem Limitpreis; tatsächliche Kosten können variieren',
        topMovers: `SEKTOREN- und BEWEGER-Listen werden mit Daten von Drittanbietern erstellt.
        Eine Liste von Unternehmen in einem bestimmten Geschäftssektor kann ein Unternehmen anzeigen, wenn die Quelldaten angeben, dass das Unternehmen einen Teil seiner Einnahmen aus dem relevanten Sektor erzielt.
        Die Liste dient nur zu Informationszwecken und stellt keine Anlageberatung oder Empfehlung zum Kauf, Halten oder Verkauf eines Wertpapiers dar.
        Listen sind keine personalisierten Empfehlungen für Sie oder andere Kunden, und die aufgeführten Wertpapiere sind möglicherweise nicht für Sie geeignet.
        Sie sollten kein Wertpapier kaufen oder verkaufen, ohne zuerst zu bestimmen, ob es für Ihr Portfolio oder Ihre Anlagestrategie geeignet ist.
        StoneX One übernimmt keine Gewährleistung oder Garantie in Bezug auf die Genauigkeit, Aktualität oder Vollständigkeit von Informationen Dritter, und die Bereitstellung dieser Informationen stellt keine Empfehlung dar.`,
        totalPnL: 'Dieser Betrag spiegelt den nicht realisierten Gewinn/Verlust für diese Position wider'
    },

    header: {
        alerts: 'Benachrichtigungen',
        tip: 'Tipp',
        trySearch: 'Versuchen Sie, nach Ihrem Lieblingswertpapier zu suchen',
        summary: 'Übersicht',
        positions: 'Positionen',
        banking: 'Bargeld',
        messages: 'Nachrichten',
        profile: 'Profil',
        help: 'Hilfe',
        legalMore: 'Rechtliches & Mehr',
        goPaperless: 'Papierlos werden',
        documents: 'Dokumente',
        tradeHistory: 'Aufträge',
        fixedPlus: 'Fixed+',
        transactions: 'Transaktionen',
        projectedIncome: 'Prognostiziertes Einkommen',
        disclosures: 'Offenlegungen',
        logOut: 'Abmelden',
        buyingPower: 'Kaufkraft',
        portfolioValue: 'Portfoliowert',
        holdings: 'Bestände',
        upcomingEarnings: 'Bevorstehende Gewinne',
        upcomingIpos: 'Bevorstehende IPOs',
        searchForAnything: 'Nach allem suchen, was gehandelt werden kann...',
        exploreMarkets: 'Märkte erkunden',
        addToList: 'Zur Liste hinzufügen',
        optionsHint: 'für Optionen',
        chatWithUs: 'Chatten Sie mit uns',
        emailUs: 'E-Mail an uns',
        resetTours: 'Tour anzeigen',
        verifyResetTours: 'Sind Sie sicher, dass Sie Ihren Tour-Fortschritt zurücksetzen möchten?',
        amex: 'Probieren Sie heute AMEX aus!',
        helpPopup: {
            contactUs: 'Kontaktieren Sie uns',
            liveChat: 'Live-Chat'
        }
    },

    notFoundSceen: {
        header: 'Es sieht so aus, als ob diese Seite nicht existiert...',
        securityHeader: 'Es sieht so aus, als ob dieses Wertpapier nicht existiert...',
        otherOptions: 'Hier sind einige andere Optionen, die Ihnen gefallen könnten',
        portfolioButton: 'Mein Portfolio',
        positionsButton: 'Meine Positionen',
        mobile: {
            wrongTurn: 'Entschuldigung, es sieht so aus, als hätten wir uns verlaufen!',
            backToStoneX: 'Zurück zu StoneX'
        }
    },

    watchlistScreen: {
        items: (numItems?: number) => `${numItems} ${numItems === 1 ? 'Element' : 'Elemente'}`,
        notFound: {
            primary: 'Es sieht so aus, als ob diese Watchlist nicht existiert',
            secondary: "Wählen Sie eine andere Watchlist aus oder erstellen Sie eine, indem Sie auf das '+' rechts klicken"
        },
        noItems: {
            primary: 'Es sieht so aus, als ob diese Liste leer ist',
            secondary: (name: string) => `Alle zu '${name}' hinzugefügten Elemente werden hier angezeigt.`
        },
        marketCap: {
            header: 'Was ist die Marktkapitalisierung?'
        },
        noLists: {
            noListTitle: 'Keine Listen',
            noListSubtitle: 'Behalten Sie Ihre Lieblingswerte mit einer Liste im Auge. Erstellen Sie unten Ihre erste Liste.',
            createNewList: 'Neue Liste erstellen',
            addToList: 'Zur Liste hinzufügen',
            emptyListTitle: 'Es sieht so aus, als ob diese Liste leer ist',
            emptyListSubtitle: 'Bleiben Sie über Ihre Lieblingsunternehmen auf dem Laufenden, indem Sie sie zu dieser Liste hinzufügen.',
            addMore: 'Mehr hinzufügen'
        },
        defaultDescription: 'Fügen Sie eine Watchlist-Beschreibung hinzu',
        createNewWatchlist: 'Neue Watchlist erstellen',
        addToWatchlist: (qsi: string) => `${qsi} zur Watchlist hinzufügen`,
        editWatchlist: 'Watchlist-Namen bearbeiten',
        createWatchlist: 'Watchlist erstellen',
        watchlistName: 'Watchlist-Name',
        create: 'Erstellen',
        update: 'Aktualisieren',
        topStories: 'Top-Nachrichten'
    },

    portfolioScreen: {
        skipToWatchlists: 'Zu Watchlists springen',
        portfolio: 'Portfolio',
        buyingPower: {
            buyingPower: 'Kaufkraft',
            marginCashAvailable: 'Margin-Bargeld verfügbar',
            cashAvailable: 'Bargeld verfügbar',
            marginAvailable: 'Margin verfügbar',
            nyseExcess: 'NYSE-Überschuss',
            startUsingMargin: 'Margin verwenden',
            totalAvailableForTrading: 'Gesamt verfügbar für Handel',
            whichAccountToAddMargin: 'Zu welchem Konto möchten Sie Margin hinzufügen?',
            initialMargin: 'Initiale Margin',
            netLiquidity: 'Netto-Liquidität'
        },
        summary: {
            allAccounts: 'Alle Konten',
            assetsHeldAway: 'Extern gehaltene Vermögenswerte',
            buyingPower: 'Kaufkraft',
            cash: 'Bargeld',
            cashAndEquivalents: 'Bargeld & Äquivalente',
            initialMargin: 'Initiale Margin',
            longMarketValue: 'Long-Marktkapitalisierung',
            longOptionValue: 'Long-Optionswert',
            totalAccountValue: 'Gesamtkontowert',
            netLiq: 'Netto-Liquidationswert',
            netOptionValue: 'Netto-Optionswert',
            openPnL: 'Offener Gewinn/Verlust',
            realizedPnL: 'Realisierter Gewinn/Verlust',
            shortMarketValue: 'Short-Marktkapitalisierung',
            shortOptionValue: 'Short-Optionswert',
            summary: 'Zusammenfassung',
            totalPnL: 'Gesamt-Gewinn/Verlust',
            unrealizedPnL: 'Unrealisierter Gewinn/Verlust',
            viewProjectedIncome: 'Prognostiziertes Einkommen anzeigen'
        },
        portfolioBreakdown: {
            cashAndEquivalents: 'Bargeld & Äquivalente',
            equities: 'Aktien',
            stocks: 'Aktien',
            futures: 'Futures',
            mutualFunds: 'Investmentfonds',
            fixedIncome: 'Festverzinsliche Wertpapiere',
            rightsAndWarrants: 'Rechte und Warrants',
            options: 'Optionen',
            uits: 'UITs',
            others: 'Andere',
            preciousMetals: 'Edelmetalle',
            entertainment: 'Unterhaltung',
            miscellaneous: 'Verschiedenes',
            telecommunications: 'Telekommunikation',
            broadcastingRadioAndTv: 'Rundfunk - Radio & TV',
            automobileAndTrucks: 'Automobile/LKWs',
            instrumentation: 'Instrumentierung',
            notGrouped: 'Nicht gruppiert',
            investmentCompanies: 'Investmentgesellschaften',
            portfolioBreakdown: 'Portfolio-Aufschlüsselung',
            contractsBreakdown: 'Vertragsaufschlüsselung',
            accountBreakdown: 'Kontenaufschlüsselung',
            holdingsBreakdown: 'Beständeaufschlüsselung',
            shares: 'Anteile',
            breakdown: 'Aufschlüsselung',
            sectorExposure: 'Sektor-Exposition',
            top10holdings: 'Top 10 Bestände',
            investing: 'Investieren',
            representingAssets: (number: string) => `Repräsentiert ${number} der Gesamtvermögenswerte`
        },
        sectors: {
            sectors: 'Sektoren',
            asOf: (date: Date) => `Stand ${SafeFormat(date, 'dd MMM yyyy, hh:mm bb')}`,
            customerDiscretionary: 'Zyklische Konsumgüter',
            healthCare: 'Gesundheitswesen',
            technology: 'Technologie',
            realEstate: 'Immobilien',
            consumerStaples: 'Basiskonsumgüter',
            utilities: 'Versorgungsunternehmen',
            communicationServices: 'Kommunikationsdienste',
            financials: 'Finanzen',
            materials: 'Materialien',
            industrials: 'Industrie',
            energy: 'Energie',
            addToWatchlist: 'Zur Watchlist hinzufügen',
            noSecurities: 'Keine Wertpapiere',
            emptyList: 'Es sieht so aus, als ob diese Liste leer ist.'
        },
        topMovers: {
            futuresTopMovers: 'Beweger des Frontmonats',
            topMovers: 'Top-Beweger',
            topGainers: 'Top-Gewinner',
            topLosers: 'Top-Verlierer',
            unavailable: 'Daten nicht verfügbar',
            topMoversUnavailable: 'Top-Beweger sind derzeit nicht verfügbar',
            frontMoversUnavailable: 'Beweger des Frontmonats sind derzeit nicht verfügbar',
            topGainersUnavailable: 'Top-Gewinner sind derzeit nicht verfügbar',
            topLosersUnavailable: 'Top-Verlierer sind derzeit nicht verfügbar'
        },
        glance: {
            title: 'Anlagebegriffe',
            portfolioValue: 'Portfoliowert',
            accountValue: 'Kontowert',
            amountInvested: 'Investierter Betrag',
            amountInvestedInfo:
                'Dieser Betrag umfasst die offenen Wertpapierpositionen Ihres Brokerage-Kontos sowie das Bargeld und die Anschaffungskosten Ihres Futures-Kontos.',
            todaysGainLoss: 'Heutiger Gewinn/Verlust',
            todaysGainLossInfo: 'Diese Berechnung schließt keine Futures-Positionen ein.',
            unrealizedGainLoss: 'Unrealisierter Gewinn/Verlust',
            unrealizedGainLossInfo: 'Aktueller Gewinn/Verlust aller offenen Positionen.',
            buyingPower: 'Kaufkraft',
            cash: 'Bargeld',
            asOfPreviousClose: '(Stand vorheriger Schlusskurs)',
            lastUpdated: (updated: string) => `Zuletzt aktualisiert ${updated}`
        },
        sidebar: {
            stocks: 'Aktien',
            mutualFunds: 'Investmentfonds',
            fixedIncome: 'Festverzinsliche Wertpapiere',
            options: 'Optionen',
            shares: 'Anteile',
            watchlists: 'Watchlists',
            sortBy: 'Sortieren nach',
            price: 'Preis',
            value: 'Wert',
            display: 'Anzeigen',
            delete: 'Löschen',
            add: 'Hinzufügen',
            edit: 'Details bearbeiten...',
            expires: (date: Date) => `Läuft ab ${SafeFormat(date, 'dd MMM yyyy')}`,
            strike: 'Strike'
        },
        warnings: {
            pleaseCheckLater: 'Bitte später noch einmal nachsehen, um weitere Entwicklungen zu diesem Thema zu erfahren.',
            systemBulletin: 'Systemmitteilung',
            resolvingIssues: 'Wir lösen ein Problem, das die Verfügbarkeit unseres ',
            system: 'Systems',
            systems: 'Systeme',
            weAreResolvingIssues: 'Wir lösen ein Systemproblem.',
            theseSystems: 'diese Systeme',
            theSystem: 'das System',
            incidentFirstReported: ' Dieser Vorfall wurde erstmals gemeldet ',
            statusEvaluated: (systems: string) => ` Der Status von ${systems} wurde zuletzt bewertet `
        },
        totalPortfolioValue: 'Gesamtportfoliowert',
        asOfDate: 'Stand-Datum',
        commonStock: 'Stammaktien',
        preferredStock: 'Vorzugsaktien',
        newListName: 'Neuer Listenname',
        create: 'Erstellen',
        documents: 'Dokumente',
        readyToStart: (isBeta: boolean) => (isBeta ? 'Bereit, loszulegen?' : 'Bereit, mit dem Handel zu beginnen?'),
        depositFunds: 'Geld hinzufügen',
        getStarted: 'Beginnen Sie, indem Sie Ihren ersten Handel platzieren.',
        myPositions: 'Meine Positionen',
        topStories: 'Top-Geschichten'
    },

    accountScreen: {
        accountBreakdown: 'Kontobestand',
        summaryDeficientChartPoints:
            'Der Zusammenfassungsdiagramm enthält derzeit nicht einen oder mehrere Werte Ihrer Konten. Dies hat keinen Einfluss auf Ihre tatsächlichen Konten oder Ihr Kaufkraft.',
        accountDeficientChartPoints:
            'Der Kontendiagramm hat derzeit nicht genügend Daten, um dargestellt zu werden. Dies hat keinen Einfluss auf Ihr tatsächliches Konto oder Ihre Kaufkraft.',
        pending: {
            title: (plural?: boolean) => `${plural ? 'Konten' : 'Konto'} in Genehmigung ausstehend`,
            info: (plural?: boolean) =>
                `Ihr ${
                    plural ? 'Konten werden' : 'Konto wird'
                } derzeit von einem unserer Spezialisten geprüft. Bitte überprüfen Sie später, ob sich dieser Status aktualisiert hat.`,
            extrasHeader: 'Während Sie warten',
            explore: 'Erkunden Sie die Märkte',
            getHelp: 'Holen Sie sich Unterstützung von unserem Support-Team'
        },
        viewDetails: 'Details anzeigen',
        noAssetBreakdownAvailable: 'Für dieses Konto sind keine Vermögensaufschlüsselungen verfügbar.',
        noIndustryBreakdownAvailable: 'Für dieses Konto sind keine Branchenaufschlüsselungen verfügbar.',
        byAsset: 'Nach Vermögenswert',
        byIndustry: 'Nach Branche',
        buyingPower: {
            initialMargin: 'Anfangsmargin',
            netLiquidity: 'Nettoliquidität'
        },
        myAccountStatusApplication: {
            docsRequired: {
                title: 'Erforderliche Dokumente',
                info: 'Wir benötigen einige Dokumente von Ihnen, um Ihre Anwendung zu bearbeiten.'
            },
            inProgress: {
                title: 'Antrag wird überprüft',
                info: 'Ihr Konto wird derzeit von einem unserer Spezialisten überprüft. Überprüfen Sie später, ob Ihr Konto genehmigt wurde.'
            },
            rejected: {
                title: 'Antrag abgelehnt',
                info: 'Ihr Kontoantrag wurde abgelehnt. Sehen Sie sich Ihren Kontoantrag an oder wenden Sie sich an unser Support-Team unten.'
            },
            verificationFailed: {
                title: 'Kontoverifizierung fehlgeschlagen',
                info: 'Wir konnten einige Informationen in unserer Anwendung nicht verifizieren. Sehen Sie sich Ihren Kontoantrag an oder wenden Sie sich an unser Support-Team unten.'
            },
            submitted: {
                title: 'Antrag eingereicht',
                info: 'Ihr Kontoantrag wurde eingereicht! Überprüfen Sie später, ob Ihr Konto genehmigt wurde.'
            },
            viewApplication: 'Anwendungsstatus anzeigen'
        }
    },

    positionsScreen: {
        account: 'Konto',
        allIndustries: 'Alle Branchen',
        allTypes: 'Alle Typen',
        averageCost: 'Durchschn. Kosten',
        change: 'Veränderung',
        changePercent: 'Veränderung %',
        closed: 'Geschlossen',
        cost: 'Kosten',
        date: 'Datum',
        downloadAll: 'Alle herunterladen',
        downloadClosed: 'Geschlossene Positionen herunterladen',
        downloadClosedFileName: () => `geschlossene-positionen-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        downloadFilename: () => `positionen-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        hide: 'Verbergen',
        longAmount: 'Long-Betrag',
        marketValue: 'Marktwert',
        marketValueFull: 'Marktwert',
        name: 'Name',
        noOpenLots: 'Keine offenen Steuerlose für diese Position verfügbar.',
        notGrouped: 'Nicht gruppiert',
        open: 'Offen',
        openPnL: 'Offener P/L',
        positions: 'Positionen',
        price: 'Preis',
        quantity: 'Menge',
        realizedPnlFor: (thingItsFor: string) => `Realisierter Gewinn und Verlust für ${thingItsFor}`,
        rollPosition: 'Position rollen',
        shortAmount: 'Short-Betrag',
        show: 'Anzeigen',
        showClosedLots: 'Geschlossene Steuerlose anzeigen',
        taxLotPreviousDayDisclosure: 'Kosten- und Gewinn- & Verlustwerte werden, wenn verfügbar, unter Verwendung angepasster Kosten berechnet.',
        taxLotsFor: (thingItsFor: string) => `Steuerlose für ${thingItsFor}`,
        taxYear: 'Steuerjahr',
        term: 'Laufzeit',
        todaysGainLoss: 'Heutiger P/L',
        todaysGainLossPercent: 'Heutiger P/L %',
        todaysGainLossTab: 'Heutiger Gewinn / Verlust',
        totalCost: 'Gesamtkosten',
        totalGainLoss: 'Gesamter P/L',
        totalGainLossPercent: 'Gesamter P/L %',
        totalGainLossTab: 'Gesamter Gewinn / Verlust',
        type: 'Typ',
        unrealizedProfitLoss: 'Unrealisierter Gewinn/Verlust',
        industryGroups: {
            [IndustryGroups.cashAndEquivalents]: 'Bargeld & Äquivalente',
            [IndustryGroups.automotiveAndTrucks]: 'Automobile / Lastwagen',
            [IndustryGroups.broadcastingRadioAndTv]: 'Rundfunk - Radio & TV',
            [IndustryGroups.conglomerates]: 'Konglomerate',
            [IndustryGroups.metalsAndMining]: 'Metalle & Bergbau',
            [IndustryGroups.miscellaneous]: 'Verschiedenes',
            [IndustryGroups.mutualFunds]: 'Investmentfonds',
            [IndustryGroups.consumerProducts]: 'Konsumgüter',
            [IndustryGroups.commercialPaper]: 'Handelspapiere',
            [IndustryGroups.treasuryBillsFlat]: 'Schatzwechsel - Flat',
            [IndustryGroups.corporateBonds]: 'Unternehmensanleihen',
            [IndustryGroups.mortgageBackedBonds]: 'Hypothekenbesicherte Anleihen',
            [IndustryGroups.govermentBonds]: 'Staatsanleihen',
            [IndustryGroups.municipalBonds]: 'Kommunalanleihen',
            [IndustryGroups.municipalBondsPut]: 'Kommunalanleihen-Put',
            [IndustryGroups.preferredStocks]: 'Vorzugsaktien',
            [IndustryGroups.aerospace]: 'Luft- und Raumfahrt',
            [IndustryGroups.advertising]: 'Werbung',
            [IndustryGroups.airTransport]: 'Lufttransport',
            [IndustryGroups.aluminum]: 'Aluminium',
            [IndustryGroups.autoParts]: 'Autoteile',
            [IndustryGroups.banks]: 'Banken',
            [IndustryGroups.beveragesBrewers]: 'Getränke - Brauereien',
            [IndustryGroups.beveragesDistillers]: 'Getränke - Destillerien',
            [IndustryGroups.beveragesSoftDrinks]: 'Getränke - Erfrischungsgetränke',
            [IndustryGroups.biotechnology]: 'Biotechnologie',
            [IndustryGroups.brokerageFirms]: 'Maklerfirmen',
            [IndustryGroups.buildingMaterials]: 'Baumaterialien',
            [IndustryGroups.businessServices]: 'Unternehmensdienstleistungen',
            [IndustryGroups.cement]: 'Zement',
            [IndustryGroups.chemicalDiversified]: 'Chemikalien - Diversifiziert',
            [IndustryGroups.chemicalSpecialties]: 'Chemische Spezialitäten',
            [IndustryGroups.coal]: 'Kohle',
            [IndustryGroups.copper]: 'Kupfer',
            [IndustryGroups.containersMetalGlass]: 'Behälter Metall / Glas',
            [IndustryGroups.containersPlastic]: 'Behälter - Plastik',
            [IndustryGroups.cosmetics]: 'Kosmetik',
            [IndustryGroups.dataProcessing]: 'Datenverarbeitung',
            [IndustryGroups.drugs]: 'Medikamente',
            [IndustryGroups.electricEquipment]: 'Elektrische Ausrüstung',
            [IndustryGroups.ermergingGrowth]: 'Wachstumsunternehmen',
            [IndustryGroups.entertainment]: 'Unterhaltung',
            [IndustryGroups.finance]: 'Finanzen',
            [IndustryGroups.fertilizers]: 'Düngemittel',
            [IndustryGroups.foods]: 'Lebensmittel',
            [IndustryGroups.foodServiceAndVending]: 'Lebensmittelservice & Automaten',
            [IndustryGroups.forestProducts]: 'Forstprodukte',
            [IndustryGroups.gaming]: 'Glücksspiel',
            [IndustryGroups.healthCareFacilities]: 'Gesundheitseinrichtungen',
            [IndustryGroups.homeFurnishings]: 'Einrichtungsgegenstände',
            [IndustryGroups.hospitalSupplies]: 'Krankenhausbedarf',
            [IndustryGroups.hotelsMotels]: 'Hotels & Motels',
            [IndustryGroups.householdAppliances]: 'Haushaltsgeräte',
            [IndustryGroups.industrialServices]: 'Industriedienstleistungen',
            [IndustryGroups.instrumentation]: 'Instrumentierung',
            [IndustryGroups.insurance]: 'Versicherung',
            [IndustryGroups.investmentCompanies]: 'Investmentgesellschaften',
            [IndustryGroups.leisureTime]: 'Freizeit',
            [IndustryGroups.machinery]: 'Maschinen',
            [IndustryGroups.machineTools]: 'Werkzeugmaschinen',
            [IndustryGroups.manufacturing]: 'Fertigung',
            [IndustryGroups.metalsFabricating]: 'Metallverarbeitung',
            [IndustryGroups.mobileHomes]: 'Mobile Homes',
            [IndustryGroups.officeEquipment]: 'Büroausstattung',
            [IndustryGroups.oilAndGas]: 'Öl & Gas',
            [IndustryGroups.oilService]: 'Öldienstleistungen',
            [IndustryGroups.paper]: 'Papier',
            [IndustryGroups.photography]: 'Fotografie',
            [IndustryGroups.printingAndPublishing]: 'Druck & Verlag',
            [IndustryGroups.railroads]: 'Eisenbahnen',
            [IndustryGroups.realEstate]: 'Immobilien',
            [IndustryGroups.restaurants]: 'Restaurants',
            [IndustryGroups.retailStores]: 'Einzelhandel - Geschäfte',
            [IndustryGroups.retailFoodChains]: 'Einzelhandel - Lebensmittelketten',
            [IndustryGroups.retailDrugChains]: 'Einzelhandel - Drogerieketten',
            [IndustryGroups.retailMerchandising]: 'Einzelhandel - Handelswaren',
            [IndustryGroups.savingsAndLoan]: 'Sparkassen & Darlehen',
            [IndustryGroups.shoes]: 'Schuhe',
            [IndustryGroups.soaps]: 'Seifen',
            [IndustryGroups.steels]: 'Stahl',
            [IndustryGroups.telecommunications]: 'Telekommunikation',
            [IndustryGroups.textiles]: 'Textilien',
            [IndustryGroups.tiresAndRubber]: 'Reifen & Gummi',
            [IndustryGroups.tobacco]: 'Tabak',
            [IndustryGroups.toys]: 'Spielzeug',
            [IndustryGroups.transportation]: 'Transport',
            [IndustryGroups.trucking]: 'Lastwagen',
            [IndustryGroups.utilitiesElectric]: 'Versorger - Elektrizität',
            [IndustryGroups.utilitiesGas]: 'Versorger - Gas',
            [IndustryGroups.utilitiesOther]: 'Versorger - Andere',
            [IndustryGroups.options]: 'Optionen'
        },
        closedPositions: {
            parentHeader: {
                symbolId: 'Symbol / ID',
                description: 'Beschreibung',
                totalQuantity: 'Gesamtmenge',
                totalCost: 'Gesamtkosten',
                totalProceeds: 'Gesamterlös',
                totalRealizedGainLoss: 'Gesamter realisierter Gewinn / Verlust',
                totalRealizedGainLossPercent: 'Gesamter realisierter Gewinn / Verlust %'
            },
            childHeader: {
                adjustedCost: 'Angepasste Kosten',
                closeDate: 'Schließungsdatum',
                closePrice: 'Schlusspreis',
                cost: 'Kosten',
                exchange: 'Börse',
                openDate: 'Eröffnungsdatum',
                price: 'Preis',
                priceOf: (qsi: string) => `${qsi} Preis`,
                proceeds: 'Erlös',
                quantity: 'Menge',
                realizedGainLoss: 'Realisierter Gewinn / Verlust',
                realizedGainLossPercent: 'Realisierter Gewinn / Verlust %',
                realizedGl: 'Realisierter Gewinn/Verlust',
                side: 'Seite',
                term: 'Laufzeit',
                totalCost: 'Gesamtkosten',
                tradeDate: 'Handelsdatum'
            }
        }
    },

    bankingScreen: {
        errors: {
            ainsIdentity: {
                title: 'Identität konnte nicht überprüft werden.',
                description:
                    'Es tut uns leid, aber wir konnten Ihre Identität nicht überprüfen. Bitte kontaktieren Sie uns unten, und wir werden unser Bestes tun, um die Dinge in Ordnung zu bringen.'
            },
            ainsMax: 'Entschuldigung, Sie haben die maximal zulässige Anzahl von verknüpften Bankkonten erreicht. Bitte entfernen Sie eins, um fortzufahren.',
            ainsMaxVoid: 'Entschuldigung, das Entfernen von Bankkonten ist auf eine Entfernung alle zwei Wochen beschränkt. Bitte versuchen Sie es später erneut.',
            betaDowntimeWindow: 'Überweisungen sind von 17 bis 20 Uhr EST nicht verfügbar.',
            transfersUnavailable: 'Überweisungen können derzeit nicht eingereicht werden.',
            insufficientWithdrawalBalanceTitle: 'Unzureichendes Guthaben',
            insufficientWithdrawalBalance:
                'Sie haben nicht genügend Guthaben für die von Ihnen angeforderte Überweisung. Wenn Sie kürzlich eine oder mehrere Positionen verkauft haben, müssen Sie möglicherweise bis zum nächsten Handelstag warten, bis Ihre Mittel sich ausgleichen und für die Überweisung verfügbar werden.',
            noAccountsAvailable: {
                title: 'Es stehen keine Konten zur Auswahl zur Verfügung.',
                description:
                    'Es tut uns leid, aber Ihnen stehen keine Konten zum Hinzufügen zur Verfügung. Bitte kontaktieren Sie uns unten, und wir werden unser Bestes tun, um die Dinge in Ordnung zu bringen.'
            },
            pendingAccount: 'Ausstehendes Konto',
            retrievingAccountsFailed: {
                title: 'Leider ist beim Abrufen Ihrer Konten ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal oder ',
                actionTitle: 'kontaktieren Sie den Support.'
            }
        },
        nextVoidAllowed: (nextVoid: Date) => `Sie können dieses Konto am ${format(nextVoid, 'DD.MM.YYYY')} löschen.`,
        maxReached: (maxAccounts: number) => `Maximal ${maxAccounts} Konten erreicht.`,
        fundingError: {
            title: 'Ein Fehler ist aufgetreten',
            subtitle: 'Bitte versuchen Sie es erneut oder kontaktieren Sie uns für Unterstützung',
            tryAgain: 'Erneut versuchen'
        },
        tryAgainIn: (dateForUnlock: Date) => `Versuchen Sie es erneut ${SafeFormatToNow(dateForUnlock)}`,
        tryAgainTomorrow: 'Versuchen Sie es morgen erneut',
        tryAgainOnMonday: 'Versuchen Sie es am Montag erneut',
        dontSeeAccount: 'Sie sehen das Konto, das Sie hinzufügen möchten, nicht? Bitte kontaktieren Sie uns unten, und wir werden die Dinge in Ordnung bringen.',
        history: 'Verlauf',
        requests: 'Anfragen',
        disclaimer:
            'Die oben genannten Informationen dienen nur zu ergänzenden und informativen Zwecken. Bitte sehen Sie sich Ihren täglichen Kontoauszug für den offiziellen Kontoverlauf an.',
        transferFunds: 'Geld überweisen',
        depositVerb: 'Einzahlung',
        withdrawVerb: 'Auszahlung',
        depositNoun: 'Einzahlung',
        withdrawalNoun: 'Auszahlung',
        fundsWillBeCredited: 'Die Gelder werden innerhalb der nächsten Tage auf Ihr Bankkonto gutgeschrieben.',
        fundsWillBeDebited: 'Ihre Gelder werden in Kürze verfügbar sein.',
        from: 'Von',
        to: 'An',
        amount: 'Betrag',
        review: 'Überprüfen',
        reviseTransfer: 'Überweisung überarbeiten',
        depositsLocked: (days: number) =>
            days === 1
                ? 'Einzahlungen sind aufgrund zu vieler fehlgeschlagener Versuche gesperrt. Bitte versuchen Sie es morgen erneut oder wenden Sie sich an den Support.'
                : `Einzahlungen sind aufgrund zu vieler fehlgeschlagener Versuche gesperrt. Bitte versuchen Sie es in ${days} Tagen erneut oder wenden Sie sich an den Support.`,
        withdrawLocked: (days: number) =>
            days === 1
                ? 'Auszahlungen sind aufgrund zu vieler fehlgeschlagener Versuche gesperrt. Bitte versuchen Sie es morgen erneut oder wenden Sie sich an den Support.'
                : `Auszahlungen sind aufgrund zu vieler fehlgeschlagener Versuche gesperrt. Bitte versuchen Sie es in ${days} Tagen erneut oder wenden Sie sich an den Support.`,
        cashHeader: 'Bargeld',
        depositFunds: 'Geld direkt von einem verknüpften Bankkonto auf Ihr StoneX-Konto einzahlen.',
        withdrawFunds: 'Geld von Ihrem StoneX-Konto auf ein verknüpftes Bankkonto abheben.',
        selectLinkedBankAcct: 'Wählen Sie das verknüpfte Bankkonto aus, das Sie verwenden möchten.',
        retrievingBankAcct: 'Ihre Bankkonten werden abgerufen...',
        linkBankAcctWith: 'Bankkonto verknüpfen mit',
        amountMayNotExceed: (limit: number) => `Der Betrag darf ${limit} nicht überschreiten.`,
        amountMustBeAtleast: (limit: number) => `Der Betrag muss mindestens ${limit} betragen.`,
        dailyLimitText: (limits) => [
            Light('Verbleibendes tägliches Limit '),
            Dark(FormatNumber.toMoney(limits?.remainingClearance)),
            Light(' von '),
            Dark(FormatNumber.toMoney(limits?.dailyMaximum))
        ],
        dailyMaximumText: (limits: FundingLimits, isDeposit: boolean) => [
            Light('Verbleibendes tägliches Limit '),
            Dark(FormatNumber.toMoney(isDeposit ? limits?.remainingDepositAmount : limits?.remainingWithdrawAmount)),
            Light(' von '),
            Dark(FormatNumber.toMoney(isDeposit ? limits?.maxDailyDepositAmount : limits?.maxDailyWithdrawAmount))
        ],
        dailyLimitText2: (limits) => {
            const cented = (money: number) => FormatNumber.toMoney(money).replace(/\.00$/, '');
            return `${cented(limits.transferredToday)} von ${cented(limits.dailyMaximum)} heute überwiesen.`;
        },
        minimumAllowedDepositText: (isDeposit: boolean, minAllowedDeposit: number | null) => {
            const minAllowedDepositText = isDeposit && !!minAllowedDeposit ? [Light(' ( '), Dark(FormatNumber.toMoney(minAllowedDeposit)), Light(' Minimum )')] : [];
            return minAllowedDepositText;
        },
        managementLimitText: (limits: FundingManagementLimits | null) => {
            if (!limits) return '';
            if (!limits.canAdd) {
                if (!limits.canVoid) return 'Um verknüpfte Konten hinzuzufügen oder zu entfernen, wenden Sie sich bitte an den Support';
                else return 'Um ein verknüpftes Konto hinzuzufügen, wenden Sie sich bitte an den Support';
            } else if (!limits.canVoid) return 'Um ein verknüpftes Konto zu entfernen, wenden Sie sich bitte an den Support';
            else return '';
        },

        fundingAvailableBalance: (availableBalance?: number) => [Light('Verfügbar zum Abheben: '), Dark(FormatNumber.toMoneyOpt2(availableBalance))],
        amountToTransfer: (transferDirection?: 'deposit' | 'withdraw') => `Betrag zum ${transferDirection === 'deposit' ? 'Einzahlen' : 'Abheben'}`,
        amountExceedsLimit: 'Betrag überschreitet das tägliche Limit',
        amountExceedsBalance: 'Betrag überschreitet das Bankguthaben',
        amountExceedsAccountBalance: 'Betrag überschreitet das Kontoguthaben',
        accountIsPending: 'Konto ist ausstehend',
        plaidReauthNeeded: 'Neuauthentifizierung mit Plaid erforderlich',
        plaidAuthenticationBroken: 'Plaid-Authentifizierung fehlgeschlagen, bitte kontaktieren Sie den Support',
        addYourBankAccount: 'Fügen Sie Ihr Bankkonto hinzu',
        addABankAccount: 'Bankkonto hinzufügen',
        addASandboxBankAccount: 'Sandbox-Bankkonto hinzufügen',
        back: 'Zurück',
        pleaseWait: 'Bitte warten...',
        validatingTransfer: 'Überweisung wird überprüft',
        thereWasAProblem: 'Es gab ein Problem',
        unknownError: {
            title: 'Es ist etwas Unerwartetes passiert.',
            subtitle: 'Bitte kontaktieren Sie den Support.'
        },
        fundingNotAllowed: {
            title: 'Finanzierung ist nicht erlaubt.',
            subtitle: 'Bitte kontaktieren Sie den Support, wenn Sie glauben, dass Sie dies fälschlicherweise erhalten haben.'
        },
        failedDailyFundingLimitCheck: {
            title: 'Ihre Anfrage überschreitet das tägliche Finanzierungslimit.',
            subtitle: 'Bitte kontaktieren Sie den Support, wenn Sie glauben, dass Sie dies fälschlicherweise erhalten haben.'
        },
        cosmosPlaidAccessFailed: {
            title: 'Abrufen von Plaid-Daten fehlgeschlagen.',
            subtitle: 'Bitte kontaktieren Sie den Support oder versuchen Sie es erneut.'
        },
        plaidBalanceCheckFailed: {
            title: 'Nicht genügend Geld auf dem Konto.',
            subtitle: 'Bitte kontaktieren Sie den Support, wenn Sie glauben, dass Sie dies fälschlicherweise erhalten haben.'
        },
        cosmosPlaidLinkIdMatchFailed: {
            title: 'Fehler bei der Verknüpfungskontosuche.',
            subtitle: 'Bitte kontaktieren Sie den Support oder versuchen Sie es erneut.'
        },
        plaidIdentityInfoFailed: {
            title: 'Abrufen von Bankdaten von Plaid fehlgeschlagen.',
            subtitle: 'Bitte kontaktieren Sie den Support oder versuchen Sie es erneut.'
        },
        likeKindNameCheckFailed: {
            title: 'Kontoinhaberschaft kann nicht überprüft werden.',
            subtitle: 'Bitte kontaktieren Sie den Support.'
        },
        previouslyLinkedAccountIdLookupFailed: {
            title: 'Fehler beim Verknüpfen des Plaid-Kontos mit dem StoneX One-Konto.',
            subtitle: 'Bitte kontaktieren Sie den Support oder versuchen Sie es erneut.'
        },
        failedToLinkAccount: {
            title: 'Fehler beim Verknüpfen des Plaid-Kontos mit dem StoneX One-Konto.',
            subtitle: 'Bitte kontaktieren Sie den Support oder versuchen Sie es erneut.'
        },
        transferWindowClosed: {
            title: 'Übertragungssystem ist wegen Wartung nicht verfügbar.',
            subtitle: 'Bitte kontaktieren Sie den Support, wenn Sie glauben, dass Sie dies fälschlicherweise erhalten haben.'
        },
        transferFailed: {
            title: 'Übertragung fehlgeschlagen.',
            subtitle: 'Bitte kontaktieren Sie den Support oder versuchen Sie es erneut.'
        },
        transferRequestFailed: 'Anfrage fehlgeschlagen',
        submittedRequestSuccessfully: 'Anfrage erfolgreich eingereicht',
        asyncInfo: 'Sie werden benachrichtigt, sobald diese Anfrage bearbeitet wurde. Bitte melden Sie sich nicht ab, bis diese Anfrage bearbeitet wurde.',
        depositsLockedError: {
            title: 'Einzahlungen gesperrt',
            subtitle: 'Zu viele fehlgeschlagene Versuche.'
        },
        minimumDepositNotMet: {
            title: (amt?: number) => (amt ? `Mindesteinzahlungsbetrag [${FormatNumber.toMoneyOpt2(amt)}] nicht erreicht.` : 'Mindesteinzahlungsbetrag nicht erreicht.'),
            subtitle: 'Bitte kontaktieren Sie den Support, wenn Sie glauben, dass Sie dies fälschlicherweise erhalten haben.'
        },
        maximumAccountsReached: {
            title: 'Konto kann nicht hinzugefügt werden. Maximale Anzahl von Konten erreicht',
            subtitle: 'Bitte kontaktieren Sie den Support, wenn Sie glauben, dass Sie dies fälschlicherweise erhalten haben.'
        },
        ainsInstructionsInactive: {
            title: 'Bankkontoinformationen für die Überweisung können nicht abgerufen werden.',
            subtitle: 'Bitte kontaktieren Sie den Support.'
        },
        maximumDepositsReached: {
            title: 'Einzahlungen gesperrt',
            subtitle: (amt: number, days: number) =>
                days === 1 ? `Nur ${amt} Einzahlungen können pro Tag getätigt werden.` : `Nur ${amt} Einzahlungen können alle ${days} Tage getätigt werden.`
        },
        maximumWithdrawalsReached: {
            title: 'Auszahlungen gesperrt',
            subtitle: (amt: number, days: number) =>
                days === 1 ? `Nur ${amt} Auszahlungen können pro Tag getätigt werden.` : `Nur ${amt} Auszahlungen können alle ${days} Tage getätigt werden.`
        },
        pleaseTryAgain: 'Bitte versuchen Sie es erneut',
        submittedSuccessfully: 'Übertragung eingereicht!',
        operationSubmittedSuccessfully: (transfer) => {
            const operation = (() => {
                switch (transfer.transferDirection) {
                    case 'deposit':
                        return 'Einzahlung';
                    case 'withdraw':
                        return 'Auszahlung';
                    default:
                        return '';
                }
            })();

            return `${operation} eingereicht`;
        },
        returnToSummary: 'Zurück zur Zusammenfassung',
        startAnotherTransfer: 'Eine weitere Überweisung starten',
        bankingUnavailable: 'Bankgeschäfte nicht verfügbar',
        pleaseComeBackLaterOrContactUs:
            'Es tut uns leid, Bankgeschäfte sind derzeit nicht verfügbar. Bitte versuchen Sie es später erneut, oder kontaktieren Sie uns unten, und wir kümmern uns darum.',
        depositUnavailableExplanation:
            'Es tut uns leid, Einzahlungen sind derzeit nicht verfügbar. Bitte versuchen Sie es später erneut, oder kontaktieren Sie uns unten, und wir kümmern uns darum.',
        depositUnavailableDueToTimeExplanation:
            'Es tut uns leid, Einzahlungen sind zwischen 14:00 und 8:00 Uhr EST nicht verfügbar. Bitte versuchen Sie es außerhalb dieser Zeiten erneut, oder kontaktieren Sie uns unten, und wir kümmern uns darum.',
        depositUnavailableDueToTimeExplanationShort: 'Einzahlungen sind zwischen 17:00 und 18:00 Uhr EST nicht verfügbar',
        depositUnavailableDueHolidaysExplanationShort: 'Einzahlungen sind an Handelstagen nicht verfügbar',
        withdrawUnavailableExplanation:
            'Es tut uns leid, Auszahlungen sind zwischen 14:00 und 8:00 Uhr EST nicht verfügbar. Bitte versuchen Sie es außerhalb dieser Zeiten erneut, oder kontaktieren Sie uns unten, und wir kümmern uns darum.',
        withdrawUnavailableExplanationShort: 'Auszahlungen sind zwischen 14:00 und 7:00 Uhr EST nicht verfügbar',
        withdrawUnavailableWeekendsExplanation: 'Auszahlungen sind an Wochenenden nicht verfügbar',
        withdrawUnavailableHolidaysExplanation: 'Auszahlungen sind an Feiertagen nicht verfügbar',
        accountPending: 'Ihr ausgewähltes StoneX-Konto wartet auf Genehmigung',
        billPay: 'Rechnung bezahlen',
        creditCardDirect: 'Kreditkarte Direkt',
        wiringInstructions: 'Verkabelungsanweisungen',
        viewWiringInstructions: 'Verkabelungsanweisungen anzeigen',
        wiringInstructionsPdfUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/en-US/WiringInstructions.pdf',
        verificationStatus: (status) => (status.includes('pending') ? 'Ausstehende Überprüfung' : 'Überprüfung fehlgeschlagen'),

        // Mobile
        selectAccount: 'Konto auswählen',
        forAddedSecurity: 'Für zusätzliche Sicherheit werden Sie aufgefordert, Ihre Bankanmeldeinformationen erneut einzugeben',
        cannotAddLimitReachedTitle: 'Kann kein weiteres Konto hinzufügen',
        cannotOpenPlaid: 'Plaid kann nicht geöffnet werden, es wurde kein Verknüpfungstoken bereitgestellt',
        cannotAddLimitReachedExplainer: 'Es können nur zwei Bankkonten gleichzeitig verknüpft werden',
        linkAcctContactSupport: 'Um ein verknüpftes Konto hinzuzufügen, kontaktieren Sie bitte den Support',
        confirmText: (transfer: Partial<TransferRequest>, snexAccount: Account, fundingSource?: FundingSource) => {
            const isDeposit = transfer.transferDirection === 'deposit';
            const extPreposition = isDeposit ? 'von' : 'in';
            const snexPreposition = isDeposit ? 'in' : 'von';
            const source = fundingSource || transfer.fundingSource;

            return [
                { text: `Sie ${isDeposit ? 'zahlen ein' : 'heben ab'}`, shade: 'light' },
                { text: FormatNumber.toMoneyOpt2(transfer.transferAmount), shade: 'dark' },
                { text: `${extPreposition} Ihrem`, shade: 'light' },
                { text: source?.name, shade: 'dark' },
                { text: 'Konto endend auf', shade: 'light' },
                { text: `****${source?.mask}`, shade: 'dark' },
                { text: `${snexPreposition}`, shade: 'light' },
                { text: snexAccount?.nickname || snexAccount?.name, shade: 'dark' }
            ];
        },
        lost: {
            title: 'Nicht gefunden',
            subtitle: 'Es scheint, als ob wir uns verirrt haben! Bitte versuchen Sie es erneut, und wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte.',
            startOver: 'Erneut versuchen'
        },

        limitReached: 'Limit erreicht',
        linkYourBankAccount: 'Verknüpfen Sie Ihr Bankkonto',
        limitReachedExplainer: (limits: FundingManagementLimits): ShadedTextList => {
            const maxLinked = limits?.maxLinkedCount;
            const maxVoid = limits?.maxVoidCount;
            const voidDays = limits?.maxVoidFrequencyDays;
            const s = (n: number) => (n === 1 ? '' : 's');
            const num = Snex1German.general.writtenInteger0Thru10;

            const addRule: ShadedTextList = [
                {
                    text: 'Als Sicherheitsmaßnahme dürfen Sie nur ',
                    shade: 'light'
                },
                {
                    text: `${num(maxLinked)} Bankkonto${s(maxLinked)} hinzufügen`,
                    shade: 'dark'
                },
                { text: '. ', shade: 'light' }
            ];
            const voidRule: ShadedTextList = [
                {
                    text: 'Bitte beachten Sie auch, dass Sie nur ',
                    shade: 'light'
                },
                { text: `${num(maxVoid)} Konto${s(maxVoid)} entfernen`, shade: 'dark' },
                { text: ' alle ', shade: 'light' },
                { text: `${num(voidDays)} Tag${s(voidDays)}`, shade: 'dark' },
                { text: ' entfernen dürfen. ', shade: 'light' }
            ];

            const contactSupport: ShadedTextList = [
                {
                    text: 'Wenn Sie ein Konto hinzufügen oder entfernen möchten, kontaktieren Sie bitte den Support unten.',
                    shade: 'light'
                }
            ];

            return [...addRule, ...voidRule, ...contactSupport];
        },
        limitReachedExplainerV2: (maxAdd: number, maxVoid: number, voidDays: number): ShadedTextList => [
            { text: 'Als Sicherheitsmaßnahme dürfen Sie nur ', shade: 'light' },
            { text: `${maxAdd} Bankkonto${maxAdd === 1 ? '' : 's'} hinzufügen`, shade: 'dark' },
            { text: '. Bitte beachten Sie auch, dass Sie nur ', shade: 'light' },
            { text: `${maxVoid} Konto${maxVoid === 1 ? '' : 's'} entfernen`, shade: 'dark' },
            { text: ' alle ', shade: 'light' },
            { text: `${voidDays} Tag${voidDays === 1 ? '' : 'e'}`, shade: 'dark' },
            { text: ' entfernen dürfen. Wenn Sie ein Konto hinzufügen oder entfernen möchten, kontaktieren Sie bitte den Support unten.', shade: 'light' }
        ],
        youMayLinkNMoreAccounts: (n) => {
            if (n <= 0) return 'Sie können keine weiteren Bankkonten verknüpfen';
            return `Sie können ${n} weitere Bankkonto${n === 1 ? '' : 's'} hinzufügen`;
        },
        linkYourAccountToAddFunds: 'Verknüpfen Sie Ihr Bankkonto, um Geld auf Ihr StoneX-Konto einzuzahlen.',
        authenticateWithPlaid: 'Mit Plaid authentifizieren',
        noBankAccounts: 'Keine Bankkonten',
        linkAnotherBankAccount: 'Ein weiteres Bankkonto verknüpfen',
        acats: {
            acatsComingSoon: {
                title: 'Überweisungen werden bald verfügbar sein!',
                subtitle: 'Bei Fragen wenden Sie sich bitte an uns.'
            },
            infoIcon: 'ACATS-Ablehnungen - Häufigste Ursachen',
            transferAccount: 'Konto übertragen',
            introText:
                'Übertragen Sie Bargeld und Anlagen von einem anderen Brokerage-Konto auf Ihr StoneX One-Konto, entweder vollständig oder teilweise. Übermitteln Sie Ihre Überweisung in wenigen Minuten und sehen Sie Ihre übertragenen Vermögenswerte in 2-3 Wochen auf Ihrem Konto erscheinen.',
            from: 'Von',
            accountNumber: 'Kontonummer',
            accountTitle: 'Kontotitel',
            accountSubtitle: 'Kontounterüberschrift',
            to: 'Zu',
            start: 'Start',
            continue: 'Weiter',
            skip: 'Überspringen',
            back: 'Zurück',
            submit: 'Einreichen',
            selectTransferType: 'Überweisungsart auswählen',
            fullTransfer: 'Vollständige Überweisung',
            fullTransferDescription: 'Übertragen Sie das gesamte Bargeld und die Positionen von Ihrem externen Konto auf Ihr StoneX One-Konto',
            partialTransfer: 'Teilüberweisung',
            partialTransferDescription: 'Wählen Sie das Bargeld und die Positionen aus, die Sie auf Ihr StoneX One-Konto übertragen möchten',
            cashOptions: 'Bargeldoptionen',
            transferAllCash: 'Alles Bargeld überweisen',
            transferNoCash: 'Kein Bargeld überweisen',
            transferCustomCash: 'Benutzerdefinierte Menge überweisen',
            positions: 'Positionen',
            transferNoPositions: 'Keine Positionen überweisen',
            addPosition: 'Positionen hinzufügen',
            positionAccountType: 'Kontotyp',
            cash: 'Bargeld',
            margin: 'Marge',
            all: 'Alle',
            custom: 'Benutzerdefiniert',
            or: 'Oder',
            positionQuantity: 'Menge',
            done: 'Fertig',
            transferringAllCash: 'Alles Bargeld',
            transferringNoneCash: 'Kein Bargeld',
            transferringCustomCash: (amount: number) => `Übertragung von ${FormatNumber.toMoney(amount)}`,
            transferringNoPositions: 'Keine Positionen',
            confirmTransfer: (type) => {
                if (type === 'Full' || type === 'Partial') return `Überweisung von ${type} bestätigen`;
                else return 'Überweisung bestätigen';
            },
            transferringFull: 'Alle Vermögenswerte werden auf Ihr StoneX One-Konto übertragen.',
            assets: 'Vermögenswerte',
            noAssetsSelected: {
                title: 'Überweisung kann nicht eingereicht werden; keine Vermögenswerte ausgewählt',
                subtitle:
                    'Um Ihre Überweisung abzuschließen, gehen Sie bitte zurück und fügen Sie Bargeld und/oder Positionen zu Ihrer Überweisung hinzu oder ändern Sie Ihre Überweisung in eine Vollüberweisung',
                switchToFull: 'Zur Vollüberweisung wechseln'
            },
            submitting: {
                title: 'Ihre Überweisung wird eingereicht',
                subtitle: 'Bitte warten Sie...'
            },
            success: {
                title: 'Überweisung eingereicht!',
                subtitle: 'Ihr Konto sollte innerhalb der nächsten 14-21 Werktage überwiesen werden.'
            },
            failure: {
                title: 'Etwas ist schiefgegangen',
                subtitle: 'Bitte kontaktieren Sie uns und wir werden die Dinge in Ordnung bringen.'
            },
            returnToSummary: 'Zurück zur Zusammenfassung'
        }
    },

    newVersionScreen: {
        newVersion: 'Neue Version',
        nowAvailable: 'Jetzt verfügbar',
        updateAndSee: 'Aktualisieren und sehen Sie, wie wir Ihnen eine bessere Erfahrung für Ihre finanzielle Reise bieten können!',
        update: 'Aktualisieren',
        notNow: 'Nicht jetzt',
        accountInfoNotShared: 'Wenn Sie aktualisieren, werden Ihre Kontoinformationen nicht an Dritte weitergegeben.',
        privacyPolicyLearnMore: 'Erfahren Sie mehr über unsere Datenschutzrichtlinie.'
    },

    messagesScreen: {
        messages: 'Nachrichten',
        noMessages: 'Keine Nachrichten',
        errorFetchingMessages: 'Entschuldigung, wir konnten Ihre Nachrichten derzeit nicht abrufen',
        viewAll: 'Alle anzeigen',
        read: 'Lesen',
        messageId: 'Nachrichten-ID',
        stoneXOneTeam: 'StoneX One Team',
        unknownSender: 'Unbekannter Absender',
        backToAllMessages: 'Zurück zu allen Nachrichten',
        deleteAll: 'Alle löschen',
        noMessagesTitle: 'Ihre Nachrichten erscheinen hier',
        noMessagesSubtitle: 'Ihr Posteingang ist leer. Sobald Sie Nachrichten erhalten, werden sie hier angezeigt.',
        newMessage: 'Neue Nachricht'
    },
    profileScreen: {
        profile: 'Profil',
        logOut: 'Abmelden',
        copyClipBoard: 'In die Zwischenablage kopieren',
        emailSettings: 'E-Mail-Einstellungen',
        settings: 'Einstellungen',
        about: 'Über',
        build: 'Build',
        version: 'Version',
        snex1Premium: 'StoneX One Premium',
        deactivate: 'Mitgliedschaft deaktivieren',
        errorDuplicateValue: 'Der eingegebene Wert entspricht dem Wert im Datensatz. Keine weiteren Maßnahmen erforderlich.',
        tabs: {
            personalInfo: 'Persönliche Informationen',
            accounts: 'Konten',
            paperless: 'Papierlos',
            local: 'Lokal'
        },
        themes: {
            dark: 'Dunkel',
            light: 'Hell',
            system: 'Systemeinstellung',
            marketHours: 'US-Aktienmarktzeiten'
        },
        personalInfo: {
            tabs: {
                profilePicture: 'Profilbild',
                personalDetails: 'Persönliche Details',
                preferences: 'Einstellungen',
                notifications: 'Benachrichtigungseinstellungen',
                taxInformation: 'Steuerinformationen',
                premiumSubscription: 'Ihr Abonnement'
            },
            viewPersonalInfo: {
                title: 'Persönliche Details',
                name: 'Name',
                email: 'E-Mail',
                birthDate: 'Geburtsdatum'
            },
            loginForm: {
                signup: 'Registrieren',
                next: 'Weiter',
                code: 'Code',
                codeSent: 'Code gesendet',
                phoneNumber: 'Telefonnummer',
                tosAndPrivacyConsentText: [
                    { text: 'Durch die Anmeldung stimmen Sie unseren ', shade: 'light' },
                    { text: 'Nutzungsbedingungen', shade: 'dark', id: 'tos' },
                    { text: ' und der ', shade: 'light' },
                    { text: 'Datenschutzerklärung', shade: 'dark', id: 'privacy' },
                    { text: ' zu.', shade: 'light' }
                ],
                verificationCodeSentMessage: 'Wir haben einen Bestätigungscode an Ihre E-Mail-Adresse gesendet. Bitte geben Sie den Code hier ein.',
                confirmPassword: 'Passwort bestätigen',
                logIn: 'Anmelden',
                getStarted: 'Loslegen',
                wantMore: 'Mehr erfahren?',
                startTrading: (qsi: string) => `Mit dem Handel ${qsi} beginnen`,
                couldNotLogIn: 'Entschuldigung, es gab ein Problem beim Einloggen',
                couldNotResetYourPassword: 'Entschuldigung, wir konnten Ihr Passwort nicht zurücksetzen',
                username: {
                    email: 'E-Mail'
                },
                confirmSignup: {
                    newUserTitle: 'Willkommen bei StoneX One!',
                    newUserSubtitle: (email: string) =>
                        `Es sieht so aus, als hätten wir die E-Mail ${email} nicht in unserem System. Möchten Sie mit der Registrierung fortfahren?`,
                    returningUserTitle: 'Willkommen zurück bei StoneX One!',
                    returningUserSubtitle: `Es sieht so aus, als hätten wir die Registrierung beim letzten Versuch, sich anzumelden, nicht abgeschlossen. Wir müssen einige Informationen erneut eingeben und Ihre E-Mail-Adresse überprüfen. Möchten Sie fortfahren?`
                },
                confirmEmployeeEmail: {
                    title: 'Hinweis zur Mitarbeiteranmeldung',
                    subtitle:
                        'Mitarbeiter von StoneX werden ermutigt, eine nicht firmeneigene E-Mail-Adresse zu verwenden, um auf StoneX One zuzugreifen. Wenn Sie fortfahren, werden Sie zu unserem Online-Anmeldeportal weitergeleitet.',
                    androidInstructionsTitle: 'Hinweis für Android-Benutzer',
                    androidInstructions:
                        'Android-Benutzer sollten den Bestätigungscode aus der Okta Verify-App kopieren, bevor sie fortfahren, die Option zum Eingeben eines Codes auswählen, wenn sie danach gefragt werden, und den Code dann einfügen, wenn sie dazu aufgefordert werden.',
                    continueAnyway: 'Trotzdem fortfahren'
                },
                password: {
                    forgotPassword: 'Passwort vergessen',
                    resetPassword: 'Passwort zurücksetzen',
                    recoverAccount: 'Konto wiederherstellen',
                    password: 'Passwort'
                },
                mfa: {
                    title: 'Mehrstufige Überprüfung',
                    sendingCodePleaseWait: 'Code wird gesendet, bitte warten',
                    mfaCodeSendMessage: 'Wir haben einen Bestätigungscode an Ihre Telefonnummer gesendet. Bitte geben Sie den Code hier ein.',
                    pleaseEnterYourPhone: 'Wir benötigen Ihre Telefonnummer, um Sie zu authentifizieren. Bitte geben Sie unten Ihre Telefonnummer ein.'
                },
                bioauth: {
                    biometrics: 'Biometrie',
                    whatBiometricsAre: 'Möchten Sie für den Zugriff auf Ihr Konto einen biometrischen Faktor (Fingerabdruck, Gesicht etc.) nutzen?',
                    allUnlocksTitle: 'Alle Freischaltungen',
                    allUnlocksDescription: 'Wir werden jedes Mal nach Ihrem biometrischen Faktor fragen, wenn Sie StoneX One starten',
                    verificationOnlyTitle: 'Nur Überprüfung',
                    verificationOnlyDescription: 'Wir werden nur nach Ihrem biometrischen Faktor fragen, wenn wir Ihre Identität überprüfen müssen',
                    disableTitle: 'Deaktiviert', // Variant for config screen
                    disableDescription: 'Wir werden nicht nach Ihrem biometrischen Faktor fragen',
                    enableTitle: 'Biometrie aktivieren',
                    enableDescription: 'Für den Zugriff auf StoneX One wird eine biometrische Authentifizierung verwendet.',
                    notDesiredTitle: 'Nein, keine Biometrie verwenden',
                    notDesiredDescription: 'Der Zugang wird nicht biometrisch gesichert (nicht empfohlen)',
                    bioEnabledTitle: 'Biometrie aktiviert',
                    bioEnabledDescription: 'Ihr Konto ist durch biometrische Authentifizierung gesichert',
                    bioDisabledTitle: 'Biometrie deaktiviert',
                    bioDisabledDescription: 'StoneX One verwendet beim Anmeldeversuch keine biometrische Authentifizierung (nicht empfohlen)',
                    notpermitted: {
                        title: 'Biometrie nicht aktiviert',
                        message: 'Bitte aktivieren Sie die Biometrie in den Einstellungen, um sich mit Face ID oder Fingerabdruck einzuloggen.',
                        link: 'Aktivieren',
                        dismiss: 'Jetzt nicht'
                    },
                    disableBiometrics: 'Biometrie deaktivieren',
                    yesUpdateBiometrics: 'Ja, Biometrie aktualisieren',
                    passwordUpdateSuccess: 'Passwort erfolgreich aktualisiert!',
                    confirmReenableBioAuth: 'Möchten Sie die biometrische Anmeldung mit Ihrem neuen Passwort wieder aktivieren?'
                },
                country: {
                    countryOfCitizenship: 'Land der Staatsangehörigkeit'
                },
                passwordReset: {
                    resetSent: 'Wiederherstellungsnachricht gesendet',
                    instructions: 'Wir haben Ihnen eine E-Mail mit weiteren Schritten gesendet, um Ihnen zu helfen, den Zugriff auf Ihr Konto wiederherzustellen.',
                    returnToLogin: 'Zurück zur Anmeldung'
                },
                whiteLabelAlert: {
                    title: 'Kein Konto gefunden',
                    message: 'Bitte wenden Sie sich an Ihren Vertriebsmitarbeiter oder den Support, wenn Sie glauben, dass dies ein Fehler ist.',
                    dismiss: 'Schließen'
                }
            },
            profile: 'Profil',
            firstName: 'Vorname',
            lastName: 'Nachname',
            emailAddress: 'E-Mail-Adresse',
            language: 'Sprache',
            theme: 'Thema',
            themeDescription: 'Systemeinstellung folgt dem Theme Ihres Geräts, und die Handelszeiten werden dunkel, wenn der Markt geschlossen ist.',
            oneClickTrading: 'One-Click-Handel',
            oneClickTradingDescription:
                'Der One-Click-Handel ermöglicht es Ihnen, einen Handel mit einem Klick abzuschließen, ohne den Handel zu überprüfen. Sie können den One-Click-Handel deaktivieren, um weiterhin den Überprüfungsbildschirm vor dem Platzieren eines Handels zu sehen.',
            futuresStrikes: 'Futures-Optionsketten-Streiks',
            optionsChainStrikes: 'Optionen-Ketten-Streiks',
            systemDefault: 'Systemstandard',
            dockSidePanel: 'Offene Aufträge und Beobachtungsliste anheften',
            tradingViewTicker: 'Tickerleiste',
            tradingViewTickerDescription:
                'Die Tickerleiste ermöglicht Ihnen einen horizontalen Blick auf wichtige Instrumentenstatistiken, basierend auf TradingView. Enthält den neuesten Preis der Symbole sowie tägliche Änderungen. Einige Preise können um 15 Minuten oder mehr verzögert sein.',
            dockSidePanelDescription:
                'Das Seitenfenster für offene Aufträge und Beobachtungsliste ermöglicht Ihnen, Ihre offenen Aktien- & Options-, Investmentfonds- und Futures-Aufträge sowie Ihre Beobachtungsliste anzuzeigen. Bitte beachten Sie, dass dies nicht auf den Übersichts- oder Kontobildschirmen angezeigt wird.',
            timezone: 'Zeitzone',
            edit: 'Bearbeiten',
            smsNumber: 'SMS-Telefonnummer',
            email: 'E-Mail',
            smsPhone: 'SMS-Telefon',
            notificationsDescription: 'Teilen Sie uns mit, wie Sie kontaktiert werden möchten.',
            sendEmail: 'E-Mail senden',
            sendText: 'SMS senden',
            addPhoneNumber: 'Telefonnummer hinzufügen',
            verificationNeeded: 'Verifizierung erforderlich',
            profilePictureDisclaimer: (maxMb: number) =>
                `Ihr ausgewähltes Profilbild wird öffentlich sichtbar und unterliegt unseren Nutzungsbedingungen. Bitte wählen Sie ein Bild aus, das weniger als ${maxMb} MB groß ist.`,
            removeProfilePicture: 'Aktuelles Bild entfernen',
            imageTooLarge: (maxMb: number) => `Entschuldigung, Ihr Bild ist zu groß. Bitte wählen Sie ein Bild kleiner als ${maxMb}MB aus`,
            unexpectedUploadError: 'Ein unerwartetes Problem ist beim Aktualisieren Ihres Bildes aufgetreten',
            idType: {
                idType: 'ID-Typ',
                alienId: 'Alien-ID',
                governmentId: 'Regierungs-ID',
                passport: 'Reisepass',
                ssn: 'Sozialversicherungsnummer',
                taxIdEin: 'US-Steuer-ID / EIN'
            },
            mdcVerify: {
                email: {
                    title: 'E-Mail aktualisieren',
                    inputText: 'Geben Sie unten Ihre neue E-Mail-Adresse ein.',
                    tryDifferent: 'Verwenden Sie eine andere E-Mail-Adresse',
                    successText: 'E-Mail-Adresse verifiziert und aktualisiert!'
                },
                phone: {
                    title: 'Telefonnummer aktualisieren',
                    phone: 'Telefon',
                    phoneNumber: 'Telefonnummer',
                    inputText: 'Geben Sie unten Ihre neue Telefonnummer ein.',
                    tryDifferent: 'Verwenden Sie eine andere Telefonnummer',
                    successText: 'Telefonnummer verifiziert und aktualisiert!'
                },
                pin: 'PIN',
                badPin: 'Entschuldigung, diese PIN wird nicht erkannt',
                resendPin: 'Verifizierungs-PIN erneut senden',
                unableToSendPin: 'Ihre Nachricht konnte nicht zugestellt werden',
                unableToSendPinInstructions: (num) =>
                    `Es scheint, als hätte Ihre Telefonnummer den Empfang von Textnachrichten von uns abgelehnt. Bitte senden Sie START an ${FormatNumber.toPhone(
                        num
                    )} und versuchen Sie es erneut.`,
                tryAgain: 'Erneut versuchen',
                unexpectedError: 'Entschuldigung, es ist ein unerwarteter Fehler aufgetreten',
                alreadyHaveAVerificationCode: 'Haben Sie bereits einen Verifizierungs-PIN?',
                clickHereToEnterYourCode: 'Klicken Sie hier, um Ihren PIN einzugeben',
                updateButton: 'Aktualisieren',
                verifyButton: 'Überprüfen',
                pinSentPleaseEnter: 'Verifizierungs-PIN gesendet. Bitte geben Sie den erhaltenen PIN unten ein.',
                toRecieveTextNotifications: 'Um Benachrichtigungen per SMS zu erhalten, fügen Sie bitte Ihre Telefonnummer hinzu.',
                addPhoneNumber: 'Telefonnummer hinzufügen'
            },
            taxId: 'Steuer-ID',
            dateOfBirth: 'Geburtsdatum',
            birthYear: 'Geburtsjahr',
            birthMonth: 'Monat',
            birthDay: 'Tag',
            whyDoWeNeedThis: 'Warum brauchen wir das?',
            taxInfoExplainer:
                'Wir verwenden Ihre ID und Ihr Geburtsdatum, um Ihre Beziehung zu verschiedenen Konten zu bestimmen, damit Sie Ihre Präferenzen für die papierlose Zustellung verwalten und Ihre Konten finanzieren können. Diese Informationen werden auch verwendet, um Ihr Eigentum an zusätzlichen Konten zu überprüfen. Ohne diese Informationen können wir Ihnen keinen vollen Kontozugriff gewähren.',
            toUpdateThisInformation: 'Um diese Informationen zu aktualisieren, wenden Sie sich bitte an den StoneX One Support',
            okay: 'Okay',
            save: 'Speichern',
            saving: 'Wird gespeichert...',
            saved: 'Gespeichert!',
            mdcControls: {
                notificationSettings: 'Benachrichtigungseinstellungen',
                notificationSettingsToolTips: {
                    missingPhone: 'Bitte fügen Sie eine gültige Telefonnummer hinzu, um diese Zustellmethode zu aktivieren',
                    invalidEventOrDeliveryMethod: 'Nachrichten für dieses Ereignis und diese Zustellmethode sind nicht verfügbar'
                },
                email: 'E-Mail',
                sms: 'SMS',
                smsSettings: 'SMS-Einstellungen',
                pushNotifications: 'Push-Benachrichtigungen',
                notifications: 'Benachrichtigungen',
                events: {
                    newOrderIsPlaced: 'Neue Bestellung wird platziert',
                    orderIsFilled: 'Bestellung wurde ausgeführt',
                    orderIsPartiallyFilled: 'Bestellung ist teilweise ausgeführt',
                    orderIsCanceled: 'Bestellung wurde storniert',
                    orderIsRejected: 'Bestellung wurde abgelehnt',
                    orderIsModified: 'Bestellung wurde geändert',
                    orderIsStopped: 'Bestellung wurde gestoppt',
                    orderIsSuspended: 'Bestellung wurde ausgesetzt',
                    orderIsOpenForBidding: 'Bestellung ist zur Abgabe geöffnet',
                    customAlertFired: 'Benutzerdefinierter Alarm ausgelöst',
                    optionExpiringSoon: 'Gehaltene Option läuft bald ab'
                }
            },
            managePremium: {
                cancel: 'Premium-Abonnement kündigen',
                canceled: (exp: string) => `Sie haben Ihr Abonnement gekündigt. Sie haben Zugriff auf Premium bis zum ${exp}.`,
                cancelModalText: (exp: string) =>
                    `Möchten Sie das Premium-Abonnement wirklich kündigen?\n\nSie können Ihre Kündigung jederzeit vor dem ${exp} rückgängig machen, um Ihr Premium zu behalten.`,
                confirmCancelation: 'Kündigung bestätigen',
                get: 'Premium erhalten',
                loading: 'Einen Moment bitte...',
                pitch: `Hören Sie sich Gewinnaufrufe an, überprüfen Sie die Top-Investoren Ihrer Lieblingsaktien und analysieren Sie das vollständige Finanzprofil Tausender Finanzprodukte für nur ${FormatNumber.toMoney(
                    PremiumProducts.premium.price
                )} pro Monat.`,
                reactivate: 'Reaktivieren / Verwalten',
                subscribed: (exp: string) =>
                    `Herzlichen Glückwunsch! Sie sind für StoneX One Premium angemeldet. Ihre nächste Rechnung in Höhe von ${FormatNumber.toMoney(
                        PremiumProducts.premium.price
                    )} wird am ${exp} ausgestellt.`
            }
        },
        accounts: {
            accounts: 'Konten',
            top: 'Top',
            addNewAccount: 'Neues Konto hinzufügen',
            accountApplicationStatus: 'Kontoantragsstatus',
            accountInformation: {
                accountInformation: 'Kontoinformationen',
                acctHolderInformation: 'Kontoinhaberinformationen',
                accountName: 'Kontoname',
                address: 'Adresse',
                altPhone: 'Alternatives Telefon',
                dateOfBirth: 'Geburtsdatum',
                email: 'E-Mail-Adresse',
                foreignId: 'Ausländische ID',
                homePhone: 'Telefon zu Hause',
                taxId: 'Steuer-ID'
            },
            registration: {
                registration: 'Registrierung',
                accountType: 'Kontotyp',
                lastStatement: 'Letzter Auszug',
                institutionCode: 'Institutionscode',
                restrictionCode: 'Einschränkungscode',
                accountStatus: 'Kontostatus',
                accountOpened: 'Konto eröffnet'
            },
            setup: {
                setup: 'Setup',
                marginAgreementOnFile: 'Margin-Vereinbarung auf Datei',
                fplAgreementsOnFile: 'FPL-Vereinbarungen auf Datei',
                principalPayments: 'Hauptzahlungen',
                dividendReinvest: 'Dividenden Wiederanlage',
                cashStandingInstructions: 'Bargeld Standing-Anweisungen',
                marginStandingInstructions: 'Margin-Steher-Anweisungen',
                dividendStandingInstructions: 'Dividenden-Steher-Anweisungen',
                calls: 'Anrufe',
                puts: 'Setzt',
                price: 'Preis',
                noOptionsContracts: 'Für dieses Wertpapier sind derzeit keine Optionsverträge verfügbar.',
                optionsLevel: {
                    optionsLevel: 'Optionshandelsgenehmigungen',
                    disallowed: 'Nicht erlaubt',
                    level1: 'Gedeckte Calls', // Covered calls
                    level2: 'Kauf von Calls + Puts', // Purchase Calls + Puts
                    level3: 'Spreads / Long Straddles', // Spreads / Long Straddles
                    level4: 'Equity Put Writing', // Equity Put Writing
                    level5: 'Ungedecktes Call-Schreiben + Index-Put-Schreiben / Kurze Straddles', // Uncovered call writing + Index put writing / short straddles
                    level6: 'Ungedeckte Indexoptionen' // Uncovered index options
                }
            },
            investmentProfile: {
                investmentProfile: 'Anlageprofil',
                investmentObjective: 'Anlageziel',
                netWorth: 'Nettowert',
                federalTaxBracket: 'Bundessteuersatz',
                liquidityNeeds: 'Liquiditätsbedarf',
                sourceOfFunds: 'Herkunft der Gelder',
                occupation: 'Beschäftigung',
                stockExperience: 'Aktienerfahrung',
                bondExperience: 'Anleihenerfahrung',
                optionExperience: 'Optionserfahrung',
                mutualFundExperience: 'Erfahrung mit Investmentfonds',
                annuityExperience: 'Rentenversicherungserfahrung',
                investmentTimeHorizon: 'Anlagehorizont'
            },
            accountDetails: {
                title: 'Kontodetails',
                type: 'Typ',
                accountNumber: 'Kontonummer',
                accountName: 'Kontoname',
                rename: 'Umbenennen',
                startMargin: 'Beginnen Sie mit der Verwendung von Margin',

                optionsTrading: 'Optionshandel',
                optionsEnabled: 'Optionen aktiviert',
                optionsLevelsForPendingAcct: 'Sobald Ihr Konto genehmigt ist, haben Sie Zugang zum Optionshandel.',
                youveEnabledOptions: 'Sie haben Optionen für dieses Konto aktiviert.',
                optionsNotEnabledDesc:
                    'Für dieses Konto ist der Optionshandel nicht aktiviert. Der Optionshandel ermöglicht es Ihnen, Verträge zu handeln, wenn Sie denken, dass der Preis eines Vermögenswerts steigen oder fallen wird.',

                marginAgreementAddedDesc:
                    'Es sieht so aus, als hätten Sie eine Margin-Vereinbarung zu diesem Konto hinzugefügt. Margin gibt Ihnen Zugang zu mehr Optionsstrategien, spekulativen Vermögenswerten wie Futures und mehr.',
                accountDoesNotHaveMarginDesc:
                    'Für dieses Konto liegt keine Margin-Vereinbarung vor. Margin gibt Ihnen Zugang zu mehr Optionsstrategien, spekulativen Vermögenswerten wie Futures und mehr.',

                corporateActionDesc: 'Unternehmensaktionen sind Maßnahmen eines Unternehmens, die den Wert der Aktionäre direkt beeinflussen.',
                corporateActionsAvailable: 'Verfügbare Unternehmensaktionen',
                corporateActions: 'Unternehmensaktionen',
                viewCorporateActions: 'Unternehmensaktionen anzeigen',
                noCorporateActionsAvailable: 'Keine Unternehmensaktionen verfügbar',

                enableOptions: 'Optionshandel aktivieren',
                gotoAccount: 'Zum Konto gehen',
                closeAccount: 'Konto schließen',
                contactUsToCloseAccount: 'Bitte kontaktieren Sie uns, und wir werden Ihr Konto schließen.',
                options: 'Optionen',
                margin: 'Margin',
                changeOptionLevel: 'Optionsebene ändern',
                marginEnabled: 'Margin aktiviert',
                unableToFindInformation: 'Es scheint, als hätten wir keine Informationen zu Ihrem Konto finden können. Entschuldigung dafür!',
                ohNo: 'Oh nein!',
                execute: 'Sie können Folgendes ausführen:',
                useButtonModify: 'Verwenden Sie die obige Schaltfläche zum Ändern.',
                renameAccount: 'Konto umbenennen',
                cancel: 'Abbrechen',
                general: {
                    general: 'Allgemein',
                    dateOpened: 'Eröffnungsdatum',
                    lastStatement: 'Letzter Auszug',
                    status: 'Status',
                    restrictionCode: 'Einschränkungscode'
                }
            }
        },
        paperless: {
            goPaperless: 'Papierlos gehen',
            paperless: 'Papierlos',
            confirms: 'Bestätigungen',
            stonexCommunications: 'StoneX-Kommunikation',
            investorRelations: 'Investorbeziehungen',
            prospectus: 'Prospekt',
            statements: 'Kontoauszüge',
            taxDocuments: 'Steuerdokumente',
            goCompletelyPaperless: 'Vollständig papierlos werden',
            goPaperlessLine1:
                'Bereit, komplett papierlos zu gehen? Drücken Sie die obige Schaltfläche, um alle Ihre Kontodokumente per E-Mail zu erhalten anstelle von per Post.',
            goPaperlessLine2: 'Oder passen Sie unten die Lieferoptionen Ihrer Konten an.',
            completelyPaperlessExplain: 'Erhalten Sie alle Dokumente per E-Mail und helfen Sie gleichzeitig der Umwelt.',
            allMail: 'Alle Post',
            allEDelivery: 'Alle E-Mail',
            allElectronic: 'Komplett elektronisch',
            mail: 'Post',
            edelivery: 'E-Mail',
            paperlessButton: 'Sie sind zu 100 % papierlos!',
            customizeOptions: 'Passen Sie unten die Lieferoptionen Ihrer Konten an.',
            youArePaperless: (percent: string) => `Sie sind zu ${percent} papierlos`,
            paperlessPopup: {
                title: 'Bitte bestätigen',
                message: 'Dies aktualisiert alle Ihre Konten, um Ihre Dokumente nicht mehr per Post zuzustellen. Fortfahren?',
                confirm: 'Ja, papierlos gehen'
            }
        },
        bioauth: {
            biometrics: 'Biometrie',
            whatBioauthIs: 'Die biometrische Anmeldung ermöglicht es Ihnen, sich schnell über biometrische Faktoren wie Ihr Gesicht oder Ihren Fingerabdruck anzumelden.',
            bioauthIsCurrentlyOnOff: (on: boolean) => [
                { text: 'Die biometrische Anmeldung ist derzeit ', shade: 'light' },
                { text: on ? 'aktiviert' : 'deaktiviert', shade: 'dark' },
                { text: '.', shade: 'light' }
            ],
            clearMyDataAndTurnBioauthOff: 'Meine Anmeldedaten löschen und die biometrische Anmeldung deaktivieren',
            turnBioauthOn: 'Biometrische Anmeldung aktivieren',

            whatBiounlockIs:
                'Wenn Sie die biometrische Anmeldung aktiviert haben, können Sie verhindern, dass andere Ihre Anmeldung verwenden, indem Sie Ihren biometrischen Faktor benötigen, um StoneX One zu entsperren.',
            biounlockIsCurrentlyOnOff: (on: boolean) => [
                { text: 'Die biometrische Entsperrung ist derzeit ', shade: 'light' },
                { text: on ? 'aktiviert' : 'deaktiviert', shade: 'dark' },
                { text: '.', shade: 'light' }
            ],
            turnBiounlockOff: 'Biometrische Entsperrung deaktivieren',
            turnBiounlockOn: 'Biometrische Entsperrung aktivieren',

            errors: {
                unableToLogYouIn: 'Entschuldigung, wir konnten Sie nicht anmelden.',
                somethingWentWrong: 'Entschuldigung, es ist ein Fehler aufgetreten.',
                couldNotVerifyYourIdentity: 'Entschuldigung, wir konnten Ihre Identität nicht überprüfen.'
            },

            verifyYourLoginToActivate: 'Überprüfen Sie Ihre Anmeldung, um die Biometrie zu aktivieren',
            verify: 'Überprüfen',
            email: 'E-Mail',
            password: 'Passwort'
        },
        changePassword: {
            changeFailedTitle: 'Update fehlgeschlagen',
            changeFailedMessage:
                'Entschuldigung, aber wir konnten Ihr Passwort nicht aktualisieren. Bitte versuchen Sie es erneut oder wenden Sie sich unten an den Support',
            validationFailedTitle: 'Update fehlgeschlagen',
            validationFailedMessage:
                'Einige Eingaben fehlen oder sind ungültig. Stellen Sie sicher, dass Sie Ihr vorhandenes Passwort eingegeben haben und dass Sie Ihr Passwort beide Male korrekt eingegeben haben.',
            authenticationFailedTitle: 'Identität kann nicht bestätigt werden',
            authenticationFailedMessage: 'Das von Ihnen eingegebene aktuelle Passwort ist falsch',
            tryAgain: 'Erneut versuchen',
            contactSupport: 'Support kontaktieren',
            changePassword: 'Passwort ändern',
            forgotPassword: 'Passwort vergessen',
            currentPassword: 'Aktuelles Passwort',
            newPassword: 'Neues Passwort',
            confirmPassword: 'Passwort bestätigen',
            updatePassword: 'Passwort aktualisieren',
            passwordMinLength: (minLength: number) => `Das Passwort muss mindestens ${minLength} Zeichen lang sein`,
            passwordLetter: 'Das Passwort muss mindestens einen Buchstaben enthalten',
            passwordRequirementsMessage: (minLength: number) =>
                `Das Passwort muss mindestens ${minLength} Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten.`,
            cannotReuseOldPassword: 'Das neue Passwort darf nicht mit dem aktuellen Passwort übereinstimmen',
            newPasswordConfirmMismatch: 'Neues Passwort und Bestätigung stimmen nicht überein',
            passwordRequirements: 'Passwortanforderungen',
            passwordRecoveryRequirements: (minLength: number) => [
                `Muss mindestens ${minLength} Zeichen lang sein`,
                'Muss mindestens einen Großbuchstaben enthalten',
                'Muss mindestens eine Zahl enthalten'
            ]
        }
    },
    documentsScreen: {
        documents: 'Dokumente',
        filters: 'Filter',
        name: 'Name',
        date: 'Datum',
        account: 'Konto',
        preview: 'Vorschau',
        download: 'Herunterladen',
        share: 'Teilen',
        moreActions: 'Weitere Aktionen',
        viewDocument: 'Dokument ansehen',
        allAccounts: 'Alle Konten',
        downloading: 'Wird heruntergeladen...',
        saveToDownloads: 'In Downloads speichern',
        documentNotFound: 'Für dieses Datum ist keine Datei vorhanden.',
        docTypes: {
            taxDocuments: 'Steuerdokumente',
            intlPublicForms: 'INTL öffentliche Formulare',
            ifsNewAccts: 'IFS NEUE KONTEN',
            marginRequirements: 'Kunden Margin Anforderungen',
            tradeConfirmations: 'Korrespondent Bestätigungen',
            checks: 'CMA Schecks',
            accountStatements: 'Kontoauszüge',
            accountNotices: 'Konto Benachrichtigungen',
            detailedVariationAnalysis: 'Detaillierte Varianz Analyse',
            moneyOptionReport: 'Geldoption Bericht',
            memoStatement: 'Memoauszug',
            monthlyDiscretionaryAccounts: 'Monatliche Diskretionäre Konten',
            annualStatement: 'Jahresauszug',
            quaterlyStatement: 'Quartalsauszug',
            monthlyStatementExcel: 'Monatlicher Kontoauszug Excel',
            monthlyStatement: 'Monatlicher Kontoauszug',
            dailyStatement: 'Tagesauszug',
            fullyPaidEarnings: 'Voll bezahlte Erträge',
            fullyPaidLendingConfirms: 'Voll bezahlte Darlehensbestätigungen',
            fullyPaidLendingPositions: 'Voll bezahlte Darlehenspositionen'
        },
        downloadToasts: {
            savingUnavailableTitle: 'Speichern nicht verfügbar',
            savingUnavailableDesc: 'Entschuldigung, das Speichern ist auf Ihrem Gerät nicht verfügbar.',
            permissionDenied: 'Zugriff verweigert',
            allowStonexSaveFiles: 'Bitte erlauben Sie StoneX One, Dateien auf Ihrem Gerät zu speichern.',
            fileSavedTitle: 'Datei gespeichert',
            fileSavedInDevice: (foldername: string) => `Ihr Dokument wurde im ${foldername}-Ordner Ihres Geräts gespeichert`,
            savingErrorTitle: 'Speicherfehler',
            problemDownloading: 'Entschuldigung, beim Herunterladen Ihrer Datei ist ein Problem aufgetreten.'
        },
        docNames: {
            statement: (doc: SnexDocument) => `${doc.accountNumber} ${SafeFormat(doc.documentDate, 'MMMM')} Auszug`,
            check: (doc: SnexDocument) => doc.name,
            misc: (doc: SnexDocument) => doc.name,
            taxes: (doc: SnexDocument) => `${doc.accountNumber} ${SafeFormat(doc.documentDate, 'yyyy')} Steuerdokument`
        },
        symbol: 'Symbol',
        type: 'Typ',
        noDocumentsTitle: 'Keine Dokumente gefunden',
        noDocumentsDescription: 'Wir konnten keine Dokumente finden, die den untenstehenden Filtern entsprechen.',
        searchDocuments: 'Dokumente durchsuchen',
        goPaperless: 'Papierlos gehen',
        modal: {
            loading: 'Dokument wird abgerufen...',
            untitled: 'Unbenanntes Dokument'
        }
    },

    tradeHistory: {
        tradeHistory: 'Orderhistorie',
        filters: 'Filter',
        account: (accountNumber?: string) => (accountNumber ? `Konto #${accountNumber}` : 'Konto'),
        allAssetClasses: 'Alle Produkte',
        equities: 'Aktien & Optionen',
        futures: 'Futures & Optionen',
        futuresOptions: 'Futures Optionen',
        mutualFunds: 'Investmentfonds',
        allAccounts: 'Alle Konten',
        futuresTimeSpreads: 'Futures Spreads',
        cryptos: 'Kryptowährungen',
        invalidFilters: {
            popoverHeader: 'Ungültige Filter',
            popoverBody: 'Einige Ihrer Filter stehen im Widerspruch zueinander. Bitte überprüfen Sie Ihre Filter und versuchen Sie es erneut.',
            popoverActionLabel: 'Okay'
        },
        status: {
            status: 'Status',
            assetClass: 'Produkt',
            all: 'Alle',
            allStatuses: 'Alle Status',
            openAll: 'Alle Offen',
            closed: 'Geschlossen',
            closedAll: 'Alle Geschlossen',
            verification: 'Verifizierung',
            created: 'Erstellt',
            open: 'Offen/Arbeiten',
            route: 'Route',
            routed: 'Geroutet',
            cancel: 'Abbrechen',
            canceled: 'Abgebrochen',
            filled: 'Ausgeführt',
            rejected: 'Abgelehnt',
            deleted: 'Gelöscht',
            expired: 'Abgelaufen',
            confirmed: 'Bestätigt',
            unknown: 'Unbekannt',
            pending: 'Ausstehend',
            completed: 'Abgeschlossen',
            cancelled: 'Abgebrochen',
            cancelPending: 'Abbrechen ausstehend',
            cancelPartialFill: 'Teilweise Abgebrochen',
            allocated: 'Zugewiesen',
            preAllocated: 'Vorzugewiesen'
        },
        orders: 'Aufträge',
        symbol: 'Symbol',
        accountTitle: 'Konto',
        downloadTooltip: 'Alle herunterladen',
        fetchingError: 'Entschuldigung! Beim Abrufen Ihrer Orderhistorie ist ein Fehler aufgetreten.',
        noOrdersFound: 'Keine Aufträge gefunden',
        noOrdersFoundDescription: 'Wir konnten keine Aufträge finden, die den untenstehenden Filtern entsprechen',
        downloadFilename: () => `order-historie-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`
    },
    transactionsScreen: {
        tooltips: {
            partialResults: 'Teilweise Ergebnisse zurückgegeben'
        },
        account: 'Konto',
        transactions: 'Transaktionen',
        symbolId: 'Symbol',
        date: 'Datum',
        type: 'Typ',
        anyType: 'Jeder Typ',
        amount: 'Betrag',
        description: 'Beschreibung',
        noTransactionsTitle: 'Keine Transaktionen verfügbar',
        noTransactionsDesc: 'Ihre Trades, Überweisungen, Dividenden, Zinsen und alle anderen Kontobewegungen werden hier angezeigt.',
        sendingOrder: 'Auftrag wird gesendet..',
        tradeDescription: (m: Transaction) => {
            const verb = (() => {
                if (m.action?.toUpperCase() === 'BUY') return 'Gekauft';
                else if (m.action?.toUpperCase() === 'SELL') return 'Verkauft';
                else return m.action;
            })();
            const unit = Snex1German.general.unit(m.symbol, m.quantity, m.securityType);
            const decimalPlaces = `${m?.price}`.split('.')[1]?.length;
            return [SecurityTypes.Futures, SecurityTypes.FuturesOptions].includes(m?.securityType)
                ? `${verb} ${FormatNumber.toDecimal(m.quantity, 3)} ${m.description}`
                : `${verb} ${FormatNumber.toDecimal(m.quantity, 3)} ${unit} von ${m.symbol} @ ${FormatNumber.toLocaleDecimal({
                      decimalPlaces,
                      value: m.price
                  })}`;
        },
        expand: 'Erweitern',
        action: 'Aktion',
        quantity: 'Menge',
        price: 'Preis',
        baseCurrency: 'Basiswährung',
        accountType: {
            accountType: 'Kontotyp',
            cash: 'Bargeld',
            longMargin: 'Marge (Long)',
            shortMargin: 'Marge (Short)',
            annuitiesAway: 'Renten auswärts',
            stockLoan: 'Wertpapierleihe',
            tefraWithhold: 'TEFRA Einbehalt',
            whenIssued: 'Bei Ausgabe'
        },
        securityType: 'Wertpapierart',
        asOfDate: 'Stichtag',
        tradeDate: 'Handelsdatum',
        settleDate: 'Abrechnungsdatum',
        principal: 'Kapital',
        interest: 'Zinsen',
        cancelStatus: 'Stornierungsstatus',
        rebillStatus: 'Neuverrechnungsstatus',
        controlNumber: 'Kontrollnummer',
        securityNumber: 'Wertpapiernummer',
        security: 'Wertpapier',
        exportAll: (accountNumber: string) => `Alle Transaktionen für ${accountNumber} exportieren`,
        filename: (accountNumber: string) => `transaktionen-${accountNumber}-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        filters: {
            filters: 'Filter',
            sort: 'Sortieren',
            date: 'Datum'
        },
        processDate: 'Verarbeitungsdatum',
        netAmount: 'Nettobetrag',
        defaultSort: 'Standard Sortierung',
        ascending: 'Aufsteigend',
        descending: 'Absteigend',
        search: 'Suche',
        all: 'Alle',
        swipeUpSubmit: 'Nach oben wischen zum Absenden',
        activityType: {
            tradeActivity: 'Handelsaktivität',
            cashAndEquivalents: 'Bargeld & Äquivalente',
            dividendsAndInterest: 'Dividenden & Zinsen',
            options: 'Optionen',
            receiveAndDeliver: 'Empfangen & Liefern',
            exerciseAndExpiration: 'Ausübung & Verfall',
            stockLoans: 'Wertpapierleihe',
            misc: 'Sonstiges',
            deposit: 'Einzahlung',
            withdrawal: 'Abhebung',

            trade: 'Handel',
            cash: 'Bargeld',
            dividentInterest: 'Dividende / Zinsen',
            exerciseExpire: 'Ausübung / Verfall',
            receiveDeliever: 'Empfangen / Liefern',
            receiveDelieverAbbrev: 'Empf / Lief', // Abbreviated on Android,
            funding: 'Finanzierung',
            other: 'Sonstiges'
        }
    },
    projectedIncomeScreen: {
        projectedIncome: 'Prognostiziertes Einkommen',
        accountNumber: 'Kontonummer',
        symbol: 'Symbol',
        saveFilter: 'Filter speichern',
        reset: 'Zurücksetzen',
        viewAll: 'Alle anzeigen',
        pleaseSelectAnAccount: 'Wählen Sie ein Konto, um Ihr prognostiziertes Einkommen zu sehen',
        noDataAvailable: 'Keine Daten für das ausgewählte Konto verfügbar',
        name: 'Name',
        income: 'Einkommen',
        yield: 'Rendite',
        paymentDate: 'Zahlungsdatum',
        nextPaymentDate: 'Nächste Zahlung',
        maturityDate: 'Fälligkeitsdatum',
        frequency: {
            frequency: 'Häufigkeit',
            semiAnnually: 'Halbjährlich',
            quarterly: 'Vierteljährlich',
            monthly: 'Monatlich',
            annually: 'Jährlich'
        },
        projectedTotal: 'Prognostizierte Summe',
        projectedFor: (qsi: string) => `Prognostiziert für ${qsi}`,
        couponRate: 'Kuponrate',
        type: 'Typ',
        downloadTooltip: 'Prognostiziertes Einkommen herunterladen',
        downloadFilename: () => `projected-income-${SafeFormat(new Date(), 'yyyyMMdd-hhmmss')}.csv`,
        overall: 'Gesamt',
        selectAnAccountEmptyTitle: 'Ihr prognostiziertes Einkommen wird hier angezeigt. Wählen Sie ein Konto, um es zu sehen.',
        incomeBreakdown: 'Einkommensaufteilung',
        totalProfits: 'Gesamtgewinne für den Zeitraum:',
        noIncomeForMonth: 'Kein prognostiziertes Einkommen für',
        selectAnotherPeriod: 'Wählen Sie einen anderen Zeitraum im obigen Diagramm, um Ihr prognostiziertes Einkommen anzuzeigen.',
        noIncomeProjection: 'Keine Einkommensprognose verfügbar',
        noPositionSecurity: 'Es sieht so aus, als hätte dieses Konto keine Position in Wertpapieren, die Dividenden zahlen.'
    },

    disclosuresPage: {
        disclosures: 'Offenlegungen',
        documentContent: {
            pricingDisclosure: [
                'Positionen werden zum letzten Handelspreis bewertet. Wenn ein Wertpapier heute nicht gehandelt wurde, basieren die Preisinformationen auf dem besten Angebotspreis des vorherigen Geschäftstags. Das aktuelle Gebot oder Angebot kann vom angezeigten Preis abweichen.'
            ],
            projectedIncomeDisclosure: [
                'Prognostiziertes Einkommen und prognostizierte Rendite für bestimmte Arten von Wertpapieren können eine Rückzahlung des Kapitals oder Kapitalgewinne beinhalten, in welchem Fall das prognostizierte Einkommen und die prognostizierte Rendite überbewertet wären.',
                'Prognostiziertes Einkommen und prognostizierte Rendite sind Schätzungen und das tatsächliche Einkommen und die Rendite könnten niedriger oder höher als die geschätzten Beträge sein.',
                'Die prognostizierte Rendite spiegelt nur das durch eine Investition generierte Einkommen wider. Sie berücksichtigt keine Änderungen im Preis, die schwanken können.'
            ],
            marketIntelligenceDisclosure: [
                {
                    text: 'Nichts hierin darf so ausgelegt werden, dass dem Abonnenten oder Dritten eine uneingeschränkte Lizenz oder ein uneingeschränktes Recht, sei es implizit, durch Estoppel oder anderweitig, nach irgendeinem Gesetz (ob Gewohnheitsrecht oder Gesetz), Regel oder Verordnung einschließlich, aber nicht beschränkt auf, Urheberrechte oder andere geistige Eigentumsrechte, übertragen wird.'
                },
                {
                    text: 'Dieser Bericht und sein Inhalt sind nicht als Finanzberatung zu verstehen. Dieser Bericht und sein Inhalt werden dem Abonnenten zu Informationszwecken zur Verfügung gestellt und sind kein Ersatz für die Einholung professioneller Beratung von einer qualifizierten Person, Firma oder Gesellschaft, wo erforderlich. Die Genauigkeit, Vollständigkeit, Angemessenheit oder Aktualität dieses Berichts und seines Inhalts wird nicht garantiert oder gewährleistet. Der Abonnent erkennt an, dass der Bericht und sein Inhalt Arbeitsergebnisse unter Verwendung, Einbeziehung oder Bezugnahme auf Informationen Dritter sein können.'
                },
                {
                    text: 'Jegliche unbefugte Nutzung dieses Berichts und seines Inhalts ist strengstens untersagt. Dieser Bericht und sein Inhalt sind nur für die Nutzung durch den Abonnenten gemäß seinem Abonnement bestimmt und dürfen nicht kopiert, modifiziert, reproduziert, erneut veröffentlicht, verteilt, angezeigt oder für kommerzielle, gemeinnützige oder öffentliche Zwecke übertragen werden, weder ganz noch teilweise, und auch nicht für andere Zwecke zu seinem oder zum Nutzen Dritter verwendet werden. Zur Klarstellung: Der Abonnent darf diesen Bericht und seinen Inhalt nicht verwenden, um falsche, irreführende, betrügerische oder illegale Mitteilungen zu übermitteln.'
                },
                {
                    text: 'StoneX Group Inc. lehnt jegliche Haftung für die Nutzung oder das Vertrauen auf diesen Bericht und seinen Inhalt für irgendwelche Zwecke ab, die sich aus dem Abonnement, der Nutzung durch den Abonnenten und Dritten ergeben.'
                }
            ]
        },
        documentTitles: {
            termsAndConditions: 'Geschäftsbedingungen',
            pricingDisclosure: 'Preisangaben',
            generalDisclosures: 'Allgemeine Offenlegungen',
            cryptoDisclosure: 'Krypto-Offenlegung',
            projectedIncomeAndYieldDisclosure: 'Prognostiziertes Einkommen & Rendite',
            marketIntelligenceDisclosure: 'Marktintelligenz-Offenlegung'
        }
    },
    securityScreen: {
        premarket: 'Vor dem Markt',
        postmarket: 'Nach dem Markt',
        afterhours: 'Nachbörslich',
        advancedChart: 'Erweitertes Diagramm',
        advancedDomAndChart: 'Erweiterter DOM & Diagramm',
        deepAnalysis: 'Tiefenanalyse',
        tradeOptions: 'Optionen handeln',
        tradeFuturesSpreads: 'Futures-Spreads handeln',
        notFoundHeader: (qsi: string) => `${qsi} nicht gefunden`,
        notFoundSubText: 'Es sieht so aus, als könnten wir keine Informationen über dieses Symbol finden.',
        searchForSomethingElse: 'Nach etwas anderem suchen',
        enableOptionTrading: 'Optionen-Handel aktivieren',
        changeOptionLevel: 'Optionsstufe ändern',
        addToList: 'Zur Liste hinzufügen',
        notOnAnyOfYourLists: 'Nicht auf einer Ihrer Listen',
        lineChart: 'Liniendiagramm',
        candlestick: 'Kerzendiagramm',
        showVolume: 'Volumen anzeigen',
        tomorrow: 'morgen',
        today: 'heute',
        security: 'Wertpapier',
        securities: 'Wertpapiere',
        openOrders: 'Offene Aufträge',
        viewAll: 'Alle anzeigen',
        viewAllSpreads: 'Alle Spreads anzeigen',
        viewFewer: 'Weniger anzeigen',
        viewMore: 'Mehr anzeigen',
        viewLess: 'Weniger anzeigen',
        chartDataComingSoon: 'Chartdaten demnächst verfügbar',
        problemLoadingSecuritiesFor: (qsi: string) => `Es gab ein Problem beim Laden der zugehörigen Wertpapiere für ${qsi}`,
        noRelatedSecuritiesFor: (qsi: string) => `Keine zugehörigen Wertpapiere verfügbar für ${qsi}`,
        about: {
            about: (symbol: string) => 'Über',
            noDescriptionAvailable: 'Keine Beschreibung verfügbar',
            readMore: 'Mehr lesen',
            readLess: 'Weniger lesen',
            ceo: 'CEO',
            employees: 'Mitarbeiter',
            headquarters: 'Hauptsitz',
            website: 'Website',
            exchange: 'Börse',
            open: 'Geöffnet',
            unavailable: 'Nicht verfügbar',
            whitepaper: 'Whitepaper',
            reddit: 'Reddit',
            coin: 'Münze'
        },
        stats: {
            stats: 'Statistiken',
            specifications: 'Spezifikationen',
            bid: 'Geld',
            ask: 'Brief',
            open: 'Eröffnung',
            close: 'Schluss',
            previousClose: 'Vorheriger Schluss',
            previousSettle: 'Vorherige Abrechnung',
            high: 'Hoch',
            low: 'Tief',
            previousVolume: 'Vorheriges Volumen',
            volume: 'Volumen',
            volume24h: 'Volumen (24h)',
            averageVolume: 'Durchschnittliches Volumen',
            averageVolume7d: 'Durchschn. Volumen (7d)',
            week52High: '52-Wochen-Hoch',
            week52Low: '52-Wochen-Tief',
            marketCap: 'Marktkapitalisierung',
            peRatio: 'KGV',
            ytdChange: 'YTD-Änderung',
            startTime: 'Startzeit',
            stopTime: 'Stopzeit',
            endTime: 'Endzeit',
            contractSize: 'Kontraktgröße',
            tickSize: 'Tick-Größe',
            tickPrice: 'Tick-Preis',
            initialMargin: 'Ersteinschuss',
            dayTradeMargin: 'Tagesspanne',
            dayTradeMarginToolTip: 'Die Tagesspanne ist von 7:45 Uhr bis 15:30 Uhr Central Time verfügbar',
            maintenanceMargin: 'Erhaltungsmarge',
            changePercent: 'Ändern %',
            low52: '52Niedrig',
            high52: '52Hoch'
        },
        ratings: {
            analystRatings: 'Analystenbewertungen',
            asOf: 'Stand von',
            noRatingsAvailable: 'Keine Analystenbewertungen verfügbar',
            analystRatingExplain: (secName: string, action: string) => `Analysten bewerten ${secName} derzeit im Durchschnitt als ${action}`,
            analystRatingExplainShaded: (secName: string, action: string) => [
                {
                    shade: 'light',
                    text: 'Analysten bewerten derzeit im Durchschnitt '
                },
                { shade: 'dark', text: secName },
                { shade: 'light', text: ' als ' },
                { shade: 'dark', text: action }
            ],
            aConsensusOf: (totalNumberOfRatings: number, consensusDate: string | Date) => [
                { text: 'Basierend auf dem Konsens von ', shade: 'light' },
                {
                    text: `${FormatNumber.toCommas(totalNumberOfRatings)} Bewertung${totalNumberOfRatings === 1 ? '' : 'en'}`,
                    shade: 'dark'
                },
                { text: ' gesammelt am ', shade: 'light' },
                {
                    text: SafeFormat(consensusDate, 'dd MMM yyyy'),
                    shade: 'dark'
                }
            ],
            noRatingsDataAvailble: (secName: string) => `Für ${secName} sind derzeit keine Bewertungen verfügbar.`,
            problemLoadingRatings: (secName: string) => `Es gab ein Problem beim Laden der Bewertungen für ${secName}`,
            noRatingsAvailableFor: (secName: string) => `Keine Bewertungen sind für ${secName} verfügbar`,
            buy: 'Kaufen',
            sell: 'Verkaufen',
            hold: 'Halten',
            totalRatings: 'Gesamtbewertungen'
        },
        earningsEstimates: {
            earningsAndEstimates: 'Erträge & Schätzungen',
            actual: 'Tatsächlich',
            estimated: 'Geschätzt',
            actualEPS: 'Tatsächliches EPS',
            estimatedEPS: 'Geschätztes EPS',
            quarter: (quarterNumber: number) => `Q${quarterNumber}`,
            noData: (securityName?: string) => `Es sind derzeit keine Ertragsdaten für ${securityName || 'dieses Wertpapier'} verfügbar.`,
            problemLoadingEarnings: (securityName: string) => `Es gab ein Problem beim Laden des Ertragszeitplans für ${securityName}`,
            noEarningsTimeline: (securityName: string) => `Kein Ertragszeitplan ist für ${securityName} verfügbar`
        },
        options: {
            options: 'Optionen',
            optionsTitle: (symbol: string) => `${symbol} Optionen`,
            optionsSublabel: 'Calls und Puts handeln',
            contracts: 'Kontrakte',
            value: 'Wert',
            todaysGainLoss: 'Heutiger P/L',
            totalGainLoss: 'Gesamter P/L',
            account: 'Konto',
            exp: 'Verf.',
            contractsLong: (quantity: number) => `${FormatNumber.toCommas(quantity)} ${Snex1German.general.contracts(quantity)} long`,
            contractsShort: (quantity: number) => `${FormatNumber.toCommas(quantity)} ${Snex1German.general.contracts(quantity)} short`,
            unknownExpiration: 'Unbekanntes Verfallsdatum',
            contract: (n: number) => Snex1German.general.contracts(n),
            timeSpreadsTitle: (symbol: string) => `${symbol} Zeitspreads`,
            timeSpreadsSublabel: 'Kaufen und verkaufen von Kontrakten mit unterschiedlichen Verfallsdaten',
            noTimeSpreadsSublabel: 'Zeitspreads sind für diesen Kontrakt nicht verfügbar'
        },
        history: {
            history: 'Geschichte',
            tradeHistory: 'Handelsverlauf anzeigen',
            orderHistory: 'Auftragsverlauf anzeigen',
            viewMoreHistory: 'Mehr Verlauf anzeigen'
        },
        related: {
            related: 'Verwandte',
            relatedEtfs: 'Verwandte ETFs',
            noRelatedStocks: 'Keine verwandten Wertpapiere'
        },
        holdings: {
            account: 'Konto',
            accountsHolding: 'Konten halten',
            acct: (number: string) => `Kto. #${QualifiedId.Format(number)}`,
            assetsInFunds: '% Vermögenswerte im Fonds',
            averageCost: 'Durchschnittskosten',
            cost: 'Kosten',
            marketValue: 'Marktwert',
            marketValueTooltip: 'Diese Werte basieren auf dem Vortag. Heute ausgeführte Aufträge sind nicht berücksichtigt.',
            marketValueTooltipFutures: 'Der Marktwert ist Ihre gehaltenen Kontrakte multipliziert mit den Durchschnittskosten.',
            portfolio: 'Portfolio',
            portfolioDiversity: 'Portfolio-Allokation',
            positionPrice: 'Positionspreis',
            quantity: 'Menge',
            share: (n: number) => Snex1German.general.shares(n),
            shares: 'Anteile',
            showAllAccounts: 'Alle Konten anzeigen',
            todaysReturn: 'Heutige Rendite',
            topHoldings: 'Top-Beteiligungen',
            totalReturn: 'Gesamtrendite',
            viewMore: 'Mehr anzeigen',
            yourMarketValue: 'Ihr Marktwert',
            yourPosition: 'Ihre Position',
            buyMore: 'Mehr kaufen',
            shortMore: 'Mehr leerverkaufen',
            coverPosition: 'Eindecken',
            sellQsi: (qsi: string) => `${qsi} verkaufen`,
            noTopHoldingAvailable: (security) => `Für ${security} sind derzeit keine Top-Beteiligungen verfügbar`
        },
        news: {
            news: 'Nachrichten',
            marketIntelligence: 'Marktintelligenz',
            andNews: 'Und Nachrichten',
            newsAndMI: 'Nachrichten & MI',
            newsUnavailable: 'Nachrichten derzeit nicht verfügbar',
            noNewsFor: (symbol?: string) => `Keine Nachrichten verfügbar für ${symbol || 'dieses Wertpapier'}`
        },
        crypto: {
            comingSoon: 'Krypto-Handel kommt bald',
            marketComparison: {
                title: 'Marktvergleich',
                pastYear: 'Vergangenes Jahr'
            },
            socialActivity: {
                title: 'Soziale Aktivität',
                twitterFol: 'Twitter-Follower',
                redditFol: 'Reddit-Follower',
                githubFol: 'Github-Follower'
            },
            popularity: 'Popularität',
            circulatingSupply: 'Umlaufversorgung',
            totalSupply: 'Gesamtversorgung',
            maxSupply: 'Maximale Versorgung',
            coins: 'Münzen'
        }
    },

    sectorScreen: {
        sectors: 'Branchen',
        sectorNames: {
            consumerDiscretionary: 'Konsumgüter',
            healthCare: 'Gesundheitswesen',
            technology: 'Technologie',
            realEstate: 'Immobilien',
            consumerStaples: 'Konsumgüter (Grundbedarf)',
            utilities: 'Versorgungsunternehmen',
            communicationServices: 'Kommunikationsdienste',
            financials: 'Finanzdienstleistungen',
            materials: 'Materialien',
            industrials: 'Industrie',
            energy: 'Energie'
        },
        symbol: 'Symbol',
        name: 'Name',
        price: 'Preis',
        change: 'Änderung',
        changePercent: 'Änderungsprozent',
        marketCap: 'Marktkapitalisierung',
        item: 'Element',
        items: 'Elemente',
        securityChangeSelector: {
            rising: 'Steigend',
            falling: 'Fallend'
        },
        marketCapSelector: {
            large: 'Groß',
            mid: 'Mittel',
            small: 'Klein',
            micro: 'Mikro',
            nano: 'Nano',
            andBelow: 'und darunter'
        }
    },

    fundScreen: {
        minimumInvestmentNotMet: (minInvestment) => `Mindestanfangsinvestition nicht erreicht: ${FormatNumber.toMoneyOpt2(minInvestment, { showDecimals: false })}`,
        profile: {
            about: 'Über',
            noDescription: 'Keine Beschreibung verfügbar',
            category: 'Kategorie',
            inceptionDate: 'Gründungsdatum',
            indexTracked: 'Verfolgter Index',
            netAssetValue: 'Nettovermögenswert',
            totalAssets: 'Gesamtvermögen',
            beta: 'Beta',
            deferredLoad: 'Abgeschobene Belastung',
            expenseRatio: 'Kostenquote',
            fee12b1: 'Gebühr 12B1',
            frontLoad: 'Vorderbelastung',
            family: 'Fondsfamilie',
            manager: 'Fondsmanager',
            maxRedepmtionFee: 'Maximale Rücknahmegebühr',
            minimumInvestment: 'Mindestinvestition',
            minimumInitialInvestment: 'Mindestanfangsinvestition',
            aum: 'Vermögenswerte unter Verwaltung',
            averageVolume: 'Durchschnittliches Volumen',
            companySymbol: 'Unternehmen',
            priceToBook: 'Preis-Buch-Verhältnis',
            priceToEarnings: 'Kurs-Gewinn-Verhältnis',
            website: 'Webseite',
            open: 'Geöffnet',
            benchmark: 'Benchmark',
            stats: 'Statistiken',
            bid: 'Gebot',
            ask: 'Angebot'
        },
        countryExposure: {
            countryExposureTitle: 'Länderexposition',
            noExposureDataAvailable: 'Keine Expositionsdaten verfügbar',
            country: 'Land',
            exposure: 'Exposition',
            countries: 'Länder',
            noCountryExposure: 'Keine Länderexpositionsdaten verfügbar'
        },
        sectorExposure: {
            sectorExposureTitle: 'Aus welchen Sektoren besteht dieser Fonds?',
            noSectorExposure: 'Keine Sektorexpositionsdaten verfügbar',
            sectors: 'Sektoren'
        },
        Alternative: 'Alternative',
        Equity: 'Eigenkapital',
        FixedIncome: 'Festverzinsliche Wertpapiere',
        Guaranteed: 'Garantiert',
        Hedge: 'Absicherung',
        High: 'Hoch',
        Low: 'Niedrig',
        Medium: 'Mittel',
        MoneyMarket: 'Geldmarkt',
        MultiAsset: 'Multi-Asset',
        Others: 'Andere',
        RealEstate: 'Immobilien',
        Sector: 'Sektor',
        SpanishDomestic: 'Spanisch Inland',
        Traditional: 'Traditionell',
        active: 'Aktiv',
        assetClass: 'Anlageklasse',
        cutoffTimeRedemptions: 'Cutoff Time (Einlösungen)',
        cutoffTimeRedemptionsDescription: 'Gibt den Annahmeschluss für Rücknahmen des Fonds an, angezeigt in europäischer Standardzeit.',
        cutoffTimeSubscriptions: 'Cutoff Time (Abonnements)',
        cutoffTimeSubscriptionsDescription: 'Gibt den Annahmeschluss für Zeichnungen des Fonds an, angezeigt in europäischer Standardzeit.',
        fundCountry: 'Fondsland',
        fundCurrency: 'Fondswährung',
        fundGroup: 'Fondsgruppe',
        fundGroupDescription: 'Gibt den von der AllFunds Bank zugewiesenen Namen der Fondsgruppe an.',
        fundHouse: 'Fondshaus',
        fundHouseCountry: 'Fondshaus-Land',
        fundHouseDescription: 'Verwaltet die Abwicklung des Fonds mit verschiedenen Klassen. Einschließlich Transferagenten, die Geldtransfers abwickeln.',
        geographicArea: 'Geografisches Gebiet',
        geographicZone: 'Geografische Zone',
        inceptionDate: 'Inception Date',
        inceptionDateDescription: 'Das Datum, an dem der Fonds seine Geschäftstätigkeit aufnahm.',
        maximumAmount: 'Maximaler Betrag',
        maximumAmountDescription: 'Gibt den Höchstbetrag an, der für Zeichnungen des Fonds zulässig ist.',
        minAdditionalAmount: 'Min. zusätzlicher Betrag',
        minAdditionalAmountDescription: 'Gibt den zulässigen Mindestbetrag für Folgezeichnungen des Fonds an.',
        minInitialAmount: 'Min. Anfangsbetrag',
        minInitialAmountDescription: 'Gibt den Mindestbetrag an, der für die Erstzeichnung des Fonds zulässig ist.',
        notActive: 'Nicht aktiv',
        preNotificationDaysRedemptions: 'Tage der Vorabbenachrichtigung (Einlösungen)',
        preNotificationDaysRedemptionsDescription: 'Gibt die Anzahl der Pre-Notification-Tage an, die der Fonds für Rücknahmen des Fonds benötigt.',
        preNotificationDaysSubscriptions: 'Vorabbenachrichtigungstage (Abonnements)',
        preNotificationDaysSubscriptionsDescription: 'Gibt die Anzahl der Pre-Notification-Tage an, die der Fonds für Zeichnungen des Fonds benötigt.',
        redemptionsByAmount: 'Rücknahmen nach Betrag',
        redemptionsByAmountDescription: 'Gibt an, ob Rücknahmen in Höhe eines Dollarbetrags zulässig sind.',
        redemptionsByShares: 'Rücknahmen nach Anteilen',
        redemptionsBySharesDescription: 'Gibt an, ob Rücknahmen in Höhe eines Aktienbetrags zulässig sind.',
        riskCategory: 'Risikokategorie',
        riskCategoryDescription: 'Gibt auf der Grundlage des SRRI-Ratings des Fonds die Risikokategorie an, die auf den Risiken von Verlusten oder Gewinnen basiert.',
        settlementDaysRedemptions: 'Abrechnungstage (Rücknahmen)',
        settlementDaysRedemptionsDescription: 'Gibt die Anzahl der Standardabwicklungstage für Rücknahmen des Fonds an.',
        settlementDaysSubscriptions: 'Abrechnungstage (Abonnements)',
        settlementDaysSubscriptionsDescription: 'Gibt die Anzahl der Standardabwicklungstage für Zeichnungen des Fonds an.',
        status: 'Status',
        subAssetClass: 'Sub-Asset-Klasse',
        subscriptionsByAmount: 'Abonnements nach Betrag',
        subscriptionsByAmountDescription: 'Gibt an, ob Abonnements in Dollarbeträgen zulässig sind.',
        subscriptionsByShares: 'Abonnements nach Anteilen',
        subscriptionsBySharesDescription: 'Gibt an, ob Abonnements durch einen Aktienbetrag zulässig sind.',
        totalSettlementDaysRedemptions: 'Gesamtabrechnungstage (Rücknahmen)',
        totalSettlementDaysRedemptionsDescription:
            'Gibt die Gesamtzahl der Abwicklungstage für Rücknahmen des Fonds an, einschließlich der Anzahl der vom Fonds geforderten Vorabbenachrichtigungstage.',
        totalSettlementDaysSubscriptions: 'Gesamtabrechnungstage (Abonnements)',
        totalSettlementDaysSubscriptionsDescription:
            'Gibt die Gesamtzahl der Abwicklungstage für Zeichnungen des Fonds an, einschließlich der Anzahl der vom Fonds geforderten Vorabbenachrichtigungstage.',
        transferableFund: 'Übertragbarer Fonds',
        transferableFundDescription: 'Gibt an, ob der Fonds übertragbar (für Trapaso geeignet) ist, insbesondere für den spanischen Markt.',
        typeOfFund: 'Fondstyp',
        volatilityRange: 'Volatilitätsbereich',
        volatilityRangeDescription:
            'Gibt auf der Grundlage des SRRI-Ratings des Fonds die Volatilitätsspanne des Fonds an, die ein Maß für das Risikoniveau ist, das mit dem schwankenden Preis des Fonds verbunden ist.',

        srri: 'Synthetischer Risiko- und Ertragsindikator',
        potentiallyLowerReward: 'Potenziell niedrigere Belohnung',
        lowerRisk: 'Geringeres Risiko',
        higherRisk: 'Höheres Risiko',
        potentiallyHigherReward: 'Potenziell höhere Belohnung',
        potentialRiskVsReward: 'Potenzielles Risiko vs. Belohnung',
        riskIndicator: 'Risikoindikator',
        risk: 'Risiko'
    },
    deepAnalysisScreen: {
        viewFull: {
            statement: 'Vollständige Aussage anzeigen',
            balanceSheet: 'Vollständige Bilanz anzeigen',
            cashFlow: 'Vollständiger Cashflow anzeigen',
            viewAll: 'Alle anzeigen'
        },
        deepAnalysis: 'Tiefenanalyse',
        quarterly: 'Quartalsweise',
        annual: 'Jährlich',
        showMore: 'Mehr anzeigen',
        showLess: 'Weniger anzeigen',
        showAll: 'Alle anzeigen',
        financials: 'Finanzdaten',
        loadingFinancials: 'Finanzdaten werden geladen...',
        sentimentAnalysis: 'Stimmungsanalyse',
        noFinancialsFoundFor: (s) => `Keine Finanzdaten gefunden für ${s}`,
        noDataFoundFor: (s) => `Keine Daten gefunden für ${s}`,
        fundamentals: {
            fundamentals: 'Grundlagen',
            projectedRevenue: 'Prognostizierter Umsatz',
            dividends: 'Dividenden'
        },
        balanceSheet: {
            balanceSheet: 'Bilanz',
            reportDate: 'Berichtsdatum',
            filingType: 'Einreichungsart',
            fiscalDate: 'Fiskaldatum',
            fiscalQuarter: 'Fiskalquartal',
            fiscalYear: 'Fiskaljahr',
            fixedAssets: 'Sachanlagen',
            currency: 'Währung',
            currentCash: 'Aktuelle liquide Mittel',
            shortTermInvestments: 'Kurzfristige Investitionen',
            receivables: 'Forderungen',
            inventory: 'Inventar',
            otherCurrentAssets: 'Andere kurzfristige Vermögenswerte',
            currentAssets: 'Umlaufvermögen',
            longTermInvestments: 'Langfristige Investitionen',
            propertyPlantEquipment: 'Sachanlagen',
            propertyPlantEquipmentShort: 'Sachanlagen',
            goodwill: 'Goodwill',
            intangibleAssets: 'Immaterielle Vermögenswerte',
            otherAssets: 'Sonstige Vermögenswerte',
            totalAssets: 'Gesamtvermögen',
            accountsPayable: 'Verbindlichkeiten',
            accountsReceivable: 'Forderungen',
            currentLongTermDebt: 'Aktuelle langfristige Schulden',
            otherCurrentLiabilities: 'Andere kurzfristige Verbindlichkeiten',
            totalCurrentLiabilities: 'Gesamtlaufzeitverbindlichkeiten',
            longTermDebt: 'Langfristige Schulden',
            otherLiabilities: 'Andere Verbindlichkeiten',
            minorityInterest: 'Minderheitenanteil',
            totalLiabilities: 'Gesamtverbindlichkeiten',
            commonStock: 'Stammaktien',
            retainedEarnings: 'Gewinnrücklagen',
            treasuryStock: 'Schatzbestände',
            capitalSurplus: 'Kapitalüberschuss',
            shareholderEquity: 'Eigenkapital',
            netTangibleAssets: 'Netto-Sachanlagen',
            longTermLiabilities: 'Langfristige Verbindlichkeiten'
        },
        incomeStatement: {
            incomeStatement: 'Gewinn- und Verlustrechnung',
            reportDate: 'Berichtsdatum',
            filingType: 'Einreichungsart',
            fiscalDate: 'Fiskaldatum',
            fiscalQuarter: 'Fiskalquartal',
            fiscalYear: 'Fiskaljahr',
            currency: 'Währung',
            totalRevenue: 'Gesamterlöse',
            costOfRevenue: 'Umsatzkosten',
            grossProfit: 'Bruttogewinn',
            researchAndDevelopment: 'Forschung und Entwicklung',
            sellingGeneralAndAdmin: 'Vertrieb, allgemeine und Verwaltungskosten',
            operatingExpense: 'Betriebsausgaben',
            operatingIncome: 'Betriebsergebnis',
            otherIncomeExpenseNet: 'Andere Erträge / Aufwendungen netto',
            ebit: 'EBIT',
            interestIncome: 'Zinserträge',
            interestIncomeNonOperating: 'Zinserträge nicht betrieblicher Art',
            interestExpense: 'Zinsaufwand, nicht betriebliche Erträge',
            pretaxIncome: 'Vorsteuerergebnis',
            incomeTax: 'Einkommensteuer',
            minorityInterest: 'Minderheitenbeteiligung',
            netIncome: 'Nettoeinkommen',
            netIncomeBasic: 'Nettogewinn (basis)'
        },
        cashFlow: {
            cashFlow: 'Cashflow',
            capitalExpenditures: 'Investitionen in das Anlagevermögen',
            cashChange: 'Barmitteländerung',
            cashFlowFinancing: 'Cashflow Finanzierung',
            changesInInventories: 'Änderungen in den Beständen',
            changesInReceivables: 'Änderungen in den Forderungen',
            currency: 'Währung',
            depreciation: 'Abschreibung',
            dividendsPaid: 'Gezahlte Dividenden',
            exchangeRateEffect: 'Wechselkurseffekt',
            filingType: 'Einreichungsart',
            fiscalDate: 'Fiskaldatum',
            fiscalQuarter: 'Fiskalquartal',
            fiscalYear: 'Fiskaljahr',
            investingActivityOther: 'Andere Investitionstätigkeiten',
            investments: 'Investitionen',
            netBorrowings: 'Nettodarlehen',
            netIncome: 'Nettoeinkommen',
            otherFinancingCashFlows: 'Andere Finanzierungscashflows',
            reportDate: 'Berichtsdatum',
            symbol: 'Symbol',
            totalInvestingCashFlows: 'Gesamtinvestitionscashflows'
        },
        earningsCalls: {
            earningsCalls: 'Quartalszahlen-Anrufe',
            noEarningsCallsAvailable: 'Keine Quartalszahlen verfügbar',
            play: 'Abspielen',
            playing: 'Wiedergabe',
            translateEarningsCall: (str) => str
        },
        investors: {
            investors: 'Investoren',
            insiders: 'Insider',
            institutions: 'Institutionen',
            funds: 'Fonds',
            insideInvestors: 'Inneninvestoren',
            institutionalInvestors: 'Institutionelle Investoren',
            fundInvestors: 'Fondsinvestoren'
        },
        insiderRoster: {
            insiderRoster: 'Insider-Liste',
            noInsidersFound: (symbol: string) => `Keine Insider-Liste gefunden für ${symbol}`,
            shares: 'Aktien',
            asOf: 'Stand'
        },
        topInsiders: 'Top-Insider',
        advancedStats: {
            advancedStats: 'Erweiterte Statistiken',
            beta: 'Beta',
            totalCash: 'Gesamtbetrag an Bargeld',
            currentDebt: 'Aktuelle Schulden',
            revenue: 'Umsatz',
            grossProfit: 'Bruttogewinn',
            totalRevenue: 'Gesamtumsatz',
            ebitda: 'EBITDA',
            revenuePerShare: 'Umsatz pro Aktie',
            revenuePerEmployee: 'Umsatz pro Mitarbeiter',
            debtToEquity: 'Verschuldungsquote',
            profitMargin: 'Gewinnspanne',
            enterpriseValue: 'Unternehmenswert',
            enterpriseValueToRevenue: 'Unternehmenswert zu Umsatz',
            priceToSales: 'Preis-Umsatz-Verhältnis',
            priceToBook: 'Preis-Buchwert-Verhältnis',
            forwardPERatio: 'Vorwärts-P/E-Verhältnis',
            pegRatio: 'PEG-Verhältnis',
            peHigh: 'P/E Hoch',
            peLow: 'P/E Niedrig',
            week52HighDate: '52-Wochen-Hoch-Datum',
            week52LowDate: '52-Wochen-Tief-Datum',
            week52HighDateSplitAdjustOnly: '52-Wochen-Hoch-Datum (nur nach Split angepasst)',
            week52LowDateSplitAdjustOnly: '52-Wochen-Tief-Datum (nur nach Split angepasst)',
            putCallRatio: 'Put-Call-Verhältnis'
        },
        priceTarget: {
            priceTarget: 'Kursziel',
            averagePriceTarget: 'Durchschnittliches Kursziel',
            range: 'Spanne',
            low: 'Niedrig',
            median: 'Median',
            high: 'Hoch',
            average: 'Durchschnittlich'
        },
        otherInvestors: {
            insititutionalInvestors: 'Institutionelle Investoren',
            fundInvestors: 'Fondsinvestoren',
            holder: 'Inhaber',
            shares: 'Aktien',
            asOf: 'Stand',
            value: 'Wert'
        },
        splits: {
            splits: 'Aktiensplits',
            noSplitsOnRecord: (symbol: string) => `Keine Aktiensplits gefunden für ${symbol}`,
            exDate: 'Ex-Datum',
            from: 'Von',
            to: 'Zu',
            ratio: 'Verhältnis',
            reverse: 'Rückgängig machen',
            translateDescription: (desc: string) => desc
        },
        estimatedRevenue: {
            estimatedRevenue: 'Geschätzter Umsatz',
            noData: (symbol: string) => `Keine Umsatzschätzungen verfügbar für ${symbol}`,
            avg: 'Durchschnitt',
            high: 'Hoch',
            low: 'Niedrig',
            nAnalysts: '# Analysten',
            numberAnalysts: 'Anzahl Analysten',
            period: 'Zeitraum',
            revenueAvg: 'Durchschnittlicher Umsatz',
            revenueHigh: 'Hoher Umsatz',
            revenueLow: 'Niedriger Umsatz'
        },
        dividends: {
            dividends: 'Dividenden',
            noData: (symbol: string) => `Keine Dividenden verfügbar für ${symbol}`,
            mostRecentDividend: 'Neueste Dividende',
            dividendForDate: 'Dividende für Datum',
            amount: 'Betrag',
            currency: 'Währung',
            declaredDate: 'Erklärtes Datum',
            exDate: 'Ex-Datum',
            flag: 'Flagge',
            frequency: 'Frequenz',
            paymentDate: 'Zahlungsdatum',
            recordDate: 'Aufzeichnungsdatum',
            symbol: 'Symbol',
            date: 'Datum'
        }
    },

    // Option List Screen

    optionListScreen: {
        ask: 'Ask',
        askValue: 'Ask-Preis',
        bid: 'Gebot',
        bidValue: 'Gebotspreis',
        breakEven: 'Gewinnschwelle',
        buy: 'Kaufen',
        calendarSpreads: 'Kalenderspreads',
        call: 'Kauf-Option',
        change: 'Veränderung',
        changePercent: 'Veränderung %',
        close: 'Schließen',
        contractsYouSelect: 'Die von Ihnen ausgewählten Verträge erscheinen hier.',
        expirationDate: 'Verfallsdatum',
        high: 'Hoch',
        impliedVolatility: 'Implizite Volatilität',
        intrinsicValue: 'Innerer Wert',
        lastPrice: 'Letzter Preis',
        low: 'Tief',
        open: 'Eröffnung',
        openInterest: 'Offenes Interesse',
        optionChain: 'Optionskette',
        price: 'Letzter Preis',
        put: 'Verkauf-Option',
        sell: 'Verkaufen',
        sharePrice: 'Aktienkurs',
        strikePrice: 'Ausübungspreis',
        theGreeks: 'Die Griechen',
        disclaimer: '15 Minuten Verspätung',
        optionGreeksTooltipIntro:
            'Optionsgriechen werden verwendet, um das Risiko einer Option und die Sensitivität einer Option gegenüber den Variablen zu messen, die dieses Risiko ausmachen. Die Variablen werden durch die griechischen Buchstaben Delta, Gamma, Theta, Vega und Rho dargestellt.',
        optionGreeksTooltipDelta: 'Delta Δ: Änderungsrate pro 1 USD des zugrunde liegenden Wertpapiers',
        optionGreeksTooltipGamma: 'Gamma Γ: Änderungsrate von Δ',
        optionGreeksTooltipTheta: 'Theta Θ: Preisänderungsrate im Zeitverlauf',
        optionGreeksTooltipVega: 'Vega ν: Änderungsrate zwischen dem Preis der Option und der impliziten Volatilität des zugrunde liegenden Wertpapiers',
        optionGreeksTooltipRho: 'Rho ρ: Änderungsrate zwischen dem Wert einer Option und einer Änderung des Zinssatzes um 1 %',
        optionGreeksDelayed: '15-minütige Verzögerung',
        toBreakEven: 'Um die Gewinnschwelle zu erreichen',
        tradeFutures: 'Futures handeln',
        tradeOptions: 'Optionen handeln',
        tradeStock: 'Aktien handeln',
        viewMore: 'Mehr anzeigen',
        viewOptionChain: 'Optionskette anzeigen',
        volume: 'Volumen',
        floatingButtonText: (qsi: string) => `${qsi} Ketten`
    },

    // A Piece of Logic in src/components/TradeTicket/Option/OptionTradeInputPage.tsx(83,20) prevents this from being inside optionListScreen
    greeks: {
        delta: {
            text: 'Delta',
            symbol: 'Δ',
            tooltip: 'Änderungsrate pro 1 USD des zugrunde liegenden Wertpapiers.'
        },
        gamma: {
            text: 'Gamma',
            symbol: 'Γ',
            tooltip: 'Änderungsrate von Δ.'
        },
        theta: {
            text: 'Theta',
            symbol: 'Θ',
            tooltip: 'Preisänderungsrate im Zeitverlauf.'
        },
        vega: {
            text: 'Vega',
            symbol: 'v',
            tooltip: 'Änderungsrate zwischen dem Preis der Option und der impliziten Volatilität des zugrunde liegenden Wertpapiers.'
        },
        rho: {
            text: 'Rho',
            symbol: 'ρ',
            tooltip: 'Änderungsrate zwischen dem Wert einer Option und einer Änderung des Zinssatzes um 1 %.'
        }
    },
    enableOptionsScreen: {
        enableOptions: 'Optionen aktivieren',
        title: 'Sie haben anscheinend keinen Zugriff auf Optionen!',
        getStartedWithOptions: 'Beginnen Sie mit dem Optionshandel, indem Sie unten ein Konto auswählen',
        noOptionsEnabled: 'Sie haben Optionen nicht auf Ihrem Konto aktiviert. Möchten Sie das?',
        enableOptionsTrading: 'Ja, Optionshandel aktivieren',
        notNow: 'Nicht jetzt',
        noTradingOptionsEnabled: 'Sie haben den Optionshandel auf keinem Ihrer Konten aktiviert. Wählen Sie unten ein Konto aus, um loszulegen.'
    },

    optionContractScreen: {
        expiresOnDate: (date: Date) => `Läuft ab ${SafeFormat(date, 'dd MMM yyyy')}`,
        expiresSoon: 'Läuft bald ab',
        symbolLastQuote: (symbol: string) => `${symbol} Aktuellster Kurs`,
        tradeUnderlying: (symbol: string) => `${symbol} handeln`,
        viewAllOptions: 'Alle Optionen anzeigen',
        viewMoreHistory: 'Mehr Historie anzeigen',
        viewMoreDetails: 'Mehr Details anzeigen',
        history: 'Historie',
        addLeg: 'Bein hinzufügen',
        removeLeg: 'Bein entfernen',
        stats: {
            stats: 'Statistiken',
            bid: 'Gebot',
            ask: 'Ask',
            previousClose: 'Vorheriger Schlusskurs',
            breakEven: 'Gewinnschwelle',
            open: 'Eröffnung',
            close: 'Schließen',
            high: 'Hoch',
            low: 'Tief',
            volume: 'Volumen',
            openInterest: 'Offenes Interesse',
            impliedVolatility: 'Implizite Volatilität',
            intrinsicValue: 'Innerer Wert'
        }
    },

    futuresContractScreen: {
        baseContract: 'Basisvertrag',
        expiresDate: (date: string | Date) => `Läuft ab ${SafeFormat(date, 'dd MMM yyyy')}`,
        upgradeToPremium: 'Upgrade auf Market Intelligence Premium',
        viewMoreContracts: 'Mehr Verträge anzeigen',
        domNotInSession: {
            title: (symbol: string) => `${symbol} nicht in Sitzung`,
            message: 'Da dieser Vertrag nicht in Sitzung ist, ist das Orderbuch derzeit nicht verfügbar.'
        }
    },
    advancedChartScreen: {
        notifications: {
            orderNotPlaced: 'Auftrag nicht platziert',
            invalidSecurity: 'Ungültiges Sicherheitssymbol',
            invalidAccount: 'Ungültige Kontonummer',
            shortSellNotAllowed: 'Leerverkauf nicht erlaubt vom erweiterten Diagramm aus'
        }
    },

    tradeTicket: {
        adjustedOptionsDisclaimer: 'Aufgrund von Liquiditäts- und Abwicklungsriskien für angepasste Optionen können wir nur Abschlussaufträge zulassen.',
        betaMaintenanceHours: 'Außerhalb der Geschäftszeiten – Handel nicht verfügbar von 12:00 bis 01:59 Uhr EST, Sonntag bis Samstag',
        cancelEquityOrdersHours:
            'Aktien- und Aktienoptionsaufträge können nur außerhalb der Systemwartungszeiten bzw. außerhalb der Geschäftszeiten storniert werden, d. h. sonntags bis samstags von 12:00 bis 01:59 Uhr EST. Bitte versuchen Sie es später noch einmal abzubrechen.',
        modifyEquitiesDayOrdersHours:
            'Tagesaufträge für Aktien und Aktienoptionen können nur von Montag bis Freitag zwischen 8:00 und 15:59 Uhr EST bearbeitet werden. Wenn Sie diese Bestellung jetzt ändern möchten, stornieren Sie bitte und senden Sie eine neue Bestellung erneut.',
        modifyEquitiesGtcOrdersHours:
            'Aktien- und Aktienoptionsaufträge mit gültiger Gültigkeit bis zur Stornierung können nur von Montag bis Freitag zwischen 8:00 und 16:59 Uhr EST bearbeitet werden.“ Wenn Sie diese Bestellung jetzt ändern möchten, stornieren Sie bitte und senden Sie eine neue Bestellung erneut.',
        timeFrames: 'Zeitrahmen',
        oneClickTradingTitle: 'Handeln mit einem Klick',
        position: 'Position',
        sellAll: 'Alles verkaufen',
        buyAll: 'Alles kaufen',
        oneClickTradingDescription:
            'Überspringen Sie diesen Bildschirm beim nächsten Mal, wenn Sie einen Handel platzieren. Wir werden Sie nicht dazu zwingen, den Handel vor der Platzierung zu überprüfen, wenn Sie sich für den Handel mit einem Klick entscheiden.\n\nSie können es ausschalten, indem Sie zu Ihren "Einstellungen" im Abschnitt "Profil" gehen.',
        misc: {
            noChangesToReview: 'Keine Änderungen zum Überprüfen',
            setQuantity: 'Menge festlegen',
            startTradingOptions: 'Beginnen Sie mit dem Handel von Optionen',
            securityUnavailable: (symbol: string) => `Entschuldigung, ${Snex1German.general.symbolName(symbol)} ist nicht handelbar bei StoneX One.`,
            enableOptionsTradingNow: 'Optionshandel jetzt aktivieren',
            createFuturesAccount: 'Futures-Konto erstellen',
            createEquitiesAccount: 'Aktienkonto erstellen',
            accountPending: 'Dieses Konto wartet auf Genehmigung',
            applicationPending: 'Antrag ausstehend',
            notAvailableToTrade: 'Noch nicht handelbar bei StoneX One',
            noPricing: 'Keine Preisinformationen verfügbar.',
            noPricingCall: 'Bitte rufen Sie 1-866-443-5847 für Unterstützung an.',
            cannotEdit: 'Kann nicht bearbeitet werden',
            orderExtendedHoursError: (orderType: string) =>
                `${(orderType.charAt(0).toUpperCase() + orderType.slice(1)).replace(
                    'Stoplimit',
                    'Stop-Limit'
                )} Aufträge sind während des Handels außerhalb der regulären Öffnungszeiten nicht verfügbar`,
            extendedHoursMustBeChecked:
                'Die Box für den Handel außerhalb der regulären Öffnungszeiten muss angekreuzt sein, um Aufträge außerhalb der regulären Öffnungszeiten zu platzieren',
            badTimeInForce: 'Das ausgewählte Ablaufdatum des Auftrags darf zu diesem Zeitpunkt nicht verwendet werden',
            badTimeForStrat: 'Kann zu diesem Zeitpunkt nicht verwendet werden',
            premarketRequired: 'Nur Aufträge mit vorbörslicher Ausführung sind derzeit erlaubt',
            postmarketRequired: 'Nur Aufträge mit nachbörslicher Ausführung sind derzeit erlaubt',
            notAvailableToEdit: 'Auftrag kann derzeit nicht bearbeitet werden',
            isHoliday: 'Der Handel ist an Marktfeiertagen nicht möglich',
            isUnderMaintenance: 'Derzeit außerhalb der Geschäftszeiten. Bitte versuchen Sie es später erneut.',
            overnightMarginCost: 'Kosten für Übernachtmargen',
            notInSession: (s, e) => `Dieses Wertpapier wird zwischen ${SafeFormat(s, 'h:mm bb')} - ${SafeFormat(e, 'h:mm bb')} EST gehandelt`,
            notInSessionOptions: (s, e, tz) => `Optionsgeschäfte können nur zwischen ${SafeFormat(s, 'h:mm bb')} - ${SafeFormat(e, 'h:mm bb')} ${tz} platziert werden`,
            notInSessionOptionsSymbolic: (s, e, tz) => `Optionsgeschäfte können nur zwischen ${s} - ${e} ${tz} platziert werden`,
            notInSessionFutures: (s, e) =>
                `Die Handelszeiten für diesen Futures-Vertrag sind ${s ? format(utcToZonedTime(s, 'America/Chicago'), 'h:mm bb') : '---'} - ${
                    e ? format(utcToZonedTime(e, 'America/Chicago'), 'h:mm bb z', { timeZone: 'America/Chicago' }) : '---'
                }`,
            notInSessionFuturesOptions: (s, e) =>
                `Die Handelszeiten für diesen Futures-Vertrag sind ${s ? format(utcToZonedTime(s, 'America/Chicago'), 'h:mm bb') : '---'} - ${
                    e ? format(utcToZonedTime(e, 'America/Chicago'), 'h:mm bb z', { timeZone: 'America/Chicago' }) : '---'
                }`,
            notInSessionShortSell: `Leerverkaufsaufträge können nur zwischen 9:30 Uhr und 16:00 Uhr EST platziert werden`,
            invalidEditTimeAdvisory: 'Aufträge können zwischen 17:00 Uhr EST und 7:56 Uhr EST nicht bearbeitet werden.',
            residualSharesWillBeSettled:
                'Verbleibende Bruchteile von Aktien werden als ausstehend angezeigt und innerhalb von zwei Tagen nach der Transaktion automatisch abgerechnet.',
            minimumNotMetTitle: 'Mindestanfangsinvestition nicht erfüllt',
            minimumNotMetSubtext: (minimumInvestment: number): string =>
                `Dieser Fonds erfordert eine Mindestanfangsinvestition von ${FormatNumber.toMoneyOpt2(minimumInvestment)}.`,
            closedToNewInvestors: 'Dieser Fonds ist für neue Investoren geschlossen',
            isWeekend: 'Der Handel mit diesem Wertpapier ist am Wochenende nicht verfügbar',
            notInSessionGeneral: 'Entschuldigung, dieses Wertpapier ist derzeit nicht verfügbar',
            securityTypeUnavailable: (type: TradeableSecurityType | 'loading') => {
                switch (type || 'unknown') {
                    case 'loading':
                        return 'Laden';
                    case 'unknown':
                        return 'Entschuldigung, Wertpapiere dieses Typs können derzeit nicht gehandelt werden';
                    default:
                        return `Entschuldigung, ${Snex1German.general.productTypes[type]?.plural || 'Wertpapiere dieses Typs'} können derzeit nicht gehandelt werden`;
                }
            },
            validatingOrder: 'Bestellung wird validiert...'
        },

        input: {
            retry: 'Wiederholen',
            cancel: 'Abbrechen',
            notes: 'Notizen',
            tradeNotPermitted: 'Handel nicht erlaubt',
            orderNotAllowed: 'Auftrag nicht erlaubt',
            cannotShort: 'Shorten nicht möglich',
            invalidQuantity: 'Ungültige Menge',
            strategy: 'Strategie',
            x1pAlgo: 'X1P Algorithmus',
            care: 'Achtung',
            careDescription: 'Auftrag nicht gehalten',
            vwap: 'VWAP',
            vwapDescription: 'Volumengewichteter Durchschnittspreis',
            twap: 'TWAP',
            twapDescription: 'Zeitgewichteter Durchschnittspreis',
            pov: 'POV',
            povDescription: 'Prozent vom Volumen',
            sor: 'SOR',
            sorDescription: 'Smart Order Routing',
            applyForMargins: 'Für Margen bewerben',
            unknownTitle: (isError: boolean) => (isError ? 'Unbekannter Fehler' : 'Unbekannte Warnung'),
            unknownMessage: (isError: boolean) => (isError ? 'Es tut uns leid, aber ein unbekannter Fehler ist aufgetreten' : 'Es tut uns leid, aber eine unbekannte Warnung ist aufgetreten'),

            tradeNow: 'Jetzt handeln',
            unitName: (symbol) => (FuturesSymbol.IsFuturesSymbol(symbol) || OptionSymbol.IsOptionSymbol(symbol) ? 'Vertrag' : 'Aktie'),
            futures: {
                enforceFractional: 'Ungültiger Bruchwert',
                enforceTickSize: (tickSize: number) => `Preis muss in Schritten von ${tickSize} sein.`,
                numberPerContract: (formattedNumber: string) => `${formattedNumber} pro Vertrag`,
                side: 'Seite'
            },
            action: {
                buy: 'Kaufen',
                sell: 'Verkaufen',
                tabFontSize: 20
            },
            buyingPower: 'Kaufkraft',
            marketValue: 'Marktwert',
            buyingPowerAdvisory: (t) => `Sie haben nicht genügend Mittel, um ${t.quantity} Aktie(n) von ${t.securityId} zu kaufen.`,
            addFundsToContinue: 'Mittel hinzufügen, um fortzufahren',
            depositFunds: 'Mittel einzahlen',
            dismiss: 'Verwerfen',
            shares: 'Aktien',
            tradeWholeShares: 'In ganzen Aktien handeln',
            tradeDollarAmount: 'Handel mit einem Dollarbetrag',
            securityCannotBeTraded: 'Dieses Wertpapier kann nicht in dieser Einheit gehandelt werden.',
            bid: 'Gebot',
            ask: 'Angebot',
            mid: 'Mitte',
            vol: 'Vol',
            held: (symbol) => `${Snex1German.general.unit(symbol, 2)} Gehalten`,
            options: {
                breakEven: 'Break Even',
                credit: 'Kredit',
                debit: 'Debit',
                even: 'Ausgeglichen',
                last: 'Letzte',
                netAsk: 'Nettoangebot',
                netBid: 'Nettogebot',
                midpoint: 'Mittelpunkt',
                underlyingShares: 'Zugrundeliegende Aktien',
                contracts: 'Verträge',
                sharesPerContract: (n: number) => `${FormatNumber.toCommas(n)} Aktien pro Vertrag`,
                actionToOpenClose: (t, openClose) => `${t.action} zum ${openClose === 'Open' ? 'Öffnen' : 'Schließen'}`,
                unlimited: 'Unbegrenzt',
                maxGain: 'Maximaler Gewinn',
                maxLoss: 'Maximaler Verlust',
                enableOptionsTrading: 'Optionshandel aktivieren',
                upgradeOptionsLevel: 'Upgrade Ihres Optionsniveaus'
            },
            advisories: {
                notice: 'Hinweis',
                immediateExecution: {
                    orderPriceError: (priceName: 'limit' | 'stop', priceIs: 'above-market' | 'below-market') =>
                        `Auftrag wird sofort ausgeführt, da der ${priceName}-Preis ${priceIs === 'above-market' ? 'über' : 'unter'} dem Marktpreis liegt.`,
                    stopLimitPriceError: (priceIs: 'above-stop' | 'below-stop') =>
                        `Ihr Auftrag könnte sofort ausgeführt werden, sobald der Stop-Preis erreicht ist, da der Limit-Preis ${
                            priceIs === 'above-stop' ? 'über' : 'unter'
                        } dem Stop-Preis liegt.`
                },
                insufficientValue: {
                    notEnoughBuyingPower: 'Nicht genügend Kaufkraft',
                    notEnoughMarketValue: 'Nicht genügend gehalten',
                    depositFunds: 'Mittel einzahlen',
                    dismiss: 'Verwerfen'
                },
                invalidStopOrder: {
                    buy: 'Ein Kauf-Stopp-Auftrag erfordert einen Stop-Preis, der über dem aktuellen Marktpreis liegt.',
                    sell: 'Ein Verkaufs-Stopp-Auftrag erfordert einen Stop-Preis, der unter dem aktuellen Marktpreis liegt.'
                },
                amountIsEstimate: {
                    mainText: 'Dieser Betrag ist eine Schätzung.',
                    subText: 'Ihr Auftrag wird zum bestmöglichen Preis ausgeführt.'
                },
                notionalValueExceeded: {
                    mainText: 'Maximaler Nennwert überschritten',
                    subText:
                        'Die geschätzten Gesamtkosten Ihres Handels überschreiten den maximalen Handelswert Ihres Profils. Bitte kontaktieren Sie den Support für weitere Informationen.'
                }
            },
            dollars: 'Dollar',
            limitPrice: 'Limitpreis',
            stopPrice: 'Stoppreis',
            stopLimitPrice: 'Stop-Limit-Preis',
            marketPrice: 'Marktpreis',
            expiration: 'Ablauf',
            orderType: {
                limit: 'Limit',
                loc: 'LOC',
                market: 'Markt',
                moc: 'MOC',
                stop: 'Stopp',
                stoplimit: 'Stop-Limit'
            },
            reviewAction: (t) => `Überprüfen Sie ${t.action}`,
            reviewEdit: (t) => `Überprüfen Sie die ${t.action}-Auftragsbearbeitung`,
            reviewTrade: 'Handel überprüfen',
            buySymbol: (symbolName) => `Kaufen von ${symbolName}`,
            sellSymbol: (symbolName) => `Verkaufen von ${symbolName}`,
            sellAll: (t, amount) => `Alles verkaufen ${FormatNumber.toDecimal(amount, 3)} ${Snex1German.general.unit(t.securityId, amount)}`,
            selectAnAccount: 'Konto auswählen',
            timeInForce: 'Gültigkeitsdauer',
            timesInForce: {
                day: 'Gültig für den Tag',
                gtc: 'Gültig bis zur Stornierung',
                fok: 'Sofort oder Abbruch',
                ioc: 'Sofort oder Abbruch'
            },
            advancedRouting: {
                notHeld: 'Nicht gehalten'
            },
            dvp: 'DVP',
            extendedHours: {
                GTXPre: 'Erlauben Sie diese Bestellung während der erweiterten Handelszeiten auszuführen',
                GTXPost: 'Erlauben Sie diese Bestellung während der erweiterten Handelszeiten auszuführen',
                nte: 'Erlauben Sie diese Bestellung während der erweiterten Handelszeiten auszuführen'
            },
            totalCost: 'Gesamtkosten',
            estimatedSymbolAmount: (symbol) => `Geschätzter ${symbol} Betrag`,
            estimatedTotalCredit: 'Geschätzter Gesamtkredit',
            estimatedTotalCost: 'Geschätzte Gesamtkosten',
            totalGain: 'Gesamtguthaben',
            estimatedTotalGain: 'Geschätztes Guthaben',
            error: 'Etwas stimmt nicht',
            warning: 'Bitte bestätigen',
            resubmit: 'Erneut versuchen',
            submitAnyway: 'Fortfahren',
            submitShort: 'Fortfahren mit Kurzbestellung',
            continue: 'Fortfahren',
            reviseOrder: 'Bestellung überarbeiten'
        },

        reinvestmentOptions: {
            reinvestmentOptions: 'Wiederanlageoptionen',
            instructions: (t) =>
                `Bitte geben Sie an, welche der folgenden Erträge Sie in Ihrer Position mit ${t.securityId} wieder anlegen möchten. Dies sind Ihre Wiederanlagepräferenzen für diesen und alle zukünftigen Käufe von ${t.securityId} über das Konto ${t.accountNumber} und können später nicht geändert werden.`,
            mutualFundInstructions: (t) =>
                `Standardmäßig werden Ausschüttungen von Investmentfonds als Guthaben auf einem Cash-Konto verbucht. Wenn Sie Dividenden und/oder Kapitalgewinne wieder anlegen möchten, müssen Sie dies unten angeben. Alle festgelegten Wiederanlagepräferenzen werden sofort angewendet und gelten für alle zukünftigen Käufe von ${t.securityId} über das Konto ${t.accountNumber}. Sie können Ihre Wiederanlagepräferenzen über den Kundenservice ändern. Bitte beachten Sie, dass Investmentfondsausschüttungen unabhängig von den Wiederanlagepräferenzen steuerpflichtig sind. Ich möchte in das Wertpapier Folgendes reinvestieren:`,
            offshoreCashInstructions: (accountNumber: string, isin: string) =>
                `Ausschüttungen für diesen Offshore-Investmentfonds werden automatisch Ihrem Konto als Guthaben (freies Kreditguthaben) gutgeschrieben. Dies wird sofort angewendet und gilt für alle zukünftigen Käufe von ${isin} über das Konto ${accountNumber}. Durch Fortfahren mit diesem Auftrag erklären Sie sich damit einverstanden, dass die Präferenz für diesen Offshore-Investmentfonds auf Bargeld festgelegt wird.`,
            offshoreReinvestInstructions: (accountNumber: string, isin: string) =>
                `Ausschüttungen für diesen Offshore-Investmentfonds werden automatisch als Wiederanlage auf Ihr Konto angewendet. Dies wird sofort angewendet und gilt für alle zukünftigen Käufe von ${isin} über das Konto ${accountNumber}. Durch Fortfahren mit diesem Auftrag erklären Sie sich damit einverstanden, dass die Präferenz für diesen Offshore-Investmentfonds auf Wiederanlage festgelegt wird.`,
            review: 'Überprüfen',
            dividends: 'Dividenden',
            shortTermCapitalGains: 'Kurzfristige Kapitalgewinne',
            longTermCapitalGains: 'Langfristige Kapitalgewinne',
            editButtonText: 'Wiederanlageoptionen bearbeiten',
            saveButton: 'Präferenzen speichern',
            reinvest: 'Wiederanlegen',
            mutualFundsDistribution: 'Ausschüttungen von Investmentfonds sind unabhängig von den Wiederanlagepräferenzen steuerpflichtig',
            usePreferences: 'Präferenzen verwenden',
            shortTermsGains: 'Kurzfristige Gewinne',
            longTermGains: 'Langfristige Gewinne',
            shortTerm: 'Kurzfristig',
            longTerm: 'Langfristig',
            reinvestScreenDesc: (symbol: string, accountNumber?: string) =>
                `Sie können ${symbol}-Ausschüttungen einem Cash-Konto als freies Kreditguthaben zuweisen oder sie wieder in ${symbol} anlegen. Diese Präferenzen werden für alle ${symbol}-Transaktionen verwendet, die über das Konto ${accountNumber} getätigt werden, sobald Sie diesen Handel platziert haben, und können jederzeit geändert werden.`
        },

        review: {
            confirmTrade: 'Bestätigen',
            gtxDisclaimer:
                'Der Handel während der Handelssitzungen außerhalb der regulären Öffnungszeiten (einschließlich der Pre-Market-Sitzung (Montag bis Freitag von 8:00 bis 9:30 Uhr ET) und der After-Market-Sitzung (Montag bis Freitag von 16:00 bis 17:00 Uhr ET), jeweils unter Berücksichtigung der Feiertags-Handelszeiten) birgt besondere Risiken wie erhöhte Kursvolatilität, geringere Liquidität, breitere Geld-Brief-Spannen und geringere Markttransparenz und ist möglicherweise nicht für alle Anleger geeignet. StoneX One kann nicht garantieren, dass Orders außerhalb der regulären Handelszeiten zum besten Preis für ein bestimmtes Wertpapier ausgeführt werden oder dass solche Orders, einschließlich Market Orders, überhaupt ausgeführt werden. Indem Sie um Zugang zur Eingabe von Orders bitten und eine Order während des Handels außerhalb der regulären Handelszeiten bei StoneX One, einschließlich auf einer StoneX One-Handelsplattform oder mobilen Plattform, eingeben, erkennen Sie an, dass Sie die Risiken des Handels während der Handelssitzungen außerhalb der regulären Handelszeiten verstehen.',
            offshoreDisclaimer:
                'Die Annahmefristen für Offshore-Investmentfonds variieren, daher könnte dieser Handel möglicherweise den Nettoinventarwert am nächsten Tag haben. Bitte informieren Sie sich vor einer Anlage über Annahmeschlusszeiten, Fondsgebühren und andere wichtige Informationen im Verkaufsprospekt des Fonds. Aufträge für Offshore-Investmentfonds können nach der Übermittlung nicht über das System storniert oder geändert werden. Bitte wenden Sie sich an den Support, um eine Stornierung oder Änderung anzufordern.',
            equities: (priceFormatter, trade, currentMarketPrice, totalCost, limitPrice?: number, stopPrice?: number) => {
                const symbol = Snex1German.general.symbolName(trade.securityId, 'long');
                const formattedSymbol = Dark(symbol);
                const formattedLimitPrice = Dark(priceFormatter(limitPrice));
                const formattedStopPrice = Dark(priceFormatter(stopPrice));
                const formattedTotalOrderPrice = Dark(`$${totalCost.toFixed(2)}`);
                const formattedCurrentMarketPrice = Dark(priceFormatter(currentMarketPrice));

                const isOnCloseOrder = [OrderTypes.loc, OrderTypes.moc].includes(trade?.orderType);
                if (trade?.liquidate) return [Light('Sie '), Dark('verkaufen alle Ihre Anteile an'), formattedSymbol, Light('zum'), Dark('Marktpreis'), Light('.')];

                const tifQualifierLogic = [
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'GTXPRE' || trade?.extendedTimeInForce?.toUpperCase() === 'GTXPRE',
                        value: 'zwischen 8:00 und 17:00 Uhr ET'
                    },
                    {
                        rule:
                            trade?.timeInForce?.toUpperCase() === 'NTE' ||
                            trade?.extendedTimeInForce?.toUpperCase() === 'NTE' ||
                            trade?.timeInForce?.toUpperCase() === 'GTXPOST' ||
                            trade?.extendedTimeInForce?.toUpperCase() === 'GTXPOST',
                        value: 'während der heutigen erweiterten nachbörslichen Sitzung (16:00 - 17:00 Uhr EST)'
                    },
                    {
                        rule: (trade?.timeInForce?.toUpperCase() === 'DAY' || isOnCloseOrder) && !trade?.extendedTimeInForce,
                        value: 'heute'
                    },
                    {
                        rule: true, // default to GTC
                        value: 'in den nächsten 180 Tagen'
                    }
                ];

                const tifQualifier = tifQualifierLogic.find((x) => x.rule).value;

                // if equities apply extended time in force check to tif qualifier
                const actionWording = trade?.action === 'Buy' ? 'kaufen' : 'verkaufen';
                const actionWordVerb = trade?.action === 'Buy' ? 'Kauf' : 'Verkauf';
                const actionWordNoun = trade?.action === 'Buy' ? 'Kauforder' : 'Verkaufsorder';
                const xUnits = `${FormatNumber.toCommas(trade.quantity)} ${Snex1German.general.unit(trade.securityId, trade?.quantity)?.toLowerCase()}`;
                const unit = Snex1German.general.unit(trade.securityId, 1)?.toLowerCase();

                switch (true) {
                    case [OrderTypes.market, OrderTypes.moc].includes(trade?.orderType as OrderType): {
                        const perShare = isNaN(currentMarketPrice) ? '.' : `pro ${unit}.`;
                        return [
                            Light('Sie '),
                            Dark(`${actionWording} ${FormatNumber.toCommas(trade.quantity)}`),
                            Light(`${Snex1German.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} von`),
                            formattedSymbol,
                            Light('zum'),
                            Dark('Marktpreis'),
                            ...(isNaN(currentMarketPrice) ? [] : [Light('derzeit'), formattedCurrentMarketPrice]),
                            Light(perShare)
                        ];
                    }
                    case [OrderTypes.limit, OrderTypes.loc].includes(trade.orderType):
                        return [
                            Light('Wenn der Preis von'),
                            formattedSymbol,
                            Light('erreicht'),
                            formattedLimitPrice,
                            Light(`${tifQualifier}, platzieren Sie eine`),
                            Dark(actionWordNoun),
                            Light('für'),
                            Dark(xUnits),
                            Light('für'),
                            formattedTotalOrderPrice,
                            Light('oder besser.')
                        ];
                    case trade.orderType === 'stop':
                        return [
                            Light('Wenn der Preis von'),
                            formattedSymbol,
                            Light('erreicht'),
                            formattedStopPrice,
                            Light(`${tifQualifier}, platzieren Sie eine`),
                            Dark(actionWordNoun),
                            Light('für'),
                            Dark(xUnits),
                            Light('zum'),
                            Dark('Marktpreis'),
                            Light('.')
                        ];
                    case trade.orderType === 'stoplimit':
                        return [
                            Light('Wenn der Preis von'),
                            Dark(symbol),
                            Light('erreicht'),
                            formattedStopPrice,
                            Light(`${tifQualifier}, platzieren Sie eine Limitorder zum`),
                            Dark(`${actionWordVerb} ${xUnits}`),
                            Light('für'),
                            formattedTotalOrderPrice,
                            Light('zum'),
                            formattedLimitPrice,
                            Light('oder besser.')
                        ];
                }
            },
            tifQualifier(trade, formattedSymbol) {
                const isOnCloseOrder = [OrderTypes.loc, OrderTypes.moc].includes(trade?.orderType);
                if (trade?.liquidate) return [Light('Sie '), Dark('verkaufen alle Ihre Anteile an'), Dark(formattedSymbol), Light('zum'), Dark('Marktpreis'), Light('.')];

                const tifQualifierLogic = [
                    {
                        rule: trade?.timeInForce?.toUpperCase() === 'GTXPRE' || trade?.extendedTimeInForce?.toUpperCase() === 'GTXPRE',
                        value: 'zwischen 8:00 und 17:00 Uhr ET'
                    },
                    {
                        rule:
                            trade?.timeInForce?.toUpperCase() === 'NTE' ||
                            trade?.extendedTimeInForce?.toUpperCase() === 'NTE' ||
                            trade?.timeInForce?.toUpperCase() === 'GTXPOST' ||
                            trade?.extendedTimeInForce?.toUpperCase() === 'GTXPOST',
                        value: 'während der heutigen erweiterten nachbörslichen Sitzung (16:00 - 17:00 Uhr EST)'
                    },
                    {
                        rule: (trade?.timeInForce?.toUpperCase() === 'DAY' || isOnCloseOrder) && !trade?.extendedTimeInForce,
                        value: 'heute'
                    },
                    {
                        rule: true, // default to GTC
                        value: 'in den nächsten 180 Tagen'
                    }
                ];

                return tifQualifierLogic.find((x) => x.rule).value;
            },
            actionText: (trade: Partial<TradeRequest>, assetClass?: AssetClass, secMeta?: SecurityMetadata) => {
                return {
                    actionWording: trade?.action === 'Buy' ? 'kaufen' : 'verkaufen',
                    actionWordVerb: trade?.action === 'Buy' ? 'kaufen' : 'verkaufen',
                    actionWordNoun: trade?.action === 'Buy' ? 'Kauforder' : 'Verkaufsorder',
                    xUnits: `${FormatNumber.toCommas(trade.quantity)} ${Snex1German.general.unit(trade.securityId, trade?.quantity)?.toLowerCase()}`,
                    unit: Snex1German.general.unit(trade.securityId, 1)?.toLowerCase()
                };
            },
            equitiesV2: (assetClass, trade, secMeta, currentMarketPrice, totalCost, limitPrice?, stopPrice?): ShadedTextList => {
                const symbol = Snex1German.general.symbolName(trade.securityId, 'long');
                const formattedSymbol = Dark(symbol);
                const formattedLimitPrice = Dark(assetClass.formatPrice(limitPrice, secMeta));
                const formattedStopPrice = Dark(assetClass.formatPrice(stopPrice, secMeta));
                const formattedTotalOrderPrice = Dark(assetClass.formatPrice(totalCost, secMeta));
                const formattedCurrentMarketPrice = Dark(assetClass.formatPrice(currentMarketPrice, secMeta));

                const tifQualifier = Snex1German.tradeTicket.review.tifQualifier(trade, formattedSymbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWording, actionWordVerb, actionWordNoun, xUnits, unit } = Snex1German.tradeTicket.review.actionText(trade);

                const nonMarketTradeText = () => {
                    const isLimit = [OrderTypes.limit, OrderTypes.loc].includes(trade?.orderType as OrderType);
                    const isStop = trade.orderType === 'stop';
                    const isStopLimit = trade.orderType === 'stoplimit';
                    let text = [Light('Wenn der Preis von'), formattedSymbol, Light('erreicht')];

                    isStop || isStopLimit ? text.push(formattedStopPrice) : text.push(formattedLimitPrice);

                    if (isStopLimit) {
                        return text.concat([
                            Light(`${tifQualifier}, platzieren Sie eine Limitorder zum`),
                            Dark(`${actionWordVerb} ${xUnits}`),
                            Light('für'),
                            formattedLimitPrice,
                            Light('oder besser.')
                        ]);
                    } else text = text.concat([Light(`${tifQualifier}, platzieren Sie eine`), Dark(actionWordNoun), Light('für'), Dark(xUnits)]);

                    if (isLimit) return text.concat([Light('für'), formattedTotalOrderPrice, Light('oder besser.')]);
                    else return text.concat([Light('zum'), Dark('Marktpreis'), Light('.')]);
                };

                if ([OrderTypes.market, OrderTypes.moc].includes(trade?.orderType as OrderType)) {
                    const perShare = isNaN(currentMarketPrice) ? '.' : `pro ${unit}.`;
                    return [
                        Light('Sie '),
                        Dark(`${actionWording} ${FormatNumber.toCommas(trade.quantity)}`),
                        Light(`${Snex1German.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} von`),
                        formattedSymbol,
                        Light('zum'),
                        Dark('Marktpreis'),
                        ...(isNaN(currentMarketPrice) ? [] : [Light('derzeit'), formattedCurrentMarketPrice]),
                        Light(perShare)
                    ];
                } else return nonMarketTradeText();
            },
            sharesV2: (assetClass, trade, secMeta, price, sharePrice?): ShadedTextList => {
                const creditDebit = trade?.leg2DebitCredit;
                const symbol = Snex1German.general.symbolName(trade.securityId, 'long');

                const tifQualifier = Snex1German.tradeTicket.review.tifQualifier(trade, symbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWording, actionWordVerb, actionWordNoun, xUnits, unit } = Snex1German.tradeTicket.review.actionText(trade, assetClass, secMeta);

                const isMultiLeg = !!trade?.leg2Quantity;
                const leg2ActionWordNoun = trade?.leg2Action === 'Buy' ? 'Kauforder' : 'Verkaufsorder';
                const leg2Sym = Snex1German.general.symbolName(trade?.leg2SecurityId, 'long');

                const nonMarketNonMultiLegText = () => {
                    const isLimit = [OrderTypes.limit, OrderTypes.loc].includes(trade.orderType);
                    const isStopLimit = trade.orderType === 'stoplimit';

                    let text = [Light('Wenn der Preis von'), Dark(symbol), Light('erreicht'), Dark(assetClass.formatPrice(trade.orderPrice, secMeta))];

                    if (isStopLimit) {
                        return text.concat([
                            Light(`${tifQualifier}, platzieren Sie eine Limitorder zum`),
                            Dark(`${actionWordVerb} ${xUnits}`),
                            Light('für'),
                            Dark(assetClass.formatPrice(trade.stopLimitPrice, secMeta)),
                            Light('oder besser.')
                        ]);
                    } else text = text.concat([Light(`${tifQualifier}, platzieren Sie eine`), Dark(actionWordNoun), Light('für'), Dark(xUnits)]);

                    if (isLimit) return text.concat([Light('für'), Dark(assetClass.formatPrice(price, secMeta)), Light('oder besser.')]);
                    else return text.concat([Light('zum'), Dark('Marktpreis'), Light('.')]);
                };

                switch (true) {
                    case isMultiLeg:
                        return [
                            Light('Sie platzieren eine'),
                            Dark(`${actionWordNoun}`),
                            Light('für'),
                            Dark(`${FormatNumber.toCommas(trade.quantity)}`),
                            Light(`${Snex1German.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} von`),
                            Dark(symbol),
                            Light('und eine'),
                            Dark(`${leg2ActionWordNoun}`),
                            Light('für'),
                            Dark(`${FormatNumber.toCommas(trade.leg2Quantity)}`),
                            Light(`${Snex1German.general.unit(trade.leg2SecurityId, trade.leg2Quantity)?.toLowerCase()} von`),
                            Dark(leg2Sym),
                            ...(trade?.orderType === 'market'
                                ? [Light('zum'), Dark('Marktpreis'), Light('.')]
                                : [Light('für '), Dark(`${assetClass.formatPrice(price || 0, secMeta)} Netto ${creditDebit}`)])
                        ];
                    case [OrderTypes.market, OrderTypes.moc].includes(trade?.orderType as OrderType): {
                        const sharePriceFigure = 'Marktpreis';
                        const perShare = isNaN(sharePrice) ? '.' : `pro ${unit}.`;
                        return [
                            Light('Sie '),
                            Dark(`${actionWording} ${assetClass.formatQuantity(trade.quantity, secMeta)}`),
                            Light(`${Snex1German.general.unit(trade.securityId, trade.quantity)?.toLowerCase()} von`),
                            Dark(symbol),
                            Light('zum'),
                            Dark(sharePriceFigure),
                            ...(isNaN(sharePrice) ? [] : [Light('derzeit'), Dark(assetClass.formatPrice(sharePrice, secMeta))]),
                            Light(perShare)
                        ];
                    }
                    default:
                        return nonMarketNonMultiLegText();
                }
            },
            dollarsV2: (assetClass, trade, secMeta, sharePrice?): ShadedTextList => {
                const symbol = Snex1German.general.symbolName(trade.securityId);
                const { actionWording } = Snex1German.tradeTicket.review.actionText(trade);
                const sharePriceFigure = 'Marktpreis';
                return [
                    Light(`Sie ${actionWording}`),
                    Dark(assetClass.formatPrice(trade.quantity, secMeta)),
                    Light('von'),
                    Dark(symbol),
                    Light('zum'),
                    Dark(sharePriceFigure),
                    ...(isNaN(sharePrice) ? [] : [Light('derzeit'), Dark(`${assetClass.formatPrice(sharePrice, secMeta)}`)]),
                    Light('.')
                ];
            },
            futuresV2: (assetClass, trade, secMeta): ShadedTextList => {
                const format = (value: number) => assetClass.formatPrice(value, secMeta);
                const type = trade.orderType === 'stoplimit' ? 'stop-limit' : trade.orderType;
                const action = Snex1German.tradeTicket.review.actionText(trade).actionWordVerb;
                const symbol = Snex1German.general.symbolName(trade.securityId, 'short', trade.quantity, secMeta);
                const s = trade.quantity === 1 ? '' : 's';

                const base: ShadedTextList = [
                    { text: 'Sie platzieren eine', shade: 'light' },
                    { text: `${type} ${action}`, shade: 'dark', assetClass: 'futures' },
                    { text: 'Order für', shade: 'light' },
                    { text: `${trade.quantity} ${symbol}`, shade: 'dark', assetClass: 'futures' },
                    { text: `Kontrakt${s}`, shade: 'light' }
                ];

                const suffGtc: ShadedTextList = [{ text: ', gültig bis zur Stornierung', shade: 'light' }];
                const suffPeriod: ShadedTextList = [{ text: '.', shade: 'light' }];
                const suffPrice = (price: number): ShadedTextList => [
                    { text: 'bei', shade: 'light' },
                    { text: format(price), shade: 'dark', assetClass: 'futures' }
                ];
                const suffStopLimit = (stop: number, limit: number): ShadedTextList => [
                    { text: 'mit einem Stop bei', shade: 'light' },
                    { text: format(stop), shade: 'dark', assetClass: 'futures' },
                    { text: 'und einem Limit von', shade: 'light' },
                    { text: format(limit), shade: 'dark', assetClass: 'futures' }
                ];

                const parts = [
                    base,
                    type === 'limit' || type === 'stop' ? suffPrice(trade.orderPrice) : null,
                    type === 'stop-limit' ? suffStopLimit(trade.orderPrice, trade.stopLimitPrice) : null,
                    trade.timeInForce === 'GTC' ? suffGtc : null,
                    suffPeriod
                ].filter((x) => !!x);

                return ShadedTextUtils.combine(parts);
            },

            futuresSpreads: (assetClass, trade, secMeta): ShadedTextList => {
                const format = (value: number) => assetClass.formatPrice(value, secMeta);
                const type = trade.orderType === 'stoplimit' ? 'stop-limit' : trade.orderType;
                const futuresSym = new FuturesSymbol(trade.securityId);
                const inverseAction = futuresSym.nearMonthDirection === '-';
                const { actionWordVerb: action } = Snex1German.tradeTicket.review.actionText(trade);

                const fullSymbol = Snex1German.general.symbolName(trade.securityId, 'short', trade.quantity, secMeta);
                const firstSymbol = futuresSym.nearMonthContract.slice(2);
                const secondSymbol = futuresSym.farMonthContract.slice(2);

                const s = trade.quantity === 1 ? '' : 's';

                const firstActioning = (trade?.action === 'Buy' && !inverseAction) || (trade?.action === 'Sell' && inverseAction) ? 'kaufen' : 'verkaufen';
                const secondActioning = (trade?.action === 'Buy' && !inverseAction) || (trade?.action === 'Sell' && inverseAction) ? 'verkaufen' : 'kaufen';

                const suffPeriod: ShadedTextList = [{ text: '.', shade: 'light' }];

                const base: ShadedTextList = [
                    { text: 'Sie platzieren eine', shade: 'light' },
                    { text: `${type} ${action}`, shade: 'dark', assetClass: 'futures' },
                    { text: 'Order für', shade: 'light' },
                    { text: `${trade.quantity} ${fullSymbol}`, shade: 'dark', assetClass: 'futures' },
                    { text: `Kontrakt${s}`, shade: 'light' }
                ];

                const symbolExplanation: ShadedTextList = [
                    { text: 'Sie', shade: 'light' },
                    { text: `${firstActioning} ${firstSymbol}`, shade: 'dark', assetClass: 'futures' },
                    { text: 'und', shade: 'light' },
                    { text: `${secondActioning} ${secondSymbol}`, shade: 'dark', assetClass: 'futures' },
                    ...suffPeriod
                ];

                const suffGtc: ShadedTextList = [{ text: ', gültig bis zur Stornierung.', shade: 'light' }];
                const suffPrice = (price: number): ShadedTextList => [
                    { text: 'bei', shade: 'light' },
                    { text: format(price), shade: 'dark', assetClass: 'futures' }
                ];
                const suffStopLimit = (stop: number, limit: number): ShadedTextList => [
                    { text: 'mit einem Stop bei', shade: 'light' },
                    { text: format(stop), shade: 'dark', assetClass: 'futures' },
                    { text: 'und einem Limit von', shade: 'light' },
                    { text: format(limit), shade: 'dark', assetClass: 'futures' }
                ];

                const flattenedArray = () => {
                    const parts = [base];
                    if (type === 'limit' || type === 'stop') parts.push(suffPrice(trade.orderPrice));
                    if (type !== 'stop-limit' && trade.timeInForce !== 'GTC') parts.push(suffPeriod);
                    if (type === 'stop-limit') parts.push(suffStopLimit(trade.orderPrice, trade.stopLimitPrice));
                    if (type === 'stop-limit' && trade.timeInForce !== 'GTC') parts.push(suffPeriod);
                    if (trade.timeInForce === 'GTC') parts.push(suffGtc);
                    parts.push(symbolExplanation);

                    return ShadedTextUtils.combine(parts);
                };

                return flattenedArray();
            },
            cryptos: (assetClass, trade, secMeta, price, sharePrice): ShadedTextList => {
                const symbol = Snex1German.general.symbolName(trade.securityId, 'long');

                const tifQualifier = Snex1German.tradeTicket.review.tifQualifier(trade, symbol);
                if (typeof tifQualifier !== 'string') return tifQualifier;

                const { actionWordVerb, actionWording } = Snex1German.tradeTicket.review.actionText(trade);

                const isDollars = trade?.quantityQualifier === 'EvenDollar';

                if (trade.orderType === 'market') {
                    return [
                        Light('Sie sind'),
                        isDollars
                            ? Dark(`${actionWording} ${assetClass.formatPrice(trade?.quantity)} von ${symbol}`, '', 'Krypto')
                            : Dark(`${actionWording} ${assetClass.formatQuantity(trade?.quantity)} ${symbol}`, '', 'Krypto'),
                        Light('zum'),
                        Dark('Marktpreis', '', 'Krypto'),
                        Light('aktuell bei'),
                        Dark(assetClass.formatPrice(sharePrice, secMeta), '', 'Krypto'),
                        Light('pro Münze.')
                    ];
                } else {
                    const isStopLimit = trade.orderType === 'stoplimit';
                    const isStop = trade.orderType === 'stop';
                    const tif = isStop ? 'gtc' : trade?.timeInForce?.toLowerCase();
                    const expandedTif = Snex1German.tradeTicket.input.timesInForce[tif].toLowerCase();

                    if (isStopLimit) {
                        return [
                            Light('Wenn der Preis von'),
                            Dark(symbol, '', 'Krypto'),
                            Light('erreicht'),
                            Dark(assetClass.formatPrice(trade.orderPrice, secMeta), '', 'Krypto'),
                            Light(`${tifQualifier}, platzieren Sie eine Limit-Order zum`),
                            isDollars
                                ? Dark(`${actionWordVerb} ${assetClass.formatPrice(trade?.quantity)} von ${symbol}`, '', 'Krypto')
                                : Dark(`${actionWordVerb} ${trade?.quantity} ${symbol}`, '', 'Krypto'),
                            Light('für'),
                            Dark(assetClass.formatPrice(trade.stopLimitPrice, secMeta), '', 'Krypto'),
                            Light('oder besser.')
                        ];
                    } else if (isStop) {
                        return [
                            Light('Sie platzieren eine'),
                            Dark(`Stop-${actionWordVerb}`, '', 'crypto'),
                            Light('Bestellung für'),
                            isDollars
                              ? Dark(`${assetClass.formatPrice(trade?.quantity)} von ${symbol}`, '', 'crypto')
                              : Dark(`${trade?.quantity} ${symbol}`, '', 'crypto'),
                            Light('zu einem Preis von'),
                            Dark(assetClass.formatPrice(trade.orderPrice, secMeta), '', 'crypto'),
                            Light(`oder besser,`),
                            Dark(`${expandedTif}.`, '', 'crypto')
                          ]
                          
                    } else {
                        return [
                            Light('Sie platzieren eine'),
                            Dark(`Limit ${actionWordVerb}`, '', 'Krypto'),
                            Light('Order für'),
                            isDollars
                                ? Dark(`${assetClass.formatPrice(trade?.quantity)} von ${symbol}`, '', 'Krypto')
                                : Dark(`${trade?.quantity} ${symbol}`, '', 'Krypto'),
                            Light('zu einem Preis von'),
                            Dark(assetClass.formatPrice(price, secMeta), '', 'Krypto'),
                            Light('oder besser,'),
                            Dark(`${expandedTif}.`, '', 'Krypto')
                        ];
                    }
                }
            },
            reinvestmentOptions: {
                willBeCashed: (labels: string[]) => [Light(`${SpokenJoin(labels)} wird auf Ihr Konto als`), Dark('Bargeld'), Light('eingezahlt.')],
                willBeReinvested: (labels: string[]) => [Light(`${SpokenJoin(labels)} wird`), Dark('reinvestiert'), Light('.')],
                allWillBeCashed: [Light('Alle Erträge werden auf Ihr Konto als'), Dark('Bargeld'), Light('eingezahlt.')],
                allWillBeReinvested: [Light('Alle Erträge werden'), Dark('reinvestiert'), Light('.')]
            }
        },

        receipt: {
            tradeReceipt: 'Handelsbeleg',
            orderSummary: 'Bestellübersicht',
            orderType: {
                orderType: 'Auftragstyp',
                marketOrder: 'Marktauftrag',
                marketOrderDesc: 'Sofortiger Handel zum besten verfügbaren Preis',
                mocOrder: 'MOC-Auftrag',
                limitOrder: 'Limitauftrag',
                limitOrderMinMax: (sell: boolean) => `Handeln Sie zu einem ${sell ? 'Mindest-' : 'Höchst-'}preis oder besser`,
                limitOrderDesc: 'Handeln Sie zu einem Preis oder besser',
                locOrder: 'LOC-Auftrag',
                stopOrder: 'Stop-Auftrag',
                stopOrderDesc: 'Auslösen eines Marktgeschäfts zu einem festen Stoppkurs',
                stopLimitOrder: 'Stopp-Limit-Auftrag',
                stopLimitOrderDesc: 'Auslösen eines Limitgeschäfts zu einem festen Stoppkurs',
                tradeCantBePlaced: 'Dieser Handelstyp kann für diese Art von Sicherheit nicht platziert werden',
                setStop: 'Stop festlegen',
                setLimit: 'Limit festlegen',
                setStopLimit: 'Stopp-Limit festlegen',
                buyShareMaxPrice: 'Kaufen Sie Aktien zum Höchstpreis oder niedriger.',
                sellSharesMaxPrice: 'Verkaufen Sie Aktien zum Mindestpreis oder höher.',
                triggerMarket: (orderAction: string) => `Auslösen eines Markt-${orderAction} zu einem festen Stoppkurs.`,
                triggerLimit: (orderAction: string) => `Auslösen eines Limit-${orderAction} zu einem festen Stoppkurs.`
            },
            marketPrice: 'Marktpreis',
            limitPrice: 'Limitpreis',
            stopPrice: 'Stoppkurs',
            stopLimitPrice: 'Stopp-Limit-Preis',
            numberOfUnits: (symbol: string) => `Anzahl der ${Snex1German.general.unit(symbol, 0)}`,
            orderSubmitted: 'Auftrag übermittelt',
            checkForStatusUpdatesUnderPositions: 'Überprüfen Sie Statusaktualisierungen unter Positionen',
            submitAnotherOrder: 'Ein weiteren Auftrag übermitteln',
            submittingOrder: 'Auftrag wird übermittelt',
            pleaseWait: 'Bitte warten...',
            youMayClickAway: 'Sie können jetzt wegklicken, wir informieren Sie, wenn alles durch ist.',
            thereWasAProblem: 'Es gab ein Problem',
            pleaseTryAgain: 'Bitte versuchen Sie es erneut',
            estimatedTotalCost: 'Geschätzte Gesamtkosten',
            estimatedTotalGain: 'Geschätzter Gesamtgewinn',
            estimatedTotalCredit: 'Geschätzter Gesamtkredit',
            estimatedTotalDebit: 'Geschätzter Gesamtsoll',
            leg1: 'Bein 1',
            leg2: 'Bein 2'
        },
        afterHours: {
            disclosure: {
                title: 'Hinweis zur Handelssitzung außerhalb der regulären Handelszeiten',
                shortDisclosure: 'Der Handel während der Handelssitzungen außerhalb der regulären Handelszeiten birgt besondere Risiken.',
                longDisclosure: [
                    { shade: 'dark', text: 'Der Handel während der Handelssitzungen außerhalb der regulären Handelszeiten' },
                    {
                        shade: 'light',
                        text: '(einschließlich der Pre-Market-Sitzung (Montag bis Freitag 8:00 bis 9:30 Uhr ET) und der After-Market-Sitzung (Montag bis Freitag 16:00 bis 17:00 Uhr ET), jeweils abhängig von den Handelszeiten an Feiertagen)'
                    },
                    { shade: 'dark', text: ', birgt besondere Risiken,' },
                    {
                        shade: 'light',
                        text: ' wie z.B. eine größere Preisvolatilität, geringere Liquidität, breitere Geld-Brief-Spannen und geringere Marktsichtbarkeit und ist möglicherweise nicht für alle Anleger geeignet.\n\nStoneX One kann nicht garantieren, dass Orders außerhalb der regulären Handelszeiten zum besten Preis für ein bestimmtes Wertpapier ausgeführt werden oder dass solche Orders, einschließlich Marktorders, überhaupt ausgeführt werden. Indem Sie um Zugang zur Auftragserteilung bitten und während des Handels außerhalb der regulären Handelszeiten bei StoneX One eine Order aufgeben, einschließlich auf einer StoneX One-Handelsplattform oder mobilen Plattform, erklären Sie, dass Sie die Risiken des Handels während der Handelssitzungen außerhalb der regulären Handelszeiten verstehen.'
                    }
                ]
            }
        },
        shortSales: {
            acceptance: (quantity, rate) => {
                return [Light('Sie gehen short mit'), Dark(`${quantity} Aktie${quantity === 1 ? '' : 'n'}`), Light('zu einem Satz von'), Dark(`${rate}%`), Light('.')];
            },
            thisWillShortYou: (quantityShort: number, rate: number) => {
                return [
                    Light('Sie werden short gehen mit'),
                    Dark(`${quantityShort} Aktie${quantityShort === 1 ? '' : 'n'}`),
                    Light('zu einem Satz von'),
                    Dark(FormatNumber.toPercent(rate, undefined, 4)),
                    Light('.')
                ];
            },
            disclosureTitle: 'Aktienleihsätze',
            disclosure: (isExpanded) =>
                isExpanded
                    ? 'Die angezeigten Aktienleihsätze sind indikativ und entsprechen möglicherweise nicht den tatsächlichen Sätzen, die für das Ausleihen des Wertpapiers gezahlt werden. Indikative Leihgebühren werden von StoneX Financial Inc. auf der Grundlage mehrerer Faktoren und Quellen erstellt. Der tatsächliche Aktienleihsatz, der für das Ausleihen eines Wertpapiers gezahlt wird, kann erheblich von der indikativen Rate abweichen, abhängig von den Marktkonditionen für das Wertpapier zum Zeitpunkt des Darlehens. Bitte rufen Sie den Securities Lending Desk unter (212) 379-5450 an, wenn Sie Fragen haben.'
                    : 'Die angezeigten Aktienleihsätze sind indikativ und entsprechen möglicherweise nicht den tatsächlichen Sätzen, die für das Ausleihen des Wertpapiers gezahlt werden.',
            viewMore: 'Mehr anzeigen',
            viewLess: 'Weniger anzeigen'
        },
        offshore: {
            disclosure: {
                title: 'Wichtige Informationen zum Handel mit Offshore-Investmentfonds',
                shortDisclosure: 'Wichtige Informationen zum Handel mit Offshore-Investmentfonds.',
                longDisclosure: [
                    {
                        shade: 'dark',
                        text: 'Die Schlusszeiten für Offshore-Investmentfonds variieren, daher könnte dieser Handel potenziell einen NAV am nächsten Tag haben. Bitte beachten Sie den Prospekt des Fonds für Schlusszeiten, Fondskosten und andere wichtige Informationen vor der Investition.'
                    }
                ]
            }
        },
        crypto: {
            disclosure: {
              title: "Krypto-Handels- und Kontoinformationen",
              shortDisclosure: "Der Handel mit Kryptowährungen birgt erhebliche Risiken und kann zum Verlust Ihres investierten Kapitals führen. Bitte handeln Sie verantwortungsbewusst.",
              longDisclosure: [
                {
                  lineBreak: 'double',
                  text: "Alle durch StoneX One Pro erleichterten Kryptowährungshandelsaktivitäten werden über ETANA, einen vertrauenswürdigen Drittanbieterdienst, abgewickelt. Bitte beachten Sie, dass Sie beim Handel mit Kryptowährungen direkt mit ETANA interagieren und alle Trades auf deren Systemen ausgeführt werden."
                },
                {
                  lineBreak: 'double',
                  text: "\n\nDarüber hinaus werden Ihre Kryptowährungskonten bei ETANA eröffnet und verwaltet. Das bedeutet, dass ETANA für die Aufbewahrung, Sicherheit und Verwaltung Ihrer Krypto-Assets verantwortlich ist. StoneX One Pro dient als Verbindung zu den Diensten von ETANA und bietet Ihnen Zugang zu deren Handelsumgebung."
                },
                {
                  text: "\n\nBitte beachten Sie die folgenden wesentlichen Punkte:",
                  lineBreak: 'double',
                },
                {
                  text: "\n1."
                },
                {
                  shade: "dark",
                  text: "Ausführung aller Trades"
                },
                {
                  text: ": Alle auf StoneX One Pro initiierten Kryptowährungstransaktionen werden von ETANA ausgeführt. Wir führen keine Trades auf unseren Systemen aus.",
                  lineBreak: true,
                },
                {
                  text: "\n2."
                },
                {
                  shade: "dark",
                  text: "Kontoverwaltung"
                },
                {
                  text: ": Ihre Kryptowährungskonten, einschließlich aller Einzahlungen und Abhebungen, werden von ETANA verwaltet.",
                  lineBreak: true,
                },
                {
                  text: "\n3."
                },
                {
                  shade: "dark",
                  text: "Sicherheit und Verwahrung"
                },
                {
                  text: ": ETANA ist für die Sicherheit und Verwahrung Ihrer Kryptowährungsbestände verantwortlich. StoneX Financial hält oder sichert keine Kryptowährungsbestände direkt.",
                  lineBreak: true,
                },
                {
                  text: "\n4."
                },
                {
                  shade: "dark",
                  text: "Gebühren und Kosten"
                },
                {
                  text: ": Alle Gebühren oder Kosten im Zusammenhang mit dem Handel mit Kryptowährungen werden von ETANA festgelegt und eingezogen. Bitte konsultieren Sie den Gebührenplan von ETANA für detaillierte Informationen.",
                  lineBreak: true,
                },
                {
                  text: "\n5."
                },
                {
                  shade: "dark",
                  text: "Regulatorische Aufsicht"
                },
                {
                  text: ": ETANA operiert unter ihrem eigenen regulatorischen Rahmen. Es ist wichtig, die Allgemeinen Geschäftsbedingungen, die Datenschutzrichtlinie und alle regulatorischen Offenlegungen von ETANA zu überprüfen.",
                  lineBreak: 'double',
                },
                {
                  text: "\n\nDurch die Nutzung von StoneX One Pro für den Kryptowährungshandel erkennen Sie diese Bedingungen an und stimmen ihnen zu. Es ist wichtig, Ihre eigene Sorgfaltspflicht zu erfüllen und die mit dem Kryptowährungshandel verbundenen Risiken zu verstehen. Bei Problemen oder Fragen zu Ihren Kryptokonten oder -transaktionen wenden Sie sich bitte direkt an ETANA.",
                  lineBreak: 'double',
                },
                {
                  shade: "dark",
                  text: "\n\nHaftungsausschluss"
                },
                {
                  text: ": Der Handel mit Kryptowährungen birgt erhebliche Risiken und kann zum Verlust Ihres investierten Kapitals führen. Bitte handeln Sie verantwortungsbewusst."
                }
              ]
            }
        } 
    },
    orders: {
        accountNumber: 'Kontonummer',
        account: 'Konto',
        acct: 'Konto',
        contractSymbol: 'Vertragsymbol',
        dateAndTime: 'Datum & Uhrzeit',
        filledAmount: 'Ausgefüllte Menge',
        fillPrice: 'Ausführungspreis',
        onClose: 'Bei Schluss',
        order: 'Bestellung',
        openOrders: 'Offene Bestellungen',
        tradeHistory: 'Handelsgeschichte',
        orderHistory: 'Bestellverlauf',
        tradeConfirmation: 'Handelsbestätigung anzeigen',
        expirationDate: 'Verfallsdatum',
        true: 'Wahr',
        spreadSymbol: 'Spread-Symbol',
        buySide: 'Kaufseite',
        sellSide: 'Verkaufsseite',
        completedDate: 'Abschlussdatum',
        fees: 'Gebühren',
        averageTradePrice: 'Durchschnittlicher Preis',
        actionSummary: (props: ActionSummaryProps): string => {
            const action = Snex1German.orders.typedAction(props.order);
            const marketPriceText = props?.format === 'short' ? 'MP' : 'Marktpreis';
            return getActionSummary({ ...props, action, marketPriceText });
        },
        stratValue: (strat: ApiTradeStrategy) => {
            switch (strat) {
                case 'Care':
                    return 'Care';
                case 'SOR':
                    return 'SOR';
                case 'VWAP':
                    return 'VWAP';
                case 'TWAP':
                    return 'TWAP';
                case 'POV2':
                    return 'POV 2%';
                case 'POV5':
                    return 'POV 5%';
                case 'POV10':
                    return 'POV 10%';
                case 'POV15':
                    return 'POV 15%';
                case 'POV20':
                    return 'POV 20%';
                case 'POV25':
                    return 'POV 25%';
            }
        },

        fullActionTypeToOpenOrClose: (o) => {
            switch (o.action?.toLowerCase()) {
                case 'buytoopen':
                    return 'Kaufen, um zu öffnen';
                case 'buytoclose':
                    return 'Kaufen, um zu schließen';
                case 'selltoopen':
                    return 'Verkaufen, um zu öffnen';
                case 'selltoclose':
                    return 'Verkaufen, um zu schließen';
                case 'sellshort':
                    return 'Leerverkauf';
                default:
                    return o.action;
            }
        },

        numberFilledOfTotal: (o) =>
            typeof o.leavesQuantity === 'number'
                ? `${FormatNumber.toDecimal(o.orderQuantity - o.leavesQuantity, 0)} von ${FormatNumber.toDecimal(o.orderQuantity, 0)} ausgeführt`
                : '',

        actionSummary2: (o, opt, hideMoneySymbol, formatValue) => {
            const act = Snex1German.orders.typedAction(o);
            const q = Snex1German.orders.quantityPriceSummary2(o, opt, hideMoneySymbol, formatValue);
            switch (opt?.format) {
                case 'short':
                    return `${act} ${q}`;
                case 'long':
                default:
                    return `${act} ${q}`;
            }
        },
        quantityPriceSummary2: (o, opt, hideMoneySymbol, formatValue) => {
            const ac = GetAssetClassForSecurity(o.symbol);
            const orderType = DetermineOrderType(o);

            const amt: string = (() => {
                const fmt = (n: number): string =>
                    formatValue ? formatValue(n) : FormatNumber.toMoney(n, undefined, hideMoneySymbol ? 'never' : undefined, true, opt?.decimalPlaces || 2);
                const mp = opt?.format === 'short' ? 'MP' : 'Marktpreis';
                switch (orderType) {
                    case 'limit':
                    case 'stoplimit':
                        return fmt(o.limitPrice);
                    case 'stop':
                        return fmt(o.stopPrice);
                    case 'market':
                        return mp;
                }
            })();
            return `${ac.formatOrderQuantity(o)} @ ${amt}`;
        },

        action: (a, secType) => {
            const actions = {
                buytoopen: secType !== 'futures' ? 'Kaufen, um zu öffnen' : 'Kauf',
                buytoclose: secType !== 'futures' ? 'Kaufen, um zu schließen' : 'Kauf',
                selltoopen: secType !== 'futures' ? 'Verkaufen, um zu öffnen' : 'Verkauf',
                selltoclose: secType !== 'futures' ? 'Verkaufen, um zu schließen' : 'Verkauf',
                sellshort: 'Leerverkauf',
                buytocover: 'Kaufen, um zu decken',
                selltocover: 'Verkaufen, um zu decken'
            };

            const actionInput = (a || '')?.toLowerCase();
            if (actionInput === 'fullliquidation') return 'Vollständige Liquidation';
            const action = actions[actionInput] || actionInput;

            return action;
        },
        multiLegAction: (o) => {
            const actionInput = (o?.action || '')?.toLowerCase();
            const leg2Input = (o?.complexOrders?.[0]?.action || '')?.toLowerCase();
            return actionInput === leg2Input
                ? `${Snex1German.orders.action(actionInput)} 2`
                : `${Snex1German.orders.action(actionInput)} 1, ${Snex1German.orders.action(leg2Input)} 1`;
        },
        typedAction: (o, secType) => {
            const orderType = DetermineOrderType(o);

            const orderTypeLabel = (() => {
                switch (orderType) {
                    case 'market':
                        return 'Markt';
                    case 'limit':
                        return 'Limit';
                    case 'stop':
                        return 'Stopp';
                    case 'stoplimit':
                        return 'Stop-Limit';
                }
            })();

            return `${orderTypeLabel} ${Snex1German.orders.action(o.action, secType)}`;
        },
        orderSetup: {
            orderSetup: 'Order Setup',
            expires: 'Läuft ab',
            orderType: 'Auftragsart',
            quantityType: 'Mengenart',
            notional: 'Nennwert',
            algorithm: 'Algo-Strategie',
            stratPercent: 'Strategieprozentsatz',
            sellAll: 'Alles verkaufen',
            done: 'Erledigt',
            discardTitle: 'Verwerfen',
            discardChanges: 'Änderungen verwerfen',
            discardChangesMsg: 'Sie haben ungespeicherte Änderungen. Möchten Sie Ihre Änderungen verwerfen?',
            stayHere: 'Hier bleiben',

            action: 'Aktion',
            actionBottomSheet: {
                buySell: 'Kaufen-Verkaufen',
                sellBuy: 'Verkaufen-Kaufen',
                buyTheEarlierAndSellTheLater: 'Kauf des früheren Vertrags und Verkauf des späteren Vertrags',
                sellTheEalierAndBuyTheLater: 'Verkauf des früheren Vertrags und Kauf des späteren Vertrags',
                buying: 'Kauf',
                selling: 'Verkauf'
            },

            expiresBottomsheet: {
                expires: 'Läuft ab',
                goodThruTitle: 'Gültig bis zum Tag',
                goodThruSubtitle: 'Der Auftrag bleibt bis zum Ende des aktuellen Handelstags gültig.',
                goodTillCancelled: 'Gültig bis zur Stornierung (GTC)',
                goodTillCancelltedSubtitle: 'Der Auftrag bleibt gültig, bis er storniert wird, maximal 180 Tage lang.',
                dayOrdersUnavailable: 'Tagesaufträge sind derzeit nicht verfügbar',
                gtcOrdersUnavailable: 'GTC-Aufträge sind derzeit nicht verfügbar',
                premarketUnavailable: 'Vorhandelnde Aufträge sind derzeit nicht verfügbar',
                postMarketUnavailable: 'Nachhandelnde Aufträge sind derzeit nicht verfügbar',

                preMarketExecution: 'Vorhandelnde Ausführung',
                preMarketActive: 'Der Auftrag ist aktiv und kann zwischen 8 und 17 Uhr EST ausgeführt werden',
                postMarketExcution: 'Nachhandelnde Ausführung',
                postMarketActive: 'Der Auftrag ist während der PM-Sitzung nur heute aktiv und kann zwischen 16 und 17 Uhr EST ausgeführt werden',
                goodTilCancelActive: 'Der Auftrag ist gültig, bis er storniert wird, maximal 180 Tage lang.',
                goodThruDayActive: 'Der Auftrag ist bis zum Ende des aktuellen Handelstags aktiv.',

                fillOrKillExecution: 'Ausfüllen oder Stornieren',
                fillOrKillActive: 'Den gesamten Auftrag sofort ausführen oder stornieren'
            }
        },
        quantity: 'Menge',
        commission: 'Provision',
        stopPrice: 'Stop-Preis',
        limitPrice: 'Limit-Preis',
        totalCost: 'Gesamtkosten',
        orderTotalImpactLabel: (action: 'Kaufen' | 'Verkaufen' | string) => {
            const regex = /verkaufen/i; // This will match any variation of 'Sell'
            const containsSell = regex.test(action);
            return containsSell ? 'Gesamtguthaben' : 'Gesamtkosten';
        },
        swipeUpTrade: 'Nach oben wischen, um zu handeln',
        placed: 'Platziert',
        by: (who: string) => `von ${who}`,
        timeInForce: 'Gültigkeitsdauer',
        gtxPre: 'Vorhandel',
        gtxPost: 'Nachhandel',
        isin: 'ISIN',
        strategy: 'Strategie',
        averagePrice: 'Durchschnittspreis',
        actionLabel: 'Aktion',

        placedBy: 'Platziert von',
        securityName: 'Wertpapiername',
        contactRepToChange: 'Um Ihre Bestellung zu stornieren oder Änderungen daran vorzunehmen, wenden Sie sich bitte an den Support.',
        cannotBeCanceled: 'Auftrag kann nicht storniert oder geändert werden',
        lockedForOpen: 'Auftrag ist für die Eröffnung gesperrt',
        orderNumber: 'Auftragsnummer',
        orderNumbers: 'Auftragsnummern',
        cancelingOrder: 'Bestellung wird storniert',
        orderCanceled: 'Bestellung storniert',
        orderModified: 'Bestellung geändert',
        orderFilled: 'Bestellung ausgeführt',
        orderPartiallyFilled: 'Teilweise ausgeführte Bestellung',
        orderPlaced: 'Bestellung platziert',
        orderRejected: 'Bestellung abgelehnt',
        orderStopped: 'Bestellung gestoppt',
        orderSuspended: 'Bestellung ausgesetzt',
        orderBiddingOpen: 'Gebotsöffnung für Bestellung',
        orderId: 'Auftrags-ID',
        cancelOrder: 'Auftrag stornieren',
        editOrder: 'Auftrag bearbeiten',
        partialFill: (symbol, filled, quantity) => {
            const unit = OptionSymbol.IsOptionSymbol(symbol) ? 'Kontrakte' : 'Aktien';
            return `${filled} von ${quantity} ${unit} ausgeführt`;
        },
        fills: {
            sequenceNumber: 'Ausführungsfolgennummer',
            price: 'Ausführungspreis',
            quantity: 'Menge',
            timestamp: 'Zeitstempel'
        },
        statuses: {
            open: 'Offen',
            working: 'In Bearbeitung',
            pending: 'Ausstehend',
            completed: 'Abgeschlossen',
            filled: 'Ausgeführt',
            cancelled: 'Storniert',
            cancelPending: 'Stornierung ausstehend',
            cancelPartialFill: 'Teilweise storniert',
            allocated: 'Zugeordnet',
            preAllocated: 'Vorab zugeordnet',
            created: 'Erstellt',
            expired: 'Abgelaufen',
            route: 'Route',
            routed: 'Geroutet',
            verification: 'Verifizierung',
            change: 'Änderung',
            notProcessed: 'Nicht verarbeitet',
            processed: 'Verarbeitet',
            confirmed: 'Bestätigt',
            cancel: 'Stornieren',
            canceled: 'Storniert',
            rejected: 'Abgelehnt',
            unknown: 'Unbekannt',
            deleted: 'Gelöscht'
        }
    },

    messages: {
        subjectByPayload: {
            'System:AdminBroadcast': 'Ankündigung',
            'System:AdminDirect': 'Administratornachricht',
            'System:Welcome': 'Willkommen!',
            'CustomAlert:Default': 'Warnung',
            'OrderUpdate:New': 'Neuer Auftrag',
            'OrderUpdate:Filled': 'Auftrag ausgeführt',
            'OrderUpdate:Rejected': 'Auftrag abgelehnt',
            'OrderUpdate:Canceled': 'Auftrag storniert',
            defaultOrderUpdate: 'Auftragsaktualisierung'
        }
    },

    tours: {
        stepsBadgePreposition: 'von',
        lastStepButtonText: 'Fertig',
        portfolioScreen: {
            chart: [[{ text: 'Verfolgen Sie Ihr ' }, { text: 'Portfolio', weight: 'bold' }, { text: ' mit dem Portfolio-Diagramm.' }]],
            watchlist: [
                [
                    {
                        text: 'Positionen aus all Ihren Konten werden hier angezeigt.'
                    }
                ],
                [{ text: 'Sie können auch Ihre ' }, { text: 'Watchlist anpassen', weight: 'bold' }, { text: '.' }]
            ],
            depositFunds: [
                [
                    { text: 'Klicken Sie hier, um ' },
                    { text: 'Gelder einzuzahlen', weight: 'bold' },
                    {
                        text: '. Sobald Sie Gelder eingezahlt haben, können Sie mit dem Handel beginnen!'
                    }
                ]
            ],
            search: [[{ text: 'Suchen Sie nach Aktien, Investmentfonds oder allem anderen!' }]],
            profile: [
                [
                    { text: 'Klicken Sie auf das Profilbild oben, um Ihre ' },
                    {
                        text: 'Kontoeinstellungen, Transaktionen und vieles mehr',
                        weight: 'bold'
                    },
                    { text: ' anzuzeigen!' }
                ]
            ]
        },
        stockScreen: {
            candlestick: [
                [{ text: 'Möchten Sie ein ' }, { text: 'Kerzenständer-Diagramm', weight: 'bold' }, { text: '?' }],
                [
                    {
                        text: 'Klicken Sie auf dieses Symbol, um zwischen einem Liniendiagramm und einem Kerzenständer-Diagramm umzuschalten.'
                    }
                ]
            ],
            advancedChart: [
                [
                    { text: 'Unser ' },
                    { text: 'fortgeschrittenes Diagramm', weight: 'bold' },
                    {
                        text: ' bietet Ihnen Zugang zu einem Arsenal von Diagrammwerkzeugen und der Möglichkeit, '
                    },
                    { text: 'direkt aus dem Diagramm heraus zu handeln', weight: 'bold' },
                    { text: '.' }
                ]
            ],
            addToWatchlist: [
                [{ text: 'Möchten Sie diese Wertpapiere im Auge behalten? ' }, { text: 'Fügen Sie sie Ihrer Watchlist hinzu', weight: 'bold' }, { text: '.' }]
            ],
            orderType: [
                [{ text: 'Ändern Sie den ' }, { text: 'Auftragstyp', weight: 'bold' }, { text: ' durch Klicken auf das Bleistiftsymbol hier.' }],
                [{ text: '(Markt, Limit, Stop usw.)' }]
            ],
            optionsTrading: [
                [{ text: 'Sie können auf die ' }, { text: 'Optionskette', weight: 'bold' }, { text: ' für dieses Wertpapier zugreifen, indem Sie hier klicken.' }]
            ]
        },
        premiumStockScreen: {
            advancedChart: [
                [{ text: 'Multichart auf einem Bildschirm.' }],
                [{ text: 'Klicken Sie hier, um eine ' }, { text: 'technische Analyse', weight: 'bold' }, { text: ' auf einer einzelnen Ansicht durchzuführen!' }]
            ],
            alerts: [
                [{ text: 'Setzen Sie schnelle Warnungen.' }],
                [
                    { text: 'Klicken Sie auf das Glockensymbol, um ' },
                    { text: 'Trigger', weight: 'bold' },
                    { text: ' basierend auf ' },
                    { text: 'Echtzeit-Preisbewegungen', weight: 'bold' },
                    { text: ' einzurichten. Seien Sie der Erste, der Bescheid weiß!' }
                ]
            ],
            deepAnalysis: [
                [{ text: 'Tauchen Sie mit Daten ein.' }],
                [
                    {
                        text: 'Klicken Sie hier für eine umfassende Ansicht, die Unternehmensinformationen mit Echtzeit-Marktdaten kombiniert.'
                    }
                ]
            ],
            streamingNews: [
                [{ text: 'Sie haben Echtzeit-Nachrichten!' }],
                [
                    {
                        text: 'Die neuesten Nachrichten erscheinen oben, sobald sie veröffentlicht werden.'
                    }
                ]
            ]
        },
        tickerBar: {
            tradingViewMarquee: [
                [{ text: 'Auf der Ticker-Leiste erhalten Sie einen horizontalen Überblick über Indizes, Rohstoffe und mehr.  ' }],
                [{ text: 'Klicken Sie auf die Symbole, um mehr zu erfahren. Sie können dies in Ihren Profil-Einstellungen ein-/ausschalten.' }]
            ]
        }
    },

    mobileTours: {
        summaryScreen: {
            watchlist: {
                text: 'Wischen Sie hier nach oben, um Ihre Watchlists anzuzeigen und zu verwalten',
                name: 'Watchlist'
            }
        },
        securityScreen: {
            alerts: {
                text: 'Tippen Sie hier, um Ihre Warnungen für dieses Wertpapier zu verwalten',
                name: 'Warnungen'
            },
            chartToggle: {
                text: 'Wechseln Sie zwischen Diagrammansichten (Linie/Kerzenständer), indem Sie einfach hier tippen',
                name: 'Diagrammschalter'
            },
            advancedChart: {
                text: 'Tippen Sie hier, um das erweiterte Diagramm zu öffnen, mit Funktionen wie Indikatoren direkt zur Hand',
                name: 'Erweitertes Diagramm'
            },
            trade: {
                text: 'Wischen Sie von hier nach oben, um das Handelsticket zu öffnen und mit dem Handel zu beginnen!',
                name: 'Handel'
            }
        },
        navigateToursAlert: {
            title: 'Touren anzeigen',
            message: 'Navigieren Sie durch die App, um Touren anzuzeigen.'
        }
    },

    newFeatures: {
        advancedOptionTrading: {
            title: 'Fortgeschrittener Optionshandel',
            subtitle: 'Beim Handel mit mehreren Optionen werden gleichzeitig mehrere Optionsgeschäfte ausgeführt, um eine spezifische Strategie zu erstellen.',
            item1Title: 'Zwei Trades in Einem',
            item1Subtitle:
                'Der Handel mit mehreren Optionen beinhaltet das gleichzeitige Ausführen mehrerer Optionsgeschäfte. Rollen Sie bestehende gedeckte und lange Positionen in einem Handelsticket aus. Erstellen Sie Spreads und Straddles.',
            item2Title: 'Risiko-Rendite steuern',
            item2Subtitle: 'Durch Kombination von Optionen können Sie spezifische Risiko-Rendite-Profile erstellen.',
            item3Title: 'Testen Sie Ihre Marktvorhersagen',
            item3Subtitle:
                'Verschiedene Optionsstrategien können eingesetzt werden, um Ihr Portfolio basierend auf Ihren Vorhersagen über Preisbewegungen, Volatilitätsänderungen oder Zeitablauf zu steigern oder zu schützen.'
        },
        cryptoTrading: {
            title: 'Kryptowährungen sind jetzt verfügbar!',
            subtitle: 'Beginnen Sie Ihre Reise: Kaufen und Verkaufen Sie mühelos Kryptowährungen und begrüßen Sie die Zukunft der Finanzen!',
            item1Title: 'Ausführung von Trades',
            item1Subtitle: 'Alle auf StoneX One Pro initiierten Kryptowährungstrades werden von ETANA ausgeführt. Wir führen keine Trades auf unseren Systemen aus.',
            item2Title: 'Kontoverwaltung',
            item2Subtitle: 'Ihre Kryptowährungskonten, einschließlich aller Ein- und Auszahlungen, werden von ETANA verwaltet.',
            item3Title: 'Sicherheit und Verwahrung',
            item3Subtitle: 'ETANA ist verantwortlich für die Sicherheit und Verwahrung Ihrer Kryptowährungsvermögen. StoneX Financial hält oder sichert keine Kryptowährungsvermögen direkt.'
        }
    },

    miniplayer: {
        loading: (title: string | null) => `Laden ${title || 'Medien'}...`,
        noTranscriptAvailable: 'Keine Transkription verfügbar',
        noMediaAvailable: 'Keine Medien verfügbar',
        viewTranscript: 'Transkript anzeigen'
    },

    modals: {
        done: 'Fertig',
        appIdle: {
            modalHeader: 'Du bist inaktiv!',
            noticedInactivity: 'Wir haben bemerkt, dass du eine Weile inaktiv warst',
            logoutInSeconds: (seconds: number) => `Wenn keine Aktion durchgeführt wird, wirst du in ${seconds} Sekunden ausgeloggt.`,
            logout: 'Ausloggen',
            stay: 'Bleiben'
        },
        addManyToListContent: {
            title: 'Füge ein Wertpapier über die oben stehende Suche hinzu',
            button: 'Zur Beobachtungsliste hinzufügen',
            addSecurity: 'Wertpapiere hinzufügen'
        },
        addToWatchlist: {
            addSymbolToWatchlist: (symbol: string) => `${symbol} zur Beobachtungsliste hinzufügen`,
            createAList: 'Eine Liste erstellen',
            newListName: 'Neuer Listenname',
            create: 'Erstellen',
            creating: 'Erstelle...'
        },
        viewWatchlistDetails: 'Beobachtungslisten-Details anzeigen',
        deleteWatchlist: {
            deleteThisList: 'Diese Liste löschen',
            areYouSure: (listName: string) => [[{ text: 'Möchtest du ', weight: 'bold' }, { text: listName, weight: 'bold' }, { text: ' wirklich dauerhaft löschen?' }]],
            areYouSureShaded: (listName: string) => [
                {
                    text: 'Möchtest du ',
                    shade: 'light'
                },
                { text: listName, shade: 'dark' },
                { text: ' wirklich dauerhaft löschen?', shade: 'light' }
            ],
            yesDeleteList: (listName: string) => `Ja, ${listName} löschen`,
            delete: 'Löschen',
            deleting: 'Lösche...'
        },
        editWatchlistDetails: {
            editList: (listName: string) => `${listName} bearbeiten`,
            name: 'Listenname...',
            description: 'Listenbeschreibung...',
            save: 'Speichern',
            saving: 'Speichere...'
        },
        deleteAllMessages: {
            label: 'Alle Nachrichten löschen',
            body: 'Möchtest du wirklich alle Nachrichten löschen?',
            no: 'Nein',
            yes: 'Ja'
        },
        cancelOrder: {
            orderCancelled: 'Bestellung abgebrochen!',
            cancelOrder: 'Bestellung abbrechen',
            cancelOrderFailed: 'Bestellabbruch fehlgeschlagen!',
            retry: 'Bitte versuche es erneut oder kontaktiere den Support, wenn das Problem weiterhin besteht.',
            areYouSure: 'Möchtest du deine Bestellung wirklich abbrechen?',
            cancelLabel: 'Nicht abbrechen',
            ctrlKeyTip: [
                [
                    { text: 'Tipp:', weight: 'bold' },
                    { text: ' Halte die ' },
                    { text: ' Strg ', weight: 'bold' },
                    { text: ' oder ' },
                    { text: ' Befehl ⌘ ', weight: 'bold' },
                    {
                        text: ' Taste gedrückt, während du eine Bestellung abbrichst, um diese Bestätigung zu umgehen '
                    }
                ]
            ],
            cancel: 'Bestellabbruch bestätigen',
            retryCancel: 'Bestellabbruch erneut versuchen',
            canceling: 'Breche ab...'
        },
        rename: {
            rename: 'Umbenennen',
            save: 'Speichern',
            saving: 'Speichere...'
        },

        errorModal: {
            unknownError: 'Ein unbekannter Fehler ist aufgetreten'
        },
        existingAccountModal: {
            cannotRemoveAcctTitle: 'Konto kann nicht entfernt werden',
            cannotRemoveAcctDesc:
                'Entschuldigung, Konten können nur alle sechs Monate entfernt werden. Bitte kontaktiere den Support, wenn du es früher entfernen möchtest.',
            cannotRemoveAcctConfirm: 'Bestätigen',
            removeLinkedAcctTitle: 'Verknüpftes Konto entfernen',
            removeLinkedAcctConfirm: (name: string) => `Möchtest du ${name} wirklich als verknüpftes Konto entfernen`,
            yesRemoveAcct: 'Ja, Konto entfernen',
            noKeppAcct: 'Nein, Konto behalten',
            loadingYourAcct: 'Deine Konten werden geladen...',
            voidingAcct: 'Konto wird aufgehoben...',
            addAnAcct: 'Ein Konto hinzufügen'
        },
        linkedAccountsModal: {
            retrivingLinkableAcct: 'Deine verknüpfbaren Konten werden abgerufen...',
            linkingAcctWait: 'Dein Konto wird verknüpft, bitte warten...'
        },
        plaidModal: {
            plaidCompleted: 'Plaid abgeschlossen, ohne ein Konto zurückzugeben',
            overlimitAccounts: 'Entschuldigung, du kannst im Moment kein Konto hinzufügen.',
            holdTight: 'Halte durch, dein Konto wird hinzugefügt',
            verifyingBankAuth: 'Überprüfe deine Bankberechtigung...'
        }
    },

    toasts: {
        error: 'Fehler',
        sessionExpired: 'Sitzung abgelaufen',
        logInAgain: 'Erneut anmelden',
        orderSubmitted: 'Bestellung eingereicht!',
        onboardingSuccess: () => {
            return 'Herzlichen Glückwunsch! Ihr Konto wird derzeit bearbeitet und wird in Kürze angezeigt.';
        },
        onboardingSuccessTitle: (accountNumber) => {
            return `Ihr Futures-Konto (${accountNumber}) wird erstellt.`;
        },
        orderSubmittedContent: (trade: Partial<Order>) =>
            `Ihre Bestellung zum ${trade?.action?.toUpperCase()} von ${trade?.orderQuantity} ${Snex1German.general.unit(trade?.symbol, trade?.orderQuantity)} von ${
                trade?.symbol
            } wurde eingereicht (${trade?.orderNumber})`,
        orderUpdateTitle: (update) => {
            const name = update.symbol ? Snex1German.general.symbolName(update.symbol) : update.symbol || update.securityName || update.securityNumber;
            return name ? `Bestellaktualisierung: ${name}` : 'Bestellaktualisierung';
        },
        orderUpdate: (update, message) => {
            const action = (() => {
                switch (update.action) {
                    case 'Buy':
                        return 'kaufen';
                    case 'Sell':
                        return 'verkaufen';
                    case 'BuyToOpen':
                        return 'kaufen um zu eröffnen';
                    case 'SellToOpen':
                        return 'verkaufen um zu eröffnen';
                    case 'BuyToClose':
                        return 'kaufen um zu schließen';
                    case 'SellToClose':
                        return 'verkaufen um zu schließen';
                    default:
                        return 'handeln';
                }
            })();

            const statusPhrase = (() => {
                switch (update.orderStatus) {
                    case 'Allocated':
                        return 'wurde zugewiesen';
                    case 'Verification':
                        return 'wird überprüft';
                    case 'Created':
                        return 'wurde erstellt';
                    case 'Open':
                        return FuturesSymbol.IsFuturesSymbol(update.symbol) ? 'ist jetzt aktiv' : 'ist jetzt offen';
                    case 'Route':
                        return 'ist bereit zum routen';
                    case 'Routed':
                        return 'wurde geroutet';
                    case 'Cancel':
                        return 'ist bereit zur Stornierung';
                    case 'Canceled':
                        return 'wurde storniert';
                    case 'Filled':
                        return 'wurde ausgeführt';
                    case 'Rejected':
                        return 'wurde abgelehnt';
                    case 'Deleted':
                        return 'wurde gelöscht';
                    case 'Expired':
                        return 'ist abgelaufen';
                    case 'Pending':
                        return 'ist ausstehend';
                    case 'Completed':
                        return 'ist abgeschlossen';
                    case 'Cancel Pending':
                        return 'Stornierung ist ausstehend';
                    case 'Pre-Allocated':
                        return 'wurde vorab zugewiesen';
                    case 'Cancel Partial Fill':
                    case 'Unknown':
                    default:
                        return `wurde auf den Status "${update.orderStatus}" aktualisiert`;
                }
            })();

            const sym = update.symbol ? Snex1German.general.symbolName(update.symbol) : update.securityName || update.securityNumber;

            if (message.payloadType === 'OrderUpdate:Rebook') return `Ihre bearbeitete ${sym} Bestellung ist jetzt wirksam`;

            if (update.orderQuantity === null) {
                return `Ihre Bestellung ${update.action ? `zum ${action}` : 'für'} ${sym} ${message?.data['filledAt']}${statusPhrase} (${update.orderNumber})`;
            }
            return `Ihre Bestellung ${update.action ? `zum ${action}` : 'für'} ${update.orderQuantity} ${Snex1German.general.unit(
                update.symbol,
                update.orderQuantity
            )} von ${sym} ${statusPhrase} (${update.orderNumber})`;
        }
    },

    infoIconHelpText: {
        client: {
            aboutSecurity: [
                [
                    {
                        text: 'Bestimmte grundlegende Markt- oder andere Informationen werden direkt oder indirekt von Drittanbietern bereitgestellt und können von anderen Quellen abweichen.'
                    }
                ]
            ],
            aboutSrri: [
                [
                    {
                        text: 'Der synthetische Risiko- und Ertragsindikator (SRRI) ist ein wichtiger Bestandteil des Dokuments mit den wesentlichen Anlegerinformationen (KIID). Der SRRI wird verwendet, um das Risikoniveau eines OGAW-Fonds anzugeben, indem eine Zahl von 1 bis 7 formuliert wird, wobei 1 ein geringeres Risiko und 7 ein höheres Risiko anzeigt.'
                    }
                ]
            ],
            acatTransfer: [
                [
                    {
                        text: 'Die Ablehnung eines ACATS-Übertragungsantrags wird in der Regel durch Maßnahmen des liefernden Brokers eingeleitet, sobald dieser die Gelegenheit hatte, den Antrag zu überprüfen und die Details des zu übertragenden Kontos zu bestätigen. Bei bestimmten Ablehnungsbenachrichtigungen (d. h. Kategorien 1-5 und 10 unten) gewährt der ACATS-Prozess dem empfangenden Broker (IB) ein 24-Stunden-Fenster, innerhalb dessen überarbeitete Informationen übermittelt werden können, nach dessen Ablauf der Übertragungsantrag vom Kunden erneut eingereicht werden muss. Während dieses 24-Stunden-Fensters wird IB versuchen, den übertragenden Kunden zu kontaktieren, um etwaige Unstimmigkeiten, die die anfängliche Ablehnungsbenachrichtigung verursacht haben, zu beheben. Ablehnungen fallen im Allgemeinen in die folgenden Kategorien:'
                    }
                ],
                [{ text: 'Ablehnungen durch den liefernden Broker:' }],
                [
                    {
                        text: '1. Abweichende Sozialversicherungsnummer/Steuer-ID - die vom empfangenden Broker angegebene Steueridentifikationsnummer des Kunden stimmt nicht mit den Aufzeichnungen des liefernden Brokers überein.'
                    }
                ],
                [
                    {
                        text: '2. Abweichender Kontotitel - der vom empfangenden Broker angegebene Kontotitel des Kunden stimmt nicht mit den Aufzeichnungen des liefernden Brokers überein.'
                    }
                ],
                [
                    {
                        text: '3. Dokumentation erforderlich - der liefernde Broker benötigt zusätzliche Kontodokumentationen, um einen Übertragungsantrag zu bearbeiten (z. B. Sterbe- oder Heiratsurkunden).'
                    }
                ],
                [
                    {
                        text: '4. Konto ohne Vermögenswerte - das Konto enthält keine übertragbaren Vermögenswerte.'
                    }
                ],
                [
                    {
                        text: '5. Ungültige Kontonummer - die vom empfangenden Broker angegebene Kontonummer des Kunden ist nicht in den Büchern des liefernden Mitglieds.'
                    }
                ],
                [
                    {
                        text: '6. Duplikat - der liefernde Broker hat bereits einen Übertragungsantrag erhalten, der denselben empfangenden Broker und dasselbe Kundenkonto betrifft.'
                    }
                ],
                [
                    {
                        text: '7. Konto in Verteilung oder Übertragung - ein anderer ACATS-Übertrag oder eine andere Verteilung von Kontovermögenswerten direkt an den Kunden ist bereits im Gange.'
                    }
                ],
                [
                    {
                        text: '8. Kunde hat widerrufen - der Kunde hat einen schriftlichen Antrag auf Stornierung des Übertragungsantrags eingereicht.'
                    }
                ],
                [
                    {
                        text: '9. Fehlende Autorisierungsunterschrift - der Übertragungsantrag erfordert eine zusätzliche Unterschrift des Kunden und/oder des Treuhänders.'
                    }
                ],
                [{ text: 'Ablehnungen durch den empfangenden Broker:' }],
                [
                    {
                        text: '10. Kreditverletzung - das Ergebnis der Übertragung würde dazu führen, dass das Konto in einen Margin-Defizit gerät und einer Zwangsliquidation unterliegt.'
                    }
                ]
            ],
            buyingPower: [
                [
                    {
                        text: 'Ihre Kaufkraft ist der Betrag des gesamten verfügbaren Bargelds auf dem Konto, der zum Kauf von Wertpapieren verfügbar ist, zuzüglich aller verfügbaren Margen.'
                    }
                ],
                DelayedParagraph
            ],
            topMoversAndSectors: [
                [
                    {
                        text: 'Die SEKTOR- und BEWEGUNGS-Listen werden unter Verwendung von Daten erstellt, die von Drittanbietern stammen. Eine Liste von Unternehmen in einem bestimmten Geschäftsbereich kann ein Unternehmen anzeigen, wenn die Quelldaten anzeigen, dass das Unternehmen einen Teil seiner Einnahmen aus dem relevanten Bereich erzielt. Die Liste dient nur zu Informationszwecken und ist keine Anlageberatung oder Empfehlung zum Kauf, Halten oder Verkauf eines Wertpapiers. Listen sind keine personalisierten Empfehlungen für Sie oder einen anderen Kunden, und die aufgeführten Wertpapiere sind möglicherweise nicht für Sie geeignet. Sie sollten kein Wertpapier kaufen oder verkaufen, ohne vorher zu bestimmen, ob es für Ihr Portfolio oder Ihre Anlagestrategie geeignet ist. StoneX One übernimmt keine Garantie oder Gewährleistung für die Genauigkeit, Aktualität oder Vollständigkeit von Informationen Dritter, und die Bereitstellung dieser Informationen stellt keine Empfehlung dar.'
                    }
                ],
                DelayedParagraph
            ],
            news: [
                [
                    {
                        text: 'Nachrichten, Kommentare und Ereignisse stammen von Drittquellen, die nicht mit StoneX verbunden sind. StoneX billigt oder übernimmt deren Inhalt nicht. StoneX garantiert nicht, dass die bereitgestellten Informationen genau, vollständig oder rechtzeitig sind und übernimmt keine Gewähr für die Ergebnisse, die durch ihre Nutzung erzielt werden.'
                    }
                ]
            ],
            portfolioValue: [
                [
                    {
                        text: 'Der Wert Ihres Portfolios ist der gesamte Marktwert aller Positionen im Konto, einschließlich Barbeständen, abzüglich ausstehender Sollsalden und der Beträge, die zur Deckung von In-the-Money-Optionen erforderlich sind.'
                    }
                ],
                DelayedParagraph
            ]
        },

        one: {
            aboutSecurity: [
                [
                    {
                        text: 'Bestimmte fundamentale, Marktdaten oder andere Informationen werden direkt oder indirekt von Drittanbietern bereitgestellt und können von anderen Quellen abweichen.'
                    }
                ]
            ],
            aboutSrri: [
                [
                    {
                        text: 'Der synthetische Risiko- und Ertragsindikator (SRRI) ist ein wichtiger Bestandteil des Dokuments mit den wesentlichen Anlegerinformationen (KIID). Der SRRI wird verwendet, um das Risikoniveau eines OGAW-Fonds anzugeben, indem eine Zahl von 1 bis 7 formuliert wird, wobei 1 ein geringeres Risiko und 7 ein höheres Risiko anzeigt.'
                    }
                ]
            ],
            acatTransfer: [
                [
                    {
                        text: 'Die Ablehnung eines ACATS-Übertragungsantrags wird in der Regel vom liefernden Broker veranlasst, sobald dieser die Möglichkeit hatte, den Antrag zu überprüfen und die Details des zu übertragenden Kontos zu bestätigen. Bei bestimmten Ablehnungsmitteilungen (d.h. Kategorien 1-5 und 10 unten) bietet der ACATS-Prozess dem empfangenden Broker (IB) ein 24-Stunden-Fenster, in dem überarbeitete Informationen übermittelt werden können. Nach Ablauf dieser Frist muss der Übertragungsantrag vom Kunden erneut eingereicht werden. Während dieses 24-Stunden-Fensters wird IB versuchen, den übertragenden Kunden zu kontaktieren, um eventuelle Unstimmigkeiten, die die ursprüngliche Ablehnungsmitteilung verursacht haben, zu klären. Ablehnungen fallen im Allgemeinen in die folgenden Kategorien:'
                    }
                ],
                [{ text: 'Ablehnungen durch den liefernden Broker:' }],
                [
                    {
                        text: '1. Sozialversicherungsnummer/Steuer-ID stimmt nicht überein - die vom empfangenden Broker angegebene Steueridentifikationsnummer des Kunden stimmt nicht mit den Aufzeichnungen des liefernden Brokers überein.'
                    }
                ],
                [
                    {
                        text: '2. Kontobezeichnung stimmt nicht überein - die vom empfangenden Broker angegebene Kontobezeichnung des Kunden stimmt nicht mit den Aufzeichnungen des liefernden Brokers überein.'
                    }
                ],
                [
                    {
                        text: '3. Dokumentation erforderlich - der liefernde Broker benötigt zusätzliche Kontodokumente, um einen Übertragungsantrag zu bearbeiten (z.B. Sterbe- oder Heiratsurkunden).'
                    }
                ],
                [
                    {
                        text: '4. Konto leer - das Konto enthält keine übertragbaren Vermögenswerte.'
                    }
                ],
                [
                    {
                        text: '5. Ungültige Kontonummer - die vom empfangenden Broker angegebene Kontonummer des Kunden ist nicht in den Büchern des liefernden Mitglieds.'
                    }
                ],
                [
                    {
                        text: '6. Duplikat - der liefernde Broker hat bereits einen Übertragungsantrag erhalten, der den gleichen empfangenden Broker und das Kundenkonto betrifft.'
                    }
                ],
                [
                    {
                        text: '7. Konto in Verteilung oder Übertragung - ein weiterer ACATS-Übertrag oder eine andere Verteilung von Kontoassets direkt an den Kunden ist bereits im Gange.'
                    }
                ],
                [
                    {
                        text: '8. Kunde hat widerrufen - der Kunde hat schriftlich den Widerruf des Übertragungsantrags eingereicht.'
                    }
                ],
                [
                    {
                        text: '9. Fehlende Autorisierungsunterschrift - der Übertragungsantrag erfordert eine zusätzliche Unterschrift des Kunden und/oder Verwalters.'
                    }
                ],
                [{ text: 'Ablehnungen durch den empfangenden Broker:' }],
                [
                    {
                        text: '10. Kreditverstoß - das Ergebnis der Übertragung würde dazu führen, dass das Konto eine Margin-Defizit aufweist und zwangsweise liquidiert wird.'
                    }
                ]
            ],
            buyingPower: [
                [
                    {
                        text: 'Ihre Kaufkraft ist der Betrag des gesamten Bargeldes auf dem Konto, das zum Kauf von Wertpapieren zur Verfügung steht, zuzüglich aller verfügbaren Margen.'
                    }
                ]
            ],
            topMoversAndSectors: [
                [
                    {
                        text: 'Die SECTORS- und MOVERS-Listen werden unter Verwendung von Daten erstellt, die von Drittanbietern stammen. Eine Liste von Unternehmen in einem bestimmten Wirtschaftssektor kann ein Unternehmen anzeigen, wenn die Quelldaten anzeigen, dass das Unternehmen einen Teil seiner Einnahmen aus dem relevanten Sektor erzielt. Die Liste wird nur zu Informationszwecken bereitgestellt und stellt keine Anlageberatung oder Empfehlung zum Kauf, Halten oder Verkauf eines Wertpapiers dar. Listen sind keine personalisierten Empfehlungen für Sie oder andere Kunden, und die aufgeführten Wertpapiere sind möglicherweise nicht für Sie geeignet. Sie sollten kein Wertpapier kaufen oder verkaufen, ohne vorher festzustellen, ob es für Ihr Portfolio oder Ihre Anlagestrategie geeignet ist. StoneX One übernimmt keine Gewährleistung oder Garantie in Bezug auf die Genauigkeit, Aktualität oder Vollständigkeit von Informationen Dritter, und die Bereitstellung dieser Informationen stellt keine Empfehlung dar.'
                    }
                ]
            ],
            news: [
                [
                    {
                        text: 'Nachrichten, Kommentare und Ereignisse stammen aus Drittquellen, die nicht mit StoneX verbunden sind. StoneX billigt oder übernimmt deren Inhalte nicht. StoneX übernimmt keine Garantie dafür, dass die bereitgestellten Informationen genau, vollständig oder aktuell sind, und übernimmt keine Gewährleistungen für die Ergebnisse, die aus deren Nutzung erzielt werden.'
                    }
                ]
            ],
            portfolioValue: [
                [
                    {
                        text: 'Der Wert Ihres Portfolios ist der Gesamtmarktwert aller Positionen im Konto, einschließlich der Barbestände, abzüglich aller ausstehenden Debetsalden und aller Beträge, die zur Deckung von in-the-money Short-Option-Positionen erforderlich sind.'
                    }
                ]
            ],
            marketCap: [
                [
                    {
                        text: 'Die Marktkapitalisierung ist der geschätzte Wert der ausstehenden Aktien eines Unternehmens. Abhängig von der Größe dieses Wertes werden Unternehmen in die Kategorien Groß, Mittel, Klein, Mikro und Nano eingeteilt.'
                    }
                ]
            ]
        }
    },

    errors: {
        imageUploadError: 'Bild konnte nicht hochgeladen werden. Bitte stellen Sie sicher, dass die Datei ein Bild ist und weniger als 10MB groß ist.',
        accessRestricted: 'Zugang Beschränkt',
        loginDead:
            'Hallo! Wir sehen, dass Sie versuchen, sich bei StoneX One anzumelden!\nBitte wenden Sie sich an die unten stehende E-Mail-Adresse, um Ihr Konto zu aktivieren.',
        loginDeadWithDetail: (getIntoWhat: string, email: string): string =>
            `Hallo! Wie wir sehen, versuchen Sie, sich bei ${getIntoWhat} anzumelden!\nBitte kontaktieren Sie den Support über die unten stehenden Optionen, um Ihren Benutzer ${email} zu aktivieren.`,
        noDataFound: 'Keine Daten gefunden',
        trade: {
            generalTitle: 'Entschuldigung, es gab ein Problem',
            generalSubmit: 'Wir können Ihre Bestellung nicht aufgeben. Wenn Sie glauben, dass dies ein Fehler ist, wenden Sie sich bitte an den Support.',
            generalVerify: 'Wir können Ihre Bestellung nicht validieren. Wenn Sie glauben, dass dies ein Fehler ist, wenden Sie sich bitte an den Support.',
            restrictedError: 'Entschuldigung, der Handel mit diesem Wertpapier wurde eingeschränkt.',
            cantVerify: 'Entschuldigung, wir konnten Ihre Bestellung nicht verifizieren.',
            dimeProduct:
                'Entschuldigung, dieses Produkt wird nur in Zehn-Cent-Schritten notiert. Bitte passen Sie Ihren Preis auf ein Vielfaches von zehn Cent an und senden Sie ihn erneut.',
            nickelProduct:
                'Entschuldigung, dieses Produkt wird nur in Fünf-Cent-Schritten notiert. Bitte passen Sie Ihren Preis auf ein Vielfaches von fünf Cent an und senden Sie ihn erneut.',
            pennyProduct:
                'Entschuldigung, dieses Produkt wird nur in Ein-Cent-Schritten notiert. Bitte passen Sie Ihren Preis auf ein Vielfaches von einem Cent an und senden Sie ihn erneut.',
            securityOutOfSession: 'Entschuldigung, dieses Produkt kann zu dieser Tageszeit nicht gehandelt werden',
            accountRestricted: 'Dieses Konto wurde eingeschränkt. Bitte kontaktieren Sie uns, um es wieder zu aktivieren.',
            securityUnavailable: 'Entschuldigung, der Handel mit diesem Produkt ist auf StoneX One nicht verfügbar.',
            fundTradingDenied: 'Entschuldigung, Sie dürfen keine Investmentfonds handeln.',
            basicTradingDenied: 'Entschuldigung, Sie dürfen nicht handeln.',
            noBuy: 'Entschuldigung, der Kauf ist für diesen Handel nicht erlaubt',
            noOptionPosition: 'Die Menge für einen Verkaufsauftrag darf die derzeit gehaltene Menge nicht überschreiten',
            optionAgreement: 'Entschuldigung, Ihr Konto darf keine Optionen handeln.',
            optionLevel: 'Entschuldigung, Ihr Konto darf Optionen auf diese Weise nicht handeln.',
            optionPermission: 'Entschuldigung, Sie dürfen keine Optionen handeln.',
            offHours: 'Entschuldigung, der Handel findet derzeit außerhalb der Handelszeiten statt. Bitte versuchen Sie es später erneut.',
            generalNotAvailable: 'Nicht zum Handel verfügbar',
            notAvailable: (product: string) => `Entschuldigung, ${product} ist auf StoneX One nicht zum Handel verfügbar`,
            sellStopLessThanMarket: 'Ein Verkaufsstoppauftrag erfordert einen Stop-Preis, der unter dem aktuellen Marktpreis liegt.',
            buyStopGreaterThanMarket: 'Ein Kaufsstoppauftrag erfordert einen Stop-Preis, der über dem aktuellen Marktpreis liegt.',
            sxDenied: 'Sie dürfen nicht handeln. Wenn Sie glauben, dass dies ein Fehler ist, wenden Sie sich bitte an den Support.',
            shortNotAllowed: 'Leerverkäufe sind für dieses Wertpapier nicht erlaubt.',
            thisIsAShortOrder: 'Sie verkaufen Aktien, die Sie derzeit nicht besitzen. Dies ist ein Leerverkauf.',
            adjustedCloseOnly: 'Entschuldigung, angepasste Optionen dürfen nur geschlossen werden',
            adjustedCloseOnlyMessage: 'Aufgrund von Liquiditäts- und Abwicklungsrisiken erlauben wir nur Schließungsaufträge für angepasste Optionspositionen.',
            insufficientShortQuantity: (qty: number): string => {
                const sharePlurality = qty === 1 ? `Es gibt 1 Aktie` : `Es gibt ${qty} Aktien`;
                const sharesAvailable = qty === 0 ? `Es gibt keine Aktien` : sharePlurality;
                const reduceQuantityText = qty > 1 ? ' Bitte verringern Sie die gewünschte Menge.' : '';
                return `Es gibt eine unzureichende Menge, um diese Anfrage zu autorisieren. ${sharesAvailable} verfügbar.${reduceQuantityText}`;
            },
            mutualFundQrpOnly:
                'Der Kauf dieses US-Investmentfonds ist nur für qualifizierte Altersvorsorgepläne verfügbar. Wenn Sie glauben, dass dies ein Fehler ist, wenden Sie sich bitte an den Support.',
            mutualFundNoDomesticOnForeign:
                'Bestellungen für US-Investmentfonds sind in ausländischen Konten nicht erlaubt. Wenn Sie glauben, dass dies ein Fehler ist, wenden Sie sich bitte an den Support.',
            mutualFundNoForeignOnDomestic:
                'Bestellungen für Offshore-Investmentfonds sind in inländischen Konten nicht erlaubt. Wenn Sie glauben, dass dies ein Fehler ist, wenden Sie sich bitte an den Support.'
        },
        alerts: {
            duplicateRuleMessage: 'Sie haben bereits eine Alarmregel mit diesem Satz von Bedingungen definiert.',
            duplicateRuleTitle: 'Doppelte Alarmregel',
            somethingWentWrongMessage: 'Entschuldigung, etwas ist schief gelaufen. Bitte versuchen Sie es später erneut.',
            unableToVerifyCode: 'Ihr Code konnte nicht verifiziert werden, bitte versuchen Sie es erneut.',
            temporarilyUnavailable: 'Vorübergehend nicht verfügbar',
            alertsTemporarilyUnavailableMessage:
                'Wir aktualisieren unseren Benachrichtigungsdienst. Leider werden die Benachrichtigungen für ein paar Wochen nicht verfügbar sein. Wenn die Benachrichtigungen wieder verfügbar sind, müssen Sie Ihre Benachrichtigungen erneut erstellen.'
        }
    },
    warnings: {
        trade: {
            cannotAfford: (t: Partial<TradeRequest> | ApiTradeRequest, ac?: AssetClass) => {
                const isDollar = t?.quantityQualifier === 'EvenDollar';
                const q = isDollar ? FormatNumber.toMoneyOpt2(t.quantity) : FormatNumber.toCommas(t.quantity);

                switch (t.action) {
                    case 'Buy': {
                        const unit = Snex1German.general.unit(t.securityId, t.quantity)?.toLowerCase();
                        const commonSentence = `Sie haben nicht genug Mittel, um ${q}`;
                        const unitSentence = isDollar ? `von ${QualifiedId.RemovePrefix(t.securityId)} zu kaufen.` : `${unit} von ${QualifiedId.RemovePrefix(t.securityId)} zu kaufen.`;
                        return `${commonSentence} ${unitSentence}`;
                    }
                    case 'Sell': {
                        const units = Snex1German.general.unit(t.securityId, 2)?.toLowerCase();
                        const unit = Snex1German.general.unit(t.securityId, t.quantity)?.toLowerCase();
                        const showUnit = isDollar && ac?.family === 'cryptos' ? `${q}` : `${q} ${unit}`;

                        return `Sie haben nicht genug ${units}, um ${showUnit} von ${QualifiedId.RemovePrefix(t.securityId)} zu verkaufen.`;
                    }
                    default:
                        return 'Entschuldigung, Sie können sich diesen Handel nicht leisten.';
                }
            },
            shortsNotPermittedWhenLong: (t: Partial<TradeRequest> | ApiTradeRequest) => {
                return 'Wir stellen fest, dass Sie derzeit eine Long-Position halten. Bitte schließen Sie Ihre Long-Position, wenn Sie leerverkaufen möchten.';
            },
            shortsNotPermittedWhenAccountNotMargin: (t: Partial<TradeRequest> | ApiTradeRequest) => {
                const cannotAfford = Snex1German.warnings.trade.cannotAfford(t);
                return `${cannotAfford}\n\nLeerverkäufe sind in einem Cash-Konto nicht erlaubt. Bitte überprüfen Sie Ihre Positionen und ändern Sie Ihre Bestellung.`;
            },
            buyExceedsShortsHeld: (t: Partial<TradeRequest> | ApiTradeRequest) => {
                return 'Wir stellen fest, dass Sie derzeit eine Short-Position halten. Bitte schließen Sie Ihre Short-Position, wenn Sie long handeln möchten.';
            },
            shortNotPermittedAccountValue: (t: Partial<TradeRequest> | ApiTradeRequest) => {
                return 'Sie dürfen keine Aktien in einem Marginkonto mit weniger als $2,000 Eigenkapital leerverkaufen. Bitte ändern Sie Ihre Bestellung oder zahlen Sie Geld ein.';
            },
            invalidQuantity: 'Wir unterstützen den Handel mit diesem Wertpapier in der angegebenen Menge nicht. Bitte ändern Sie Ihre Menge.',
            invalidQuantityOptions: 'Wir unterstützen die eingegebene Optionsmenge nicht. Bitte ändern Sie Ihre Bestellung.',
            insufficientQuantity:
                'Es steht eine unzureichende Menge zur Verfügung, um diese Anfrage zu autorisieren. Für weitere Informationen wenden Sie sich bitte an den Support.',
            minimumNotMet: 'Entschuldigung, Ihre Bestellung erfüllt nicht die Mindestinvestition dieses Investmentfonds.',
            possibleDuplicate: 'Eine ähnliche offene Bestellung wie diese existiert bereits.',
            orderGreaterThanPosition: 'Die Bestellung ist größer als die Gesamtposition. Bitte überprüfen Sie die Bestellung, bevor Sie sie weiterleiten.',
            accountChangedToMargin: 'Kontotyp in Margin geändert.',
            accountChangedToCash: 'Kontotyp in Cash geändert.',
            foreignAcctDomesticFunds: 'Das ausgewählte Konto ist ein Auslandskonto und darf keine inländischen Investmentfonds handeln.',
            optionLevel: 'Ihr aktuelles Optionslevel erlaubt es Ihnen nicht, diesen Handel auszuführen.',
            nextDayOrder: 'Diese Bestellung wird morgen aufgegeben.',
            continue: 'Sind Sie sicher, dass Sie diese Bestellung aufgeben möchten?',
            optionsLowVolumeWarning: 'Es gibt wenig Aktivität/Interesse an dieser Bestellung. Ihre Bestellung könnte nicht ausgeführt werden.',
            isShort:
                'Sie verkaufen Aktien, die Sie derzeit nicht besitzen. Dies ist ein Leerverkauf. Bitte bestätigen Sie, dass dies der erwartete Handel ist, den Sie durchführen möchten.',
            cutoffTime:
                'Diese Bestellung ist nach der Annahmeschlusszeit für den Fonds. Dies ist eine Bestellung für den nächsten Tag und wird den Nettoinventarwert des nächsten Tages erhalten.',
            acknowledgeTitle: "Anerkennen und Bestätigen",
            cryptoWarning: "Indem Sie den Handel mit Kryptowährungen auf unserer Plattform fortsetzen, bestätigen Sie, dass Sie diese Offenlegungserklärung gelesen, verstanden und akzeptiert haben und den von ETANA festgelegten Bedingungen für den Handel mit Kryptowährungen und das Kontomanagement zustimmen.\n\nWenn Sie Fragen haben oder weitere Erläuterungen zu dieser Offenlegungserklärung benötigen, wenden Sie sich bitte an unser Support-Team."
        }
    },

    footer: {
        global: {
            chatWithSupport: 'Mit dem Support chatten',
            copyrightLine: [
                [
                    {
                        text: 'Überprüfen Sie den Hintergrund von StoneX Financial Inc oder eines ihrer Anlageexperten auf '
                    },
                    {
                        text: "FINRA's BrokerCheck",
                        url: 'https://brokercheck.finra.org/'
                    },
                    { text: '.  © ' },
                    { text: new Date().getFullYear().toString() },
                    { text: ' StoneX Group Inc. Alle Rechte vorbehalten. ' }
                ]
            ],
            disclosures,
            disclosuresByEnvironement: (env: Environment) => (env === 'pro' ? proDisclosures : disclosures),
            importantDisclosures: 'Wichtige Offenlegungsinformationen',
            marketDataDisclosure: 'Marktdaten bereitgestellt von Xignite',
            openAnAccount: 'Konto eröffnen',
            privacy: 'Datenschutz',
            systemRequirements: 'Systemanforderungen',
            termsOfUse: 'Nutzungsbedingungen'
        },
        disclosurePdfUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/en-US/DisclosuresFull.pdf'
    },

    mobileUi: {
        bottomBar: {
            summary: 'Zusammenfassung',
            cash: 'Bargeld',
            browse: 'Durchsuchen',
            messages: 'Nachrichten'
        }
    },

    alerts: {
        addCondition: 'Bedingung hinzufügen',
        saveAlert: 'Alarm speichern',
        updateAlert: 'Update-Benachrichtigung',
        editExistingAlert: 'Vorhandene Warnung bearbeiten',
        createAlert: 'Neuen Alarm erstellen',
        createAnAlertToSeeItHere: 'Erstellen Sie einen Alarm, um ihn hier zu sehen.',
        interpretConditions: (field: 'latestPrice' | 'changePercent' | 'Last', operator: '>' | '>=' | '<' | '<=', valueNumberString: string) => {
            const { fieldLabel, formattedValue } = (() => {
                switch (field) {
                    case 'Last':
                    case 'latestPrice':
                        return {
                            fieldLabel: 'Preis',
                            formattedValue: FormatNumber.toMoney(Number(valueNumberString))
                        };
                    case 'changePercent':
                        return {
                            fieldLabel: 'Preisänderung in Prozent',
                            formattedValue: FormatNumber.toPercent(Number(valueNumberString))
                        };
                    default:
                        return {
                            fieldLabel: 'Wert',
                            formattedValue: FormatNumber.toCommas(Number(valueNumberString))
                        };
                }
            })();
            const op = (() => {
                switch (operator) {
                    case '>':
                        return 'größer als';
                    case '>=':
                        return 'größer als oder gleich';
                    case '<':
                        return 'kleiner als';
                    case '<=':
                        return 'kleiner als oder gleich';
                    default:
                        return 'erreicht';
                }
            })();

            return `${fieldLabel} ${op} ${formattedValue}`;
        },
        priceAlert: 'Preiswarnung',
        youDontHaveAlerts: 'Es sieht so aus, als hätten Sie keine Alarme!',
        mutualFundsUnavailable: 'Alarme sind für Investmentfonds nicht verfügbar',
        futuresUnavailable: 'Alarme sind für Futures nicht verfügbar',
        createAlertSheet: {
            selectCondition: 'Bedingung auswählen',
            change: 'Änderung %',
            latestPrice: 'Letzter Preis',
            priceAlert: 'Preiswarnung',
            createAlert: 'Alarm erstellen',
            updateAlert: 'Alarm aktualisieren',
            deleteAlert: 'Alarm löschen',
            alert: 'Alarm',
            updateAlertFor: (symbol: string) => (symbol ? `Alarm für ${symbol} aktualisieren` : 'Alarm aktualisieren'),
            target: 'Ziel',
            chooseSecurity: 'Wertpapier auswählen',
            greaterThan: 'Größer als',
            greaterThanEqual: 'Größer als oder gleich',
            lessThan: 'Kleiner als',
            lessThanEqual: 'Kleiner als oder gleich',
            alertsTitle: 'Alarme',
            newAlert: 'Neuer Alarm',
            searchForSecurity: 'Nach einem Wertpapier suchen...',
            searchSecurity: 'Wertpapiere suchen',
            noAlertsYet: 'Noch keine Alarme',
            createNewAlertRule: 'Neue Alarmregel erstellen',
            editSymbol: 'Symbol bearbeiten',
            repeatDaily: 'Täglich wiederholen',
            instructions: {
                createAlertBtn: 'Erstellen Sie Ihre erste Alarmregel mit dem unten stehenden Button!',
                lookUpSecurity: 'Suchen Sie ein Wertpapier, um Ihre erste Alarmregel zu erstellen!',
                getNotified: 'Erhalten Sie in Echtzeit Benachrichtigungen, wenn sich der Preis eines Wertpapiers ändert.'
            }
        }
    },

    equitiesMarginOnboarding: {
        title: 'Sie fügen Ihrem Konto eine Margin hinzu',
        agreementNotAllowed: 'Es tut uns leid, es sieht so aus, als könnten wir diesem Konto keine Margin-Vereinbarung hinzufügen.',
        contactSupport: 'Wenn Sie der Meinung sind, dass dies unerwartet ist, wenden Sie sich bitte an unser Support-Team',
        byChoosing:
            'Durch die Wahl, Ihrem Konto eine Margin hinzuzufügen, beantragen Sie die Erlaubnis, Geld von StoneX zu leihen und Ihre Vermögenswerte als Sicherheit zu verwenden.',
        understandRisk:
            'Es ist wichtig, dass Sie die Risiken, die mit der Verwendung von Margin verbunden sind, vollständig verstehen, sei es beim Handel mit Wertpapieren auf Margin oder bei der Nutzung des Eigenkapitals Ihres Margin-Kontos für andere Zwecke.',
        agreementDescription:
            'Die Margin-Vereinbarung ist eine vertragliche Vereinbarung zwischen Ihnen und dem Broker. Diese Vereinbarung ermöglicht es Ihnen, Wertpapiere mit geliehenen Mitteln zu kaufen. Bitte überprüfen Sie die Margin-Vereinbarung, bevor Sie mit Margin handeln.',
        close: 'Schließen',
        agreeAndContinue: 'Zustimmen und Weiter',
        reviewAgreement: 'Margin-Vereinbarung überprüfen',
        submitted: 'Alles erledigt! Ihre Margin-Vereinbarung wurde Ihrem Konto hinzugefügt',
        failed: 'Es scheint, dass wir die Margin-Vereinbarung nicht zu Ihrem Konto hinzufügen konnten'
    },

    futuresOnboarding: {
        sfi: 'Futures Commission Merchant von SFI',
        stepLabels: [
            'Willkommen!',
            'Kontaktinformationen',
            'Persönliche Informationen',
            'Kontoinformationen',
            'Beschäftigungsinformationen',
            'Handelserfahrung',
            'Verbindungen',
            'Regulatorische Informationen',
            'Finanzinformationen',
            'Überprüfung',
            'Vereinbarungen',
            'Zertifizierung'
        ],
        submitMessage: 'Bitte warten Sie, während wir Ihr Futures-Konto vorbereiten...',
        submitMessageTitle: 'Ihr neues Futures-Konto wird erstellt',
        successMessage: (account: string) => `Ihr Futures-Konto (${account}) wird erstellt.`,
        errorMessage: 'Etwas ist schief gelaufen.',
        tryAgain: 'Bitte versuchen Sie es erneut oder kontaktieren Sie uns, und wir werden das Problem beheben.',
        okButton: 'Ok',
        closeButton: 'Schließen',
        accountDelayMessage: 'Herzlichen Glückwunsch! Ihr Futures-Konto wird derzeit bearbeitet und erscheint in Kürze.',
        openAccount: 'Ein Futures-Konto eröffnen',
        openAccountNotAllowed: 'Bitte wenden Sie sich an Ihren Vertreter für Unterstützung',
        openDocumentToEnable: 'Dokument öffnen und überprüfen, um zu aktivieren',
        openAccountComingSoon: 'Eröffnung eines Futures-Kontos demnächst verfügbar!',
        welcome: (name: string) => `Willkommen ${name}!`,
        hasReachedMax:
            'Sie haben bereits ein Futures-Konto in StoneX One eröffnet. Bitte klicken Sie auf die untenstehende Schaltfläche, um zur Startseite von StoneX One zurückzukehren.',
        missingRequiredData: 'Fehlende erforderliche Daten',
        nextButton: 'Weiter',
        returnHome: 'Zurück zur Startseite',
        disabledNextText: 'Bitte vervollständigen Sie die erforderlichen Elemente oben, bevor Sie fortfahren',
        submitButton: 'Absenden',
        goBackButton: 'Zurück',
        yesButton: 'Ja',
        noButton: 'Nein',
        welcomeSubtitle: 'Lassen Sie uns ein Futures-Konto für Sie einrichten',
        contactInfo: {
            title: 'Lassen Sie uns Ihre Kontaktinformationen erhalten',
            name: 'Name',
            firstName: 'Vorname',
            lastName: 'Nachname',
            email: 'E-Mail',
            phone: 'Telefon',
            street: 'Straße',
            streetAddress: 'Straßenadresse',
            street2: 'Wohnung#, Gebäude#, usw...',
            city: 'Stadt',
            state: 'Staat oder Region',
            country: 'Land',
            zip: 'Postleitzahl',
            error: 'Muss mindestens 21 Jahre alt sein'
        },
        personalInfo: {
            title: 'Persönliche Informationen',
            citizenQuestion: 'Sind Sie ein US-Bürger?',
            citizenYes: 'Ich bin ein US-Bürger',
            citizenNo: 'Ich bin kein US-Bürger',
            ssnQuestion: 'Haben Sie eine Sozialversicherungsnummer oder ITIN?',
            ssnYes: 'Ich habe eine Sozialversicherungsnummer oder ITIN',
            ssnNo: 'Ich habe keine Sozialversicherungsnummer oder ITIN',
            ssn: 'Sozialversicherungsnummer oder ITIN',
            country: 'Staatsangehörigkeit',
            dob: 'Geburtsdatum',
            governmentId: 'Regierungs-Identifikationsnummer'
        },
        accountInfo: {
            title: 'Lassen Sie uns Ihre Kontoinformationen erfassen',
            accountType: 'Einzelkonto',
            accountTypeSubtitle: 'Ein Standardkonto mit nur einem Inhaber',
            sourceOfFunds: 'Mittelherkunft',
            intendedInitialDeposit: 'Geplante anfängliche Einzahlung',
            expectedMonthlyTrading: 'Erwartetes monatliches Handelsvolumen',
            sourceOfFundsOptions: [
                { label: 'Broker', value: 'Broker' },
                { label: 'Geschenk', value: 'Gift' },
                { label: 'Erbschaft', value: 'Inheritance' },
                { label: 'Versicherung', value: 'Insurance' },
                { label: 'Rechtliche Einigung', value: 'Legal Settlement' },
                { label: 'Rente', value: 'Pension' },
                { label: 'Ruhestand', value: 'Retirement' },
                { label: 'Verkauf', value: 'Sale' },
                { label: 'Löhne', value: 'Wages' }
            ],
            intendedInitialDepositOptions: [
                { label: '0 - $1,000', value: 0 },
                { label: '$1,001 - $10,000', value: 1000 },
                { label: '$10,001 - $50,000', value: 10000 },
                { label: '$50,001 - $100,000', value: 50000 },
                { label: '$100,001 - $250,000', value: 100000 },
                { label: '$250,001 - $500,000', value: 250000 },
                { label: '$500,001 - $1,000,000', value: 500000 },
                { label: '$1,000,001 - $5,000,000', value: 1000000 },
                { label: '$5,000,000 oder mehr', value: 5000000 }
            ],
            expectedMonthlyTradingOptions: [
                { label: '0 - 100', value: 0 },
                { label: '101 - 1,000', value: 100 },
                { label: '1,001 - 10,000', value: 1000 },
                { label: '10,001 - 50,000', value: 10000 },
                { label: '50,000 oder mehr', value: 50000 }
            ]
        },
        employmentInfo: {
            title: 'Beschäftigungsinformationen',
            status: 'Beschäftigungsstatus',
            employedTitle: 'Angestellt',
            employedSubtitle: 'Ich bin derzeit bei einem Unternehmen beschäftigt',
            studentTitle: 'Selbstständig',
            studentSubtitle: 'Ich bin derzeit selbstständig',
            unemployedTitle: 'Andere',
            unemployedSubtitle: 'Ich bin derzeit nicht beschäftigt und auch nicht im Ruhestand',
            retiredTitle: 'Im Ruhestand',
            retiredSubtitle: 'Ich bin derzeit im Ruhestand',
            employerAddress: {
                title: 'Arbeitgeberadresse',
                country: 'Land',
                street: 'Adresszeile 1',
                street1: 'Adresszeile 2',
                city: 'Stadt',
                state: 'Bundesland',
                zip: 'Postleitzahl'
            },
            years: 'Jahre beim Arbeitgeber',
            employerDetailsTitle: 'Angaben zum Arbeitgeber',
            occupationTitle: 'Beruf',
            name: 'Name des Arbeitgebers',
            jobTitle: 'Berufsbezeichnung',
            industryTitle: 'Branche',
            jobFunctionTitle: 'Aufgabenbereich',
            primarySourceOfIncomeTitle: 'Was ist Ihre Hauptquelle für Einkommen?',
            incomeOptions: [
                { label: 'Broker', value: 'Broker' },
                { label: 'Geschenk', value: 'Gift' },
                { label: 'Erbschaft', value: 'Inheritance' },
                { label: 'Versicherung', value: 'Insurance' },
                { label: 'Rechtliche Einigung', value: 'Legal Settlement' },
                { label: 'Rente', value: 'Pension' },
                { label: 'Ruhestand', value: 'Retirement' },
                { label: 'Verkauf', value: 'Sale' },
                { label: 'Löhne', value: 'Wages' }
            ],
            occupationOptions: [
                { label: 'Buchhalter/Prüfer/Buchhalter', value: 'Accountant/Auditor/Bookeeper' },
                { label: 'Admin/Büroleiter', value: 'Admin/Office Manager' }
            ],
            industryOptions: [],
            jobFunctionOptions: []
        },
        tradingExperience: {
            title: 'Erzählen Sie uns von Ihrer bisherigen Handelserfahrung',
            experienceYears: [
                { label: 'weniger als 1 Jahr', value: '0' },
                { label: '1 bis 2 Jahre', value: '1' },
                { label: '2 bis 3 Jahre', value: '2' },
                { label: '3 oder mehr Jahre', value: '3' }
            ],
            commoditiesTitle: 'Erfahrung im Rohstoffhandel',
            commoditiesQuestion: 'Haben Sie Erfahrung im Rohstoffhandel?',
            commoditiesYears: 'Jahre Erfahrung mit Rohstoffen',
            securitiesQuestion: 'Haben Sie Erfahrung im Wertpapierhandel?',
            securitiesYears: 'Jahre Erfahrung mit Wertpapieren',
            otcTitle: 'Erfahrung im außerbörslichen Handel',
            otcQuestion: 'Haben Sie Erfahrung im außerbörslichen Handel (OTC)?',
            otcYears: 'Jahre Erfahrung im OTC-Handel',
            professionalTrader: 'Qualifizieren Sie sich gemäß der Definition von CME als professioneller Marktteilnehmer für Marktdaten?',
            professionalTraderUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/Futures-Market-Data-Subscription-Terms.pdf',
            professionalTraderPdfLabel: 'Allgemeine Geschäftsbedingungen für die Abonnements von Futures-Marktdaten',
            tradingExperienceTitle: 'Handelserfahrung',
            doTradingExperience: 'Haben Sie Handelserfahrung?',
            yearsExperience: 'Jahre Erfahrung',
            professionalTraderTitle: 'Professioneller Trader',
            commodityExchangeAct: 'Commodity Exchange Act',
            governmentRelations: 'Regierungsbeziehungen',
            bankruptcy: 'Konkurs',
            legalDisputes: 'Rechtsstreitigkeiten',
            closedCommodityAccounts: 'Geschlossene Rohstoffkonten'
        },
        affiliationsInfo: {
            title: 'Nur ein paar schnelle Fragen',
            politicalOrGovernment:
                'Sind Sie oder ein Mitglied Ihrer unmittelbaren Familie derzeit ein politischer Amtsträger in einer Regierungsabteilung oder verbunden mit einem staatlichen Unternehmen, das nicht der US-Regierung angehört?',
            employeeOfStoneX:
                'Sind Sie oder ein Mitglied Ihrer unmittelbaren Familie Mitarbeiter von oder verwandt mit einem Mitarbeiter der FMC-Division von StoneX Financial Inc. ("FCM") oder deren Tochtergesellschaften?',
            regulatoryMember: 'Sind Sie bei der National Futures Association ("NFA") oder einer anderen Regulierungsbehörde registriert oder Mitglied an einer Börse?',
            regulatoryBody: 'Regulierungsbehörde',
            registrationNumber: 'Registrierungsnummer',
            employeeName: 'Name des Mitarbeiters',
            employeeRelationship: 'Beziehung',
            nfaBody: 'Name der NFA-Behörde',
            nfaRegNumber: 'NFA-Registrierungsnummer'
        },
        regulatoryInfo: {
            title: 'Lassen Sie uns einige regulatorische Informationen sammeln',
            registrationExemption: 'Betreiben Sie gemäß einer Registrierungsausnahme gemäß dem Commodity Exchange Act?',

            bankruptcy: 'Waren Sie Gegenstand eines Insolvenzverfahrens, einer Verwaltung oder einer ähnlichen Maßnahme?',
            disputeOrArbitration: 'Hatten Sie einen Rechtsstreit, eine Schiedsgerichtsverfahren oder eine Schadensersatzklage im Zusammenhang mit einem Rohstoffkonto?',
            unsatisfiedDebitBalance: 'Haben Sie jemals ein Konto bei einem Rohstoffunternehmen mit einem unerledigten Sollsaldo geschlossen?'
        },
        financialInfo: {
            title: 'Finanzinformationen',
            error: 'Finanzen erfüllen nicht die Anforderungen für ein Futures-Konto',
            page2Title: 'Fortsetzung der Finanzinformationen',
            annualRevenue: 'Jährlicher Umsatz aus allen Quellen in US-Dollar',
            annualRevenueOptions: [
                { label: 'Unter 25.000 $', value: '0' },
                { label: '25.000 $ - 50.000 $', value: '0.025' },
                { label: '50.000 $ - 100.000 $', value: '0.05' },
                { label: '100.000 $ - 250.000 $', value: '0.1' },
                { label: '250.000 $ - 500.000 $', value: '0.25' },
                { label: '500.000 $ - 1.000.000 $', value: '0.5' },
                { label: '1.000.000 $ - 5.000.000 $', value: '1' },
                { label: 'Mehr als 5.000.000 $', value: '5' }
            ],
            netWorth: 'Gesamtnettowert in US-Dollar',
            netWorthOptions: [
                { label: 'Unter 100.000 $', value: '0' },
                { label: '100.000 $ - 500.000 $', value: '0.1' },
                { label: '500.000 $ - 1.000.000 $', value: '0.5' },
                { label: '1.000.000 $ - 5.000.000 $', value: '1' },
                { label: 'Mehr als 5.000.000 $', value: '5' }
            ],
            workingCapital: 'Verfügbares Betriebskapital in US-Dollar',
            workingCapitalOptions: [
                { label: 'Unter 25.000 $', value: '0' },
                { label: '25.000 $ - 100.000 $', value: '0.025' },
                { label: '100.000 $ - 250.000 $', value: '0.1' },
                { label: '250.000 $ - 500.000 $', value: '0.25' },
                { label: '500.000 $ - 1.000.000 $', value: '0.5' },
                { label: '1.000.000 $ - 5.000.000 $', value: '1' },
                { label: 'Mehr als 5.000.000 $', value: '5' }
            ],
            annualRevenueShort: 'Jährlicher Umsatz',
            totalNetWorth: 'Gesamtnettowert',
            availableWorkingCapital: 'Verfügbares Betriebskapital'
        },
        review: {
            title: 'Lassen Sie uns Ihre Informationen überprüfen',
            subTitle: 'Wir sind fast fertig! Schauen wir uns die Antworten, die Sie gegeben haben, an, um sicherzustellen, dass alles korrekt ist',
            personal: 'Persönlich',
            account: 'Konto',
            funding: 'Finanzierung',
            trustedContact: 'Vertrauenskontakt',
            addTrustedContact: 'Vertrauenskontakt hinzufügen',
            tradingInfo: 'Handelsinformationen',
            regulatoryInfo: 'Regulatorische Informationen',
            stoneXAffiliation: 'StoneX-Zugehörigkeit',
            nfaAffiliation: 'NFA-Zugehörigkeit',
            jobTitle: 'Berufsbezeichnung',
            employer: 'Arbeitgeber',
            yearsWithEmployer: 'Jahre beim Arbeitgeber',
            employment: 'Beschäftigung',
            neitherStoneXAffiliation:
                'Weder Sie noch jemand in Ihrer unmittelbaren Familie oder im gleichen Haushalt ist ein Mitarbeiter oder Vertreter von StoneX Group Inc. oder einem StoneX-Unternehmen.',
            neitherNfaAffiliation:
                'Weder Sie noch jemand in Ihrer unmittelbaren Familie oder im gleichen Haushalt ist bei einer Börse, einem Mitgliedsunternehmen einer Börse, der FINRA oder einem kommunalen Wertpapierhändler beschäftigt oder damit verbunden.',
            notice: 'Hinweis',
            noticeDescription: 'Futures-Konten werden unter Futures Commission Merchant of SFI erstellt',
            employedStatus: (employedStatus: string) => {
                return [Light('Sie sind'), Dark(`${employedStatus}`), Light('.')];
            },
            accountOpening: (accountType: string) => {
                return [Light('Sie öffnen ein '), Dark(`${accountType}`), Light('.')];
            },
            exemptUnderCommodityExchangeAct: (areNot: string) => {
                return `Sie ${areNot} betreiben gemäß einer Befreiung von der Registrierung nach dem Commodity Exchange Act.`;
            },
            relatedToGovernment: (areNot: string) => {
                return `Sie oder ein Mitglied Ihrer unmittelbaren Familie ${areNot} derzeit politischer Beamter in einer Regierungsabteilung oder mit einem staatlichen Unternehmen verbunden, das nicht der US-Regierung gehört.`;
            },
            hasBankruptcy: (haveNot: string) => {
                return `Sie ${haveNot} Gegenstand eines Insolvenzverfahrens, einer Zwangsverwaltung oder einer ähnlichen Maßnahme gewesen.`;
            },
            hasLegalDisputeWithCommodities: (haveNot: string) => {
                return `Sie ${haveNot} sich in einem Rechtsstreit, Schiedsverfahren oder Reparationsverfahren im Zusammenhang mit einem Warenkonto befunden.`;
            },
            hasClosedCommodityAccountWithDebitBalance: (haveNot: string) => {
                return `Sie ${haveNot} ein Konto mit einem ungedeckten Sollsaldo bei einem Warenhandelsunternehmen geschlossen.`;
            },
            isProffesionalTrader: (areNot: string) => {
                return `Sie ${areNot} ein professioneller Trader.`;
            }
        },
        agreements: {
            arbitration: 'Schiedsgerichtsbarkeit',
            accept: 'Ich akzeptiere',
            doNotAccept: 'Ich akzeptiere nicht',
            acceptArbitration: 'Akzeptieren Sie die Schiedsvereinbarung? (Optional)',
            arbitrationAgreement:
                'Ich versichere und garantiere, dass ich die "Schiedsvereinbarung" gelesen habe und die darin enthaltenen Bestimmungen verstehe, und ich treffe folgende Entscheidung:',
            pageTitle: 'Wichtige Dokumente, die für Ihr Konto gelten',
            titleText:
                'Es ist erforderlich, dass Sie alle Inhalte auf dieser Seite und jeden der unten aufgeführten erforderlichen Dokumente sorgfältig prüfen, indem Sie auf die Dokumenttitel klicken, um auf deren Inhalt zuzugreifen. Bitte lesen Sie alle Dokumente sorgfältig durch, bevor Sie den Abschnitt zur Klientenzertifizierung abschließen.',
            part1Title: 'Teil 1: Datenschutzerklärungen',
            part2Title: 'Teil 2: Risikohinweise für Futures & Futures-Optionen',
            part3Title: 'Teil 3: Vereinbarungen für Futures & Futures-Optionen-Konten',
            part4Title: 'Teil 4: Begriffe für Marktdaten für Futures & Futures-Optionen',
            clientCertTitle: 'Klientenzertifizierung',
            acceptAndContinueText: "Durch Klicken auf die Schaltfläche 'Akzeptieren & Weiter' unten:",
            W9title: 'IRS Formular W-9 Zertifizierung',
            perjuryCertTitle: 'Unter Strafe des Meineids bestätige ich, dass:',
            perjuryCertItem1: 'Ich bin ein US-Bürger oder eine andere US-Person',
            perjuryCertItem2: 'ist meine korrekte Steueridentifikationsnummer',
            perjuryCertItem3: 'Ich unterliege keiner Rückhaltesteuer',
            perjuryCertItem4:
                'Der auf diesem Formular eingetragene FATCA-Code (falls vorhanden), der angibt, dass der Zahlungsempfänger von der FATCA-Meldung befreit ist, ist korrekt. (Als US-Finanzinstitut, das Ihr Konto in den USA unterhält, haben wir keinen FATCA-Code angefordert, und dieser Punkt ist nicht anwendbar).',
            clientAcknowledgementTitle: 'Kundenbestätigung',
            clientAcknowledgementItem1: [
                [
                    {
                        text: 'Ich bestätige, dass ich alle Dokumente in '
                    },
                    { text: '“Teil 1: Datenschutzerklärungen”', weight: 'bold' },
                    {
                        text: ' gelesen und verstanden habe und weiterhin versichere und garantiere, dass die von mir im Zusammenhang mit der Kontoeröffnung bereitgestellten Informationen wahr '
                    },
                    {
                        text: 'und korrekt sind und dass sich die FCM-Abteilung von StoneX Financial Inc. und StoneX Markets LLC jeweils auf die '
                    },
                    {
                        text: 'hierin enthaltenen Informationen als Grundlage für die Einrichtung eines oder mehrerer Kundenkonten verlassen. Ich versichere, erkläre und garantiere, dass alle '
                    },
                    {
                        text: 'Informationen, einschließlich geprüfter oder ungeprüfter Finanzdaten, oder Antragsteller, die die Einkommens- und Vermögensdaten ausfüllen, die '
                    },
                    {
                        text: 'in diesem Kontoantrag enthalten sind, und alle anderen bereitgestellten Informationen gemäß den Anti-Geldwäsche-Richtlinien und -Verfahren wahr und korrekt sind. '
                    },
                    { text: 'Gesetze und Verordnungen. ' }
                ]
            ],
            clientAcknowledgementItem2: [
                [
                    {
                        text: 'Ich versichere und garantiere, dass ich alle Dokumente in '
                    },
                    {
                        text: '“Teil 2: Risikohinweise zu Futures & Futures-Optionen” ',
                        weight: 'bold'
                    },
                    {
                        text: 'einschließlich der Risikohinweise für Futures und börsengehandelte Optionsrisiken, '
                    },
                    {
                        text: 'Risikohinweise für Warenoptionen und alle Inhalte der Dokumente innerhalb des zusätzlichen Offenlegungsdokuments gelesen und verstanden habe.'
                    }
                ]
            ],
            clientAcknowledgementItem3: [
                [
                    {
                        text: 'Ich versichere und garantiere, dass ich die '
                    },
                    {
                        text: '“StoneX Financial Inc. Futures & Exchange-Traded Options Customer Agreement” ',
                        weight: 'bold'
                    },
                    {
                        text: 'in ihrer Gesamtheit gelesen und verstanden habe und mich damit einverstanden erkläre, gemäß den darin festgelegten Bedingungen gebunden zu sein, wie sie von Zeit zu Zeit gemäß ihren Bedingungen geändert werden'
                    },
                    { text: 'werden.' }
                ]
            ],
            clientAcknowledgementItem4: [
                [
                    {
                        text: 'Ich versichere und garantiere, dass ich die '
                    },
                    { text: '“Plattform-Nutzer-Vereinbarung” ', weight: 'bold' },
                    {
                        text: 'in ihrer Gesamtheit gelesen und verstanden habe und mich damit einverstanden erkläre, an die Bedingungen gebunden zu sein, denen ich bezüglich des Zugriffs und '
                    },
                    { text: 'der sonstigen Nutzung der Plattform unterliege' }
                ]
            ],
            clientAcknowledgementItem5: [
                [
                    {
                        text: 'WAHL DER SCHIEDSVERFAHREN FÜR STREITIGKEITEN IN BEZUG AUF FUTURES-KONTEN: ',
                        weight: 'bold'
                    },
                    {
                        text: 'Ich versichere und garantiere, dass ich die “Schiedsvereinbarung” ',
                        weight: 'bold'
                    },
                    {
                        text: 'gelesen und die hierin enthaltenen Bestimmungen verstanden habe und die folgende Wahl treffe:',
                        weight: 'bold'
                    }
                ]
            ],
            clientAcknowledgementItem6: [
                [
                    {
                        text: 'Die folgenden Informationen gelten für Kunden, die elektronische Überweisungen über das Automated Clearing House (ACH) wählen: ',
                        weight: 'bold'
                    },
                    {
                        text: 'Hiermit bevollmächtige ich die FCM-Abteilung von StoneX Financial Inc. (“FCM”) soweit der Kunde ein Konto einrichtet '
                    },
                    {
                        text: 'mit der FCM, um per elektronischer Überweisung über das Automated Clearing House (ACH) Verbraucherpreiszahlungen und Einlagen (PPD) auf mein festgelegtes Konto einzuleiten, das von mir über die Plattform ausgewählt und authentifiziert wurde, das bei einer '
                    },
                    {
                        text: 'Depositenfinanzinstitution geführt wird, und um von Zeit zu Zeit von diesem Konto abzubuchen, um etwaige "Margin Call" oder andere Defizite in '
                    },
                    {
                        text: 'meinem FCM-Konto gemäß der FCM-Kundenvereinbarung zu begleichen.'
                    },
                    {
                        text: 'Ich erkenne an, dass die Initiierung von ACH-Transaktionen auf mein Konto durch FCM den Bestimmungen des US-amerikanischen Rechts entsprechen muss. '
                    },
                    {
                        text: 'Ich erkenne ferner an, dass die Höhe aller im Rahmen dieser Autorisierung ausgeführten Abbuchungen variieren kann, aber jede Abbuchung soll '
                    },
                    {
                        text: 'dem dann fälligen Betrag des "Margin Call" oder eines anderen Defizits (wie gemäß der FCM-Kundenvereinbarung festgelegt) zuzüglich etwaiger anwendbarer Gebühren entsprechen. '
                    }
                ]
            ],
            clientAcknowledgementItem7: [
                [
                    { text: 'Ich versichere und garantiere, dass ich die ' },
                    { text: '“Abonnementbedingungen für Futures-Marktdaten” ', weight: 'bold' },
                    {
                        text: 'in ihrer Gesamtheit gelesen und verstanden habe und mich damit einverstanden erkläre, an die Bedingungen gebunden zu sein, denen ich bezüglich des Zugriffs und '
                    },
                    { text: 'der sonstigen Nutzung der Plattform unterliege' }
                ]
            ],
            arbitrationAcceptText: 'Ich akzeptiere die Schiedsvereinbarung',
            arbitrationDeclineText: 'Ich lehne die Schiedsvereinbarung ab',
            IRSnote:
                'Die IRS benötigt Ihre Zustimmung zu keiner Bestimmung dieses Dokuments außer den erforderlichen Bescheinigungen, um eine Rückerstattung von Sicherungseinbehalten zu vermeiden.',
            acceptAndContinueButton: 'Akzeptieren und Fortfahren'
        },
        documents: {
            privacyPolicy: 'StoneX Datenschutzrichtlinie und StoneX Datenschutzgesetz',
            antiMoneyLaundering: 'Geldwäschebekämpfungsverfahren',
            commoditiesFuturesRiskDisclosure: 'Risikohinweise der Commodities Futures Trading Commission',
            commoditiesOptionsRiskDisclosure: 'Optionsinformationsblatt',
            addlDisclosures: 'Zusätzliches Offenlegungsdokument einschließlich der folgenden:',
            subText: {
                nfaRiskDisclosure: 'NFA Zusätzliche Risikohinweise',
                addlRiskDisclosure: 'Zusätzliche Risikohinweise',
                materialConflicts: 'Offenlegung von wesentlichen Interessenskonflikten',
                electronicTrading: 'Offenlegung des elektronischen Handels und der Auftragsrouten',
                noticeToOmnibus: 'Hinweis an Omnibuskonten zu Beschränkungen des Zugangs für sanktionierte Parteien',
                underlyingOrSpot: 'Offenlegung von Basis- oder Spot-Virtual-Currency-Produkten',
                clearedSwaps: 'Offenlegung von geclearten Swaps'
            },
            stoneXCustomerAgreement: 'StoneX Financial Inc. Futures & Exchange-Traded Options Kundenvereinbarung',
            arbitrationAgreement: 'Schiedsvereinbarung',
            platformAgreement: 'Plattform-Nutzer-Vereinbarung',
            virtualCurrencyRiskDisclosure: 'Risikohinweis für virtuelle Währungen',
            marketDataSubscriptionTerms: 'Abonnementbedingungen für Futures-Marktdaten',
            blobStorageUrls: {
                privacyPolicies: 'https://snex1storage.blob.core.windows.net/$web/docs/StoneX-Datenschutzrichtlinien.pdf',
                commodityFuturesTradingComissionRiskDisclosure:
                    'https://snex1storage.blob.core.windows.net/$web/docs/Risikohinweise-der-Commodity-Futures-Trading-Commission-202210X.pdf',
                optionsDisclosureStatement: 'https://snex1storage.blob.core.windows.net/$web/docs/Options-Informationsblatt-202210X.pdf',
                acknowledgementOfAdditionalDisclosures: 'https://snex1storage.blob.core.windows.net/$web/docs/Anerkennung-zusätzlicher-Offenlegungen-202210X.pdf',
                virtualCurrencyRiskStatements: 'https://snex1storage.blob.core.windows.net/$web/docs/NFA-CFTC-Risikohinweise-für-virtuelle-Währungen-202210X.pdf',
                futuresAndExchangeTradedOptionsCustomerAgreement:
                    'https://snex1storage.blob.core.windows.net/$web/docs/Kundenvereinbarung-für-Futures-und-börsengehandelte-Optionskunden-SFI_FCM_202210X.pdf',
                platformUserAgreement: 'https://snex1storage.blob.core.windows.net/$web/docs/Plattform-Nutzer-Vereinbarung-SFI_FCM_202210X.pdf',
                arbitrationAgreement: 'https://snex1storage.blob.core.windows.net/$web/docs/Schiedsvereinbarung-SFI_FCM_202210X.pdf',
                futuresMarketDataSubscriptionTerms: 'https://snex1storage.blob.core.windows.net/$web/docs/Abonnementbedingungen-für-Futures-Marktdaten.pdf'
            }
        },
        submit: {
            title: 'Bedingungen und Vereinbarungen'
        }
    },
    mediantSSO: {
        buttonText: 'Unternehmensaktionen'
    },
    equitiesOnboarding: {
        // Look at fieldTranslations
        yes: 'Ja',
        no: 'Nein',
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail',
        continue: 'Fortfahren',
        showIssues: 'Probleme anzeigen',
        are: 'sind',
        areNot: 'sind NICHT',
        openAccount: 'Eröffnen Sie ein Aktienhandelskonto',
        personalInfo1: {
            title: 'Wie lautet Ihr Name und Ihr Geburtsdatum?',
            birthDate: 'Geburtsdatum'
        },
        contactInfo: {
            title: 'Wie können wir Sie erreichen?',
            // email
            validationMessage: 'Telefonnummer muss 10 Ziffern enthalten'
        },
        personalInfo2: {
            title: 'Ihre Staatsangehörigkeit',
            label: 'Sind Sie US-Bürger?',
            yesCitizen: 'Ja, ich bin US-Bürger',
            noCitizen: 'Nein, ich bin kein US-Bürger',
            ssnHeader: 'SSN oder Steuer-ID muss 9 Ziffern enthalten',
            ssnOr: 'SSN oder ITIN',
            alert: 'Es tut uns leid, jedoch ist der Zugang zu StoneX One derzeit nur für US-Bürger mit einer Sozialversicherungsnummer verfügbar.',
            desc: 'Der Zugang zu StoneX One steht derzeit nur US-Bürgern mit einer Sozialversicherungsnummer zur Verfügung.'
        },
        chooseAccount: {
            title: 'Wählen Sie Ihr Konto',
            description: 'Drücken Sie lange auf einen Kontotyp, um weitere Details zu erhalten',
            individual: 'Individuell',
            individualDescription:
                'Ein steuerpflichtiges Konto mit nur einem Eigentümer, in dem Sie in Aktien, Anleihen, ETFs und Investmentfonds investieren können. Sie können sich für den Handel mit Margen und Optionen bewerben. Dieses Konto hat keine erforderlichen Auszahlungen, und Sie können jederzeit Geld auf dieses Konto einzahlen und davon abheben.',
            individualForeign: 'Individuell Ausländisch',
            individualForeignDescription:
                'Ein Konto mit nur einem Eigentümer, in dem Sie in Aktien, Anleihen, ETFs und Investmentfonds investieren können. Sie können sich für den Handel mit Margen und Optionen bewerben. Dieses Konto hat keine erforderlichen Auszahlungen, und Sie können jederzeit Geld auf dieses Konto einzahlen und davon abheben.',
            rothIRA: 'Roth IRA',
            rothIRADescription:
                'Ein Individual Retirement Account, der es Ihnen ermöglicht, nach Steuern zu zahlen. Obwohl es keine steuerlichen Vorteile im aktuellen Jahr gibt, sind Ihre Beiträge und etwaige Gewinne aus Investitionen steuerfrei, und Sie können sie nach dem Alter von 59½ und sobald das Konto seit fünf Jahren geöffnet ist, steuer- und strafrechtlich frei abheben.',
            traditionalIRA: 'Traditional IRA',
            traditionalIRADescription:
                'Ein Individual Retirement Account, der es Ihnen ermöglicht, mit vor Steuern gezahlten Dollar zu zahlen und etwaige Gewinne aus Investitionen sind bis zum Rückzug im Ruhestand steuerlich aufgeschoben. Bestimmte Beitragsgrenzen, Strafen für vorzeitige Auszahlungen und erforderliche Mindestauszahlungen sind für dieses Konto anwendbar.'
        },
        addressInfo: {
            title: 'Wo wohnen Sie?',
            // streetAddress, City, State, Zip are all done in futuresOnboarding
            secondary: 'Apt #, Gebäude #, usw.'
        },
        employmentOptions: {
            title: 'Was ist Ihr Beschäftigungsstatus?',
            employment: 'Beschäftigung',
            employmentStatus: {
                employed: 'Angestellt',
                youAreEmployed: 'angestellt',
                unemployed: 'Arbeitslos',
                youAreUnemployed: 'arbeitslos',
                student: 'Student',
                youAreStudent: 'ein Student',
                retired: 'Im Ruhestand',
                youAreRetired: 'im Ruhestand'
            },
            employmentInfo: {
                title: 'Beschäftigungsinformationen',
                occupation: 'Beruf',
                employer: 'Arbeitgeber',
                jobTitle: 'Berufsbezeichnung',
                jobFunction: 'Berufliche Funktion',
                industry: 'Industrie',
                employerPhone: 'Arbeitgeber Telefon',
                yearsEmployed: 'Beschäftigungsdauer (in Jahren)'
            },
            employmentAddress: 'Beschäftigungsadresse',
            occupations: {
                accountingOrAuditing: 'Buchhaltung oder Wirtschaftsprüfung',
                advertisingOrMarketingSVCE: 'Werbung oder Marketing (SVCE)',
                civilService: 'Öffentlicher Dienst',
                it: 'Informationstechnologie (IT)',
                domesticHelp: 'Hausangestellte',
                education: 'Bildung',
                trainingEmployment: 'Ausbildung und Beschäftigung',
                engineering: 'Ingenieurwesen',
                executiveManagement: 'Leitende Verwaltung',
                finance: 'Finanzen',
                insurance: 'Versicherung',
                doctor: 'Arzt',
                dental: 'Zahnarzt',
                nurse: 'Krankenschwester',
                socialServices: 'Sozialdienste',
                homemaker: 'Hausfrau/Hausmann',
                hospitality: 'Gastgewerbe',
                tourism: 'Tourismus',
                transport: 'Transport',
                humanResources: 'Personalwesen',
                manufacturing: 'Produktion',
                military: 'Militär',
                adminSecty: 'Verwaltung und Sekretariat',
                customerService: 'Kundenservice',
                other: 'Andere',
                salesOrRetail: 'Vertrieb oder Einzelhandel',
                science: 'Wissenschaft',
                agent: 'Agent',
                banker: 'Banker',
                business: 'Geschäft',
                policeFireLaw: 'Polizei, Feuerwehr, Recht',
                attorneyJudgeLegal: 'Anwalt, Richter, Rechtlich',
                politician: 'Politiker'
            },
            jobFunction: {
                creditCollections: 'Kreditinkasso',
                financialPlanning: 'Finanzplanung',
                financialAccounting: 'Finanzbuchhaltung',
                payroll: 'Lohnabrechnung',
                procurement: 'Beschaffung',
                riskManagement: 'Risikomanagement',
                tax: 'Steuer',
                treasury: 'Schatzmeister',
                businessManagement: 'Unternehmensführung',
                adminManager: 'Verwaltung und Management',
                executiveAssistant: 'Persönlicher Assistent',
                officeClerks: 'Büroangestellte',
                counseling: 'Beratung',
                recruiting: 'Rekrutierung',
                training: 'Schulung',
                applicationsSystems: 'Anwendungen und Systeme',
                businessSystems: 'Geschäftssysteme',
                databaseAdmin: 'Datenbankverwaltung',
                ITArchitecture: 'IT-Architektur',
                ITProjectManagement: 'IT-Projektmanagement',
                networkServices: 'Netzwerkdienste',
                softwareDevelopment: 'Softwareentwicklung',
                systemsSupport: 'Systemunterstützung',
                technicalServices: 'Technische Dienstleistungen',
                webServices: 'Webdienste',
                generalCounsel: 'Generalberatung',
                legalServices: 'Rechtsdienstleistungen',
                legalSupport: 'Rechtliche Unterstützung',
                audioVisual: 'Audiovisuell',
                communications: 'Kommunikation',
                designServices: 'Design-Dienstleistungen',
                marketing: 'Marketing',
                mediaRelations: 'Medienbeziehungen',
                publications: 'Publikationen',
                writingEditorial: 'Schreiben und Redaktion',
                other: 'Andere'
            },
            industries: {
                accounting: 'Buchhaltung',
                advertisingMarketing: 'Werbung und Marketing',
                aerospaceDefense: 'Luft- und Raumfahrt und Verteidigung',
                architectureDesign: 'Architektur und Design',
                automotive: 'Automobil',
                childcare: 'Kinderbetreuung',
                communicationsTelecommunications: 'Kommunikation und Telekommunikation',
                constructionCarpentryLandscaping: 'Bau und Schreinerei',
                education: 'Bildung',
                energy: 'Energie',
                engineering: 'Ingenieurwesen',
                financialServices: 'Finanzdienstleistungen',
                gamingCasinoCardClub: 'Spiele, Casino, Kartenspielverein',
                governmentPublicAdministration: 'Regierung und öffentliche Verwaltung',
                healthcareMedicalServices: 'Gesundheits- und medizinische Dienstleistungen',
                hotelHospitality: 'Hotel und Gastgewerbe',
                informationTechnology: 'Informationstechnologie',
                insurance: 'Versicherung',
                legalServicesPublicSafety: 'Rechtsdienstleistungen und öffentliche Sicherheit',
                manufacturing: 'Herstellung',
                mediaEntertainment: 'Medien und Unterhaltung',
                nonProfitCharity: 'Gemeinnützigkeit und Wohltätigkeit',
                printingPublishing: 'Druck und Verlagswesen',
                realEstate: 'Immobilien',
                restaurantFoodService: 'Restaurant und Lebensmittelversorgung',
                transportation: 'Transport',
                travel: 'Reisen',
                other: 'Andere'
            }
        },
        funding: {
            title: 'Was ist Ihre Finanzierungsquelle?',
            // Source and estimatedLiqNet already completed in futures onboarding.
            sourceIncome: {
                title: 'Einkommensquelle',
                wages: 'Löhne', // all
                sale: 'Verkauf',
                inheritance: 'Erbschaft',
                gift: 'Geschenk',
                insurance: 'Versicherung',
                legalSettlement: 'Rechtliche Einigung',
                pension: 'Rente',
                retirement: 'Ruheständler',
                broker: 'Broker'
            },
            estimatedLiquidNet: {
                title: '',
                a: 'Weniger als 50.000 $', // MoneyOptions reference MoneyOptions.ts
                b: '50.000 $ - 99.999 $',
                c: '100.000 $ - 199.999 $',
                d: '200.000 $ - 499.999 $',
                e: '500.000 $ - 999.999 $',
                f: '1 Million $ - 2,49 Millionen $',
                g: '2,5 Millionen $ oder mehr'
            }
        },
        trustedContact: {
            title: 'Vertrauenswürdiger Kontakt',
            description: 'Möchten Sie einen vertrauenswürdigen Kontakt hinzufügen?',
            contactInfo: {
                title: 'Kontaktinformationen des vertrauenswürdigen Kontakts',
                relationship: 'Beziehung',
                phoneNumber: 'Telefonnummer',
                contactsEmailOpt: 'E-Mail des Kontakts (optional)',
                addAddress: 'Möchten Sie die Adresse des vertrauenswürdigen Kontakts hinzufügen?'
            },
            addressTitle: 'Adresse des vertrauenswürdigen Kontakts'
        },
        stoneXAffiliation: {
            title: 'StoneX Zugehörigkeit',
            titleInfo: 'Informationen zur StoneX Zugehörigkeit',
            description:
                'Sind Sie oder jemand in Ihrer unmittelbaren Familie oder im selben Haushalt ein Mitarbeiter oder Vertreter von StoneX Group Inc. oder eines mit StoneX verbundenen Unternehmens?',
            position: 'Position bei StoneX',
            relationToEmployee: 'Beziehung zum Mitarbeiter',
            affiliatedEntity: 'Verbundenes Unternehmen',
            affiliationName: 'Name der StoneX-Zugehörigkeit',
            affiliationRelationship: 'Beziehung zur Zugehörigkeit',
            relationship: {
                child: 'Kind',
                self: 'Selbst',
                spouse: 'Ehepartner',
                other: 'Andere'
            },
            entity: 'StoneX-Unternehmen, mit dem verbunden',
            affiliate: {
                stnx: 'STNX',
                infa: 'INFA',
                sswm: 'SSWM',
                ssia: 'SSIA'
            },
            affiliation: {
                // FirstName
                // LastName
                areYou: 'Sind Sie',
                you: 'Sie',
                areOtherUsBrokers: 'Sind Sie mit einem in den USA registrierten Broker verbunden, der nicht Mitglied der Branche ist?',
                areAccreditedMember: 'Sind Sie ein akkreditierter Investor des Industriemitglieds?',
                areRegisteredAdvisor: 'Sind Sie mit einem in den USA registrierten Anlageberater verbunden.',
                areOfficerOwnsTenPercent:
                    'Sind Sie ein leitender Angestellter, Direktor oder besitzen Sie 10 Prozent oder mehr Aktien eines börsennotierten Unternehmens.',
                areEmployeeOfStonex: 'Sind Sie Mitarbeiter oder assoziierte Person von StoneX Financial Inc. oder einer ihrer Einführenden Firmen.',
                areNaturalPersonOrEntityWithoutTaxId: 'Sind Sie eine natürliche Person oder juristische Person ohne US-Steueridentifikationsnummer.',
                otherUsBrokers: 'verbunden mit einem in den USA registrierten Broker, der nicht Mitglied der Branche ist.',
                accreditedMember: 'ein akkreditierter Investor des Industriemitglieds.',
                registeredAdvisor: 'verbunden mit einem in den USA registrierten Anlageberater.',
                officerOwnsTenPercent: 'ein leitender Angestellter, Direktor oder Besitzer von 10 Prozent oder mehr Aktien eines börsennotierten Unternehmens.',
                employeeOfStonex: 'ein Mitarbeiter oder assoziierte Person von StoneX Financial Inc. oder einer ihrer Einführenden Firmen.',
                naturalPersonOrEntityWithoutTaxId: 'eine natürliche Person oder juristische Person ohne US-Steueridentifikationsnummer.'
            }
        },
        industryAffiliation: {
            title: 'Branchenzugehörigkeit',
            description:
                'Sind Sie, oder ist jemand in Ihrer unmittelbaren Familie oder im selben Haushalt, bei einer Börse, einem Mitgliedsunternehmen einer Börse, FINRA oder einem kommunalen Wertpapierhändler beschäftigt oder verbunden?',
            // yes
            // no
            firmName: 'Firmenname',
            sec144: 'Sind Sie, oder ist jemand in Ihrer unmittelbaren Familie oder im selben Haushalt, ein politikgestaltender Offizier, Direktor, ein 10-Prozent-Anteilseigner oder anderweitig als verbundenes Unternehmen eines börsennotierten Unternehmens im Sinne der SEC-Regel 144 anzusehen?',
            companyName: 'Firmenname',
            ticker: 'Unternehmens-Tickersymbol oder CUSIP'
        },
        review: {
            title: 'Überprüfung',
            personal: 'Persönlich',
            // firstName
            // lastName
            // email
            // Phone Number
            countryOfCitizenship: 'Staatsbürgerschaftsland', // repeated
            ssnItin: 'Sozialversicherungsnummer / ITIN',
            account: 'Konto',
            accountDescription: 'Sie eröffnen ein ', // you are opening an
            employment: 'Beschäftigung', // Employment
            employmentDescription: 'Sie sind ', // you are
            funding: 'Finanzierung', // Funding
            // sourceofIncome and type (use funding above)
            // estimated net worth and amount (use funding above)
            // trusted contact
            // firstName from sharedTranslations
            // lastName from sharedTranslations
            // phoneNumber
            relationship: 'Beziehung',
            noStoneXAffiliation: '',
            address: 'Adresse',
            // firmName
            sec144Name: 'Name des SEC-Regel-144-Unternehmens', // SEC Rule 144 Company Name
            sec144Ticker: 'Ticker des SEC-Regel-144-Unternehmens', // SEC Rule 144 Company Ticker
            notPolicyMaker:
                'Weder Sie noch jemand in Ihrer unmittelbaren Familie noch jemand im gleichen Haushalt ist/sind ein politischer Entscheidungsträger, Direktor, ein 10-Prozent-Anteilseigner oder anderweitig als verbundenes Unternehmen eines börsennotierten Unternehmens im Sinne der SEC-Regel 144 anzusehen.',
            notEmployed:
                'Weder Sie noch jemand in Ihrer unmittelbaren Familie noch jemand im gleichen Haushalt ist/sind bei einer Börse, einem Mitgliedsunternehmen einer Börse, FINRA oder einem kommunalen Wertpapierhändler beschäftigt oder mit diesen verbunden.'
        },
        agreements: {
            title: 'Hinzufügen eines nicht widersprechenden wirtschaftlich Berechtigten',
            description:
                'Gemäß der Vorschrift gibt StoneX Financial Inc den Namen und die Adresse des Kontoinhabers an die Unternehmen oder Emittenten von Wertpapieren weiter, die sie halten. Diese Unternehmen oder Emittenten fordern diese Informationen an, falls sie die Aktionäre in Bezug auf wichtige Mitteilungen an Aktionäre kontaktieren müssen.',
            label: 'Optieren Sie aus der Weitergabe dieser Informationen an diese Unternehmen und/oder Emittenten aus? (Optional)',
            optIn: 'Opt In - Meine Informationen bei Bedarf weitergeben',
            optOut: 'Opt Out - Meine Informationen nicht weitergeben'
        },
        disclosures: {
            title: 'Offenlegungen und Vereinbarungen',
            reviewAccept: 'Überprüfen und akzeptieren Sie unsere Offenlegungen und Vereinbarungen',
            reviewDisclosures: 'Offenlegungen überprüfen',
            disclosuresAccepted: 'Offenlegungen akzeptiert!',
            acceptContinue: 'Akzeptieren und Fortfahren',
            pressFinishToCreate: 'Drücken Sie auf Fertig, um Ihr Konto zu erstellen',
            finish: 'Fertig',
            missingData: 'Es scheint, als fehlen noch einige Daten',
            finishLater: 'Später abschließen'
        }
    },
    equitiesOptionsOnboarding: {
        gotIt: 'Verstanden',
        whatAreOptions: {
            title: 'Was sind Optionen?',
            atItsCore:
                'Im Kern ist der Handel mit Optionen wie der Erhalt eines "Tickets", das Ihnen das Recht, aber nicht die Verpflichtung gibt, eine Aktie zu einem bestimmten Preis innerhalb eines bestimmten Zeitrahmens zu kaufen oder zu verkaufen.',
            analogy: 'Lassen Sie uns eine einfache Analogie verwenden:',
            imagine: `Stellen Sie sich vor, Sie sehen, dass nächsten Monat ein Konzert Ihrer Lieblingsband angekündigt wird, und Sie sind unsicher, ob Sie teilnehmen können. Anstatt sofort das Ticket zu kaufen, zahlen Sie eine kleine Gebühr für eine "Option", um das Ticket zu dem aktuellen Preis jederzeit vor dem Konzert zu kaufen.`
        },
        financialInformation: {
            title: 'Finanzinformationen',
            annualIncome: 'Jahreseinkommen',
            estNw: 'Geschätztes Nettovermögen',
            estLiquidNw: 'Geschätztes liquides Nettovermögen',
            selectAnnualIncome: 'Wählen Sie das jährliche Einkommen aus',
            selectEstNw: 'Wählen Sie das geschätzte Nettovermögen aus',
            selectLiquidNw: 'Wählen Sie das geschätzte liquide Nettovermögen aus'
        },
        investmentExperience: {
            title: 'Anlageerfahrung',
            0: '0 Jahre',
            1: '0-2 Jahre',
            2: '2-5 Jahre',
            3: '5+ Jahre',
            stocksAndBonds: 'Aktien und Anleihen',
            mutualFunds: 'Investmentfonds',
            options: 'Optionen',
            annuities: 'Renten',
            altInvestments: 'Alternative Investitionen',
            selectNumYears: 'Wählen Sie die Anzahl der Jahre aus'
        },
        requestOptionsLevel: {
            title: 'Anfordern eines Optionslevels',
            description:
                'Da der Handel mit Optionen verschiedene Risikostufen aufweist, wird der Optionshandel in mehrere "Levels" oder "Stufen" eingeteilt. Wir fordern Sie auf, für ein bestimmtes Handelsniveau zugelassen zu werden, basierend auf Ihrer Erfahrung, finanziellen Situation und Ihrem Verständnis der damit verbundenen Risiken.'
        },
        riskTolerance: {
            title: 'Risikotoleranz',
            description: 'Halten Sie jede Option lange gedrückt, um mehr zu erfahren',
            conservative: 'Konservativ',
            moderate: 'Mäßig',
            aggressive: 'Aggressiv',
            speculative: 'Spekulativ',
            conservativeDesc:
                'Sie suchen aktuelles Einkommen. Langfristiges Kapitalwachstum ist kein Ziel. Sie sind bereit, sehr niedrige Volatilitätsniveaus und den möglichen Verlust eines Teils des Kapitals zu akzeptieren, aber die Kapitalerhaltung ist immer noch ein wichtiges Anliegen.',
            moderateDesc:
                'Sie streben sowohl Kapitalwachstum als auch aktuelles Einkommen an. Sie sind bereit, moderate Volatilitätsniveaus zu akzeptieren, um Kapitalzuwachs oder höhere Ertragsrenditen und den möglichen Verlust eines Teils des Kapitals zur Verfolgung Ihres Anlageziels zu erzielen.',
            aggressiveDesc:
                'Sie streben langfristiges Kapitalwachstum an. Aktuelles Einkommen ist kein Ziel. Sie sind bereit, sehr hohe Volatilitätsniveaus und den möglichen Verlust eines erheblichen Teils des Kapitals zu akzeptieren, um Ihr Anlageziel zu verfolgen.',
            speculativeDesc:
                'Sie streben realisierte Gewinne aus kurzfristigen Bewegungen in den Wertpapierpreisen an. Sie sind bereit, die höchsten Volatilitätsniveaus und den möglichen Totalverlust des Kapitals zu akzeptieren, der beim Versuch, Ihr Anlageziel zu erreichen, auftreten kann.'
        },
        optionsLevel: {
            title: 'Optionslevel',
            description: 'Wir haben Optionslevel basierend auf Ihrer Risikotoleranz aktiviert',
            level1: {
                title: 'Level 1',
                action: 'Gedeckte Calls schreiben'
            },
            level2: {
                title: 'Level 2',
                action1: 'Level-1-Strategien',
                action2: 'Kauf von Calls & Puts'
            },
            level3: {
                title: 'Level 3',
                action1: 'Level-1-2-Strategien',
                action2: 'Spreads & Straddles'
            },
            level4: {
                title: 'Level 4',
                action1: 'Level-1-3-Strategien',
                action2: 'Ungedeckte Puts schreiben',
                action3: 'Gedeckte Cash-Equity-Puts schreiben'
            },
            level5: {
                title: 'Level 5',
                action1: 'Level-1-4-Strategien',
                action2: 'Ungedeckte Calls schreiben'
            },
            requiresMargin: 'Benötigt Marge',
            addMargin: 'Fügen Sie Ihrer Konto Marge hinzu',
            levelRequiresMargin: 'Level erfordert Marge',
            level2Margin: 'Für Handelsstufen über 2 müssen Sie eine Margenvereinbarung vorlegen.'
        },
        optionsAgreement: {
            title: 'Optionsvereinbarung',
            description: 'Bevor Sie Ihre Bewerbung einreichen, müssen Sie die folgende Vereinbarung sorgfältig lesen und verstehen',
            review: 'Optionsvereinbarung überprüfen',
            close: 'Schließen'
        },
        review: {
            title: 'Überprüfung',
            financialInfo: 'Finanzinformationen',
            estLiquidNw: 'Geschätztes liquides Nettovermögen',
            netWorth: 'Nettovermögen',
            income: 'Einkommen',
            experienceInfo: 'Erfahrungsinformationen',
            stocksAndBonds: 'Aktien und Anleihen',
            options: 'Optionen',
            mutualFunds: 'Investmentfonds',
            annuities: 'Renten',
            altInvestments: 'Alternative Investitionen',
            optionsInfo: 'Optionsinformationen',
            optionsLevel: 'Optionslevel',
            investmentObjective: 'Anlageziel'
        }
    },
    sharedOnboarding: {
        submissionModal: {
            multiAssetTrading: 'Multi-Asset-Handel steht kurz bevor.',
            loading: 'Einen Moment bitte...',
            allDone: 'Alles erledigt! Lass uns mit dem Handel beginnen.',
            notAbleToCreateAccount: 'Es sieht so aus, als hätten wir die Erstellung Ihres Kontos nicht abschließen können.',
            tapAnywhereContinue: 'Tippen Sie überall hin, um fortzufahren',
            tapAnywhereForSupport: 'Tippen Sie überall hin, um mit dem Support zu chatten',
            applicationPending: 'Ihr Konto wird bearbeitet. Wir werden Ihnen eine E-Mail senden, wenn Ihr Konto erstellt wurde.',
            applicationQueued: 'Ihr Kontenantrag wurde in die Warteschlange gestellt. Wir werden Ihnen eine E-Mail senden, wenn Ihr Konto erstellt wurde.'
        },
        agreementRequired: (agreementName: string) => `${agreementName} muss akzeptiert werden, um fortzufahren.`,
        selectAccount: {
            title: (name: string) => `Willkommen ${name}!`,
            subtitle: 'Lassen Sie uns ein neues StoneX One-Konto für Sie erstellen',
            equitiesButtonLabelTitle: 'Aktien',
            futuresButtonLabelTitle: 'Termingeschäfte',
            equitiesButtonLabelSubtitle: 'Eröffnen Sie ein Aktienhandelskonto',
            futuresButtonLabelSubtitle: 'Eröffnen Sie ein Termingeschäftskonto'
        },
        true: 'Ja',
        false: 'Nein',
        signup: {
            leaveSignup: 'Registrierung verlassen',
            confirmCancelSignup: 'Sind Sie sicher, dass Sie Ihre Registrierung abbrechen möchten?',
            yesCancel: 'Ja, abbrechen',
            noStayHere: 'Nein, hier bleiben'
        },
        headerTranslations: {
            personal: 'Persönliche Informationen',
            account: 'Kontoinformationen',
            address: 'Adressinformationen',
            employment: 'Beschäftigungsinformationen',
            funding: 'Finanzierungsinformationen',
            trustedContact: 'Vertrauenskontakt',
            stonexAffiliation: 'StoneX-Zugehörigkeit',
            industryAffiliation: 'Branchenzugehörigkeit',
            whichAccount: 'Welches Konto möchten Sie eröffnen?'
        },
        fieldTranslations: {
            personal: {
                firstName: 'Vorname',
                lastName: 'Nachname',
                email: 'E-Mail',
                phoneNumber: 'Telefonnummer',
                usCitizen: 'Sind Sie US-Bürger?',
                countryOfCitizenship: 'Land der Staatsbürgerschaft',
                ssnOrItin: 'Sozialversicherungsnummer oder ITIN'
            },
            account: {
                accountType: 'Kontotyp'
            },
            address: {
                address: 'Adresse',
                city: 'Stadt',
                state: 'Bundesland',
                zipCode: 'Postleitzahl'
            },
            employment: {
                employmentStatus: 'Beschäftigungsstatus',
                occupation: 'Beruf',
                employer: 'Arbeitgeber',
                employerPhoneNumber: 'Telefonnummer des Arbeitgebers',
                jobTitle: 'Berufsbezeichnung',
                industry: 'Industrie',
                jobFunction: 'Aufgabenbereich',
                employerCountry: 'Land des Arbeitgebers',
                employerAddress: 'Adresse des Arbeitgebers',
                employerAddress2: 'Adresse des Arbeitgebers 2',
                employerCity: 'Stadt des Arbeitgebers',
                employerState: 'Bundesland des Arbeitgebers',
                employerZipCode: 'Postleitzahl des Arbeitgebers'
            },
            funding: {
                sourceOfIncome: 'Einkommensquelle',
                estimatedLiquidNetWorth: 'Geschätzter flüssiger Nettovermögenswert'
            },
            trustedContact: {
                addTrustedContact: 'Vertrauenskontakt hinzufügen',
                withTrustedContact: 'Vertrauenskontakt hinzufügen?',
                trustedContactFirstName: 'Vorname des Vertrauenskontakts',
                trustedContactLastName: 'Nachname des Vertrauenskontakts',
                trustedContactEmail: 'E-Mail des Vertrauenskontakts',
                trustedContactPhoneNumber: 'Telefonnummer des Vertrauenskontakts',
                trustedContactRelationship: 'Beziehung zum Vertrauenskontakt',

                withTrustedContactAddress: 'Vertrauenskontaktadresse hinzufügen?',
                trustedContactAddress: 'Adresse des Vertrauenskontakts',
                trustedContactCity: 'Stadt des Vertrauenskontakts',
                trustedContactState: 'Bundesland des Vertrauenskontakts',
                trustedContactZipCode: 'Postleitzahl des Vertrauenskontakts'
            },
            stonexAffiliation: {
                withStonexAffiliation: 'StoneX-Zugehörigkeit hinzufügen?',
                stonexAffilFirstName: 'Vorname der StoneX-Zugehörigkeit',
                stonexAffilLastName: 'Nachname der StoneX-Zugehörigkeit',
                stonexAffilPosition: 'Position der StoneX-Zugehörigkeit',
                stonexAffilEntity: 'Entität der StoneX-Zugehörigkeit',
                sameAsEmploymentInformation: 'Gleich wie Beschäftigungsinformationen',

                stonexAffilOtherUsBrokers: 'Andere US-Broker zur StoneX-Zugehörigkeit hinzufügen?',
                stonexAffilAccreditedMember: 'StoneX-Zugehörigkeit: Akkreditiertes Mitglied',
                stonexAffilWithRegisteredAdvisor: 'StoneX-Zugehörigkeit mit registriertem Berater',
                stonexAffilOfficerOwnsTenPercent: 'StoneX-Zugehörigkeit: Offizier besitzt zehn Prozent',
                stonexAffilEmployeeOfStonex: 'StoneX-Zugehörigkeit: Mitarbeiter von StoneX',
                stonexAffilNaturalPersonOrEntityWithoutTaxId: 'StoneX-Zugehörigkeit: Natürliche Person oder Entität ohne Steuer-ID'
            },
            industryAffiliation: {
                withIndustryAffiliation: 'Branchenzugehörigkeit hinzufügen?',
                industryAffilFirmName: 'Firmenname der Branchenzugehörigkeit',
                withIndustryAffiliationRule144: 'Branchenzugehörigkeit nach Regel 144 hinzufügen?',
                industryAffilRule144CompanyName: 'Firmenname nach Regel 144 der Branchenzugehörigkeit',
                industryAffilRule144CompanySymbolOrCusip: 'Firmensymbol oder CUSIP nach Regel 144 der Branchenzugehörigkeit'
            },
            whichAccount: {
                continueStockTradingApplication: 'Aktienantragsverfahren fortsetzen',
                stockTradingAccount: 'Aktienhandelskonto',
                futuresTradingAccount: 'Futures-Handelskonto'
            }
        },
        accountOpeningSelection: {
            pageTitle: 'Konten',
            pageSubtitle: 'Wählen Sie ein Konto aus, das verwaltet oder ein neues Konto erstellt werden soll',
            margin: 'Marge',
            options: 'Optionen',
            accountNumber: 'Kontonummer',
            accountType: 'Typ',
            activeApplication: 'Aktive Anwendung',
            selectedAccountOptions: {
                addMargin: 'Marge hinzufügen',
                marginSubLabel: 'Marge gibt Ihnen Zugang zu verschiedenen Optionsstrategien und mehr.',
                addOptions: 'Optionen hinzufügen',
                optionsSubLabel:
                    'Der Handel mit Optionen ermöglicht es Ihnen, Verträge zu handeln, wenn Sie glauben, dass der Preis eines Vermögenswerts steigen oder fallen wird.',
                continueApplication: 'Antrag fortsetzen',
                continueApplicationSubLabel: 'Setzen Sie Ihre Bewerbung dort fort, wo Sie aufgehört haben.'
            },
            selectionBadge: {
                inProgress: 'In Bearbeitung',
                created: 'Erstellt',
                pending: 'Ausstehend'
            }
        }
    },
    futuresGlance: {
        labels: {
            cash: 'Bargeld',
            netLiq: 'Netto-Liquidität',
            openPnl: 'Offener Gewinn/Verlust',
            realizedPnl: 'Realisierter Gewinn/Verlust',
            buyingPower: 'Kaufkraft'
        },
        info: {
            netLiq: 'Wert des Kontos, wenn es zu aktuellen Marktpreisen liquidiert wird.',
            openPnl: 'Aktueller Gewinn/Verlust aller offenen Positionen.',
            realizedPnl: 'Dieser Wert ist der tagesaktuelle realisierte Gewinn/Verlust.',
            lastUpdated: 'Für Ihr Futures-Konto ist dies der Wert der Netto-Liquidität des Kontos.',
            buyingPower:
                'Ihre Kaufkraft ist der Betrag des gesamten Bargeldes auf dem Konto, der zum Kauf von Futures und Futures-Optionen sowie aller verfügbaren Margen zur Verfügung steht.'
        }
    },
    fplBanner: {
        bannerText: (isSummary?: boolean, theme?: AppliedAppTheme): ShadedTextList => [
            {
                text: isSummary
                    ? 'Es sieht so aus, als ob eines oder mehrere Ihrer Konten für die Beantragung von '
                    : 'Es sieht so aus, als ob Ihr Konto für die Beantragung von ',
                shade: theme === 'dark' ? 'light' : 'dark'
            },
            {
                text: 'Vollständigem Leihen gegen Sicherheiten',
                shade: 'dark',
                color: theme === 'dark' ? null : '#2cb333'
            },
            {
                text: ' berechtigt ist',
                shade: theme === 'dark' ? 'light' : 'dark',
                lineBreak: true
            },
            {
                text: 'Klicken Sie hier, um mehr zu erfahren',
                shade: 'dark',
                color: '#2cb333'
            },
            {
                text: ' darüber, wie Sie ungenutzte Vermögenswerte in Einkommen umwandeln können.',
                shade: theme === 'dark' ? 'light' : 'dark'
            }
        ],
        bannerTextMobileSummary: [
            { shade: 'light', text: 'Eines oder mehrere Ihrer Konten sind für die Beantragung von ' },
            { shade: 'dark', text: ' Vollständigem Leihen gegen Sicherheiten! ' },
            { shade: 'light', text: 'Tippen Sie hier, um damit zu beginnen, ungenutzte Vermögenswerte in Einkommen umzuwandeln.' }
        ],
        bannerTextMobileAccount: [
            { shade: 'light', text: 'Dieses Konto ist für die Beantragung von' },
            { shade: 'dark', text: ' \nVollständigem Leihen gegen Sicherheiten! ' },
            { shade: 'light', text: 'Tippen Sie hier, um damit zu beginnen, ungenutzte Vermögenswerte in Einkommen umzuwandeln.' }
        ],

        applyNow: 'Jetzt bewerben',
        learnMore: 'Mehr erfahren'
    },
    fplModal: {
        headerText: [
            {
                text: 'Bereit, ungenutzte Vermögenswerte in Einkommen umzuwandeln?',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'Freischalten des vollständigen Wertpapierleihens gegen Barzahlung',
                shade: 'dark'
            }
        ],
        eligibilityText: 'Sie benötigen mindestens $50.000 Eigenkapital in einem Margekonto, um berechtigt zu sein',
        aboutText:
            'Wertpapierleihe ist die Praxis, Wertpapiere von einer Partei an eine andere zu verleihen. Oft ermöglicht diese Praxis dem Kreditnehmer, Wertpapiere zu verkaufen und eine Short-Position am Markt einzunehmen.',
        revenueStreamText: [
            {
                text: 'Neuer Einnahmestrom:',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'Mit dem vollständigen Wertpapierleihen gegen Barzahlung können Sie potenziell Ihre Portfolioerträge steigern, indem Sie Wertpapiere verleihen, die Sie bereits besitzen.',
                shade: 'light'
            }
        ],
        maintainOwnershipText: [
            {
                text: 'Besitz beibehalten:',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'Sie behalten weiterhin die volle Eigentümerschaft an Ihren Investitionen, was bedeutet, dass Ihre Erträge weiterhin aufgrund der Marktsituation performen, und der Kreditnehmer wird alle Dividenden und Zinszahlungen auszahlen, während das Wertpapier verliehen ist. Es kann jedoch als Barzahlung anstelle einer Dividende gebucht werden, was steuerliche Auswirkungen haben könnte.',
                shade: 'light'
            }
        ],
        loansAreSecureText: [
            {
                text: 'Darlehen sind sicher:',
                shade: 'dark',
                lineBreak: true
            },
            {
                text: 'Während die Wertpapiere verliehen sind, stellt der Kreditnehmer Ihnen Sicherheiten für den vollen Wert der geliehenen Wertpapiere zur Verfügung.',
                shade: 'light'
            }
        ],
        howItWorksTitle: 'So funktioniert das vollständige Wertpapierleihen gegen Barzahlung',
        howItWorksText:
            'StoneX verwaltet den Verleihprozess, vom Verbinden von Kreditgebern und Kreditnehmern über die Vereinbarung der Verwahrung von Sicherheiten bis hin zur Abrechnung von Gebühren nach Ablauf der Darlehenslaufzeit.',
        bulletPointsText: [
            'Kreditgeber erwirtschaften eine Rendite auf Bargeldinvestitionen in einem positiven Zinsumfeld',
            'Kreditgeber verdienen eine Gebühr für Darlehen, die durch Bargeld oder Wertpapiere besichert sind',
            'Wertpapiere werden auf Anforderung des Kreditgebers zurückgerufen oder durch Verkauf von Wertpapieren durch den Kreditgeber',
            'Kreditnehmer zahlt alle Dividenden-/Zinszahlungen zurück, während das Wertpapier verliehen ist',
            'Kunde erhält noch am selben Tag Sicherheiten',
            'Sicherheiten werden relativ zum Wert des verliehenen Vermögenswerts aufrechterhalten',
            'Kreditnehmer behält alle mit dem verliehenen Wertpapier verbundenen Stimmrechte'
        ],
        invitedToFpl: `Sie sind eingeladen zum vollständigen Wertpapierleihen gegen Barzahlung`,
        fullyPaidLending: 'Vollständiges Wertpapierleihen gegen Barzahlung',
        maximizeProfits: `Maximieren Sie potenzielle Gewinne, indem Sie Ihre Aktien mit dem vollständigen Wertpapierleihen gegen Barzahlung verleihen - Wandeln Sie ungenutzte Vermögenswerte in Einkommen um.`,
        addFunds: 'Fonds hinzufügen'
    },
    fplProfile: {
        applyForFPL: 'Beantragen Sie vollständiges Wertpapierleihen gegen Barzahlung',
        fplProfileLink: 'Vollständiges Wertpapierleihen gegen Barzahlung',
        fplProfileBannerText: (theme?: AppliedAppTheme) => [
            {
                text: 'Maximieren Sie potenzielle Gewinne, indem Sie Ihre Aktien verleihen mit',
                shade: theme === 'dark' ? 'light' : 'dark'
            },
            {
                text: 'Vollständigem Wertpapierleihen gegen Barzahlung',
                shade: theme === 'dark' ? 'dark' : null,
                color: theme === 'dark' ? null : '#2cb333'
            },
            {
                text: '!',
                shade: theme === 'dark' ? 'light' : 'dark'
            }
        ],
        learnMore: 'Mehr erfahren',
        pending: {
            header: 'Vollständiges Wertpapierleihen gegen Barzahlung - Ausstehend',
            body: 'Antrag eingereicht'
        },
        enabled: {
            header: 'Vollständiges Wertpapierleihen gegen Barzahlung - Aktiviert',
            body: `Sie haben das vollständige Wertpapierleihen gegen Barzahlung für dieses Konto aktiviert.`
        }
    },
    fplOnboarding: {
        welcome: {
            title: 'Bewerben Sie sich um vollständig bezahltes Wertpapierleihe',
            text: 'Verdienen Sie Zinsen auf Aktien in Ihrem Portfolio. Behalten Sie das Eigentum / Verkaufen Sie Ihre Anteile jederzeit. Sie können nicht abstimmen, während Aktien verliehen sind. Sie benötigen mindestens 50.000 USD Eigenkapital in einem Margin-Konto, um teilnahmeberechtigt zu sein.'
        },
        fplInfo: {
            title: 'Wählen Sie ein teilnahmeberechtigtes Konto aus',
            text: 'Das ausgewählte Margin-Konto muss mindestens 50.000 USD Eigenkapital aufweisen'
        },
        agreements: {
            title: 'Wichtige Vereinbarungen für Ihr Konto',
            requiredClick:
                'Es ist erforderlich, dass Sie alle Inhalte auf dieser Seite und jeden der erforderlichen Dokumente unten sorgfältig überprüfen, indem Sie auf die Dokumententitel klicken, um auf deren Inhalt zuzugreifen. Bitte lesen Sie alle Dokumente sorgfältig durch, bevor Sie den Abschnitt abschließen.',
            subtitle: (): ShadedTextList => [
                { text: 'Es ist erforderlich, dass Sie alle Inhalte auf dieser Seite und jeden der erforderlichen Dokumente überprüfen, indem Sie auf die ' },
                {
                    text: 'Dokumententitel unten klicken, um auf deren Inhalt zuzugreifen. Bitte lesen Sie alle Dokumente sorgfältig durch, bevor Sie den Abschnitt abschließen.'
                }
            ],
            clientCertTitle: 'Kundenbescheinigung',
            loanAgreementText: (): ShadedTextList => [
                { text: 'Mit dem Ankreuzen dieses Kästchens erklärt sich der Kreditgeber mit den nachstehenden Bedingungen einverstanden:' },
                { text: '1. DIE WERTPAPIERE IN IHREN MARGIN-KONTEN UND JEGLICHE WERTPAPIERE, FÜR DIE SIE NICHT VOLLSTÄNDIG BEZAHLT HABEN, ZUSAMMEN MIT ALLEN ' },
                { text: 'DAZUGEHÖRIGEN EIGENTUMSRECHTEN, KÖNNEN AN STONEX FINANCIAL VERLIEHEN ODER AN ANDERE VERLIEHEN WERDEN; UND' },
                { text: '2. DIESE VEREINBARUNG ENTHÄLT EINE VORVEREINBARTE SCHIEDSVEREINBARUNG IN ABSATZ 29, BEGINNEND AUF SEITE 11 DIESER VEREINBARUNG.' },
                { text: 'Bestimmung der Leihgebühr', shade: 'dark', lineBreak: true },
                {
                    text: 'Die Leihgebühr wird ein Prozentsatz der neuen Erträge sein, die vom Darlehensnehmer für die erneute Verleihung der Wertpapiere des Kreditgebers erhalten werden. '
                },
                {
                    text: 'Die Leihgebühr wird täglich anfallen, einschließlich des Tages, an dem die verliehenen Wertpapiere auf den Darlehensnehmer übertragen werden, und ausschließlich des Tages, an '
                },
                { text: 'dem die verliehenen Wertpapiere an den Kreditgeber zurückgegeben werden. Die Leihgebühr wird monatlich automatisch ' },
                { text: 'auf das Konto des Kreditgebers gutgeschrieben.' }
            ],
            loanAgreementPDFUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/FPL/SNEX_Fully%20Paid%20Master%20Securities%20Loan%20Agreement%2020231219X.pdf',
            loanAgreementPDFTitle: 'Vollständig bezahlte Master-Wertpapierleihevereinbarung',
            fplAccountControlAgreementText: (): ShadedTextList => [
                { text: 'Mit dem Ankreuzen dieses Kästchens erklärt sich der Kreditgeber mit den nachstehenden Bedingungen einverstanden:' },
                { text: 'Mitteilung über die ausschließliche Kontrolle', shade: 'dark', lineBreak: true },
                { text: 'Der gesicherte Gläubiger teilt hiermit dem Verwahrer mit, dass er die ausschließliche Kontrolle über die Sicherheiten ' },
                { text: '(wie im ACA definiert) ausübt; (ii) weist den Verwahrer an, aufhören, den Anweisungen bezüglich der Sicherheiten zu ' },
                { text: 'zu folgen, die vom Kunden stammen; und (iii) erklärt und gewährleistet gegenüber dem Verwahrer, dass diese Mitteilung ' },
                {
                    text: 'über die ausschließliche Kontrolle gesetzlich und gemäß der einschlägigen Vereinbarung zwischen Kunde und gesichertem Gläubiger autorisiert ist. '
                },
                { text: 'Der Verwahrer hat keine Pflicht, Verpflichtung oder Autorität zu bestimmen, ' },
                { text: 'ob die Lieferung dieser Mitteilung über die ausschließliche Kontrolle (oder deren Bedingungen) ordnungsgemäß ist, selbst wenn der Kunde ' },
                { text: 'Einwände erhebt oder den Verwahrer anweist, diese Mitteilung über die ausschließliche Kontrolle nicht zu beachten.' }
            ],
            fplAccountControlAgreementPDFUrl: 'https://snex1storage.blob.core.windows.net/$web/docs/FPL/SNEX_FPL%20Account%20Control%20Agreement%2020231227X.pdf',
            fplAccountControlAgreementPDFTitle: 'Vollständig bezahlte Kontrollevereinbarung für Wertpapierleihekonten',
            axosAcknowledgementText:
                'Sie erhalten in ein paar Tagen eine E-Mail von Axos, um die Anwendung über DocuSign abzuschließen. Klicken Sie dieses Kästchen an, um anzuerkennen, dass Ihre Kontobestätigung ohne Abschluss der Axos-Anwendung, die an Ihre E-Mail-Adresse gesendet wird, nicht verarbeitet werden kann.'
        },
        disclosures: {
            title: 'Wichtige Offenlegungen für Ihr Konto',
            requiredClick:
                'Es ist erforderlich, dass Sie alle Inhalte auf dieser Seite und das erforderliche Offenlegungsdokument unten sorgfältig überprüfen, indem Sie auf den Dokumententitel klicken, um auf dessen Inhalt zuzugreifen. Bitte lesen Sie alle Dokumente sorgfältig durch, bevor Sie den Abschnitt Offenlegungen abschließen.',
            subtitle: 'Es ist erforderlich, dass Sie alle Inhalte auf dieser Seite sorgfältig überprüfen, bevor Sie sie absenden',
            scrollTitle: 'Wichtige Merkmale und Risiken',
            scrollSubtitle: 'StoneX Vollständig bezahltes Wertpapierleihprogramm',
            importantCharAndRiskAgreementPDFUrl:
                'https://snex1storage.blob.core.windows.net/$web/docs/FPL/SNEX_FPL%20Important%20Characteristics%20and%20Risks%20202401117X.pdf',
            importantCharAndRiskAgreement: 'Vollständig bezahlte Wertpapierleihe Wichtige Merkmale und Risiken Vereinbarung',
            importantCharAndRiskAgreementText: (): ShadedTextList => [
                {
                    text: 'Mit dem Ankreuzen dieses Kästchens bestätigt der Kreditgeber hiermit den Erhalt des Offenlegungsdokuments von StoneX Financial Inc. mit dem Titel'
                },
                { text: '"VOLLSTÄNDIG BEZAHLTES WERTPAPIERLEIHEPROGRAMM, WICHTIGE MERKMALE UND RISIKEN"' }
            ]
        },
        submitting: {
            title: 'Einreichung Ihrer Anwendung zur Genehmigung',
            completedTitle: 'Anwendung eingereicht',
            loadingMessage: (): ShadedTextList => [
                { text: 'Bitte warten Sie, während wir Ihre Anfrage zur Teilnahme an der vollständig bezahlten Wertpapierleihe einreichen.' }
            ],
            successMessage: (): ShadedTextList => [
                { text: 'Anfrage erfolgreich eingereicht.', shade: 'dark', lineBreak: true },
                { text: 'Geschätzte Wartezeit für die Genehmigung beträgt 2 Werktage', shade: 'light' }
            ],
            axosMessage: 'Halten Sie Ausschau nach einer E-Mail von Axos',
            errorMessage: 'Beim Einreichen Ihrer Anfrage ist ein Fehler aufgetreten.'
        }
    },
    loggedOutScreen: {
        title: 'Sie wurden ausgeloggt',
        body: 'Sie können dieses Browserfenster schließen oder wenn Sie versehentlich ausgeloggt wurden, können Sie unten auf die Schaltfläche klicken, um sich erneut anzumelden.'
    },
    mobileFplOnboarding: {
        apply: {
            title: 'Bewerben Sie sich um vollständig bezahltes Wertpapierleihe',
            account: 'Konto',
            selectAccount: 'Konto auswählen',
            incomeGenerationTitle: 'Einkommensgenerierung',
            incomeGenerationDesc: 'Generieren Sie zusätzliches Einkommen aus Ihrem bestehenden Aktienportfolio, ohne Ihre Vermögenswerte zu verkaufen.',
            ownershipRetentionTitle: 'Eigentumsretention',
            ownershipRetentionDesc: 'Behalten Sie das Eigentum und das potenzielle Kapitalwachstum Ihrer Aktien bei, während Sie Zinserträge erzielen.',
            riskAdjustedReturnsTitle: 'Risikobereinigte Renditen',
            riskAdjustedReturnsDesc: 'Verbessern Sie die Renditen des Portfolios mit minimalem Risiko und ohne zusätzliche Investitionen.',
            votingTitle: 'Abstimmung',
            votingDesc: 'Sie können nicht abstimmen, während Aktien verliehen sind.',
            eligibilityTitle: 'Berechtigung',
            eligibilityDesc: 'Sie benötigen mindestens 50.000 USD Eigenkapital in einem Margin-Konto, um teilnahmeberechtigt zu sein.'
        },
        disclosures: {
            title: 'Vereinbarungen',
            masterSecuritiesLoan: 'Vollständig bezahlte Master-Wertpapierleihevereinbarung',
            lendingAccountControl: 'Vollständig bezahlte Kontrollevereinbarung für Wertpapierleihekonten',
            lendingImportantCharacteristicsAndRisks: 'Vollständig bezahlte Wertpapierleihe Wichtige Merkmale und Risiken Vereinbarung',
            reviewAccept: 'Überprüfen und akzeptieren Sie unsere Offenlegungen und Vereinbarungen',
            reviewDisclosures: 'Offenlegungen überprüfen',
            disclosuresAccepted: 'Offenlegungen akzeptiert!',
            acceptContinue: 'Akzeptieren und fortfahren',
            pressFinishToCreate: 'Drücken Sie auf Fertig stellen, um Ihr Konto zu erstellen',
            finish: 'Fertig',
            missingData: 'Es scheint, als ob noch einige Daten fehlen',
            finishLater: 'Später abschließen'
        },
        flowStage: {
            submittedTitle: 'Ihre Anwendung zur Genehmigung eingereicht',
            submittedSubtitle: 'Geschätzte 2 Werktage für die Genehmigung. Halten Sie Ausschau nach einer E-Mail von Axos!',
            loadingTitle: 'Bitte warten Sie, während wir Ihr FPL-Konto vorbereiten',
            failedTitle: 'Es sieht so aus, als ob wir Ihr Konto zur Genehmigung nicht einreichen konnten'
        },
        acknowledgements: {
            title: 'Anerkennungen',
            message:
                'Sie erhalten in ein paar Tagen eine E-Mail von Axos, um die Anwendung über DocuSign abzuschließen. Akzeptieren Sie dies, um anzuerkennen, dass die Kontobestätigung nicht abgeschlossen werden kann, wenn Sie diesen letzten Schritt nicht abschließen.',
            accept: 'Anerkennung akzeptieren'
        }
    },
    mobileNavBar: {
        home: 'Startseite',
        cash: 'Bargeld',
        trade: 'Handel',
        orders: 'Aufträge'
    },
    oneProNetwork: {
        discoverMore: 'Entdecke mehr',
        insights: 'einsichten',
        latestPosts: 'Neueste Beiträge'
    }
};

export const Snex1GermanTsxStub = null;
