// @ts-strict-ignore
import Axios from 'axios'
import { SnexAxios } from 'phoenix/stores/AxiosHelpers'
import { Dispatch } from 'redux'
import { T } from '../../assets/lang/T'
import { GetConfig, resolveGainLsAppId, StorageKeys, Urls } from '../../constants'
import { UserFeatures } from '../../constants/UserFeatures'
import { ApiError } from '../../models'
import { ThemeVariant } from '../../theming/ThemeVariants'
import { GetEnableDebugLogging, GetNoFeatures, GetSimulateNotFunded, GetSimulateOptionsTrading, LowercaseObjectKeys } from '../../util'
import { Jwt, JwtHasExpired } from '../../util/Jwt'
import { Lightstreamer } from '../../util/LightstreamerClientManager'
import { GetMemo, SetMemo } from '../../util/Memo'
import { GlobalState } from '../GlobalState'
import { MyInfo } from '../models'
import { MyPrefs } from '../models/Users/MyPrefs'
import { MyToken } from '../models/Users/MyToken'
import { UserFeature } from '../models/Users/UserFeature'
import { WebTosAcceptanceResponse } from '../models/Users/WebTosAcceptanceResponse'
import { Actions } from './Constants'
import { ReduxApiDelete, ReduxApiGet, ReduxApiPut } from './Helpers'
import { ReduxPollingStart, ReduxPollingStop } from './PollingHelpers'

export const GetMyUserInfoAction = (bypassUseStored?: boolean) => ReduxApiGet(Urls.users.myInfo(), Actions.Users.GetMyInfo)
    .useStored(s => bypassUseStored ? false : (s.user.myInfo.loading || s.user.myInfo.data), () => 'myInfo', 10)
    .withLoading()
    .onSuccess((res: MyInfo): MyInfo => {
        const newState = {
            ...res,
            authenticated: true,
            hasFunded: GetSimulateNotFunded() ? false : res.hasFunded
        }
        return newState
    })
    .onError(_ => ({ authenticated: false }))
    .withMutex()
    .run()

export const GetUserHasAcceptedWebTos = () => ReduxApiGet(Urls.users.getHasAcceptedWebTos(), Actions.Users.GetHasAcceptedWebTos)
    .withMutex()
    .onSuccess((res: WebTosAcceptanceResponse) => res)
    .run()

export const UpdateWebTosAcceptance = (termsId: string) => ReduxApiPut(Urls.users.reportHasAcceptedWebTos(termsId), Actions.Users.ReportHasAcceptedWebTos)
    .withMutex().withLoading().run()

export const UpdateMyUserInfoAction = (name: string) => ReduxApiPut(Urls.users.myInfo(), Actions.Users.UpdateMyInfo, { name })
    .withMutex().withLoading().run()

export const UpdateCurrentUserDockSidePanelAction = (dockSidePanel) => ReduxApiPut(Urls.users.myInfo(), Actions.Users.UpdateMyInfo, { dockSidePanel })
.withMutex().run()

export const UpdateMyUserPhoneNumberAction = (number: string) => ReduxApiPut(Urls.users.myInfo(), Actions.Users.UpdateMyPhone, { notificationPhone: number })
    .withMutex().showToasts().withLoading().run()

export const VerifyMyUserPhoneNumberAction = (pin: string) => ReduxApiPut(Urls.messages.verifyPhone(pin), Actions.Messages.VerifyPhone)
    .withMutex().showToasts().withLoading().onSuccess((r, dispatch) => {
    // @ts-ignore
        dispatch(GetMyUserInfoAction(true))
        return r
    }).run()

export const UpdateMyUserImageAction = (file: FileList) => {
    return async (dispatch: Dispatch) => {
        dispatch({ type: Actions.Users.UpdateMyImage.Loading })
        try {
            const formData = new FormData()
            formData.append('image', file.item(0))
            // @ts-ignore
            const token: MyToken = await dispatch(GetUserTokenAction())
            const  data = await SnexAxios.ApiPut(Urls.users.updateMyImage(), {data: formData }).run()
            dispatch({ type: Actions.Users.UpdateMyImage.Success, data })
            return data
        } catch (e) {
            dispatch({ type: Actions.Users.UpdateMyImage.Failure, error: e })
            dispatch({
                type: Actions.Errors.ReportApiError,
                error: <ApiError>{
                    errorCode: 'ERROR',
                    errorMessage: T(s => s.errors.imageUploadError)
                },
                errorDisplay: 'toast'
            })
            return { ...e, failed: true }
        }
    }
}

export const DeleteMyUserImageAction = () => ReduxApiDelete(Urls.users.updateMyImage(), Actions.Users.DeleteMyImage).showToasts().run()

export const GetUserTokenAction = () => {
    const config = GetConfig()

    // This method only really works on web, since there are no cookies on mobile
    const fromStore = config.Store.getState().user.myToken?.data?.accessToken
    const fromWindow = GetMemo(StorageKeys.JwtMemo)
    const effective = fromWindow || fromStore

    return ReduxApiGet(Urls.users.getMyToken(), Actions.Users.GetMyToken, {
        Authorization: `Bearer ${effective}`
    })
        .withMutex()
        .useStored((s: GlobalState) => {
            if (JwtHasExpired(s.user.myToken.data?.accessToken)) {
                if (GetEnableDebugLogging()) console.warn('Token has expired! Discarding')
                return null
            } else return s.user.myToken.data
        }, () => 'user-token', 120)
        .withoutToken()
        .onSuccess((result: MyToken) => {
            const normal = LowercaseObjectKeys(result) as MyToken
            const token = normal.accessToken
            const gainLsAppId = resolveGainLsAppId()
            if (GetEnableDebugLogging()) console.info('Setting access token to', token)
            SetMemo(StorageKeys.JwtMemo, token, 'GetUserToken redux action, .onSuccess handler')
            Lightstreamer.SetUser('snex', token, '')
            Lightstreamer.SetUser('gain', `${Jwt.TryGetSecureAuthCdsId(token)}:${gainLsAppId}`, token)
            return normal
        })
        .onError((e: any, dispatch: any) => e)
        .run()
}

export const StartTokenPollingAction = (frequencySeconds = 120) => ReduxPollingStart('token', GetUserTokenAction, frequencySeconds)
export const StopTokenPollingAction = () => ReduxPollingStop('token')

export const GetUserInfoAction = (userId: string, bypassStore?: boolean) => ReduxApiGet(Urls.users.getUserInfo(userId), Actions.Users.GetUserInfo)
    .withMutex(() => `usr:${userId}`).useStored(s => bypassStore ? undefined : s.user.byId[userId]?.info.data).withLoading().withSubject(userId).run()

export const GetCurrentUserPrefsAction = () => ReduxApiGet(Urls.users.getMyPrefs(), Actions.Users.GetMyPrefs).withMutex()
    .run()

export const UpdateCurrentUserPrefsAction = (prefs: MyPrefs) => ReduxApiPut('', Actions.Users.UpdateMyPrefs, prefs.preference)
    .withSubject(prefs).withLoading().withBatching('prefs', _ => Urls.users.updateMyPrefs(), 1000).showToasts().run()

export const UpdateCurrentUserOneClickTradingAction = (oneClickTrading: boolean) => ReduxApiPut(Urls.users.updateMyOneClickTrading(), Actions.Users.UpdateMyOneClickTrading, { oneClickTrading })
    .withSubject(oneClickTrading).run()

 export const UpdateCurrentUserTradingViewTickerAction = (showTradingViewTicker: boolean) => ReduxApiPut(Urls.users.updateMyTradingViewTicker(), Actions.Users.UpdateMyTradingViewTicker, { showTradingViewTicker })
    .withSubject(showTradingViewTicker).run()

export const UpdateCurrentUserThemeAction = (theme: ThemeVariant) => ReduxApiPut(Urls.users.updateMyTheme(), Actions.Users.UpdateMyTheme, { theme })
    .withSubject(theme).withLoading().run()

export const UpdateCurrentUserFuturesStrikesAction = (strikes?: number) => ReduxApiPut(Urls.users.updateFuturesStrikes(), Actions.Users.UpdateFuturesStrikes, { strikes })
    .withSubject(strikes).withLoading().run()

export const UpdateCurrentUserStrikesAction = (strikes?: number) => ReduxApiPut(Urls.users.updateStrikes(), Actions.Users.UpdateStrikes, { strikes })
    .withSubject(strikes).withLoading().run()

export const GetCurrentUserTaxInfoAction = () => ReduxApiGet(Urls.users.getMyTaxInfo(), Actions.Users.GetMyTaxInfo)
    .withMutex().showToasts().onSuccess((res: any) => ({ ...res, dateOfBirth: new Date(res.dateOfBirth) }))
    .run()

export const UpdateCurrentUserTaxInfoAction = (taxInfo: { taxType: string, taxId: string, dateOfBirth: Date }) => ReduxApiPut(
    Urls.users.updateMyTaxInfo(), Actions.Users.UpdateMyTaxInfo, taxInfo
).showToasts().withLoading().onSuccess(() => ({ ...taxInfo })).run()

export const HasBeenWelcomedAction = () => ReduxApiPut(Urls.users.reportHasBeenWelcomed(), Actions.Users.UpdateMyProgress)
    .withMutex().withLoading().run()

export const AcceptMobileTosAction = () => ReduxApiPut(Urls.users.reportHasAcceptedMobileTos(), Actions.Users.UpdateMyProgress)
    .withMutex().withLoading().run()

/** @deprecated */
export const ReportProgressAction = (progressNames: string) => ReduxApiPut(Urls.users.updateMyProgress(progressNames), Actions.Users.UpdateMyProgress)
    .withSubject(progressNames).withLoading().run()

/** @deprecated */
export const ResetProgressAction = (progressNames?: string[]) => ReduxApiDelete(Urls.users.resetMyProgress(progressNames), Actions.Users.ResetMyProgress)
    .withSubject(progressNames).withLoading().showToasts().run()

export const GetCurrentUserFeaturesAction = () => ReduxApiGet(Urls.users.getMyFeatures(), Actions.Users.GetMyFeatures)
    .withMutex().useStored(s => s.user.myFeatures?.data?.length ? s.user.myFeatures?.data : null)
    .onSuccess((r) => {
        if (GetNoFeatures()) return []
        else if (GetSimulateOptionsTrading()) {
            return <UserFeature[]>[
                {
                    key: UserFeatures.OptionTrading,
                    description: 'Injected option trading feature',
                    name: 'Option Trading',
                    parameters: []
                },
                {
                    key: UserFeatures.BasicTrading,
                    description: 'Injected basic trading feature',
                    name: 'Basic Trading',
                    parameters: []
                }
            ]
        }
        return r
    })
    .run()

// Note: not putting this into the reducer since you really should use the OTT as soon as you get it
export const GetOneTimeTokenAction = () => ReduxApiGet(Urls.users.getOneTimeToken(), Actions.Users.GetOneTimeToken).run()
