// @ts-strict-ignore
import { Typography, Checkbox } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Flex } from '..';
import { StorageKeys } from 'phoenix/constants';
import { useAppWindowSize, WindowSizes } from 'hooks/UseWindowSize';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { useText } from 'phoenix/hooks/UseText';
import { CandleStickButton } from '../SecurityChartSection/CandleStickButton';
import { LineChartButton } from '../SecurityChartSection/LineChartButton';
import { SnexButton } from '../SnexButton/SnexButton';

export type ChartDisplayType = 'line' | 'candle'
interface CandleLineToggleControlsProps {
    onDisplayTypeChange: (newType: ChartDisplayType) => void,
    onToggleVolume?: (newValue: boolean) => void,
    allowVolumeToggle?: boolean
}

export const CandleLineToggleControls = React.memo((props: CandleLineToggleControlsProps) => {

    const { onDisplayTypeChange, onToggleVolume, allowVolumeToggle } = props;
    const [ appWindowSize, setAppWindowSize ] = useAppWindowSize();
    const text = useText(s => s.misc);
    const [ displayType, setDisplayType ] = useLocalStorage<ChartDisplayType>(StorageKeys.BigChartDisplayType, 'line');
    const [ showVolume, setShowVolume ] = useLocalStorage<boolean>(StorageKeys.BigChartShowVolume, true);

    useEffect(() => onDisplayTypeChange(displayType), [ displayType ]);
    useEffect(() => onToggleVolume(showVolume), [ showVolume ]);

    const nextDisplayType = displayType === 'candle' ? 'line' : 'candle'

    const size = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.tablet: return 17;
            default: return 25;
        }
    }, [ appWindowSize ])

    const paddingLeft = useMemo(() => {
        switch (appWindowSize) {
            case WindowSizes.tablet: return 10;
            default: return 15;
        }
    }, [ appWindowSize ])

    return (
        <Flex row id='tour-01-candle-chart-button'>
            <div style={{ padding: 3, marginTop: 3, marginLeft: 5, paddingLeft, borderLeft: 'solid 1px #e3e3e3' }}>
                <SnexButton eventTag={`Chart type switched to ${nextDisplayType}`} flavor='secondary-transparent'
                    style={{ minWidth: 0, padding: '2px 6px', margin: 0 }}
                    onClick={ () => setDisplayType(nextDisplayType) }>
                    { displayType === 'candle' ? <LineChartButton height={size} /> : <CandleStickButton height={size} /> }
                </SnexButton>
            </div>
            { displayType === 'candle' && allowVolumeToggle 
            && <Flex
                align='center'
                style={{ marginLeft: 15, padding: 3, marginTop: 7 }}
                onClick={() => setShowVolume(!showVolume)}>
                <Typography variant='subtitle2'>{ text.showVolume }</Typography>
                <Checkbox checked={ showVolume } size='small' style={{ padding: 0, marginLeft: 5 }} />
            </Flex>}
        </Flex>
    )

})