// @ts-strict-ignore
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol'
import { ReduxAction } from '../../../models'
import { EstimateFiscalQuarter, RemapObjectValues } from '../../../util'
import { DescribeObject } from '../../../util/DebugHelpers'
import { Actions } from '../../actions'
import { GlobalState } from '../../GlobalState'
import { SecurityCompanyInfo } from '../../models'
import { SecurityScreenBundle, SecurityScreenFutureBundle, SecurityScreenPartTwoBundle } from '../../models/MobileScreens/SecurityScreenBundle'
import { asData, orNew } from '../ReducerUpdateHelpers'
import { MobileStateUpdates } from './MobileStateUpdates'
import { usePositionsStore } from 'phoenix/stores/PositionsStore'

export const SecurityScreenReducer = (state: GlobalState = new GlobalState(), action: ReduxAction): GlobalState => {
    switch (action.type) {
        case Actions.MobileScreens.LoadSecurityScreen.Success: return SecurityScreenPartOneReducer(state, action)
        case Actions.MobileScreens.LoadSecurityScreenPartTwo.Success: return SecurityScreenPartTwoReducer(state, action)
        case Actions.MobileScreens.LoadSecurityScreenForFuture.Success: return SecurityScreenFutureReducer(state, action)
        default: return state
    }
}

const SecurityScreenPartOneReducer = (state: GlobalState = new GlobalState(), action: ReduxAction): GlobalState => {
    const bundle = action.data as SecurityScreenBundle
    const qsi = bundle.qsi

    state.mobileScreenLoading.securityScreen = state.mobileScreenLoading.securityScreen.succeeded()

    bundle.quotesAndCharts = bundle.quotesAndCharts.map(qc => ({ ...qc, charts: { ...qc.charts, loRes: qc.charts?.['1d'] || [] } }))

    MobileStateUpdates.addLogos(state, bundle.logos)
    MobileStateUpdates.addQuotesAndCharts(state, bundle.quotesAndCharts)
    MobileStateUpdates.addMetadata(state, bundle.metadata)

    state.securities.bySymbol[qsi] = {
        ...(state.securities.bySymbol[qsi] || {}),
        companyInfo: orNew<SecurityCompanyInfo>(state.securities.bySymbol[qsi]?.companyInfo).succeeded(bundle.info)
    }

    if (bundle.equityKeyStats) state.securities.bySymbol[qsi].companyKeyStats = asData(bundle.equityKeyStats)
    if (bundle.fundProfile) state.funds.byQsi[qsi] = { ...(state.funds.byQsi[qsi] || {}), profile: asData(bundle.fundProfile) }
    if (bundle.optionContractData) state.securities.riskFreeRate = asData(bundle.optionContractData.riskFreeRate)
    if (bundle.cryptoStats) state.securities.bySymbol[qsi].cryptoStats = asData(bundle.cryptoStats)

    return { 
        ...state,
        securities: {
            ...state.securities
        }
    }
}

const SecurityScreenPartTwoReducer = (state: GlobalState = new GlobalState(), action: ReduxAction): GlobalState => {
    const bundle = action.data as SecurityScreenPartTwoBundle
    const qsi = bundle.qsi

    MobileStateUpdates.addQuotesAndCharts(state, bundle.quotesAndCharts)
    MobileStateUpdates.addMetadata(state, bundle.metadata)
    MobileStateUpdates.addLogos(state, bundle.logos)

    state.securities.bySymbol[qsi] = {
        ...(state.securities.bySymbol[qsi] || {}),
        relatedSymbols: orNew<string[]>(state.securities.bySymbol[qsi]?.relatedSymbols).succeeded(bundle.related)
    }

    state.news.bySymbol[qsi] = asData(bundle.news)

    if (bundle.equityData) {
        state.securities.bySymbol[qsi] = {
            ...state.securities.bySymbol[qsi],
            companyKeyStats: asData(bundle.equityData.keyStats),
            analystTrends: asData(bundle.equityData.ratings),
            earnings: asData(bundle.equityData.earningsHistory),
            earningsEstimates: asData({
                ...bundle.equityData.earningsEstimates,
                data: bundle.equityData.earningsEstimates.data.map(x => ({ ...x, ...EstimateFiscalQuarter(x.period) }))
            })
        }
    }

    if (bundle.fundData) {
        state.funds.byQsi[qsi] = {
            ...state.funds.byQsi[qsi],
            profile: asData(bundle.fundData.profile),
            countryExposure: asData(bundle.fundData.countryExposure),
            sectorExposure: asData(bundle.fundData.sectorExposure),
            holdings: asData(bundle.fundData.holdings)
        }

        state.securities.bySymbol[qsi] = {
            ...state.securities.bySymbol[qsi],
            companyKeyStats: asData(bundle.fundData.keyStats)
        }

        MobileStateUpdates.addLogos(state, bundle.fundData.extraLogos)
    }

    // Futures will disclose news and related symbols for the base symbol; leaf them in now
    // Important note: if we ever encounter more date-contracted securities like futures, this code should be generalized
    if (FuturesSymbol.IsFuturesSymbol(qsi)) {

        const base = new FuturesSymbol(qsi).baseContract;
        state.news.bySymbol[base] = asData(bundle.news)
        state.securities.bySymbol[base] = {
            ...state.securities.bySymbol[base],
            relatedSymbols: asData(bundle.related)
        }

    }

    return { 
        ...state,
        securities: {
            ...state.securities
        }
    }
}

const SecurityScreenFutureReducer = (state: GlobalState = new GlobalState(), action: ReduxAction): GlobalState => {
    const bundle = action.data as SecurityScreenFutureBundle

    MobileStateUpdates.addQuotesAndCharts(state, bundle.quoteAndChart)
    MobileStateUpdates.addMetadata(state, bundle.metadata)

    return {...state}
}
