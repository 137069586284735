// @ts-strict-ignore
import { Order, OrderType } from '../redux/models'

/** @deprecated */
export const DeterminOrderType = (order: Order): OrderType => DetermineOrderType(order)

export const DetermineOrderType = (order: Order): OrderType => {
    switch (true) {
        case !!order.orderType: return order.orderType
        case !!order.limitPrice && !!order.stopPrice: return 'stoplimit'
        case !!order.limitPrice && !order.stopPrice: return 'limit'
        case !order.limitPrice && !!order.stopPrice: return 'stop'
        default: return 'market'
    }
}

/** @deprecated */
export const FormattedOrderTypeLabel = (order: Order) => DeterminOrderType(order)
