// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T";
import { GetConfig } from "phoenix/constants";
import { PlaidLinkOptions } from "phoenix/models/Plaid/PlaidHandler";
import { PlaidMetadata } from "phoenix/models/Plaid/PlaidMetadata";
import { GetUniqueTicketNumber } from "./Utils";
import { ErrorLogger } from "phoenix/util/ErrorLogger";
import { PlaidLinkError, PlaidLinkOnEventMetadata, PlaidLinkOnExitMetadata, PlaidLinkStableEvent } from "react-plaid-link";
import { usePlaidStore } from "phoenix/stores/PlaidStore";

const _plaidDestructors = {}

export type PlaidEnvironment = 'sandbox' | 'production'
export const DoPlaid = async (mode: 'create' | 'update', receivedRedirectUri?: string, fundingSourceId?: string, env?: PlaidEnvironment): Promise<{ success: boolean, token: string, error: any, metadata: PlaidMetadata }> => {

    try
    {
        const config = GetConfig();
        const ticket = GetUniqueTicketNumber('plaid-create');

        let successResolve;
        const successPromise = new Promise<{ success: boolean, token: string, error: any, metadata: PlaidMetadata }>((resolve) => { successResolve = resolve })

        const getLinkToken = async (fundingSourceId?: string) =>  {
            const token = await usePlaidStore.getState().getToken(mode, fundingSourceId, env);
            localStorage.setItem('linkToken', JSON.stringify(token?.linkToken))
            return token;
        }


        const { linkToken }: { linkToken: string } =  receivedRedirectUri && localStorage.getItem('linkToken')? JSON.parse(localStorage.getItem('linkToken')) : await getLinkToken(fundingSourceId);   

        const options: PlaidLinkOptions = {
            token: linkToken,
            env: env || config.Plaid.Environment,
            language: T(s => s.general.localeShort),
            onSuccess: (token: string, metadata: PlaidMetadata) => successResolve({ success: true, token, metadata }),

            // https://plaid.com/docs/link/web/#onevent
            onEvent: (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => {
                if(!!metadata.error_code || !!metadata.error_message || !! metadata.error_type) {
                    const plaidError = {eventName, ...metadata}
                    console.log({plaidError})
                    ErrorLogger.RecordError({name: `${plaidError.error_code}|${plaidError.error_type}`, message: plaidError.error_message}, 'AddPlaidSource')
                } else {
                    const plaidEvent = {eventName, ...metadata}
                    console.log({plaidEvent})
                }
            },

            // https://plaid.com/docs/link/web/#onexit
            onExit: (error: PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => {

                if(error) {
                    const plaidError = {error, ...metadata}
                    console.log({plaidError})
                    ErrorLogger.RecordError({name: `${plaidError.error.error_code}|${plaidError.error.error_type}`, message: plaidError.error.display_message}, 'PlaidExit')
                } else {
                    const plaidEvent = {...metadata}
                    console.log({plaidEvent})
                }

                if (_plaidDestructors[ticket]) {
                    _plaidDestructors[ticket]()
                    delete _plaidDestructors[ticket]
                }
                successResolve({ success: false, token: null, error, metadata })
            },
        }

        // used in oAuth flow
        if(receivedRedirectUri) {
            options.receivedRedirectUri = receivedRedirectUri
        }

        if (mode === 'create') options.product = config.Plaid.Products;

        const { open, destroy } = config.Plaid.Handler.create(options)

        _plaidDestructors[ticket] = destroy;
       open(); 
        
        return successPromise;
    }
    catch(e)
    {
        console.log({plaidError: e})
        ErrorLogger.RecordError(e, 'DoPlaid')
        throw e
    }

   
}