// @ts-strict-ignore
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { AssetTradeability } from 'phoenix/constants/AssetTradeability';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { CalculatedAccountValuation } from 'phoenix/hooks/UseAccountValuation';
import { GetFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';
import { AssetClass, StandardPriceToQuantityFormula, StandardQuantityToPriceFormula } from 'phoenix/models/AssetClasses/AssetClass';
import { Account, AccountSummary, MyInfo, OptionSymbol } from 'phoenix/redux/models';
import { AggregateBuyingPower } from 'phoenix/redux/models/AccountSummary/AccountBuyingPower';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { FormatNumber } from 'phoenix/util';
import { Ts } from 'phoenix/assets/lang/T';
import { ChartRanges } from 'phoenix/constants';
import { OrderTypes } from 'phoenix/constants/Trade';
import { QualifiedId } from 'phoenix/util/QualifiedId';

export const FuturesAssetClass: AssetClass = {
    // Typing
    type: 'future',
    family: 'futures',
    derivative: 'base',
    derivatives: ['option', 'time-spread'],
    algoliaTypes: ['future'],

    // Trading + Tradeability
    tradeability: AssetTradeability?.future,
    accountCanTradeThis: (account: Account) => FuturesSymbol.IsFuturesSymbol(account.accountNumber),
    userAllowedToTradeThis: () => GetFeatureFlag(FeatureFlags.FuturesTrading),
    defaultOrderQuantity: 1,
    defaultOrderType: () => OrderTypes.limit,
    /** @deprecated Please use defaultTradePrice */
    defaultLimitPriceToLatestPrice: true,
    defaultTradePrice: {
        market: 'bid-ask',
        limit: 'bid-ask',
        stop: 'bid-ask',
        stoplimit: 'bid-ask'
    },
    negativePriceAllowed: false,

    // Features
    flags: {
        mobile: { trade: FeatureFlags.FuturesTrading },
        web: { trade: FeatureFlags.FuturesTrading }
    },
    hasDeepAnalysis: false,
    canCreateAlerts: false,
    hasOptions: true,
    hasMultiLeg: false,
    optionsType: 'option', // TODO -- make a futures-options type for this
    canWatchlist: true,
    tradePriceShortcutButtonsVariant: 'tick',
    hasDerivatives: (() => FuturesAssetClass?.derivatives.length > 1),

    // Charting
    neverShowChartPulse: true, // TODO -- is this right?
    hasAdvancedChart: true,
    advancedChartConfiguration: {
        supported_resolutions: ['1', '10', '30', '1D', '1W']
    },
    chartVariants: ['line', 'candles', 'dom'],
    chartRanges: () => {
        const text = Ts((s) => s.misc.ranges);

        return [
            { label: text.oneDay, value: ChartRanges.oneDay },
            { label: text.fiveDay, value: ChartRanges.fiveDays },
            { label: text.oneMonth, value: ChartRanges.oneMonth },
            { label: text.threeMonths, value: ChartRanges.threeMonths },
            { label: text.oneYear, value: ChartRanges.oneYear },
            { label: text.fiveYear, value: ChartRanges.fiveYears }
        ];
    },
    accountChartRanges: ['24h', '5d', '1m', '3m', '1y', '5y'],
    securityChartRanges: ['1d', '5d', '1m', '3m', '1y', '5y'],

    // Formatting + Codec
    getPriceFormatInfo: (meta: SecurityMetadata) => {
        const tickSize = meta?.tickSize || 0.0001;
        const places = meta?.maxDecimalPlaces;
        return {
            decimalPlaces: places,
            tickSize,
            moneyFormatOptions: {
                decimalPlaces: places,
                fractionalGranularity: meta?.fractionalGranularity,
                fractionalParts: meta?.fractionalParts,
                hideCurrencySymbol: true,
                maxDecimalPlaces: places,
                min: 0,
                minDecimalPlaces: places,
                showDecimals: true,
                tickSize,
                useFractional: meta?.isFractional
            }
        };
    },


    formatOrderQuantity: (o) => {
        const { showDecimals = true, decimalPlaces = 2 } = o?.quantityQualifier === 'Shares' ? { showDecimals: true, decimalPlaces: 8 } : {};
        let qty = FormatNumber.removeDecimalsIfZero(FormatNumber.toCommas(o.orderQuantity, showDecimals, decimalPlaces))

        if (o.quantityQualifier === 'EvenDollar') {
            qty = `${FormatNumber.toMoneyOpt2(o?.cost)}`;
        }

        return qty
    },

    formatPrice: (price: number, meta: SecurityMetadata) => FormatNumber.toMoneyOpt2(price, FuturesAssetClass?.getPriceFormatInfo(meta).moneyFormatOptions),
    formatQuantity: (quantity: number, meta: SecurityMetadata) => FormatNumber.toCommas(quantity, true, 0),
    unit: 'contract',
    idMatches: (id: string) => FuturesSymbol.IsFuturesSymbol(id) && !OptionSymbol.IsOptionSymbol(id),
    getSymbolName: s => QualifiedId.RemovePrefix(s),
    getSymbolNameLines: s => [QualifiedId.RemovePrefix(s)],
    getBaseSymbol: s => new FuturesSymbol(s).baseContract,
    getConcreteSymbol: s => {
        const fsym = new FuturesSymbol(s)
        return fsym.type === 'concrete' ? fsym.withPrefix : fsym.baseContract
    },

    // Colors
   /** @deprecated Please do not use this value. It does not use theming Use useAssetClassColors hook */
    primaryColor: '#51BBD6',
    positiveChangeColor: 'rgb(2,179,52)',
    negativeChangeColor: 'rgb(178,41,46)',
    showColorHalo: true,

    // Pricing
    getQuantityPrice: StandardQuantityToPriceFormula,
    getPriceForQuantity: StandardQuantityToPriceFormula,
    getQuantityForPrice: StandardPriceToQuantityFormula,
    hideTradeImpact: true,
    getTodaysEarningsLabel: (l) => l.positionsScreen.todaysGainLoss,
    getTotalEarningsLabel: (l) => l.positionsScreen.openPnL,

    // Account Data
    getBuyingPowerFigures: (power: AggregateBuyingPower, lang: Snex1LanguagePack) => [
        { id: 'cash', label: lang.portfolioScreen.buyingPower.cashAvailable, value: FormatNumber.toMoneyOpt2(power?.futuresCashAvailable) },
        { id: 'margin', label: lang.portfolioScreen.buyingPower.initialMargin, value: FormatNumber.toMoneyOpt2(power?.futuresInitialMargin) },
        { id: 'netliq', label: lang.portfolioScreen.buyingPower.netLiquidity, value: FormatNumber.toMoneyOpt2(power?.futuresNetLiquidity) }
    ],

    getAccountSummaryFigures: (summary: AccountSummary, lang: Snex1LanguagePack) => [
        { id: 'unrealizedPnl', label: 'Unrealized P&L', value: FormatNumber.toMoneyOpt2(summary?.unrealizedPnLCurrent), type: 'money' },
        { id: 'realizedPnl', label: 'Realized P&L', value: FormatNumber.toMoneyOpt2(summary?.futuresClosedPnLTopDay) },
        { id: 'totalPnl', label: 'Total P&L', value: FormatNumber.toMoneyOpt2(summary?.futuresTotalPnL), bold: true },
        { id: 'cash', label: 'Cash', value: FormatNumber.toMoneyOpt2(summary?.totalCashCurrent) },
        { id: 'netliq', label: 'Net Liquidation Value', value: FormatNumber.toMoneyOpt2(summary?.futuresNetLiquidationValue) },
        { id: 'bp', label: 'Buying Power', value: FormatNumber.toMoneyOpt2(summary?.futuresReferenceBuyingPower) },
        { id: 'initialMargin', label: 'Initial Margin', value: FormatNumber.toMoneyOpt2(summary?.futuresInitialMargin) },
        { id: 'shortOptions', label: 'Short Option Value', value: FormatNumber.toMoneyOpt2(summary?.futuresShortOptionValue) },
        { id: 'longOptions', label: 'Long Option Value', value: FormatNumber.toMoneyOpt2(summary?.futuresLongOptionValue) },
        { id: 'netOptions', label: 'Net Option Value', value: FormatNumber.toMoneyOpt2(summary?.futuresNetOptionValue), bold: true }
    ],
    getNumberOfStrikes: (user: MyInfo) => user.strikes,
    getAccountGlanceFigures: (val: CalculatedAccountValuation, lang: Snex1LanguagePack) => {
        const l = lang.portfolioScreen.glance;
        return [
            { id: 'value', label: l.accountValue, value: val?.value },
            { id: 'cash', label: l.cash, value: val?.summary?.totalCashCurrent },
            { id: 'todayPnl', label: l.todaysGainLoss, value: val?.change },
            { id: 'unrealized', label: l.unrealizedGainLoss, value: val?.unrealizedGainLoss },
            { id: 'buying-power', label: l.buyingPower, value: val?.buyingPower?.buyingPower }
        ].map((i) => ({ ...i, value: FormatNumber.toMoneyOpt2(i.value) }));
    },

    // Documents
    documentDeliveryOptions: 'electronic-only',

    showShortPositionIcon: false,
    showStopPriceShortcutButtons: true,
};
