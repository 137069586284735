import { Flex } from 'components';
import React, { MouseEvent, useEffect, useState } from 'react';
import { CulledCollapse } from 'components/CulledCollapse/CulledCollapse';
import { Grid, Tooltip, Typography } from '@mui/material';
import { NumberBlip } from 'components/NumberBlip/NumberBlip';
import { OptionButton } from '../OptionButton';
import { SnexButton } from 'components/SnexButton';
import { ChevronRight, HelpOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { FormatNumber } from 'phoenix/util';
import { useColors } from 'hooks/UseColors';
import { useText } from 'phoenix/hooks/UseText';
import { SnexAxios } from 'phoenix/stores/AxiosHelpers';
import { Urls } from 'phoenix/constants/Urls';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';

// Presentation component for contracts in options chain and time spreads

export type ContractRowTemplate = {
    link: string;
    collapsed: string[];
    expanded: { label: string; value: string }[];
};

interface OptionGreeksResType {
    delta: number;
    gamma: number;
    theta: number;
    vega: number;
    rho: number;
    impliedVolatility: number;
    optionPriceTime: string;
    calculationTime: string;
}

interface OptionGreeksResponse {
    optionGreeks: {
        [symbol: string]: OptionGreeksResType;
    };
}

export type ContractRowProps = {
    symbol?: string;
    disabled?: boolean;
    expanded: boolean;
    onSelect: (e: MouseEvent<HTMLButtonElement>) => void;
    lastPrice: string;
    onExpand: (expanded: boolean) => any;
    priceMovement: 'positive' | 'negative' | 'neutral';
    selected: boolean;
    template: ContractRowTemplate;
    ownedShares: number;
    rowHeader: string; // Strike price for options, contract name for futures time spreads
    viewMoreText: string;
    padFirstColumn?: boolean;
};

const Nugget = (x: { label: string; value: string }) => {
    return (
        <Grid item style={{ marginBottom: 10, height: 55 }} xs={2}>
            <Flex column>
                <Typography variant='h6'>{x.label}</Typography>
                <Typography className='option-column-field right-align' style={{ marginTop: 5, fontSize: 15 }}>
                    {x.value}
                </Typography>
            </Flex>
        </Grid>
    );
};

export default function ContractListRow({
    symbol,
    expanded,
    onSelect,
    lastPrice,
    onExpand,
    ownedShares,
    priceMovement,
    rowHeader,
    selected,
    template,
    viewMoreText,
    padFirstColumn
}: ContractRowProps): JSX.Element {
    const { collapsed: collapsedTemplate, expanded: expandedTemplate, link } = template;
    const classes = [`contract-row-container ${priceMovement}`, expanded ? ' expanded' : '', ...(selected ? ' selected' : '')].join('');
    const formatted = FormatNumber.toCommas(ownedShares);
    const colors = useColors();
    const text = useText((s) => s.optionListScreen);

    const [optionGreeksResponse, setOptionGreeksResponse] = useState<OptionGreeksResponse | null>(null);

    useEffect(() => {
        const fetchOptionGreeks = async () => {
            try {
                if (!symbol) return;
                if (FuturesSymbol.IsFuturesSymbol(symbol)) return;
                const url = Urls.options.getGreeks([symbol]);
                const response: OptionGreeksResponse = await SnexAxios.ApiGet<OptionGreeksResponse>(url).run();
                setOptionGreeksResponse(response);
            } catch (error) {
                console.error('Error fetching option greeks:', error);
            }
        };

        if (expanded && symbol) {
            fetchOptionGreeks();
        }
    }, [expanded, symbol]);

    const greeks = symbol ? optionGreeksResponse?.optionGreeks[symbol] : undefined;
    const toolTipIntro = text.optionGreeksTooltipIntro;
    const toolTipItems = [
        text.optionGreeksTooltipDelta,
        text.optionGreeksTooltipGamma,
        text.optionGreeksTooltipTheta,
        text.optionGreeksTooltipVega,
        text.optionGreeksTooltipRho
    ];

    // TODO handle this better in CSS, quick style fix for a hotfix
    const left = -23 - formatted.length * 3.5;

    return (
        <div className={classes}>
            <div
                className='contract-row'
                role='button'
                onClick={() => {
                    if (template?.expanded?.length) onExpand(!expanded);
                }}
            >
                <Flex row align='center' justify='flex-end'>
                    <Typography
                        className={`option-column-field${padFirstColumn ? ' padded-column-field' : ''}`}
                        style={{ flex: 0, fontWeight: 'bold', position: 'relative' }}
                    >
                        {ownedShares !== 0 && <NumberBlip n={ownedShares} size={18} style={{ marginRight: 5, position: 'absolute', left, top: 2 }} />}
                        {rowHeader}
                    </Typography>
                </Flex>
                {collapsedTemplate.map((x, i) => (
                    <Typography key={i} className={`option-column-field right-align${padFirstColumn ? ' padded-column-field' : ''}`}>
                        {x}
                    </Typography>
                ))}
                <OptionButton alignIcon='right' icon={selected ? 'check' : 'plus'} style={{ minWidth: '118px' }} selected={!!selected} onClick={onSelect}>
                    {lastPrice}
                </OptionButton>
            </div>
            <CulledCollapse in={expanded}>
                <hr />
                <Grid container style={{ padding: '10px 40px', gridAutoColumns: '1fr', gridAutoFlow: 'column' }}>
                    {expandedTemplate.map(({ label, value }, i) => (
                        <Nugget key={i} label={label} value={value} />
                    ))}
                    {greeks && <Nugget label={text.impliedVolatility} value={FormatNumber.toPercent2(greeks.impliedVolatility, 'exceptZero', 3)} />}{' '}
                    <Grid item style={{ flex: 1 }}>
                        <Flex justify='flex-end' align='flex-end' style={{ height: '100%' }}>
                            <Link to={link}>
                                <SnexButton flavor='action-link'>
                                    {viewMoreText} <ChevronRight />
                                </SnexButton>
                            </Link>
                        </Flex>
                    </Grid>
                </Grid>
                {optionGreeksResponse && symbol && (
                    <div>
                        <hr
                            style={{
                                width: '100%',
                                color: colors.inputBorderColor
                            }}
                        />
                        <Grid container style={{ padding: '2px 40px' }}>
                            <Grid item>
                                <Typography variant='h4'>{text.theGreeks}</Typography>
                                <div style={{ marginLeft: '2px', color: 'rgba(150,150,150,0.8)', fontSize: 11 }}>{text.optionGreeksDelayed}</div>
                            </Grid>
                            <Grid item>
                                <div style={{ marginLeft: '2px' }}></div>
                            </Grid>
                            <Tooltip
                                title={
                                    <div style={{ fontSize: '16px', marginTop: '8px' }}>
                                        <p>{toolTipIntro}</p>
                                        <ul>
                                            {toolTipItems.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                            >
                                <HelpOutline style={{ fontSize: 20, color: colors.primaryItemColor, verticalAlign: 'middle' }} />
                            </Tooltip>
                            <Grid item style={{ flex: 1 }} />
                            <Nugget label='Delta Δ' value={greeks?.delta?.toString() || ''} />
                            <Nugget label='Gamma Γ' value={greeks?.gamma?.toString() || ''} />
                            <Nugget label='Theta Θ' value={greeks?.theta?.toString() || ''} />
                            <Nugget label='Vega ν' value={greeks?.vega?.toString() || ''} />
                            <Nugget label='Rho ρ' value={greeks?.rho?.toString() || ''} />
                        </Grid>
                    </div>
                )}
            </CulledCollapse>
        </div>
    );
}
