// @ts-strict-ignore
import { Typography , Skeleton } from '@mui/material';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DonutChart, Flex, SectionHeader } from '..';
import { LinesEllipsis } from '../LinesEllipsis/LinesEllipsis';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { GetFundSectorExposure } from 'phoenix/redux/actions/FundActions';
import { FundSectorExposure } from 'phoenix/redux/models/Funds/FundSectorExposure';
import { FormatNumber, GenerateRandomColor } from 'phoenix/util';
import { SectorExposureLoadingGrid } from './LoadingGrid';
import { useText } from 'phoenix/hooks/UseText';
import { useAppWindowSizeVariable } from 'hooks/UseWindowSize';
import { ErrorBoundary, TestErrorComponent } from 'components/ErrorBoundary/ErrorBoundary';
import { useColors } from 'hooks/UseColors';


interface SectorNuggetData extends FundSectorExposure { color: string }

const SectorNugget = ({ sector, isActive }: { sector: SectorNuggetData, isActive?: boolean }) => {
    return (
        <Flex row style={{ width: '50%', overflow: 'hidden', padding: '15px 5px', boxSizing: 'border-box', opacity: isActive ? 1.0 : 0.5 }}>
            <div style={{ height: 15, width: 15, backgroundColor: sector.color || 'grey', margin: 3, marginRight: 10, borderRadius: 3 }} />
            <Flex align="flex-start" justify="flex-start" column style={{ flex: 1 }}>
                <Typography variant="h6" style={{ width: '100%' }}><LinesEllipsis lines={1}>{sector.sector}</LinesEllipsis></Typography>
                <Typography variant="h6" style={{ fontWeight: 500 }}>{FormatNumber.toPercent(sector.exposure) || '---'}</Typography>
            </Flex>
        </Flex>
    )
}

export const FundSectorExposureSection = (p: { symbol?: string }) => {
    const { donutChartSegments: SectorColors } = useColors()

    const dispatch = useDispatch();
    const sectorExposure = useSnexStore(s => s.funds.byQsi[p.symbol]?.sectorExposure)
    const text = useText(t => t.fundScreen.sectorExposure);
    const [focusedSliceName, setFocusedSliceName] = useState(null);
    const isLoading = useMemo(() => sectorExposure?.loading || sectorExposure?.pristine, [sectorExposure?.loading, sectorExposure?.pristine])

    useEffect(() => { dispatch(GetFundSectorExposure(p.symbol)) }, [p.symbol])

    const handleMouseOver = useCallback((series: any, event: PointerEvent) => { setFocusedSliceName(series.target.name) }, [])
    const handleMouseOut = useCallback((series: any, event: PointerEvent) => { setFocusedSliceName(null) }, [])

    const data: SectorNuggetData[] = useMemo(() => sectorExposure?.data.filter(d => d.exposure).map((d, idx) => ({ ...d, color: SectorColors[idx] || GenerateRandomColor() })), [sectorExposure?.data])

    const donutSize = useAppWindowSizeVariable({ mobile: 150, tablet: 170, sm: 200, md: 220, def: 250 })

    return (
        <ErrorBoundary>
            {!isLoading && !data?.length ? null : <Flex column fullWidth style={{ overflow: 'hidden', margin: '25px 0px' }}>
                <SectionHeader label={text.sectorExposureTitle} />
                {data && <Flex row fullWidth style={{ marginTop: 10 }}>
                    <Flex wrap row justify="flex-start" style={{ width: '55%' }}>
                        {isLoading ? <SectorExposureLoadingGrid /> : data?.map((s, key) => <SectorNugget key={key} sector={s} isActive={s.sector === focusedSliceName || !focusedSliceName} />)}
                    </Flex>
                    <Flex justify="center" align="flex-start" style={{ width: '45%' }}>
                        {isLoading ? <Skeleton height={donutSize} width={donutSize} variant="circular" style={{ marginTop: 20 }} /> : <DonutChart
                            onMouseOut={handleMouseOut}
                            onMouseOver={handleMouseOver}
                            disableAnimation
                            hideLabels
                            data={data?.map(s => ({ name: s.sector, y: s.exposure, color: s.color }))}
                            dataLabelDistance={10}
                            size={donutSize}
                        />}
                    </Flex>
                </Flex>}
            </Flex>}
        </ErrorBoundary>
    );
}

