// @ts-strict-ignore
import { Subscription } from 'lightstreamer-client-web';
import { GetConfig } from 'phoenix/constants';
import { LightstreamerSubscriptionMode } from 'phoenix/constants/LightstreamerSubscriptionMode';
import { SnexLsItemUpdate } from 'phoenix/ls-shim/models/SnexLsItemUpdate';
import { SnexLsSubscription } from 'phoenix/ls-shim/models/SnexLsSubscription';
import { SnexLsSubscriptionListener } from 'phoenix/ls-shim/models/SnexLsSubscriptionListener';
import { GlobalConfig } from 'phoenix/models';
import { StreamingActionNames } from 'phoenix/redux/actions';
import { UserState } from 'phoenix/redux/models';
import {
    GetDisableGainStreaming,
    GetDisableSnexStreaming,
    GetEnableDebugLogging,
    GetShowDebugHud,
    Lightstreamer,
    LSUpstreamSource,
    MutexEnter,
    MutexExit
} from 'phoenix/util';
import { DebugDumpManager } from 'phoenix/util/DebugDumpManager';
import { TicketManager } from 'phoenix/util/TicketManager';

const log = (...args: any[]) => {
    if (GetEnableDebugLogging()) console.log(`[StreamingHelpers]`, ...args);
};

// Subscribe to Lightstreamer with a given set of items and fields
export const ZustandLiveSubscribe = async (
    groupName: string,
    itemNames: string[],
    actionNames: StreamingActionNames,
    fields: string[],
    onUpdate?: (item: any) => any,
    options?: Partial<{
        mode: LightstreamerSubscriptionMode;
        maxFrequencySeconds: number;
        subject: any;
        includeSnapshot: boolean;
        namespace: string;
        allEvents: boolean; // By default, only Error and Update events are fired; set this to true to emit all types
        upstream: LSUpstreamSource;
    }>
) => {
    const me: UserState = (GetConfig() as GlobalConfig).Store.getState().user;

    // check to see if user has delayed data and if so mutate itemNames to use dQuote
    if (me.myInfo?.data?.hasDelayedData === true) {
        itemNames = itemNames?.map((item) => (item.startsWith('quote:') ? `d${item}` : item));
    }

    const unLockMutex = async () => await Promise.all(itemNames?.map((item) => MutexExit(item)));

    await Promise.all(itemNames?.map((item) => MutexEnter(item)));

    options = options || {};
    const emitAllEvents = options?.allEvents;

    const ns = options.namespace || 'default';

    // Check with TicketManager if subscription already exists
    let ticket = TicketManager.PutTicketIfExists(itemNames, ns);
    if (ticket) {
        await unLockMutex();
        log(`Ticket already exists in ${ns} for`, itemNames, ':', ticket);
        return ticket;
    }

    // Otherwise make the whole subscription and report it to the ticket manager
    log(`Subscribing to ${itemNames.join(',')} in group ${groupName} w/ ${actionNames.Subscribing}`);
    const stack = GetShowDebugHud() ? new Error().stack : null;

    const scrubbedItemNames = itemNames?.filter((i) => i.indexOf(' ') === -1);
    if (!scrubbedItemNames.length) {
        log('WARN: No valid items to subscribe');
        await unLockMutex();
        return null;
    }

    const sub = new SnexLsSubscription(options.mode || 'DISTINCT', itemNames, fields);

    const listener = new SnexLsSubscriptionListener();

    if (emitAllEvents) console.log({ type: actionNames.Subscribing, subject: options.subject });

    if (emitAllEvents) {
        listener.onSubscription = () => {
            console.log({ type: actionNames.Subscribed, subject: options?.subject });
        };
    }
    listener.onSubscriptionError = (code: number, message: string) => {
        DebugDumpManager.RecordEvent({ item: itemNames?.join(', '), event: 'Subscription Error', other: `${code} - ${message} - ${JSON.stringify(options)}` });
        console.log({
            type: actionNames.Terminated,
            error: { errorCode: 'LS_SUBSCRIBE_ERROR', errorMessage: `Live data subscription failed with code ${code}`, errorNote: message },
            subject: options?.subject
        });
    };
    if (emitAllEvents) listener.onUnsubscription = () => console.log({ type: actionNames.Unsubscribed, subject: options?.subject });

    listener.onItemUpdate = async (update: SnexLsItemUpdate) => {
        if (GetShowDebugHud()) TicketManager.TickUpdate(itemNames, ns);
        let item = onUpdate ? onUpdate(update) : update;
        if (typeof item.then === 'function') item = await item;
        const action = { type: actionNames.Update, data: item, subject: options?.subject };
        console.log(action);
    };
    listener.onListenEnd = (sub: Subscription) => {
        DebugDumpManager.RecordEvent({
            item: itemNames?.join(', '),
            event: 'Subscription Terminated',
            other: `${sub.getItemGroup()} - ${sub?.getItems()?.join(', ')} - ${JSON.stringify(options)}`
        });
        return { type: actionNames.Terminated };
    };

    sub.setDataAdapter(groupName);
    sub.setRequestedMaxFrequency(options.maxFrequencySeconds || 1);
    sub.setRequestedSnapshot(options.includeSnapshot ? 'yes' : 'no');
    sub.addListener(listener);

    // @ts-ignore
    const esub: ExtendedSubscription = sub;
    esub.namespace = ns;
    esub.stack = stack;
    esub.upstream = options?.upstream || 'snex';

    DebugDumpManager.RecordEvent({
        item: itemNames?.join(', '),
        event: 'New Subscription',
        other: `${groupName} / ${itemNames?.join(', ')} /  ${JSON.stringify(options)}`
    });
    // Report new subscription to TicketManager, and get a new ticket for this consumer
    TicketManager.PutSubscription(esub);
    ticket = TicketManager.PutTicketIfExists(itemNames, ns);

    try {
        const disabled = options.upstream === 'gain' ? GetDisableGainStreaming() : GetDisableSnexStreaming();
        if (!disabled) Lightstreamer.Subscribe(esub);
        else DebugDumpManager.RecordEvent({ item: `<SESSION:${options.upstream}>`, event: 'Debug: Subscribe Disabled', other: 'Not subscribing per debug option' });
        await unLockMutex();
        return ticket;
    } catch (error) {
        log('ERROR', { error, groupName, itemNames, actionNames, options });
        await unLockMutex();
        return null;
    }
};
