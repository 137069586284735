import React, { useState } from 'react'
import { DeleteFundingModal } from './FundingScreen/Transfers/FundingTicketSourceButton'



export const NeilDebugScreen = (): JSX.Element => {

  const [showFundingModal, setShowFundingModal] = useState<boolean>(true)

  return (
    <DeleteFundingModal onClose={() => setShowFundingModal(false)} show={showFundingModal} />
  )
}









