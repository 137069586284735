// @ts-strict-ignore
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import 'components/Modals/DynamicModal/DynamicModal.scss';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import useSidePanelStore from 'store/SidePanel';
import { Avatar } from '@mui/material';
import { ModelTraining } from '@mui/icons-material';
import { ListHeader } from 'components/ListHeader';
import { T } from 'phoenix/assets/lang/T';
import { GetAllOpenOrdersAction, GetWatchlistsAction } from 'phoenix/redux/actions';
import { useDispatch } from 'react-redux';
import { Order } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { IsMutualFundBySymbol } from 'phoenix/util/IsMutualFund';
import { CulledCollapse } from 'components/CulledCollapse/CulledCollapse';
import { WithScrollbar } from 'components/WithScrollbar/WithScrollbar';
import SidePanelOrderRow from './SidePanelOrderRow';
import { Card } from 'components/Card';
import { LoadingSpinner } from 'components/LottieAnimations';
import { WatchlistReorderDropZone } from 'components/Watchlists/WatchlistReorderDropZone';
import { SubList } from 'components/SubList';
import { WatchlistItemReorderDropZone } from 'components/SecurityCell/WatchlistCell/WatchlistItemReorderDropZone';
import { LazyLoadedSecurityCell } from 'components/SecurityCell/LazyLoadedSecurityCell';
import { LiveDataNamespaces } from 'phoenix/constants/LiveDataNamespaces';
import { XS } from 'phoenix/xstream/XS';
import { useRouteLocation } from 'hooks/UseRouteLocation';
import { uniqBy } from 'lodash';
import { useText } from 'phoenix/hooks/UseText';
import './index.scss';
import { ApiData } from 'phoenix/models';
import { CryptoSymbol } from 'phoenix/redux/models/Crypto/CryptoSymbol';
import { useOutsideClickHandler } from 'hooks/UseOutsideClickHandler';
import { OneProNetworkSidebar } from 'components/OneProNetwork/components/sidebar/OneProNetworkSidebar';
import { FeatureFlags } from 'phoenix/constants/FeatureFlags';
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';

function SidePanel() {
    const oneProFlagEnabled = useFeatureFlag(FeatureFlags.OneProNetwork);
    const dispatch = useDispatch();
    const { dockSidePanel } = useSnexStore((s) => s.user.myInfo)?.data || {};
    const { expanded, setExpanded, openOrdersExpanded, setOpenOrdersExpanded } = useSidePanelStore((s) => s) || {};
    const [show, setShow] = useState<boolean>(expanded);
    const [animation, setAnimation] = useState<' slide-in' | ' slide-out' | ''>('');
    const orders: ApiData<Order[]> = useSnexStore((s) => s.orders.openOrders);
    const filteredOrders = uniqBy(
        orders?.data?.filter((o) => ['Working', 'Open', 'NotProcessed', 'Pending'].includes(o.orderStatus)),
        'orderId'
    );
    const ordersLoaded = !orders.pristine && !orders.loading;
    const latestMessage = useSnexStore((s) => s.messages.latest);
    const watchlists = useSnexStore((s) => s.watchlists.all);
    const watchlistsLoaded = !watchlists.pristine && !watchlists.loading;
    const loading = orders.pristine || orders.loading || watchlists.pristine || watchlists.loading;
    const sortedWatchlists = useMemo(() => {
        return watchlists.data.sort((a, b) => a.sequence - b.sequence);
    }, [watchlists.data, watchlists.loading, watchlists.pristine]);
    const [currentLocation] = useRouteLocation();
    const forbidden = currentLocation?.pathname === '/' || currentLocation?.pathname?.includes('/account/');
    const mediaPlayerState = useSnexStore((s) => s.miniplayer);
    const mediaPlayerDocked = mediaPlayerState?.open && ['expanded', 'fullscreen'].includes(mediaPlayerState.size);

    const wrapperRef = useRef(null)
    useOutsideClickHandler(wrapperRef, () => { setExpanded(false) })

    useEffect(() => {
        if (dockSidePanel) dispatch(GetAllOpenOrdersAction({ size: 1000 }));
        dispatch(GetWatchlistsAction());
        setOpenOrdersExpanded(true);

        return () => XS.stopNs(LiveDataNamespaces.SidePanel);
    }, []);

    useEffect(() => {
        if (expanded && !show) setShow(true);
        if (expanded && ordersLoaded && watchlistsLoaded) setAnimation(' slide-in');
        if (!expanded && show) setAnimation(' slide-out');
        if (!expanded && !show) setAnimation('');
    }, [expanded, ordersLoaded, watchlistsLoaded, show]);

    useEffect(() => {
        if (!expanded) XS.stopNs(LiveDataNamespaces.SidePanel);
    }, [expanded]);

    if (forbidden || mediaPlayerDocked || !dockSidePanel || (!filteredOrders.length && !watchlists?.data?.length)) return;

    return (
        <div className={`side-panel-wrapper${animation}`} onAnimationEnd={() => !expanded && setShow(false)} ref={wrapperRef}>
            <div className='side-panel'>
                {show && (
                    <Card>
                        <WithScrollbar hideScrollbar>
                            { oneProFlagEnabled && <OneProNetworkSidebar showFull/> }
                            {filteredOrders.length ? (
                                <>
                                    <ListHeader
                                        collapsable
                                        isCollapsed={!openOrdersExpanded}
                                        title={T((s) => s.orders.openOrders)}
                                        onCollapseExpand={() => setOpenOrdersExpanded(!openOrdersExpanded)}
                                    />
                                    <CulledCollapse eventTag='Side Panel Open Orders' in={openOrdersExpanded}>
                                        <SidePanelOrders orders={filteredOrders} />
                                    </CulledCollapse>
                                </>
                            ) : null}
                            {watchlists?.data?.length && (
                                <>
                                    <div className='header-row'>
                                        <h5>{T((s) => s.portfolioScreen.sidebar.watchlists)}</h5>
                                    </div>
                                    {sortedWatchlists.map((list, listIdx) =>
                                        list?.securities?.length ? (
                                            <Fragment key={listIdx}>
                                                <WatchlistReorderDropZone watchlists={sortedWatchlists} index={listIdx}>
                                                    <SubList id={list.id} key={list.id} title={list.name}>
                                                        <>
                                                            {list.securities.map((d, key) => (
                                                                <WatchlistItemReorderDropZone watchlistId={list.id} index={key} key={key}>
                                                                    <LazyLoadedSecurityCell
                                                                        hideAccountName
                                                                        index={key}
                                                                        key={`${d.symbol}-${key}`}
                                                                        streamingNamespace={LiveDataNamespaces.SidePanel}
                                                                        symbol={d.symbol}
                                                                        variant='watchlist'
                                                                        watchlistId={list.id}
                                                                    />
                                                                </WatchlistItemReorderDropZone>
                                                            ))}

                                                            <WatchlistItemReorderDropZone watchlistId={list.id} index={list.securities.length}>
                                                                <div style={{ height: 5 }}></div>
                                                            </WatchlistItemReorderDropZone>
                                                        </>
                                                    </SubList>
                                                </WatchlistReorderDropZone>
                                                <WatchlistReorderDropZone watchlists={sortedWatchlists} index={sortedWatchlists.length}>
                                                    <div style={{ height: 5 }}></div>
                                                </WatchlistReorderDropZone>
                                            </Fragment>
                                        ) : null
                                    )}
                                </>
                            )}
                        </WithScrollbar>
                    </Card>
                )}
            </div>
            <Avatar className='side-panel-icon positioned' onClick={() => setExpanded(!expanded)} role='button'>
                {loading ? <LoadingSpinner size={25} /> : <ModelTraining />}
            </Avatar>
        </div>
    );
}

export default React.memo(SidePanel);

function SidePanelOrders({ orders }: { orders: Order[] }) {
    const { futuresExpanded, setFuturesExpanded, mutualFundsExpanded, setMutualFundsExpanded, equitiesExpanded, setEquitiesExpanded, cryptosExpanded, setCryptosExpanded } = useSidePanelStore((s) => s) || {};
    const text = useText((t) => t.tradeHistory);
    const [futures, setFutures] = useState<Order[]>([]);
    const [mutualFunds, setMutualFunds] = useState<Order[]>([]);
    const [equities, setEquities] = useState<Order[]>([]);
    const [cryptos, setCryptos] = useState<Order[]>([]);

    useEffect(() => {
        setOrdersFilters();

        // Needs to be in an async function as checking for MutualFund by symbol is async
        async function setOrdersFilters() {
            const orderTypes: { futures: Order[]; mutualFunds: Order[]; equities: Order[]; cryptos: Order[] } = { futures: [], mutualFunds: [], equities: [], cryptos:[] };
            for (const order of orders) {
                if (FuturesSymbol.IsFuturesSymbol(order.symbol)) orderTypes.futures.push(order);
                else if (await IsMutualFundBySymbol(order.symbol)) orderTypes.mutualFunds.push(order);
                else if(CryptoSymbol.IsCryptoSymbol(order.symbol)) orderTypes.cryptos.push(order);
                else orderTypes.equities.push(order);
            }

            setFutures(orderTypes.futures);
            setMutualFunds(orderTypes.mutualFunds);
            setEquities(orderTypes.equities);
            setCryptos(orderTypes.cryptos);
        }
    }, [orders]);

    return orders?.length ? (
        <>
            {equities.length > 0 && (
                <>
                    <SubList title={text.equities} isWatchListItem={false} isCollapsed={equitiesExpanded} onCollapse={() => setEquitiesExpanded(!equitiesExpanded)}>
                        <>
                            {equities.map((order, i) => (
                                <SidePanelOrderRow key={i} order={order} />
                            ))}
                        </>
                    </SubList>
                </>
            )}
            {futures.length > 0 && (
                <>
                    <SubList title={text.futures} isWatchListItem={false} isCollapsed={futuresExpanded} onCollapse={() => setFuturesExpanded(!futuresExpanded)}>
                        <>
                            {futures.map((order, i) => (
                                <SidePanelOrderRow key={i} order={order} />
                            ))}
                        </>
                    </SubList>
                </>
            )}
            {mutualFunds.length > 0 && (
                <>
                    <SubList
                        title={text.mutualFunds}
                        isWatchListItem={false}
                        isCollapsed={mutualFundsExpanded}
                        onCollapse={() => setMutualFundsExpanded(!mutualFundsExpanded)}
                    >
                        <>
                            {mutualFunds.map((order, i) => (
                                <SidePanelOrderRow key={i} order={order} />
                            ))}
                        </>
                    </SubList>
                </>
            )}
              {cryptos.length > 0 && (
                <>
                    <SubList
                        title={text.cryptos}
                        isWatchListItem={false}
                        isCollapsed={cryptosExpanded}
                        onCollapse={() => setCryptosExpanded(!cryptosExpanded)}
                    >
                        <>
                            {cryptos.map((order, i) => (
                                <SidePanelOrderRow key={i} order={order} />
                            ))}
                        </>
                    </SubList>
                </>
            )}
        </>
    ) : null;
}
