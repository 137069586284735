// @ts-strict-ignore
import React, { MouseEvent, ReactElement, ReactNode } from 'react';
import style from './style.module.scss';

interface MenuItemProps {
    ariaLabel?: string;
    disabled?: boolean;
    onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
    children: ReactNode;
}

export const MenuItem = ({ ariaLabel = '', children, disabled, onClick }: MenuItemProps): ReactElement => {
    return (
        <button
            {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
            className={`${style.menuItemWrapper}${disabled ? ` ${style.disabled}` : ''}`}
            onClick={!disabled ? (e) => onClick(e) : null}
            tabIndex={(!disabled || ariaLabel) && 0} // Sometimes aria-label is used as a11y alternative to hover tooltip, so make this focusable
        >
            {children}
        </button>
    );
};
