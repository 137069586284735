import { useSelectedAccountForOrderRequest } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { TradeTicketAccountDropdown } from 'components/AccountDropdown/TradeTicketAccountDropdown';
import { QuantityInputSplit } from 'components/InputSplit/QuantityInputSplit';
import useOptionsOpenClose from 'hooks/useOptionsOpenClose';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { T } from 'phoenix/assets/lang/T';
import { Urls } from 'phoenix/constants';
import { AccountPermittedToOptionsLevel, OptionsLevel } from 'phoenix/constants/OptionsLevels';
import { OptionsOpenClose, TradeActions } from 'phoenix/constants/Trade';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import useOptionsOrderPermitted from 'phoenix/hooks/useOptionsOrderPermitted';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { FuturesAssetClass } from 'phoenix/models/AssetClasses/FuturesAssetClass';
import { useAssetClass } from 'phoenix/models/AssetClasses/useAssetClass';
import { GetSecurityMetadataAction } from 'phoenix/redux/actions';
import { OptionSymbol } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { floatMath, SafeFormat, toMoneyOpt2 } from 'phoenix/util';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Flex } from '../..';
import { TradeCancelHeader } from '../Headers/TradeCancelHeader';
import { TradeInputHeader } from '../Headers/TradeInputHeader';
import { GetTradeTicketQuote } from '../Shared/helpers';
import { ImmediateExecutionAdvisory } from '../Shared/ImmediateExecutionAdvisory';
import {
    AlgoControl,
    BigTradeButton,
    BuyingPowerDisplayV2,
    OptionContractSummaryRow,
    OptionTotalCostDisplay,
    OptionUnderlyingHoldingsDisplay,
    PriceInputSplit,
    TimeInForceSplit
} from '../Shared/TradeFormComponents';
import { TradeInputValidationButton } from '../Shared/TradeInputValidationButton';
import { TradeTicketSection } from '../Shared/TradeTicketSection';
import { OptionsTradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';

interface OptionTradeInputPageProps {
    color?: string;
}

const priceInputFields = ['limitPrice', 'stopPrice'];

const OptionTradeInputPageComponent = (props: OptionTradeInputPageProps) => {
    const dispatch = useDispatch();
    const viewModel = useTradeTicketViewModel<OptionsTradeTicketViewModel>();
    const {
        awaitingConfirmation,
        hasModifyingOrderChanged,
        initialLimitPrice,
        initialQuantity,
        initialStopPrice,
        limitPrice,
        modifyingOrder,
        orderType = 'market',
        quantity,
        setViewModel,
        stopPrice,
        symbol = '',
        timeInForce = 'Day',
        tradeAction = 'Buy'
    } = viewModel;
    // Use quantity input key to force a remount of the quantity input component
    // such as when using "Sell All" button to override user input
    const [quantityInputKey, setQuantityInputKey] = useState<number>(0);
    const isFuture = FuturesSymbol.IsFuturesSymbol(symbol);
    const quote = GetTradeTicketQuote(symbol);
    const text = useText((t) => t);
    const optSym = new OptionSymbol(symbol);
    const osiSym = optSym.toOsiSymbol();
    const logo = useSnexStore((s) => s.logos.bySymbol[symbol]);
    const [marketTimeSegment] = useMarketTimeSegmentV2();

    const ac = useAssetClass(symbol);
    const selectedAccountNumber = useSelectedAccountForOrderRequest(ac);
    const canTradeOptions = useMemo(() => AccountPermittedToOptionsLevel(selectedAccountNumber, OptionsLevel.CoveredCalls), [selectedAccountNumber]);
    const { openClose } = useOptionsOpenClose();
    const actionText = isFuture
        ? T((t) => t.optionListScreen[tradeAction?.toLowerCase() as keyof Omit<Snex1LanguagePack['optionListScreen'], 'floatingButtonText'>])
        : text.tradeTicket.input.options.actionToOpenClose({ action: tradeAction }, openClose || 'Loading');
    const action = !!openClose && openClose !== OptionsOpenClose.Loading ? actionText : 'Loading';
    const meta = useSnexStore((s) => s.securities.bySymbol[symbol]?.metadata?.data) as SecurityMetadata;

    // Futures only - for enforcing tick size
    const [tickSizeError, setTickSizeError] = useState<string[]>([]);
    const fsym = useMemo(() => new FuturesSymbol(optSym.underlyingSymbol), [optSym.underlyingSymbol]);
    const base = useMemo(() => fsym.baseContract, [fsym.baseContract]);
    const { formatPrice, formatQuantity, getPriceFormatInfo } = useAssetClass(symbol);
    const formatOptions = getPriceFormatInfo(meta);
    const { decimalPlaces, moneyFormatOptions, tickSize } = formatOptions;
    const userInfo = useSnexStore((s) => s.user?.myInfo.data);
    const putCall = T((t) => t.general.options.putCallWord(optSym.putCall ? optSym.putCall : 'C'));
    const futuresDispatch = useCallback(() => {
        dispatch(GetSecurityMetadataAction(base));
    }, [base, dispatch]);
    const showDollarSign = isFuture ? false : undefined;
    // When false, app will prompt users to upgrade options level
    // Adjusted options have separate rules regardless of options levels
    // So set this to true and let validation take care of it
    const permittedForEquitiesNonadjusted = useOptionsOrderPermitted({
        accountNumber: selectedAccountNumber,
        action: tradeAction,
        quantity: quantity || initialQuantity || 0,
        symbol
    });
    const permittedForEquities = meta?.isAdjusted ? true : permittedForEquitiesNonadjusted;

    const {
        assetFamily,
        disableTif,
        getErrorProps,
        GetDispatch,
        hasAdvancedRouting,
        headerTitle,
        permitted,
        sharesLabel,
        showEnableOptions,
        step,
        useOptionsBuyingPower
    } = isFuture
        ? {
              assetFamily: FuturesAssetClass.family,
              disableTif: modifyingOrder,
              getErrorProps: (field: 'stopPrice' | 'limitPrice'): { error?: boolean; helperText?: string } =>
                  tickSizeError.includes(field)
                      ? {
                            error: true,
                            helperText: moneyFormatOptions?.useFractional
                                ? text.tradeTicket.input.futures.enforceFractional
                                : text.tradeTicket.input.futures.enforceTickSize(tickSize)
                        }
                      : {},
              GetDispatch: futuresDispatch,
              hasAdvancedRouting: false,
              headerTitle: fsym.noPrefix,
              permitted: true,
              sharesLabel: undefined,
              showEnableOptions: false,
              step: tickSize,
              useOptionsBuyingPower: false
          }
        : {
              assetFamily: EquitiesAssetClass.family,
              disableTif: marketTimeSegment === 'postmarket',
              getErrorProps: (): { error?: boolean; helperText?: string } => ({ error: false, helperText: undefined }),
              GetDispatch: undefined,
              hasAdvancedRouting: userInfo?.hasAdvancedRouting,
              headerTitle: optSym.underlyingSymbol,
              permitted: permittedForEquities,
              sharesLabel: typeof meta?.deliverableCount === 'number' ? text.tradeTicket.input.options.sharesPerContract(meta?.deliverableCount) : '',
              showEnableOptions: selectedAccountNumber && !canTradeOptions,
              step: 0.01,
              useOptionsBuyingPower: true
          };

    const showAlgo = hasAdvancedRouting && marketTimeSegment === 'open';

    useEffect(() => {
        if (GetDispatch) {
            GetDispatch();
        }
    }, [GetDispatch]);

    const handleFieldUpdate = (name: keyof OptionsTradeTicketViewModel, value: OptionsTradeTicketViewModel[keyof OptionsTradeTicketViewModel]) => {
        // Futures only: If it's a price, and the value is divisible by the tick size, it's valid
        if (isFuture && priceInputFields.includes(name)) {
            const isInputValid = !isNaN(value as number) && !floatMath(value as number, tickSize, (v, s) => v % s);
            const newTickSizeError = isInputValid ? [...tickSizeError.filter((x) => x !== name)] : [...tickSizeError, name];
            setTickSizeError(newTickSizeError);
        }

        setViewModel({ [name]: value });
    };

    const handleAccountSelect = useCallback(() => setViewModel({ awaitingConfirmation: false }), [setViewModel]);

    return (
        <Flex column>
            {awaitingConfirmation ? (
                <TradeCancelHeader
                    logo={logo}
                    text={text.tradeTicket.input}
                    showLogo={modifyingOrder}
                    symbol={symbol}
                    title={headerTitle}
                    onCancel={() => setViewModel({ awaitingConfirmation: false, validateResponse: undefined })}
                />
            ) : (
                <TradeInputHeader customActions={[]} subtitle={action} />
            )}
            <TradeTicketSection noBorder style={{ paddingTop: 20, paddingBottom: 10 }}>
                <TradeTicketAccountDropdown
                    assetFamily={assetFamily}
                    balanceType={tradeAction?.toLowerCase()}
                    defaultToFirst={'if-only-one'}
                    disabled={modifyingOrder}
                    isByAssetClass
                    onSelect={handleAccountSelect}
                    skipInitialSelect={modifyingOrder || !!selectedAccountNumber}
                    style={{ marginBottom: 15 }}
                    symbol={symbol}
                    useOptionsBuyingPower={useOptionsBuyingPower}
                />
                {['stop', 'stoplimit'].includes(orderType) && (
                    <PriceInputSplit
                        arrows={isFuture}
                        formatOptions={moneyFormatOptions}
                        formatter={(v: number) => formatPrice(v, meta)}
                        initialValue={initialStopPrice}
                        label={text.tradeTicket.input.stopPrice}
                        onValueChange={(v) => handleFieldUpdate('stopPrice', v)}
                        step={step}
                        showDollarSign={showDollarSign}
                        {...getErrorProps('stopPrice')}
                    />
                )}
                {['limit', 'stoplimit'].includes(orderType) && (
                    <PriceInputSplit
                        arrows={isFuture}
                        formatOptions={moneyFormatOptions}
                        formatter={(v: number) => formatPrice(v, meta)}
                        initialValue={initialLimitPrice}
                        label={orderType === 'limit' ? text.tradeTicket.input.limitPrice : text.tradeTicket.input.stopLimitPrice}
                        onValueChange={(v) => handleFieldUpdate('limitPrice', v)}
                        step={step}
                        showDollarSign={showDollarSign}
                        {...getErrorProps('limitPrice')}
                    />
                )}
                <OptionContractSummaryRow
                    action={action}
                    ask={formatPrice(quote?.ask || 0, meta)}
                    bid={formatPrice(quote?.bid || 0, meta)}
                    expDate={SafeFormat(optSym.expDate, 'MM/dd/yyyy')}
                    last={formatPrice(quote?.last ? quote?.last : tradeAction === TradeActions.Buy ? quote?.ask || 0 : quote?.bid || 0, meta)}
                    putCall={putCall}
                    onCancel={() => setViewModel({ symbol: undefined })}
                    showCloseButton={!modifyingOrder}
                    strike={toMoneyOpt2(meta?.strikePrice || 0, { decimalPlaces, hideCurrencySymbol: isFuture })}
                    symbol={fsym.noPrefix}
                />
                <OptionUnderlyingHoldingsDisplay accountNumber={selectedAccountNumber} />
                <QuantityInputSplit
                    accountNumber={selectedAccountNumber}
                    arrows={true}
                    formatter={formatQuantity}
                    initialValue={initialQuantity}
                    key={`${symbol}-${quantityInputKey}`}
                    label={T((t) => t.general.contracts(0))}
                    liquidateOnSellAll={false}
                    onBuySellAll={() => setQuantityInputKey(quantityInputKey + 1)}
                    onValueChange={(v) => handleFieldUpdate('quantity', v)}
                    showPosition={true}
                    showSellAll={true}
                    step={1} // Used for shares only, 1 share per tick
                    sublabel={sharesLabel}
                    symbol={symbol}
                    tradeAction={tradeAction}
                />
                {orderType !== 'market' && (
                    <TimeInForceSplit
                        disabled={disableTif}
                        text={text.tradeTicket.input}
                        value={timeInForce}
                        onValueChange={(v) => handleFieldUpdate('timeInForce', v)}
                    />
                )}
            </TradeTicketSection>
            <ImmediateExecutionAdvisory
                action={tradeAction}
                limitPrice={limitPrice}
                orderType={orderType}
                quote={quote}
                stopPrice={stopPrice}
                text={text.tradeTicket.input.advisories}
            />
            <TradeTicketSection style={{ paddingBottom: 20 }}>
                {showAlgo && <AlgoControl />}
                <OptionTotalCostDisplay />
                {
                    [
                        {
                            rule: selectedAccountNumber && showEnableOptions,
                            value: (
                                <BigTradeButton fillColor={props.color} onClick={() => window.open(Urls.registration.enableOptions(selectedAccountNumber))}>
                                    {text.tradeTicket.input.options.enableOptionsTrading}
                                </BigTradeButton>
                            )
                        },
                        {
                            rule: selectedAccountNumber && quantity && !permitted,
                            value: (
                                <BigTradeButton fillColor={props.color} onClick={() => window.open(Urls.registration.enableOptions(selectedAccountNumber))}>
                                    {text.tradeTicket.input.options.upgradeOptionsLevel}
                                </BigTradeButton>
                            )
                        },
                        {
                            rule: true,
                            value: <TradeInputValidationButton disabled={tickSizeError.length > 0 || undefined || (modifyingOrder && !hasModifyingOrderChanged)} />
                        }
                    ].find((x) => x.rule)?.value
                }
            </TradeTicketSection>
            <TradeTicketSection noBorder>
                {/* set action to buy to force buying power to show always on equity #108615 */}
                <BuyingPowerDisplayV2 accountNumber={selectedAccountNumber} symbol={osiSym} useOptionsBuyingPower={useOptionsBuyingPower} />
                {/* <TradeDebug trade={trade} /> */}
            </TradeTicketSection>
        </Flex>
    );
};

export const OptionTradeInputPage = React.memo(OptionTradeInputPageComponent);
