export const FeatureFlags = {
    AcatsTransfer: 'ACATS_TRANSFER',
    Funding: 'FEAT_FUNDING',
    FuturesTrading: 'FEAT_TRADE_FUTURES',
    /** @deprecated Please use FuturesTimeSpreads */ FuturesSpreads: 'FEAT_TRADE_FTS',
    FuturesTimeSpreads: 'FEAT_TRADE_FTS',
    MobileFuturesTimeSpreads: 'MOBILE_FEAT_TRADE_FTS',
    FuturesOnboarding: 'FEAT_ONBOARDING_FUTURES',
    MyAccountsIFrame: 'FEAT_MYACCOUNTS_IFRAME',
    Preferred: 'FEAT_PREFERRED',
    onboardingV2: 'FEAT_NEW_TORCH_ONBOARDING',
    FuturesDeepAnalysis: 'FEAT_FUTURES_DEEP_ANALYSIS',
    PremiumEnrollment: 'FEAT_PREMIUM_ENROLLMENT',
    newUserAccountTypeSelect: 'NEW_USER_ACCOUNT_TYPE_SELECT',
    fullyPaidLending: 'FEAT_FULLY_PAID_LENDING',
    asyncFunding: 'FEAT_ENABLE_ASYNC_FUNDING',

    offshoreMutualFundTrading: 'FEAT_TRADE_OFFSHORE_MUTUAL_FUND',
    offshoreMutualFundViewing: 'FEAT_VIEW_OFFSHORE_MUTUAL_FUND',
    marginsInFuturesStatsViewing: 'FEAT_VIEW_MARGINS_IN_FUTURES_STATS',
    OneProNetwork: 'WEB_FEAT_ONE_PRO_NETWORK',

    algoliaAi: 'FEAT_ALGOLIA_AI',

    Mobile: {
        OffshoreMutualFundTrading: 'FEAT_TRADE_OFFSHORE_MUTUAL_FUND_MOBILE',
        OffshoreMutualFundViewing: 'FEAT_VIEW_OFFSHORE_MUTUAL_FUND_MOBILE',
        DeepAnalysis: 'FEAT_MOBILE_DEEP_ANALYSIS',
        WatchlistDetails: 'FEAT_MOBILE_WATCHLIST_DETAILS',
        Onboarding: {
            options: 'FEAT_MOBILE_OPTIONS_ONBOARDING',
            margin: 'FEAT_MOBILE_MARGIN_ONBOARDING'
        },
        AdvancedOptions: 'FEAT_MOBILE_ADVANCED_OPTIONS',
        MobileFullyPaidLending: 'MOBILE_FEAT_FULLY_PAID_LENDING',
        MobileAlerts: 'MOBILE_FEAT_ALERTS',

        ViewCrypto: 'FEAT_CRYPTO_VIEW_MOBILE',
        TradeCrypto: 'FEAT_CRYPTO_TRADE_MOBILE',
        OneProNetwork: 'MOBILE_FEAT_ONE_PRO_NETWORK'
    },
    Web: {
        TickerTape: 'FEAT_WEB_TICKER_TAPE',

        ViewCrypto: 'FEAT_CRYPTO_VIEW_WEB',
        TradeCrypto: 'FEAT_CRYPTO_TRADE_WEB',
        ExperimentalAdvChartTradeTicket: 'FEAT_EXPERIMENTAL_ADV_CHART_TRADE_TICKET'
    }
};
