// @ts-strict-ignore
import { useMemo } from 'react'
import { FeatureFlags } from '../constants/FeatureFlags'
import { GetProductByCode, PremiumProductMetadata, PremiumProducts, ProdCode, ProductCode, TierLevel } from '../constants/PremiumProducts'
import { useSnexStore } from './UseSnexStore'
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag'
import { QualifiedId } from 'phoenix/util/QualifiedId'
import { useActiveAndPendingAccounts } from "phoenix/hooks/useActiveAndPendingAccounts"

export interface TierInfo {
    tierLoading: boolean
    tierPristine: boolean
    isPreferred: boolean
    isTrial: boolean
    isFree: boolean
    isPaid: boolean
    tier: string // e.g., free, trial:sx1, preferred:mi+sx1
    tierPrefix: TierLevel,
    product: ProductCode,
    productMetadata: PremiumProductMetadata,
    permittedForDeepAnalysis: boolean,
    permittedForAlerts: boolean,
    preferredEnabledBySystemFlag: boolean,
    paymentAccountNumber: string
}

const deepAnalysisProducts = new Set([
    ProdCode('trial', PremiumProducts.premium),
    ProdCode('preferred', PremiumProducts.premium),
    ProdCode('preferred', PremiumProducts.miPremiumBundle)
])

export const useTier = (): TierInfo => {
    const preferredAllowed = useSnexStore(s => s.featureFlags.byId[FeatureFlags.Preferred]?.enabled || false)
    const subApiData = useSnexStore(s => s.subscription.mySubscription)
    const sub = useMemo(() => subApiData?.data, [subApiData])
    const premiumEnrollmentFeatureFlag = useFeatureFlag(FeatureFlags.PremiumEnrollment);

    const { product, prefix, productMetadata } = useMemo(() => {
        const none = { product: null, prefix: sub?.tier, productMetadata: null }
        if (!sub?.tier) return none
        const cidx = sub.tier.indexOf(':')
        if (cidx <= 0) return none
        const parts = sub.tier.split(':')
        const meta = GetProductByCode(parts[1])
        return { product: parts[1], prefix: parts[0], productMetadata: meta }
    }, [sub])

    const { accounts } = useActiveAndPendingAccounts();
    const permittedForAlerts = useMemo(() => premiumEnrollmentFeatureFlag ? sub?.tier !== 'free' : true, [premiumEnrollmentFeatureFlag, sub])

    const hasEquityAccounts = accounts?.find(a => QualifiedId.Class(a.accountNumber) === 'equities');

    if (!preferredAllowed || !hasEquityAccounts) {
        return {
            tierLoading: subApiData.loading || subApiData.pristine,
            tierPristine: subApiData.pristine,
            isPreferred: false,
            isTrial: false,
            isFree: true,
            isPaid: false,
            tier: 'free',
            tierPrefix: 'free',
            product: null,
            productMetadata: null,
            preferredEnabledBySystemFlag: false,
            permittedForDeepAnalysis: false,
            permittedForAlerts: false,
            paymentAccountNumber: null
        }
    }

    return {
        tierLoading: subApiData.loading || subApiData.pristine,
        tierPristine: subApiData.pristine,
        isPreferred: sub ? /^preferred/.test(sub.tier) : false,
        isTrial: sub ? /^trial/.test(sub.tier) : false,
        isFree: sub ? sub?.tier === 'free' : true,
        isPaid: sub ? sub?.tier !== 'free' : false,
        tier: sub?.tier,
        tierPrefix: prefix as TierLevel,
        product,
        productMetadata,
        preferredEnabledBySystemFlag: preferredAllowed,
        permittedForDeepAnalysis: premiumEnrollmentFeatureFlag ? deepAnalysisProducts.has(sub?.tier) : true,
        permittedForAlerts,
        paymentAccountNumber: sub?.paymentAccountNumber
    }
}

