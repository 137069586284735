// @ts-strict-ignore
import axios, { AxiosRequestConfig, Method } from 'axios';
import { ApiError } from 'phoenix/models';
import { GetJwt } from 'phoenix/resolvers/JwtStorage';
import { GetEnableDebugLogging, JwtHasExpired, MutexEnter, MutexExit } from 'phoenix/util';
import { ErrorLogger } from 'phoenix/util/ErrorLogger';
import { GenerateFancifulName } from 'phoenix/util/GenerateFancifulName';

class AxiosApiRequest<TResult> {
    method: Method;
    url: string;
    body?: any;
    headers?: any;
    mutexReuseResult: boolean;
    useVulcanToken?: boolean;
    skipToken: boolean;
    mutexKeySelector?: () => string;
    sentryLogging: boolean;

    constructor(method: Method, url: string, options?: AxiosRequestConfig<any>) {
        this.method = method;
        this.url = url;
        this.body = options?.data;
        this.headers = options?.headers;
        this.mutexKeySelector = null;
        this.mutexReuseResult = true;
        this.useVulcanToken = false;
        this.skipToken = false;
        this.sentryLogging = false;
    }

    // Add mutual exclusion (mutex) per selected key. Each request gets a key. If a request with the same key is already
    // pending, the request will sleep until the first returns. If reuseResult is true, then the result of the first will
    // be used immediately for the others. Key selector is executed at runtime to minimize the chances of a race. If no
    // key selected is provied, then the request's URL is used as the mutex key
    withMutex = (keySelector?: string | (() => string), reuseResult = true) => {
        this.mutexKeySelector = (() => {
            if (!keySelector) return () => (Array.isArray(this.url) ? this.url.join(';') : this.url);
            else if (typeof keySelector === 'string') return () => keySelector;
            else return keySelector;
        })();
        this.mutexReuseResult = reuseResult;
        return this;
    };

    withLogging = () => {
        this.sentryLogging = true;
        return this;
    };

    withVulcanToken = (withVulcanToken = false) => {
        this.useVulcanToken = withVulcanToken;
        return this;
    };

    // Normally, requets will reach out to the API to get the current user's token to include in the request
    // If you call this, then it won't do that
    withoutToken = () => {
        this.skipToken = true;
        return this;
    };

    mockRun = async (mockedResult: TResult) => {
        return await new Promise((resolve) => {
            setTimeout(() => {
                return resolve(mockedResult);
            }, 3000);
        });
    };

    run = async (): Promise<TResult> => {
        try {
            const sentryLogging = this.sentryLogging;
            const mutexKey = this.mutexKeySelector === null ? null : this.mutexKeySelector();
            if (mutexKey) {
                const shared = await MutexEnter(this.mutexKeySelector());
                if (this.mutexReuseResult && shared !== null) {
                    if (GetEnableDebugLogging()) console.log(`Using raced result for ${mutexKey}`);
                    return shared as TResult;
                }
            }
            /* populate jwt if we have it. could be null for anon endpoints */
            const jwt = (() => {
                try {
                    return GetJwt(this.useVulcanToken ? 'vulcan' : 'snex');
                } catch (err) {
                    ErrorLogger.RecordError(err);
                    return null;
                }
            })();
            let headers = { ...this.headers };
            /* add bearer if not skiptoken and jwt exists */
            if (!this.skipToken && !!jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` };

            const handle = async (url: string) => {
                const logMessage = `[HTTP] ${this.method}   > ${url} / ${GenerateFancifulName(jwt)}`;
                if (GetEnableDebugLogging()) console.log(logMessage);
                if (sentryLogging) ErrorLogger.RecordMessage(logMessage, url);
                const start = new Date().getTime();
                const { data }: { data: TResult } = await axios({ method: this.method, url, data: this.body, headers });
                const responseLogMessage = `[HTTP] ${this.method} <   ${url} ${new Date().getTime() - start}ms`;
                if (GetEnableDebugLogging()) console.log(responseLogMessage);
                if (sentryLogging) ErrorLogger.RecordMessage(responseLogMessage, url);
                if (mutexKey) MutexExit(mutexKey, data);
                return data;
            };

            return await handle(this.url);
        } catch (error) {
            const errorLogMessage = `[HTTP] ${this.method} XXX ${this.url}: ${error}`;
            if (GetEnableDebugLogging()) console.log(errorLogMessage, error);
            ErrorLogger.RecordError(error, this.url, {info: {method: this.method, url: this.url}});
            const errorJson = (<XMLHttpRequest>error?.request)?.responseText;
            try {
                throw errorJson && JSON.parse(errorJson);
            } catch (x) {
                console.warn('Failed to parse error response', x, ` to request ${this.url}; original error:`, errorJson || error);
                throw error;
            }
        }
    };
}

const Axios_ApiGet = <TResult>(url: string, options?: AxiosRequestConfig<any>): AxiosApiRequest<TResult> => new AxiosApiRequest<TResult>('GET', url, options);
const Axios_ApiDelete = <TResult>(url: string, options?: AxiosRequestConfig<any>): AxiosApiRequest<TResult> => new AxiosApiRequest<TResult>('DELETE', url, options);
const Axios_ApiPost = <TResult>(url: string, options?: AxiosRequestConfig<any>): AxiosApiRequest<TResult> => new AxiosApiRequest<TResult>('POST', url, options);
const Axios_ApiPut = <TResult>(url: string, options?: AxiosRequestConfig<any>): AxiosApiRequest<TResult> => new AxiosApiRequest<TResult>('PUT', url, options);

export const SnexAxios = {
    /* actions */
    ApiGet: Axios_ApiGet,
    ApiDelete: Axios_ApiDelete,
    ApiPost: Axios_ApiPost,
    ApiPut: Axios_ApiPut
};
